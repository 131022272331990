import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { capitalize, get } from 'lodash';

import BaseButton from '../../components/_ui/BaseButton/BaseButton';

import { antNotification } from '../../MainUtils';
import { EntityConstants } from '../constants/Constants';
import { getVectors } from '../../projectFlow/selectors/selectors';
import { listOfIssuesFromStore } from '../../projectFlow/reducers/issues/IssuesSlicer';
import { ProjectTypeCommonConstants } from '../../projectFlow/constants/Constants';
import { checkEntityPerm } from '../actions/entityPermActions';
import { entityDelete } from '../actions/entityActions';
import { setFlagNotSaveData, setFlagOpenConfirm } from '../../components/un-save-data-confirm/unSaveDataSlicer';
import useGetUserPerm from '../../permissions/hooks/useGetUserPerm';
import { setDeletingDocUuid } from '../../projectFlow/reducers/slicers/quillSlicer';

function HardDeleteEntityContainer({
  isAllowDelete,
  actionForEntity,
  antNotificationSuccess = false,
  checkHardDelete,
  className,
  block,
  component,
  customConstants,
  disabled = false,
  noHideBtn,
  entityType,
  entityUUID,
  type,
  hideLabel,
  hideTooltip = false,
  iconSize = 0.7,
  name = '',
  typeInLabel = '',
  partitionType,
  notSavedData,
  setNotSavedData,
  item = '',
  shape,
  sizeBtn,
  styleBtn,
  tooltipPlacement,
  typeBtn,
  doubleConfirm,
  removeDataWarning,
  updateTestSuiteData,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showRemoveButton, setShowRemoveButton] = useState(false);

  const { getPerms } = useGetUserPerm({
    entityUUID,
    partitionType,
    needCheckPerm: false,
  });

  const {
    isOpenConfirm,
  } = useSelector((state) => get(state, 'projectManagementService.project.unSaveData', {}));

  const checkDeletePerm = async () => {
    if (entityUUID) {
      const data = {
        entity_uuid: entityUUID,
        perm: ['delete'],
        partition: partitionType,
      };
      // console.log('crdt data', data)
      const perms = await getPerms(data);
      // console.log('crdt perms', perms)
      setShowRemoveButton(perms.delete);
      // dispatch(checkEntityPerm(data)).then((res) => setShowRemoveButton(res));
    }
  };

  const listOfIssues = useSelector(listOfIssuesFromStore);
  const listOfVectors = useSelector(getVectors);

  const getEntities = () => {
    switch (entityType) {
      case 'issue':
        return listOfIssues;
      case 'vector':
        return listOfVectors;
      default:
        return [];
    }
  };

  const entitiesFromStore = getEntities();

  const removeEntityFromStore = (entityType, entities, uuid) => {
    const getType = () => {
      switch (entityType) {
        case 'vector':
          return ProjectTypeCommonConstants.GET_PROJECT_VECTORS_SUCCESS;
        case 'issue':
          return ProjectTypeCommonConstants.LIST_OF_ISSUES_SUCCESS;
        case 'projectIdea':
          return ProjectTypeCommonConstants.LIST_OF_PROJECT_IDEAS_SUCCESS;
        default:
          return null;
      }
    };

    return { type: getType(), payload: [...entities].filter((item) => item.uuid !== uuid) };
  };

  const clearStoreFromEntity = () => {
    if (entitiesFromStore.length > 0) {
      dispatch(removeEntityFromStore(entityType, entitiesFromStore, entityUUID));
    }
  };

  const deleteRequest = async (UUID, newParam) => {
    // console.log('in deleteRequest');
    dispatch(setFlagOpenConfirm(false));
    dispatch(setFlagNotSaveData(false));

    if(newParam.entity_type === "testCase"){
      updateTestSuiteData()
    }

    const data = type === 'affix' ? { affix_uuid: UUID, affix_type: entityType } : newParam;
    const endpoint = type === 'affix' ? 'entity/affix/delete' : 'entity/delete';

    const constants = customConstants || [
      EntityConstants.HARD_DELETE_PERM_REQUEST,
      EntityConstants.HARD_DELETE_PERM_SUCCESS,
      EntityConstants.HARD_DELETE_PERM_FAILURE,
    ];

    const options = {
      partition: partitionType,
      onSuccess: (_, __, status) => {
        if (status !== 200) {
          antNotification('error', t('notifications.text.error.base', 'Error'));
        } else {
          if (setNotSavedData) {
            setNotSavedData?.({
              ...notSavedData,
              notSaved: false,
            });
          }
          clearStoreFromEntity();

          if (antNotificationSuccess) {
            antNotification('success', t('wms.noun.success', 'Success'));
          }

          if (actionForEntity) {
            actionForEntity(item, entityUUID);
          }
        }
      },
    };

    return dispatch(entityDelete({
      endpoint,
      constants,
      data,
      options,
    })).catch(() => antNotification('error', 'Access denied!'));
  };

  const hardDeleteEntity = async () => {   
    const newParams = {
      entity_type: entityType,
    };

    if (type !== 'affix') {
      if (typeof entityUUID === 'string') {
        newParams.entity_uuid = entityUUID;
      } else {
        newParams.entity_uuids = entityUUID;
      }
    }
    await deleteRequest(entityUUID, newParams); 
  };

  const getRemovingText = () => capitalize(
    t(`wms.delete_entity.${typeInLabel || name}`),
  );

  const initFunc = (uuid) => {
    if (!isNil(isAllowDelete)) {
      setShowRemoveButton(isAllowDelete);
      return;
    }
    if (checkHardDelete || isNil(checkHardDelete)) {
      checkDeletePerm();
    } else {
      setShowRemoveButton(true);
    }
  };

  const cancelFunc = () => { 
    dispatch(setDeletingDocUuid(null));
  }

  const showSecondConfirm = () => {
    Modal.confirm({
      title: capitalize(
        t('wms.modals.headers.removing_process', 'removing process'),
      ),
      width: '600px',
      content: capitalize(t('wms.delete_entity.make_sure')),
      okText: capitalize(
        t('wms.buttons.removing_process.yes', 'yes, I am'),
      ),
      okType: 'danger',
      cancelText: capitalize(
        t('wms.buttons.removing_process.no', 'no, I\'m not'),
      ),
      open: false,
      centered: true,
      maskClosable: true,
      okButtonProps: { className: 'btnDanger' },
      cancelButtonProps: { className: 'btnPrimary-outline' },
      onCancel() {
        cancelFunc()
      },
      onOk() {
        hardDeleteEntity().then();
      },
    });
  };

  const showConfirm = () => {
    Modal.confirm({
      title: capitalize(
        t('wms.modals.headers.removing_process', 'removing process'),
      ),
      width: '600px',
      content: `${getRemovingText()}?`,
      okText: capitalize(
        t('wms.buttons.removing_process.yes', 'yes, I am'),
      ),
      okType: 'danger',
      cancelText: capitalize(
        t('wms.buttons.removing_process.no', 'no, I\'m not'),
      ),
      open: false,
      centered: true,
      maskClosable: true,
      okButtonProps: { className: 'btnDanger' },
      cancelButtonProps: { className: 'btnPrimary-outline' },
      onCancel() {
        cancelFunc()
      },
      onOk() {
        if (doubleConfirm) {          
          return showSecondConfirm();
        }           
        hardDeleteEntity().then();
      },
    });
  };

  const showRemoveDataWarningConfirm = () => {
    Modal.confirm({
      title: capitalize(
        t('wms.modals.headers.removing_process', 'removing process'),
      ),
      width: '600px',
      content: 'Your changes may be lost, do you want to continue?',
      okText: 'Continue',
      okType: 'warning',
      cancelText: 'Cancel',
      open: false,
      centered: true,
      maskClosable: true,
      okButtonProps: { className: 'btnWarning' },
      cancelButtonProps: { className: 'btnPrimary-outline' },
      onCancel() {
        cancelFunc()
      },
      onOk() {
        showConfirm();
      },
    });
  };

  useEffect(() => {
    initFunc(entityUUID);
  }, [entityUUID, isAllowDelete]);

  const renderIcon = <Icon path={mdiTrashCanOutline} size={iconSize} />;

  if (showRemoveButton || noHideBtn) {
    return (
      <BaseButton
        id={`deleteButton${entityUUID}`}
        onClick={() => {
          if (entityType === 'projectIdea' || entityType === 'projectDocument') { 
            dispatch(setDeletingDocUuid(entityUUID));
          }     
          if (removeDataWarning) {                  
            return showRemoveDataWarningConfirm();
          }          
          return showConfirm();
        }}
        size={sizeBtn}
        shape={shape}
        disabled={disabled || (noHideBtn && !showRemoveButton) || isOpenConfirm}
        style={styleBtn}
        type={typeBtn}
        block={block}
        className={className || 'btnDanger-outline line-height-inherit'}
      >
        {component || (
          <>
            {(hideLabel && !hideTooltip) ? (
              <Tooltip
                className="line-height-inherit"
                id={`delete_button_${entityUUID}`}
                placement={tooltipPlacement}
                title={capitalize(t('wms.tooltips.remove', 'remove'))}
              // getPopupContainer={() => document.getElementById(`delete_button_${entityUUID}`)}
              >
                {renderIcon}
              </Tooltip>
            ) : renderIcon}

            {!hideLabel && <span className="ml-1">{capitalize(t(`wms.buttons.remove.${name}`, `remove ${name}`))}</span>}
          </>
        )}
      </BaseButton>
    );
  }
  return '';
}

HardDeleteEntityContainer.propTypes = {
  actionForEntity: PropTypes.func,
  antNotificationSuccess: PropTypes.bool,
  checkHardDelete: PropTypes.bool,
  className: PropTypes.string,
  component: PropTypes.element,
  entityType: PropTypes.string,
  entityUUID: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  hideLabel: PropTypes.bool,
  iconSize: PropTypes.number,
  name: PropTypes.string,
  partitionType: PropTypes.string,
  shape: PropTypes.string,
  sizeBtn: PropTypes.string,
  styleBtn: PropTypes.object,
  tooltipPlacement: PropTypes.string,
  typeBtn: PropTypes.string,
  disabled: PropTypes.bool,
};

export default HardDeleteEntityContainer;
