import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Col, Tooltip } from 'antd';
import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import { capitalize } from 'lodash';

function DetailedReportInfoActivitiesTable({
  activities = [],
}) {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const currentIssueList = useMemo(() => (activities
    .slice(currentOffset, currentOffset + pageLimit)), [activities, currentOffset, pageLimit]);

  const columns = useMemo(() => ([
    {
      dataIndex: 'text',
      title: capitalize(t('wms.table.headers.title', 'title')),
      render: (cell) => <Tooltip title={cell}>{cell.length > 20 ? `${cell.slice(0, 20)}...` : cell}</Tooltip>,
    },
    {
      dataIndex: 'time',
      title: t('wms.reports.stats.personal_hours', 'personal_hours'),
      render: (cell) => <span>{cell}</span>,
    },
  ]), [activities]);

  const onPageChange = ({ pageLimit: newPageLimit, currentPage: newCurrentPage, offset }) => {
    setCurrentPage(newCurrentPage);
    setCurrentOffset(offset);
    setPageLimit(newPageLimit);
  };

  const init = () => {
    setTotalCount(activities.length);
    setCurrentOffset(0);
    setCurrentPage(1);
  };

  useEffect(() => {
    init();
  }, [activities]);

  const remotePagination = {
    sizePerPageList: [5, 10, 25, 50],
    defaultPageLimit: 5,
    totalSize: totalCount,
    page: currentPage,
    onChange: onPageChange,
    // onSizePerPage: onSizePerPageTable,
  };

  return (
    <Col span={24} className="collapsedTable-table">
      <BaseTableWithPagination
        pageLimit={pageLimit}
        changePageLimit={setPageLimit}
        useCustomPagination
        columns={columns}
        data={currentIssueList}
        total={get(remotePagination, 'totalSize')}
        pageSizeOptions={get(remotePagination, 'sizePerPageList')}
        disablePagination={get(remotePagination, 'totalSize') <= get(remotePagination, 'defaultPageLimit')}
        newCurrentPage={get(remotePagination, 'page')}
        defaultPageLimit={get(remotePagination, 'defaultPageLimit')}
        getPaginationOptions={get(remotePagination, 'onChange')}
        rowKey="id"
        rowClassName="universal_table_row"
        paginationSize="small"
        headerRowClassName="universal_header_table_row"
      />
    </Col>
  );
}

DetailedReportInfoActivitiesTable.propTypes = {
  activities: PropTypes.array,
};

export default DetailedReportInfoActivitiesTable;
