import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrCreateUserBoard } from '../actions/userBoardActions';
import { getOrCreateUserBoardStorage } from '../actions/userBoardStorageActions';

import { getIsFetchingUserBoard } from '../selectors/userBoardSelector';
import { getIsFetchingUserBoardStorage, getUserBoardStorage, getUserBoardStorageUUID } from '../selectors/userBoardStorageSelector';

import { CLEAR_USER_BOARD } from '../slices/userBoardSlice';

const useUserBoard = ({ projectUUID, partition, actor }) => {
  const dispatch = useDispatch();

  const userBoardStorage = useSelector(getUserBoardStorage);
  const userBoardStorageUUID = useSelector(getUserBoardStorageUUID);
  const isFetchingUserBoardStorage = useSelector(getIsFetchingUserBoardStorage);

  const isFetchingUserBoard = useSelector(getIsFetchingUserBoard);

  const onReloadUserBoard = async () => {
    if (userBoardStorageUUID
      && userBoardStorage?.parent === projectUUID
      && partition
      && actor
    ) {
      dispatch(CLEAR_USER_BOARD);
      await dispatch(getOrCreateUserBoard({
        parent: userBoardStorageUUID,
        partition: partition,
        actor: actor
      }));
    }
  }

  useEffect(() => {
    if (projectUUID && partition && actor) {
      dispatch(getOrCreateUserBoardStorage({
        actor: actor,
        parent: projectUUID,
        partition: partition
      }));
    }
  }, [projectUUID, partition, actor]);

  useEffect(() => {
    if (userBoardStorageUUID && userBoardStorage?.parent === projectUUID && partition && actor) {
      dispatch(getOrCreateUserBoard({
        parent: userBoardStorageUUID,
        partition: partition,
        actor: actor
      }));
    }
  }, [JSON.stringify(userBoardStorage), partition, actor])

  return {
    onReloadUserBoard,
    isFetchingUserBoard: isFetchingUserBoard && isFetchingUserBoardStorage,
  };
};

export default useUserBoard;
