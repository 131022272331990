/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  commentData: [],
  commentIsLoading: false,
  commentError: '',

  commentListData: [],
  commentListIsLoading: false,
  commentListError: false,
};

const workConfigCommentSlice = createSlice({
  name: 'workConfigComment',
  initialState,
  reducers: {
    CREATE_WORK_CONFIG_COMMENT_REQUEST: (state) => {
      state.commentIsLoading = true;
    },
    CREATE_WORK_CONFIG_COMMENT_SUCCESS: (state, action) => {
      state.commentIsLoading = false;
      state.commentData = action.payload;
      state.commentError = '';
    },
    CREATE_WORK_CONFIG_COMMENT_FAILURE: (state, action) => {
      state.commentIsLoading = false;
      state.commentData = [];
      state.commentError = action.payload;
    },

    GET_WORK_CONFIG_COMMENT_LIST_REQUEST: (state) => {
      state.commentIsLoading = true;
    },
    GET_WORK_CONFIG_COMMENT_LIST_SUCCESS: (state, action) => {
      state.commentIsLoading = false;
      state.commentData = action.payload;
      state.commentError = '';
    },
    GET_WORK_CONFIG_COMMENT_LIST_FAILURE: (state, action) => {
      state.commentIsLoading = false;
      state.commentData = [];
      state.commentError = action.payload;
    },
  },
});

export const workConfigCommentActions = workConfigCommentSlice.actions;

export default workConfigCommentSlice.reducer;
