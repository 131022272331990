import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { prop, propOr } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';

import DocView from '../../components/docView&ideas/docView/DocView';
import { getDocumentContent } from '../../selectors/selectors';
// import { getDocumentStateRequest, setDocumentContent } from '../../actions/DocumentsActions';

// import { getStateID } from '../../components/docView&ideas/helpers/statesAndPatches/idHelpers';
// import buildContent from '../../components/docView&ideas/helpers/statesAndPatches/buildContent';

function DocViewWrapper({
  currentSelected,
  defaultPartition,
  showEditor,
  // setShowEditor,
}) {
  // const dispatch = useDispatch();
  const documentContent = useSelector(getDocumentContent);
  // const docFetching = useSelector(getProjDocFetching);

  const [openComments, setOpenComments] = useState(false);

  // const docUUID = prop('uuid', currentSelected);
  const docParams = prop('params', currentSelected);
  const hasOptionalData = propOr(false, 'optional_data', currentSelected);
  const docLoading = !showEditor;

  // const getDocumentState = async (uuid, stateID) => {
  //   const optionalDataConfig = {
  //     uuid,
  //     stateID,
  //     partition: defaultPartition,
  //   };

  //   // console.log('stateID for read:', stateID);

  //   if (stateID && stateID !== '0') {
  //     return dispatch(getDocumentStateRequest(optionalDataConfig));
  //   }

  //   return null;
  // };

  // const initFunc = async () => {
  //   console.log('hasOptionalData', hasOptionalData)
  //   if (hasOptionalData) {
  //     // console.log('HAS OPTIONAL DATA');
  //     const currentActualState = prop('actualState', docParams);
  //     const stateID = getStateID(currentActualState);
  //     const type = prop('entity_type', currentSelected);

  //     // console.log('ACTUAL STATE:', currentActualState);

  //     const state = await getDocumentState(docUUID, stateID);
  //     const checkIsOldStructure = !Array.isArray(state);
  //     if (checkIsOldStructure) {
  //       const { text, patches } = state || {};
  //       dispatch(setDocumentContent({startHtmlString: text,patches}));
  //     } else {
  //       console.log('crdt ------>>>>>> new structure');
  //     }

  //     // const [buildedContent] = buildContent(text, patches, type);
  //     // console.log('Builded Content:', buildedContent);
     
  //   } else dispatch(setDocumentContent(null));
  // };

  // useEffect(() => {
  //   if (docUUID) {
  //     initFunc().then(() => setShowEditor(true));
  //   }
  // }, [docUUID]);

  return (
    <DocView
      CKEditorReadOnly
      closeBtns
      defaultPartition={defaultPartition}
      description={hasOptionalData ? documentContent : prop('text', docParams)}
      disableData
      disableEntityChange
      disableInputs
      docFetching={docLoading}
      entityDocType={prop('entity_type', currentSelected)}
      hideElements={['options']}
      isDisabled
      isSidePanelOpen={openComments}
      lifeTime={prop('lifeTime', docParams)}
      readOnlySpreadsheet
      setIsSidePanelOpen={setOpenComments}
      showEditor={showEditor}
      title={prop('title', docParams)}
    />
  );
}

DocViewWrapper.propTypes = {
  currentSelected: PropTypes.object,
  defaultPartition: PropTypes.string,
  showEditor: PropTypes.bool,
  setShowEditor: PropTypes.func,
};

export default DocViewWrapper;
