import { get, head, isNil } from 'lodash';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import MainCollapseSection from '../../../containers/qaView/MainCollapseSection';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import {
  getAllTestCasesInCycle, getTestCycle, listTestCaseInCycleFromArchive, oneTestCycleArchive,
} from '../../../selectors/selectors';
import ListOfTestCases from '../case/ListOfTestCases';
import ListOfTestCycles from './ListOfTestCycles';
import MainTestCycles from './MainTestCycles';

export default function CycleSection({
  title,
  id,
  isArchive = false,
  configsCase,
  totalCountCase,
  setNewConfigCaseCallback,
  deleteCycle,
  createEntitiesFromJSON,
  totalCountCycle,
  setNewConfigCallback,
  selectTestCycleCallback,
  config,
  classNameBtn,
  typeCard,
  defaultCollapseState,
  partition, mainClassName, customFilterStyle,
}) {
  const { changeQAHistory } = useContext(BugTrackerContext);

  const dataTable = useSelector(isArchive
    ? listTestCaseInCycleFromArchive
    : getAllTestCasesInCycle);

  const oneTestCycle = useSelector(isArchive ? oneTestCycleArchive : getTestCycle);

  const [removeCaseInCycleFromBreadCrumb, setRemoveCaseInCycleFromBreadCrumb] = useState(false);

  const disabledBtnImport = isArchive || (!isNil(oneTestCycle)
  && oneTestCycle.length !== 0
  && oneTestCycle[0].params.status !== 'unexecuted'
  && oneTestCycle[0].params.status !== 'in progress');

  return (
    <MainCollapseSection
      titleCollapseBtn={title}
      id={id}
      typeCard={typeCard}
      classNameBtn={classNameBtn}
      splitSection={!!get(oneTestCycle, '[0].uuid')}
      changeQAHistory={changeQAHistory}
      defaultCollapseState={defaultCollapseState}
      firstComponent={(
        <ListOfTestCycles
          title={title}
          isArchive={isArchive}
          id={isArchive ? 'archive' : 'cycle'}
          config={config}
          selectTestCycle={selectTestCycleCallback}
          totalCount={totalCountCycle}
          setNewConfigCallback={setNewConfigCallback}
          deleteCycle={deleteCycle}
          createEntitiesFromJSON={createEntitiesFromJSON}
        />
        )}
      secondComponent={(
        <MainTestCycles
          isArchive={isArchive}
          deleteCycle={deleteCycle}
          partition={partition}
        />
        )}
      thirdComponent={(
        <ListOfTestCases
          disabledBtnImport={disabledBtnImport}
          dataTable={dataTable}
          parentData={head(oneTestCycle)}
          mainClassName={mainClassName}
          customFilterStyle={customFilterStyle}
          selectedCycle={get(oneTestCycle, '[0].uuid')}
          typeOfParent={isArchive ? 'cycleArchive' : 'cycle'}
          isArchive={isArchive}
          colorTable={isArchive ? 'rgb(226, 200, 8)' : '#1890ff'}
          removeCaseInCycleFromBreadCrumb={removeCaseInCycleFromBreadCrumb}
          setRemoveCaseInCycleFromBreadCrumb={setRemoveCaseInCycleFromBreadCrumb}
          totalCount={totalCountCase}
          config={configsCase}
          setNewConfigCallback={setNewConfigCaseCallback}
        />
          )}
    />
  );
}
