import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Flex, Row, Tooltip,
} from 'antd';

import './userList.scss';
import { capitalize } from 'lodash';
import CircleActorAvatar54origins from '../../54origins/components/CircleActorAvatar54origins';

export default function UserItem({
  onClickCallback,
  label = '',
  uuid = '',
  isSelected = false,
}) {
  const getPartOfText = (text, size) => (text?.length > size ? (
    <Tooltip
      title={text}
      className="user-item-tooltip ml-2"
    >
      {`${text.slice(0, size)}...`}
    </Tooltip>
  ) : <span className="ml-2">{text}</span> || '');

  const initials = label.split(' ')
    .map((item) => item[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);

  return (
    <Row
      id='userItem'
      align="center"
      onClick={() => onClickCallback(uuid)}
      style={{
        background: isSelected ? '#afe7ff' : '',
      }}
      className="w-full h-full user-item-for-list pl-3 pr-3"
    >
      <Col span={6}>
        <CircleActorAvatar54origins
          name={initials}
          actorUUID={uuid}
          size={35}
        />
      </Col>
      <Col span={18}>
        {getPartOfText(label, 20)}
      </Col>
    </Row>
  );
}

UserItem.propTypes = {
  onClickCallback: PropTypes.func,
  label: PropTypes.string,
  uuid: PropTypes.string,
  isSelected: PropTypes.bool,
};
