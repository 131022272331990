import { head } from "ramda";
import { ProjectTypeCommonConstants } from "../../constants/Constants";

const initialState = {
  cookBookEntity: {},
  cookBookPublicEntityUuid: "",
  cookBookPublicEntityUuidUser: "",
  listTemplateCookBook: [],
  listTemplatePublicCookBook: [],
  customTemplate: [],
  fetching: true,
  fetchingCommon: true,
  fetchingPublic: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // ---------------- GET_COOK_BOOK_ENTITY ----------------
    case ProjectTypeCommonConstants.GET_COOK_BOOK_ENTITY_REQUEST:
      return {
        ...state,
        fetchingCommon: true,
      };
    case ProjectTypeCommonConstants.GET_COOK_BOOK_ENTITY_SUCCESS:
      return {
        ...state,
        cookBookEntity: payload?.uuid,
        fetchingCommon: false,
      };
    case ProjectTypeCommonConstants.GET_COOK_BOOK_ENTITY_FAILURE:
      return {
        ...state,
        fetching: false,
        fetchingPublic: false,
        fetchingCommon: false,
        error: payload,
      };
    // ----------------- GET_COOK_BOOK_ENTITY_PUBLIC---------------
    case ProjectTypeCommonConstants.GET_COOK_BOOK_ENTITY_PUBLIC_REQUEST:
      console.log("GET_COOK_BOOK_ENTITY_PUBLIC_REQ", payload);
      return {
        ...state,
        fetchingPublic: true,
      };
    case ProjectTypeCommonConstants.GET_COOK_BOOK_ENTITY_PUBLIC_SUCCESS:
      console.log("GET_COOK_BOOK_ENTITY_PUBLIC_SUCCESS", payload);
      return {
        ...state,
        cookBookPublicEntityUuid: payload?.uuid,
        fetchingPublic: false,
      };

    case ProjectTypeCommonConstants.GET_COOK_BOOK_ENTITY_PUBLIC_FAILURE:
      console.log("GET_COOK_BOOK_ENTITY_PUBLIC_FAILURE", payload);
      return {
        ...state,
        fetchingPublic: false,
      };
    // --------------------------------
    case ProjectTypeCommonConstants.GET_COOK_BOOK_ENTITY_PUBLIC_USER_SUCCESS:
      return {
        ...state,
        cookBookPublicEntityUuidUser: payload?.uuid,
        fetchingPublic: false,
      };
    // ------------------ GET_LIST_COOK_BOOK --------------
    case ProjectTypeCommonConstants.GET_LIST_COOK_BOOK_REQUEST:
      return {
        ...state,
        fetchingPublic: true,
      };
    case ProjectTypeCommonConstants.GET_LIST_COOK_BOOK_SUCCESS:
      return {
        ...state,
        listTemplateCookBook: payload,
        fetchingPublic: false,
      };
    case ProjectTypeCommonConstants.GET_LIST_COOK_BOOK_FAILURE:
      return {
        ...state,
        fetching: false,
        fetchingPublic: false,
        fetchingCommon: false,
        error: payload,
      };
    // ------------------- GET_LIST_COOK_BOOK_PUBLIC -------------
    case ProjectTypeCommonConstants.GET_LIST_COOK_BOOK_PUBLIC_REQUEST:
      return {
        ...state,
        fetchingPublic: true,
      };
    case ProjectTypeCommonConstants.GET_LIST_COOK_BOOK_PUBLIC_SUCCESS:
      return {
        ...state,
        listTemplatePublicCookBook: payload,
        fetchingPublic: false,
      };
    case ProjectTypeCommonConstants.GET_LIST_COOK_BOOK_PUBLIC_FAILURE:
      return {
        ...state,
        fetching: false,
        fetchingPublic: false,
        fetchingCommon: false,
        error: payload,
      };
    // ------------- CLEAR_COOK_BOOK_STORE-------------------
    case ProjectTypeCommonConstants.CLEAR_COOK_BOOK_STORE:
      return {
        ...state,
        fetching: true,
        fetchingCommon: true,
        fetchingPublic: true,
        listTemplateCookBook: [],
        listTemplatePublicCookBook: [],
        customTemplate: [],
        cookBookEntity: {},
        cookBookPublicEntityUuid: "",
        cookBookPublicEntityUuidUser: "",
      };
    // ------------- SET_CUSTOM_TEMPLATE_COOK_BOOK -------------------
    case ProjectTypeCommonConstants.SET_CUSTOM_TEMPLATE_COOK_BOOK:
      return {
        ...state,
        customTemplate: payload,
      };
    // --------------- CLEAR_CUSTOM_TEMPLATE_COOK_BOOK -----------------
    case ProjectTypeCommonConstants.CLEAR_CUSTOM_TEMPLATE_COOK_BOOK:
      return {
        ...state,
        customTemplate: [],
      };
    default:
      return state;
  }
};
