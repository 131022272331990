import PropTypes from 'prop-types';
import React, { useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row, Col, Input, Flex,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, get } from "lodash";

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';

import { getFetchingNewTestSuite, getProjectParams, getProjectUUID } from '../../../selectors/selectors';
import { getHeadUUID } from '../../../../MainUtils';
import { clearListTestCaseInSuiteInStore } from '../../../actions/QaActions';
import { BugTrackerContext, ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import { incrementAndGetCount } from '../../../../entity/actions/entityActions';
import { defaultArrayTypeOfTesting, descriptionTypeOFTesting } from '../QaUtils';


const { TextArea } = Input;

function NewTestSuite({
  newTestSuiteCallback,
  toggleModal,
  isAssignOpen,
  setIsAssignOpen,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const disabledSumbit = useRef(false);

  const {
    sendAssignedNotifications,
  } = useContext(ProjectMainLayerContext);

  const {
    createPromiseNewTestSuite,
    partitionType,
  } = useContext(BugTrackerContext);

  const fetchingNewTestSuite = useSelector(getFetchingNewTestSuite);
  const projectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);

  const defaultState = {
    title: '',
    description: '',
    casesCount: 0,
    assignToUsersOption: [],
    typeOfTesting: [],
  };

  const [state, changeState] = useState(defaultState);

  const {
    title,
    description,
    casesCount,
    assignToUsersOption,
    typeOfTesting,
  } = state;

  const disableSubmit = title.trim().length === 0 || fetchingNewTestSuite;

  const onFormChange = (event) => {
    const { name, value } = event.target;

    changeState({
      ...state,
      [name]: (value.length > 128 && name === 'title') ? value.slice(0, 128) : value,
    });
  };

  const submitNewTestSuit = async () => {
    if (!disabledSumbit.current) {
      disabledSumbit.current = true;
      dispatch(clearListTestCaseInSuiteInStore);

      const { increment } = await dispatch(incrementAndGetCount({
        entity_uuid: projectUUID,
        field_name: 'suite',
      }, partitionType));

      const params = {
        title,
        description,
        cases_count: casesCount,
        order: [],
        typeOfTesting: [],
        assignToUser: assignToUsersOption,
        id: `S${increment}`,
      };

      createPromiseNewTestSuite(params)
        .then((data) => {
          const suiteUUID = getHeadUUID(data);

          const assignedUsers = get(params, 'assignToUser');

          sendAssignedNotifications({
            entity_type: 'testSuite',
            eventName: 'assigned_test_suite',
            assignedUsers,
            entityUUID: get(data, '[0].uuid'),
            message: get(data, '[0].params.title', ''),
            entityUUIDsFields: {
              testSuiteUUID: get(data, '[0].uuid'),
              projectUUID,
            },
          });

          changeState(defaultState);

          if (newTestSuiteCallback) {
            newTestSuiteCallback(suiteUUID);
          }
        });

      if (toggleModal) {
        toggleModal();
      }
      disabledSumbit.current = false;
    }
  };

  const getActors = (value) => {
    changeState({
      ...state,
      assignToUsersOption: value,
    });
  };

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        {capitalize(t('wms.labels.title', 'title'))}
        <span style={{ color: 'red' }}>*</span>
        <Input
          maxLength={128}
          showCount
          name="title"
          onChange={onFormChange}
          defaultValue={title}
        />
      </Col>
      <Col span={24}>
        <Flex className="w-full">
          <AssigningUsersRoot
            header="Assigning users"
            needUpdateProjectUsers
            isOpen={isAssignOpen}
            setIsOpen={setIsAssignOpen}
            onUpdateUsers={(data) => getActors(data?.users?.map((el) => el?.uuid))}
            globalOptions={{
              uuids: projectParams?.usersSearch ?? [],
              defaultColumn: 'project',
            }}
            placement="left"
            isPopover
            tabs={{
              allConfig: {
                label: 'All',
                value: 'all',
                textSelectButton: 'Add to suite',
                showSections: ['button-add-user'],
              },
              projectConfig: {
                label: 'Project',
                value: 'project',
                textSelectButton: 'Add to suite',
                defaultItems: projectParams?.users ?? [],
                showSections: ['string-role', 'button-add-user'],
              },
            }}
            usersConfig={{
              label: 'Suite users',
              showSections: ['button-remove-user', 'string-role'],
              textDeleteButton: 'Delete users',
              defaultItems: assignToUsersOption ?? [],
            }}
            projectUuid={projectUUID}
            partition={partitionType}
            popoverText={(<span style={{ color: 'black' }}>Assigned users:</span>)}
          />
          <WrapperAvatarsGroupForProjectWithHook
            actors={assignToUsersOption || []}
            avatarSize={28}
            popoverTrigger="click"
            popoverPlacement="right"
          />
        </Flex>
      </Col>

      <Col span={24}>
        {capitalize(t('wms.labels.description', 'description'))}
        :
        <TextArea
          row={4}
          name="description"
          defaultValue={description}
          onChange={onFormChange}
          style={{ height: '150px' }}
        />
      </Col>
      <Col span={24} className=" flex items-end justify-end">
        <BaseButton
          className="btnPrimary"
          onClick={submitNewTestSuit}
          disabled={disableSubmit}
          loading={fetchingNewTestSuite}
        >
          {capitalize(t('wms.buttons.submit', 'submit'))}
        </BaseButton>
      </Col>
    </Row>
  );
}

NewTestSuite.propTypes = {
  newTestSuiteCallback: PropTypes.func,
  toggleModal: PropTypes.func,
  isAssignOpen: PropTypes.bool,
  setIsAssignOpen: PropTypes.func,
};

export default NewTestSuite;
