import React, { memo } from 'react';
import { Col } from 'antd';
import { mdiFormatAlignBottom, mdiFormatAlignTop } from '@mdi/js';
import { useTranslation } from 'react-i18next/';
import PropTypes from 'prop-types';

import { capitalize } from 'lodash';
import DataGridActionButton from './DataGridActionButton';

function AddOneRowInStep({
  addLineDownCollback,
  addLineTopCollback,
  idx,
}) {
  const { t } = useTranslation();

  return (
    <Col className="flex">
      <DataGridActionButton
        toolTipText={capitalize(
          t('wms.tooltips.add_line_below', 'add line below'),
        )}
        iconPath={mdiFormatAlignBottom}
        onClick={() => addLineDownCollback(idx)}
        hideBorder="right"
        borderRadius="4px 0 0 4px"
      />
      <DataGridActionButton
        toolTipText={capitalize(
          t('wms.tooltips.add_line_above'),
        )}
        iconPath={mdiFormatAlignTop}
        onClick={() => addLineTopCollback(idx)}
        hideBorder="right"
      />
    </Col>
  );
}

AddOneRowInStep.propTypes = {
  addLineDownCollback: PropTypes.func,
  addLineTopCollback: PropTypes.func,
  idx: PropTypes.number,
};

export default memo(AddOneRowInStep);
