import { UsersPartitionConstants } from '../../constants/Constants';

const initialState = {
  isMultiCreatingTimelogsFromActivitiesLoading: false,
  isMultiCreatingTimelogsFromIssuesLoading: false,

  isLoadingTimelog: false,
  timelogData: [],
  timelogError: '',

  isLoading: false,
  data: [],
  dataTotal: 0,
  error: '',

  activitiesData: [],
  activitiesTotal: 0,
  activitiesIsLoading: false,
  activitiesError: '',

  issuesData: [],
  issuesTotal: 0,
  issuesIsLoading: false,
  issuesError: '',
};

const userTimelogsReducer = (state = initialState, action) => {
  const {
    type,
    payload,
    total,
  } = action;

  switch (type) {
    case UsersPartitionConstants.MULTI_CREATE_USER_TIMELOGS_FROM_ACTIVITIES_REQUEST:
      return {
        ...state,
        isMultiCreatingTimelogsFromActivitiesLoading: true,
      };
    case UsersPartitionConstants.MULTI_CREATE_USER_TIMELOGS_FROM_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isMultiCreatingTimelogsFromActivitiesLoading: false,
      };
    case UsersPartitionConstants.MULTI_CREATE_USER_TIMELOGS_FROM_ACTIVITIES_FAILURE:
      return {
        ...state,
        isMultiCreatingTimelogsFromActivitiesLoading: false,
      };

    case UsersPartitionConstants.MULTI_CREATE_USER_TIMELOGS_FROM_ISSUES_REQUEST:
      return {
        ...state,
        isMultiCreatingTimelogsFromIssuesLoading: true,
      };
    case UsersPartitionConstants.MULTI_CREATE_USER_TIMELOGS_FROM_ISSUES_SUCCESS:
      return {
        ...state,
        isMultiCreatingTimelogsFromIssuesLoading: false,
      };
    case UsersPartitionConstants.MULTI_CREATE_USER_TIMELOGS_FROM_ISSUES_FAILURE:
      return {
        ...state,
        isMultiCreatingTimelogsFromIssuesLoading: false,
      };

    case UsersPartitionConstants.CREATE_USER_TIMELOG_REQUEST:
      return {
        ...state,
        isLoadingTimelog: true,
      };
    case UsersPartitionConstants.CREATE_USER_TIMELOG_SUCCESS:
      return {
        ...state,
        timelogData: payload,
        isLoadingTimelog: false,
        ertimelogErrorror: '',
      };
    case UsersPartitionConstants.CREATE_USER_TIMELOG_FAILURE:
      return {
        ...state,
        timelogData: [],
        isLoadingTimelog: false,
        timelogError: payload,
      };

    case UsersPartitionConstants.GET_USER_ACTIVITIES_TIMELOGS_REQUEST:
      return {
        ...state,
        activitiesIsLoading: true,
      };
    case UsersPartitionConstants.GET_USER_ACTIVITIES_TIMELOGS_SUCCESS:
      return {
        ...state,
        activitiesData: payload,
        activitiesTotal: total,
        activitiesIsLoading: false,
        activitiesError: '',
      };
    case UsersPartitionConstants.GET_USER_ACTIVITIES_TIMELOGS_FAILURE:
      return {
        ...state,
        activitiesData: [],
        activitiesTotal: 0,
        activitiesIsLoading: false,
        activitiesError: payload,
      };

    case UsersPartitionConstants.GET_USER_ISSUES_TIMELOGS_REQUEST:
      return {
        ...state,
        issuesIsLoading: true,
      };
    case UsersPartitionConstants.GET_USER_ISSUES_TIMELOGS_SUCCESS:
      return {
        ...state,
        issuesData: payload,
        issuesTotal: total,
        issuesIsLoading: false,
        issuesError: '',
      };
    case UsersPartitionConstants.GET_USER_ISSUES_TIMELOGS_FAILURE:
      return {
        ...state,
        issuesData: [],
        issuesTotal: 0,
        issuesIsLoading: false,
        issuesError: payload,
      };

    case UsersPartitionConstants.GET_USER_TIMELOGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UsersPartitionConstants.GET_USER_TIMELOGS_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: '',
      };
    case UsersPartitionConstants.GET_USER_TIMELOGS_FAILURE:
      return {
        ...state,
        data: [],
        isLoading: false,
        error: payload,
      };

    case UsersPartitionConstants.CLEAR_USER_TIMELOGS:
      return {
        ...state,
        data: [],
        isLoading: false,
        error: '',
      };
    case UsersPartitionConstants.CLEAR_USER_ACTIVITIES_TIMELOGS:
      return {
        ...state,
        activitiesData: [],
        activitiesTotal: 0,
        activitiesIsLoading: false,
        activitiesError: '',
      };
    case UsersPartitionConstants.CLEAR_USER_ISSUES_TIMELOGS:
      return {
        ...state,
        issuesData: [],
        issuesTotal: 0,
        issuesIsLoading: false,
        issuesError: '',
      };

    default: return state;
  }
};

export default userTimelogsReducer;
