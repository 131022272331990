import React, { useContext, useState, useEffect } from 'react';
import Icon from '@mdi/react';
import {
  mdiCarBrakeAlert, mdiIdentifier,
} from '@mdi/js';
import {
  Badge, Card, Col, Row, Space, Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';
import { antNotification, capitalizeFirstChar, formatDateTimeForWms } from '../../../../MainUtils';
import CardWithFilter from '../../issuesView/CardWithFilter';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import AntDesignSearchBox54origins from '../../../../54origins/components/AntDesignSearchBox54origins';
import BtnModal from '../../BtnModalComponent';
import ChecklistForm from './ChecklistForm';
import {
  getListOfCheckLists, getListOfCheckListsFetching, getMe, getProjectUUID,
} from '../../../selectors/selectors';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import HardDeleteEntityContainer from '../../../../entity/containers/HardDeleteEntityContainer';
import TestStatusDropdown from '../componentsForMultipleUse/TestStatusDropdown';
import { updateChecklist } from '../../../actions/QaActions';
import { defaultArrayStatus } from '../QaUtils';
import useTableFilter from '../../../../components/_ui/hooks/useTableFilter';
import { cycleStatusFilterConfig, defaultChecklistsFilter } from '../constants/qaFilterOptions';

export default function ListOfChecklist({
  title,
  config,
  setNewConfigCallback,
  totalCountCheckList,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const defaultListChecklists = useSelector(getListOfCheckLists);
  const loading = useSelector(getListOfCheckListsFetching);
  const myUserUuid = useSelector(getMe);
  const projectUUID = useSelector(getProjectUUID);

  const [selectedRow, changeSelectedRow] = useState('');

  const [sizePerPage, setSizePerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentTags, setCurrentTags] = useState([]);
  const [orderRules, changeOrderRules] = useState({});

  const {
    selectChecklist,
    partitionType,
    deleteChecklist,
    getListOfChecklists,
  } = useContext(BugTrackerContext);

  const {
    filterConfig: checklistsFilterConfig,
    changeFilterConfig: changeChecklistsFilterConfig,
  } = useTableFilter('checklist', {});

  // Поиск, пагинация и сортировка
  const currentSortCallback = (dataSort) => {
    if (!get(dataSort, 'order')) {
      changeOrderRules({
        orderBy: 'created',
        order: 'desc',
      });
    } else {
      changeOrderRules(dataSort);
    }
  };

  const changePaginationOptions = ({
    pageLimit,
    currentPage: newCurrentPage,
    offset,
  }) => {
    const data = {
      ...config,
      offset,
      limit: pageLimit,
    };
    setNewConfigCallback('checklist', data);
    setCurrentPage(newCurrentPage);
  };

  const changeLimit = (data) => {
    setSizePerPage(data);
    setCurrentPage(1);

    const param = {
      ...config,
      limit: data,
      offset: 0,
    };
    setNewConfigCallback('checklist', param);
  };

  const setSearchIssue = (value) => {
    const data = {
      ...config,
      offset: 0,
      search_data: {
        value,
        fields: {
          params: ['title', 'customId'],
        },
      },
    };
    setNewConfigCallback('checklist', data);
    setCurrentPage(1);
  };

  // Обновление
  const onChangeStatusCallback = (status, row) => {
    const newHelperInStatus = (
      (row?.progress?.unexecuted === 0
        && row?.progress?.progress === 0)
      && row?.progress?.quantityCase !== 0
    );

    const data = {
      entity_type: 'checklist',
      uuid: row?.uuid,
      partition: partitionType,
      params: {
        status,
        helperInStatus: status !== 'in progress' ? false : newHelperInStatus,
        executed_by: status !== 'in progress' ? myUserUuid : '',
        executed_on: status !== 'in progress' ? formatDateTimeForWms(moment()) : '',
      },
    };

    dispatch(updateChecklist(data))
      .then(() => {
        antNotification('success', t('notifications.text.success.change_status_checklist', 'Checklist status has been changed'));
        getListOfChecklists();
      });
  };

  // Таблица

  const onRow = (record) => ({
    onClick: () => {
      const { uuid } = record || {};

      changeSelectedRow(uuid);
      selectChecklist(uuid);
    },
  });

  const rowClassName = (row) => `not-hide-checkbox select_test_cycle_row ${selectedRow === get(row, 'uuid') ? 'selected' : ''} `;

  const columns = [
    {
      dataIndex: ['id'],
      title: (<Icon path={mdiIdentifier} size={1} />),
    },
    {
      dataIndex: ['title'],
      key: 'title',
      title: (
        <Col className="d-flex align-item-end minWidth130">
          <AntDesignSearchBox54origins
            onSearch={setSearchIssue}
            placeholder="Search"
          />
        </Col>
      ),
      render: (cell) => cell && (
        <EntityParamTag
          param="title"
          value={cell}
          type="value"
        />
      ),
    },
    {
      dataIndex: ['status'],
      key: 'status',
      sorter: true,
      title: capitalizeFirstChar(t('wms.table.headers.status', 'Status')),
      filters: defaultArrayStatus,
      ...(cycleStatusFilterConfig && { ...cycleStatusFilterConfig?.status }),
      ...(checklistsFilterConfig?.status && { defaultFilteredValue: checklistsFilterConfig?.status }),
      onFilter: (value, record) => {return record.status?.includes(value.toLowerCase());},
      render: (cell, row) => (
        <div
          style={row.helperInStatus
            ? { border: '2px solid red', padding: '2px', borderRadius: '5px' }
            : {}}
          className="d-flex align-items-center"
        >
          <TestStatusDropdown
            currentStatus={cell || 'unexecuted'}
            row={row}
            type="checklist"
            onChangeStatusCallback={onChangeStatusCallback}
          />

          {row.helperInStatus
            && (
              <Tooltip
                title={row.helperInStatus && t(
                  'wms.tooltips.update_cycle_status',
                  'Some of your test cases have been deleted. Please update the test cycle status',
                )}
              >
                <Icon
                  color="red"
                  path={mdiCarBrakeAlert}
                  size={0.8}
                  className="ml-1"
                />
              </Tooltip>
            )}
        </div>

      ),
    },
    {
      dataIndex: ['params'],
      title: capitalizeFirstChar(t('wms.table.headers.actions', 'actions')),
      render: (cell, row) => {
        const deleteChecklistCallback = () => {
          deleteChecklist(row.uuid);
        };
        return (
          <HardDeleteEntityContainer
            name="checklist"
            labelMode={false}
            entityUUID={row.uuid}
            className="ant-btn customAntOutDangerBtn ant-btn-default"
            styleBtn={{
              borderRadius: '4px',
            }}
            actionForEntity={deleteChecklistCallback}
            checkHardDelete={false}
            entityType={row.entity_type}
            type="entity"
            sizeBtn="small"
            hideLabel
            antNotificationSuccess
            partitionType={partitionType}
          />
        );
      },
    },
  ];

  // Фильтрация
  const defaultLabelCheckbox = [
    {
      rowText: 'wms.table.headers.status',
      isRow: true,
      tags: [
        { key: 'status', value: 'unexecuted', label: capitalizeFirstChar(t(`wms.filters.${'unexecuted'}`, 'unexecuted')) },
        { key: 'status', value: 'in progress', label: capitalizeFirstChar(t(`wms.filters.${'in progress'}`, 'in progress')) },
        { key: 'status', value: 'fail', label: capitalizeFirstChar(t(`wms.filters.${'failed'}`, 'failed')) },
        { key: 'status', value: 'pass', label: capitalizeFirstChar(t(`wms.filters.${'passed'}`, 'passed')) },
        { key: 'status', value: 'block', label: capitalizeFirstChar(t(`wms.filters.${'blocked'}`, 'blocked')) },
      ],
    }];

  const onSelectTagsCallback = (tags = []) => {
    setCurrentTags(tags);
    const tagsArr = tags.map((e) => e.value);
    setCurrentPage(1);
    const param = {
      ...config,
      limit: sizePerPage,
      offset: 0,
      params: {},
    };

    param.parent = projectUUID;

    if (!isEmpty(tagsArr)) {
      tags.forEach((tag) => {
        if (param.params[tag.key] === undefined) {
          param.params[tag.key] = [];
        }
        param.params[tag.key].push(tag.value);
      });
    }
    setNewConfigCallback('checklist', param);
  };

  useEffect(() => {
    if(checklistsFilterConfig){
      let param = {
      ...config,
      ...orderRules,
      limit: sizePerPage,
      offset: 0,
      params: {...checklistsFilterConfig},
    };

    setNewConfigCallback('checklist', param);
    }
    
  }, [checklistsFilterConfig, orderRules]);
  
  return (
    <Row>
      <Col span={24}>
      <Row>
      <h6 className="m-0 mr-2 text-no-wrap mb-2">
        {title}
        <Badge
          count={defaultListChecklists.length}
          showZero
          style={{ background: '#1890ff'}}
          className="ml-1 mb-1"
        />
        <Space
          size={[5, 5]}
            // wrap
          className="justify-content-end ml-1"
        >
          <BtnModal
            title={`${capitalizeFirstChar(t('wms.verb.create', 'create'))} ${t('wms.noun.checklist.0')}`}
            width="50%"
            btnComponent={(
              <BaseButton
                className="btnPrimary-outline ml-1"
                size="small"
              >
                {`${capitalizeFirstChar(t('wms.verb.add', 'add'))} ${t('wms.noun.checklist.0')}`}
              </BaseButton>
              )}
          >
            <ChecklistForm
              typeOfForm="newForm"
            />
          </BtnModal>
        </Space>
      </h6>
      </Row>
      <Col span={24} className="wrapper_main_card_table mt-2 mb-3">
      <BaseTableWithPagination
        loading={loading}
        newCurrentPage={currentPage}
        pageLimit={sizePerPage}
        changePageLimit={changeLimit}
        useCustomPagination
        columns={columns}
        data={defaultListChecklists}
        rowKey="uuid"
        size="small"
        pageSizeOptions={[5, 25, 50, 100]}
        total={totalCountCheckList}
        getPaginationOptions={changePaginationOptions}
        onRow={onRow}
        rowClassName={rowClassName}
        currentSortCallback={currentSortCallback}
        headerRowClassName="not-hide-checkbox header-table-test-cycle"
        filterCallback={changeChecklistsFilterConfig}
      />
      </Col>
      </Col>
    </Row>
    
  );
}
ListOfChecklist.propTypes = {
  title: PropTypes.string,
  config: PropTypes.object,
  setNewConfigCallback: PropTypes.func,
  totalCountCheckList: PropTypes.number,
};
