import { getListAndReadAffix } from '../../../affix/affixActions';
import axiosRequest from '../../../api/apiAxios';
import { partitionNamesConfig } from '../../../api/appConfig';
import { workConfigCommentActions } from '../reducers/slices/workCofigCommentSlice';

export const getWorkConfigCommentListActionCreator = (data, constants) => {
  const config = {
    data: {
      affix_type: 'workConfigComment',
      ...data,
    },
    partition: partitionNamesConfig.partition3,
    constants: constants || [
      workConfigCommentActions.GET_WORK_CONFIG_COMMENT_LIST_REQUEST,
      workConfigCommentActions.GET_WORK_CONFIG_COMMENT_LIST_SUCCESS,
      workConfigCommentActions.GET_WORK_CONFIG_COMMENT_LIST_FAILURE,
    ],
  };

  return (dispatch) => dispatch(getListAndReadAffix(config));
};

export const createWorkConfigCommentActionCreator = (data) => {
  const config = {
    affix_type: 'workConfigComment',
    ...data,
  };

  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'entity/affix/create',
        [
          workConfigCommentActions.CREATE_WORK_CONFIG_COMMENT_REQUEST,
          workConfigCommentActions.CREATE_WORK_CONFIG_COMMENT_SUCCESS,
          workConfigCommentActions.CREATE_WORK_CONFIG_COMMENT_FAILURE,
        ],
        config,
        {
          partition: partitionNamesConfig.partition3,
          onSuccess: resolve,
          onFailure: reject,
        },
      ),
    );
  });
};
