import React from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';

import './SelectComponentWithShowMore.scss';
import { capitalize } from 'lodash';

export default function SelectComponentWithShowMore({
  options,
  placeholder,
  handleChangeCallback,
  hideShowMore,
  onClickShowMore,
  onBlurSelectCallback,
  valueSuites,
}) {
  const { t } = useTranslation();
  const noOptionsMessage = () => capitalize(t('wms.select.no_options', 'no options'));

  function MenuList(props) {
    return (
      <components.MenuList {...props}>
        {props.children}
        {!hideShowMore && (
          <div className="flex justify-center w-full">
            <div
              onClick={onClickShowMore}
              role="button"
              tabIndex="0"
              className="chow_more_btn_drop_down"
            >
              Show more
            </div>
          </div>
        )}
      </components.MenuList>
    );
  }
  return (
    <Select
      isMulti
      noOptionsMessage={noOptionsMessage}
      options={options}
      valueSuites={valueSuites}
      // disabled={true}
      className="basic-multi-select z-500"
      classNamePrefix="select z-500"
      onChange={handleChangeCallback}
      placeholder={placeholder}
      closeMenuOnSelect={false}
      components={{ MenuList }}
      onBlur={onBlurSelectCallback}
    />
  );
}
