import { UsersPartitionConstants } from '../../constants/Constants';

const initialState = {
  fetchingMainPrivateIssue: true,
  fetchingMainPrivatePlanning: true,
  mainPrivateIssue: {},
  parentPrivatePlanning: [],
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case UsersPartitionConstants.GET_MAIN_PRIVATE_ISSUE_REQUEST:
      return {
        ...state,
        fetchingMainPrivateIssue: true,
      };
    case UsersPartitionConstants.GET_MAIN_PRIVATE_ISSUE_SUCCESS:
      return {
        ...state,
        fetchingMainPrivateIssue: false,
        mainPrivateIssue: payload,
      };
    case UsersPartitionConstants.GET_MAIN_PRIVATE_ISSUE_FAILURE:
      return {
        ...state,
        fetchingMainPrivateIssue: false,
        mainPrivateIssue: {},
      };
    case UsersPartitionConstants.GET_PARENT_PRIVATE_PLANNING_REQUEST:
      return {
        ...state,
        fetchingMainPrivatePlanning: true,
      };
    case UsersPartitionConstants.GET_PARENT_PRIVATE_PLANNING_SUCCESS:
      return {
        ...state,
        fetchingMainPrivatePlanning: false,
        parentPrivatePlanning: payload,
      };
    case UsersPartitionConstants.GET_PARENT_PRIVATE_PLANNING_FAILURE:
      return {
        ...state,
        fetchingMainPrivatePlanning: false,
        parentPrivatePlanning: [],
      };
    default: return state;
  }
};
