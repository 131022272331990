import { prop } from 'ramda';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SelectComponentWithShowMore from '../../../../entity/components/selectComponentWithShowMore/SelectComponentWithShowMore';
import { capitalize } from 'lodash';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import { getCurrentArchiveStateUUID } from '../../../selectors/archiveStateSelectors';
import { getProjectUUID } from '../../../selectors/selectors';

export default function WrapperSelectComponent({
  handleChangeCallback,
  onBlurSelectCallback,
  valueSuites = [],
}) {
  const { t } = useTranslation();
  // const dispatch = useDispatch();

  const { partitionType, getListOfTestSuitesFunc } = useContext(BugTrackerContext);
  const projectUUID = useSelector(getProjectUUID);
  const archiveStateUUID = useSelector(getCurrentArchiveStateUUID);
  const finalParentForQA = archiveStateUUID || projectUUID;

  const defaultConfig = {
    partition: partitionType,
    parent: finalParentForQA,
    constants: ['REQUEST', 'SUCCESS', 'FAILURE'],
    limit: 5,
    offset: 0,
  };

  const [config, setConfig] = useState(defaultConfig);
  const [option, setOption] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const transformSuiteForSelect = (list = []) => {
    const data = list.map((item) => ({
      label: item.title,
      value: item.uuid,
    }));

    setOption((state) => ([...state, ...data]));
  };

  const onClickShowMore = () => {
    setConfig((state) => ({ ...state, offset: state.offset + 5 }));
  };

  const getListOfTestSuites = async () => {
    const data = await getListOfTestSuitesFunc(config);
    setTotalCount(prop('total', data));
    transformSuiteForSelect(prop('data', data));
  };
  useEffect(() => {
    getListOfTestSuites();
  }, [JSON.stringify(config)]);

  return (
    <SelectComponentWithShowMore
      options={option}
      valueSuites={valueSuites}
      hideShowMore={totalCount <= option.length}
      onClickShowMore={onClickShowMore}
      placeholder={capitalize(t('wms.placeholders.select_suite', 'select suite'))}
      handleChangeCallback={handleChangeCallback}
      onBlurSelectCallback={onBlurSelectCallback}
    />
  );
}
