import React, {
  useState, useEffect, useLayoutEffect, useCallback,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Col, Flex, Input, Space, Spin, Badge, Tooltip,
} from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  mdiHistory,
  mdiChat,
} from '@mdi/js';
import Icon from '@mdi/react';
import { capitalize } from 'lodash';
import cn from 'classnames';
import AddToBookmark from '../../AddToBookmark';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import QuillEditor from '../QuillEditor/QuillEditor';
import CardSidePanel from '../../commonComponents/cardSidePanel/CardSidePanel';
import CommentsStartPage from '../../comment/CommentsStartPage/CommentsStartPage';
import CopyURL from '../../../../components/CopyURL';
import CommonInfo from '../../commonComponents/CommonInfo';
import DocsOptions from '../docsOptions/DocsOptions';
import DocTypeLabel from '../DocTypeLabel';
import ExpandModeLayout, { useExpandModeContext } from '../../commonComponents/expandModeLayout/ExpandModeLayout';
import ExpirationTime from '../../../../components/expirationTime/ExpirationTime';
import GoToButton from '../../../../components/buttons/GoToButton';
import HardDeleteEntityContainer from '../../../../entity/containers/HardDeleteEntityContainer';
import JSpreadsheet from '../../../../../../JSpreadsheet/JSpreadsheet';
import ParamsRadio from '../../commonComponents/ParamsRadio';
import RestoreEntityTypeBtn from '../../archiveState/RestoreEntityTypeBtn';

import { antNotification } from '../../../../MainUtils';

import {
  getProjDocFetching,
  getProjectDocumentUUID,
  getProjectDocumentCopyUUID,
  getProjDoc,
  getTotalComments,
} from '../../../selectors/selectors';

import { getUserPublicProfileUUID } from '../../../../userFlow/store/selectors/selectors';
import { getUserName } from '../../../../entity/selectors/selectors';
import {
  clearAllComments,
  updateCommentsCountRequest,
} from '../../../actions/projectFlowActions';
import { getDeletingDocUuid, setDeletingDocUuid } from '../../../reducers/slicers/quillSlicer';
import { useQuill } from '../QuillEditor/useQuill/useQuill';

import { useResize } from '../../../../hooks/useResize';
import useUpdateDocumentParameter from '../helpers/useUpdateDocumentParameter';

import { docStatusOptions } from '../constants/docsStatuses';
import { getDocumentTypeInfo } from '../constants/docsEntityTypes';
import { COMMENT_TYPE } from '../../comment/commentType';
import { getDocumentContent } from '../../../selectors/selectors';
import { getDeletedDocHtml } from '../../../reducers/slicers/quillSlicer';
import DocSaveBlock from '../docSaveBlock/DocSaveBlock';
import { getAllDocPerms } from '../../../reducers/slicers/quillSlicer';

import './DocView.scss';
import { partitionNamesConfig } from '../../../../api/appConfig';


function DocView({
  afterDeleteABookmark,
  changeDescriptionCallback,
  changeDocumentState,
  closeBtns,
  closeUnsaveDataConfirm,
  defaultPartition,
  description,
  disableInputs,
  docFetching = false,
  docStatus,
  entityDocType,
  getDescription,
  getDescriptionForMerge,
  getSpreadsheet,
  hideElements = [],
  isDisabled,
  isSidePanelOpen = false,
  lifeTime,
  readOnlySpreadsheet,
  showEditor,
  spreadsheetEditor,
  title,
  deleteDocumentCallback,
  restoreEntityCallback,
  setIsEditedDescription,
  setIsShowHistoryModal,
  setIsSidePanelOpen,
  showHistoryModal,
  setNotSavedDataCallback,
  updateProjectDocument,
  updateDocumentsTableCallback,
  documentUUID = '',
  handleCreateDocWhenDelete,
  hideExpandLayout = [],
  setSaveDocument,
  reloadDocumentWithoutSaving,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { width } = useResize();
  const {
    showMetaInfo,
    // isBaseMode,
    isWideMode,
    isVerticalMode,
    isFullscreenMode,
  } = useExpandModeContext();


  const docUUID = useSelector(getProjectDocumentUUID);
  const fetching = useSelector(getProjDocFetching);
  const docCopyUUID = useSelector(getProjectDocumentCopyUUID);
  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const projDocOrIdeas = useSelector(getProjDoc);
  const userFullName = useSelector(getUserName);
  const commentsCountStore = useSelector(getTotalComments);
  // console.log('commentsCountStore', commentsCountStore);
  const documentContent = useSelector(getDocumentContent);
  const [showTitleError, setShowTitleError] = useState(false);
  const [commentsCountLocal, setCommentsCountLocal] = useState(0);
  // console.log('commentsCountLocal', commentsCountLocal);
  // const [isCardCollapsed, setIsCardCollapsed] = useState(false);
  const deletingUuidRef = useRef(null);
  const deletedDocHtml = useSelector(getDeletedDocHtml);
  const currentUserDocPerms = useSelector(getAllDocPerms);

  const [isUndoTooltipOpen, setUndoTooltipOpen] = useState(false);

  const { updateDocumentParameter } = useUpdateDocumentParameter({
    entityUUID: docUUID,
    entityType: entityDocType,
    commentType: COMMENT_TYPE.TOTAL,
    partition: defaultPartition,
  });

  // --------------------- clear quill editor ----------------------
  const { clearAllValues, handleUnmountEditor } = useQuill();
  const deletingUuid = useSelector(getDeletingDocUuid);

  const quillUnmountFunction = async ({ docUUID,
    defaultPartition,
    defaultActualState,
    withoutConnection,
    newTextLength,
    userDataArr, editorRef, providerRef, documentRef }) => {
    // const currentData = Y.encodeStateAsUpdate(documentRef?.current);
    const currentData = editorRef.current.root.innerHTML;

    clearAllValues({ editorRef, providerRef, documentRef });
    // WARN: очистка значений редактора, провайдера и документа обязательна!

    if (deletingUuidRef.current !== docUUID) {
      // TODO: нужно пофиксить баг с созданием повторяющихся дельт, тогда можно убрать эту проверку
      // проверка на повторяющиеся дельты запускается, если активный док не удален пользователем

      // console.log('crdt check');

      const resp = await handleUnmountEditor({
        docUUID,
        defaultPartition,
        defaultActualState: Number(defaultActualState),
        withoutConnection,
        newTextLength,
        userDataArr,
        currentData
      });
      // console.log('crdt resp', resp)
      if (resp && !!handleCreateDocWhenDelete) {
        handleCreateDocWhenDelete({ entityUUID: docUUID, entityType: entityDocType, docHtmlString: resp })
        // console.log('crdt need to create new doc')
      };

    } else {
      // console.log('crdt cancel check');
      dispatch(setDeletingDocUuid(null));
    }
  }

  useEffect(() => {
    deletingUuidRef.current = deletingUuid;
  }, [deletingUuid]);

  // ------------------------------------------------------

  const {
    parent,
    partition,
    created,
    params: {
      type,
      title: defaultTextTitle,
      lifeTime: defaultLifeTime,
      status: defaultDocStatus,
      updated: lastActivity = '',
      modifiedBy,
      columnsWidths: defaultColumnsWidths,
      actualState: defaultActualState,
      [COMMENT_TYPE.TOTAL]: defaultCommentsCount,
    } = {},
    uinfo: {
      first_name: creatorFirstName,
      last_name: creatorLastName,
    } = {},
  } = projDocOrIdeas?.[0] || {};
  const isNoCreatePerm = !currentUserDocPerms?.create;
  const isNoUpdatePerm = !currentUserDocPerms?.update;
  const isNoDeletePerm = !currentUserDocPerms?.delete;

  const documentFetching = docFetching || fetching;

  const creatorInfo = `${creatorFirstName} ${creatorLastName}`;

  const isNote = entityDocType === 'projectIdea';
  const isSpreadsheet = entityDocType === 'projectSpreadsheet';
  const isDocumentOrIdea = entityDocType === 'projectDocument' || entityDocType === 'projectIdea' || entityDocType === 'historyOfIdeaChanges';
  const isProjectDescriptionDocument = type === 'project description' || type === 'project infrastructure';

  const noVersions = !defaultActualState || defaultActualState === '0';
  const saveOrUndoBtnDisabled = (!docCopyUUID && isDisabled) || isNoUpdatePerm;

  // console.log('IS DOCUMENT OR IDEA:', isDocumentOrIdea);
  // console.log('IS SPREADSHEET:', isSpreadsheet);

  const hideExpirationDate = docStatus ? docStatus === 'outdated' : defaultDocStatus === 'outdated';
  const lockExpirationDate = docStatus ? docStatus === 'important' : defaultDocStatus === 'important';
  const isSystemStatus = defaultDocStatus === 'system';

  const changeInput = (e) => {
    const { value } = e.target;

    changeDocumentState({ title: value, change: true });
  };

  const changeDocStatus = (statusValue) => {
    updateDocumentParameter({ status: statusValue }, updateDocumentsTableCallback);
  };

  const changeLifeTime = (time) => {
    const { lifeTime } = time;

    if (moment(lifeTime).toDate() < moment(new Date()).toDate() || lifeTime === '') {
      changeDocumentState({ lifeTime: moment().format('YYYY-MM-DD'), change: true });
      antNotification('warning', t('notifications.text.warning.incorrect_date', 'incorrect_date'));
    } else {
      changeDocumentState({ lifeTime, change: true });
    }
  };

  const updateCommentsCount = async () => {
    // console.log('commentsDEFAULT', defaultCommentsCount);
    // console.log('commentsLOCAL', commentsCountLocal);
    // console.log('commentsSTORE', commentsCountStore);

    if (commentsCountStore !== commentsCountLocal) {
      const commentsConfig = {
        entity_type: entityDocType,
        entity_uuid: docUUID,
        params: {
          [COMMENT_TYPE.TOTAL]: commentsCountStore,
        },
      };


      const response = await dispatch(updateCommentsCountRequest(commentsConfig, defaultPartition));
      setCommentsCountLocal(response[0]?.params[COMMENT_TYPE.TOTAL] || 0);

      await updateDocumentsTableCallback?.();
    }
  };

  const checkSpreadsheetDescription = (description) => {
   return Array.isArray(description) ? description : [];
  }

  const updateDocOrIdea = async () => {
    if (title?.trim().length === 0 || defaultTextTitle?.trim().length === 0) {
      setShowTitleError(true);
      return;
    }
    setShowTitleError(false);

    const changedContent = getDescription(entityDocType);

    const currentTime = (n = '1', period = 'year') => (moment(lifeTime || defaultLifeTime)
      .format('YYYY-MM-DD') || moment().add(n, period).format('YYYY-MM-DD'));

    const config = {
      entity_type: entityDocType === 'historyOfIdeaChanges' ? 'projectIdea' : entityDocType,
      params: {
        status: defaultDocStatus,
        updated: moment().locale('en').format('LLL'),
        title: title ?? defaultTextTitle,
        // text: docCopyUUID ? description : getDescription(entityDocType),
        lifeTime: isNote ? currentTime('2', 'week') : currentTime('1', 'year'),
        modifiedBy: userFullName ?? '',
        // commentsCount: totalComments,
      },
      entity_uuid: docUUID,
    };

    if (isSpreadsheet) {
      // console.log('NEED SEND TEXT');
      config.params.text = docCopyUUID ? checkSpreadsheetDescription(description) : changedContent;
      config.params.columnsWidths = spreadsheetEditor?.getWidth() || [];
    }

    if (type) {
      config.params.type = type;
    }

    if (type === 'project description' || type === 'project infrastructure') {
      config.params.status = 'system';
      const pureChangedContent = typeof changedContent === 'string' ? changedContent.replace(/<(.|\n)*?>/g, '') : '';
      config.params.symbols_count = pureChangedContent?.length || 0;
      // console.log('updateProjectDocument pureChangedContent?.length', pureChangedContent?.length);
    }

    await updateProjectDocument(config, entityDocType, defaultLifeTime);
    if (setSaveDocument) {
      setSaveDocument(prev => !prev)
    }
  };

  const undoChangesDocOrIdea = (docType) => {
    const initialState = {
      title: null,
      status: null,
      lifeTime: null,
      change: false,
    };


    switch (docType) {
      case 'projectDocument':
      case 'projectIdea': {
        changeDocumentState(initialState);
        setIsEditedDescription(false);
        break;
      }
      case 'projectSpreadsheet': {
        // console.log('Set data', description);
        const descriptionData = checkSpreadsheetDescription(description);
        spreadsheetEditor?.setData(descriptionData);
        changeDocumentState(initialState);
        break;
      }
    }

    setNotSavedDataCallback(false);
    setShowTitleError(false);
    closeUnsaveDataConfirm();
    antNotification('success', t('notifications.text.success.undo_changes', 'Undo changes'));
  };

  const shortCutSaveDocument = (event) => {
    if (event.keyCode === 83 && (navigator.userAgent.indexOf('Mac') !== -1 ? event.metaKey : event.ctrlKey)) {
      event.preventDefault();
      event.stopPropagation();
      if (!event.repeat && !saveOrUndoBtnDisabled) updateDocOrIdea();
    }
  };

  //----------------------------------------------------------

  const toggleCommentsPanel = () => setIsSidePanelOpen?.((prev) => !prev);

  const closeCommentsPanel = useCallback(() => setIsSidePanelOpen?.(false), []);

  const goToButtonLabel = (documentType) => {
    let labelPath;

    switch (documentType) {
      case 'projectDocument': {
        labelPath = 'go_to_doc';
        break;
      }
      case 'projectIdea': {
        labelPath = 'go_to_idea';
        break;
      }
      case 'projectSpreadsheet': {
        labelPath = 'go_to_spreadsheet';
        break;
      }
      default: labelPath = 'go_to_doc';
    }
    return labelPath;
  };

  const pathToRedirect = {
    pathname: (partition || defaultPartition) === partitionNamesConfig.partition3
      ? `/public/user/${myPublicUUID}/projects/docs`
      : '/pm/projects/docs',
    search: `?activeProject=${parent}&${isNote ? 'activeNote' : (isSpreadsheet ? 'activeSheet' : 'activeDoc')}=${docUUID}`,
  };

  useLayoutEffect(() => {
    setIsSidePanelOpen(isFullscreenMode || isWideMode);
  }, [docUUID, isFullscreenMode, isWideMode]);

  useLayoutEffect(() => {
    if (docUUID) {
      setCommentsCountLocal(defaultCommentsCount || 0);
    }
  }, [docUUID, defaultCommentsCount]);

  useEffect(() => {
    if (docUUID) {
      updateCommentsCount?.();
    }
  }, [commentsCountStore]);

  useEffect(() => () => dispatch(clearAllComments()), []);

  const docSaveBlockProps = {
    showTitleError,
    disableInputs,
    isProjectDescriptionDocument,
    title,
    defaultTextTitle,
    changeInput,
    isUndoTooltipOpen,
    setUndoTooltipOpen,
    undoChangesDocOrIdea,
    entityDocType,
    documentFetching,
    saveOrUndoBtnDisabled,
    updateDocOrIdea
  }

  const optionsUpdateDisabledElements = isNoUpdatePerm ? ['addRow', 'addColumn'] : [];
  const optionsCreateDisabledElements = isNoCreatePerm ? ['addIssue', 'addBalk', 'addVector'] : [];
  const optionsDisabledElements = [...optionsUpdateDisabledElements, ...optionsCreateDisabledElements];

  return (
    <Spin
      size="large"
      spinning={docFetching}
      wrapperClassName={cn(
        'document-card-spinner document-expand-container',
        { wide: isWideMode && isDocumentOrIdea },
        { vertical: isVerticalMode },
        { fullscreen: isFullscreenMode },
      )}
    >
      <Col
        id="document"
        className="h-full card-with-side-panel"
        onKeyDown={shortCutSaveDocument}
      >
        <Col className="document-expand-content" style={{ width: isFullscreenMode ? (isSidePanelOpen && 'calc(100% - 400px - 24px)') || 'auto' : 'auto', transition: '.3s all  ease-in-out' }}>
          <Flex gap={10} vertical flex="1 1 100%">
            <Flex gap="10px 20px" wrap="wrap">
              <Flex align="flex-start">
                <ExpandModeLayout.Actions
                  parentType="document"
                  isFetching={docFetching}
                  disabledOptions={hideExpandLayout}
                />
              </Flex>

              <Flex gap={5} wrap="wrap" align="flex-start" flex="1 1 auto">
                {!hideElements.includes('linkBtn') && (
                  <CopyURL
                    partition={defaultPartition}
                    entityType={entityDocType}
                    entityUUID={docUUID}
                  />
                )}

                {!hideElements.includes('bookmarkBtn') && (
                  <AddToBookmark
                    className=""
                    afterDeleteABookmark={afterDeleteABookmark}
                    entityUUID={docUUID}
                    partition={defaultPartition}
                  />
                )}

                {!hideElements.includes('docInfoBtn') && (
                  <CommonInfo
                    creator={creatorInfo}
                    createdDate={created}
                    lastActivity={lastActivity}
                    lastModifiedBy={modifiedBy}
                    version={defaultActualState}
                  />
                )}

                <DocTypeLabel entityDocType={entityDocType} />
              </Flex>

              <Flex align="center">
                {!hideElements.includes('goToBtn') && (
                  <GoToButton
                    pathForLabelTranslate={`wms.buttons.${goToButtonLabel(entityDocType)}`}
                    pathToRedirect={pathToRedirect}
                  />
                )}
              </Flex>

              <Flex gap={5} align="center" wrap="wrap">
                <Space size={[5, 5]} wrap>
                  <RestoreEntityTypeBtn
                    archiveEntityUUID={docUUID}
                    callbackAction={restoreEntityCallback}
                  />

                  <Tooltip title={isFullscreenMode && capitalize(t('wms.buttons.comments', 'comments'))}>
                    <BaseButton
                      className="btnPrimary-outline"
                      size="small"
                      disabled={documentFetching}
                      onClick={toggleCommentsPanel}
                    >
                      <Icon path={mdiChat} size={0.8} className="mr-1" />
                      {(width > 1599 && !isFullscreenMode) && capitalize(t('wms.buttons.comments', 'comments'))}
                      <Badge count={commentsCountLocal || 0} showZero color="#ff4d4f" size="small" className="ml-1" />
                    </BaseButton>
                  </Tooltip>

                  {(!showHistoryModal && !closeBtns && !isSpreadsheet) && (
                    <Tooltip title={isFullscreenMode && capitalize(t('wms.buttons.history', 'history'))}>
                      <BaseButton
                        className="btnPrimary-outline"
                        size="small"
                        disabled={documentFetching || noVersions || documentContent?.startHtmlString}
                        onClick={() => setIsShowHistoryModal?.(true)}
                      >
                        <Icon path={mdiHistory} size={0.8} className="mr-1" />
                        {(width > 1599 && !isFullscreenMode) && capitalize(t('wms.buttons.history', 'history'))}
                      </BaseButton>
                    </Tooltip>
                  )}

                  {!hideElements.includes('options') && (
                    <DocsOptions
                      commentType={COMMENT_TYPE.TOTAL}
                      entityUUID={docUUID}
                      entityType={entityDocType}
                      title={title ?? defaultTextTitle}
                      spreadsheetEditor={spreadsheetEditor}
                      defaultPartition={defaultPartition}
                      isDisabled={documentFetching}
                      isFullscreen={isFullscreenMode}
                      disabledElements={optionsDisabledElements}
                    />
                  )}
                  <HardDeleteEntityContainer
                    disabled={isNoDeletePerm}
                    sizeBtn="small"
                    type="entity"
                    iconSize={0.8}
                    name={getDocumentTypeInfo(entityDocType)?.text}
                    isAllowDelete={!isSystemStatus}
                    entityType={entityDocType}
                    entityUUID={docUUID}
                    checkHardDelete={false}
                    partitionType={defaultPartition}
                    hideLabel
                    hideTooltip
                    actionForEntity={() => deleteDocumentCallback(docUUID, projDocOrIdeas?.[0])}
                  />

                </Space>
              </Flex>

              {isSpreadsheet && <Flex gap={5} align="center" wrap="wrap">
                <DocSaveBlock {...docSaveBlockProps} />
              </Flex>}

              {!isFullscreenMode && <hr className="m-0 w-full" />}


                <Flex
      className={cn("wrapper-title", { "mt-1": !isFullscreenMode })}
      gap="small"
      align="center"
      flex="1 0 auto"
    >
      <Input
        maxLength={100}
        name="title"
        status={showTitleError && "error"}
        disabled={disableInputs || isProjectDescriptionDocument}
        showCount
        placeholder={
          showTitleError
            ? capitalize(
                t("wms.placeholders.title_error", "Title can't be empty!")
              )
            : capitalize(t("wms.placeholders.title", "title"))
        }
        value={title ?? defaultTextTitle}
        onChange={changeInput}
      />
          {!isSpreadsheet && <DocSaveBlock {...docSaveBlockProps} />}
          </Flex>  
              <Flex gap="12px 24px" align="center" wrap="wrap" flex="1 1 100%">
                {!hideElements.includes('docStatus') &&  (
                  <ParamsRadio
                    className="flex items-center flex-shrink-0"
                    title={capitalize(t('wms.labels.status', 'status'))}
                    currentValue={defaultDocStatus}
                    options={docStatusOptions(isSystemStatus)}
                    classNameForTitle="mr-2 wrapper-title"
                    classNameForButtonsBox={isSystemStatus ? '' : 'p-1'}
                    disabled={disableInputs || isSystemStatus || isNoUpdatePerm}
                    withIcons
                    withBorder={!isSystemStatus}
                    onChangeValue={changeDocStatus}
                  />
                )}

                {!hideExpirationDate && showMetaInfo && !isProjectDescriptionDocument && (
                  <ExpirationTime
                    expirationDate={lifeTime || defaultLifeTime}
                    isEdit={!disableInputs && !lockExpirationDate && !isNoUpdatePerm}
                    unlimited={lockExpirationDate}
                    onChangeFieldCallback={changeLifeTime}
                  />
                )}
              </Flex>
            </Flex>

            <Flex gap={10} vertical flex="1 1 auto">
              <div className="editor-wrapper">
                {isDocumentOrIdea && showEditor && (
                  <QuillEditor
                    defaultPartition={defaultPartition}
                    docUUID={docUUID}
                    defaultActualState={defaultActualState}
                    deletedDocHtml={deletedDocHtml}
                    startHtmlString={documentContent?.startHtmlString}
                    startStateVector={documentContent?.startStateVector}
                    isProjectDescriptionDocument={isProjectDescriptionDocument}
                    quillUnmountFunction={quillUnmountFunction}
                    reloadDocumentWithoutSaving={reloadDocumentWithoutSaving}
                    withoutConnection={!currentUserDocPerms?.update}
                    disabled={!currentUserDocPerms?.update}
                  />
                )}

                {isSpreadsheet && showEditor && (
                  <JSpreadsheet
                    showFormulas
                    isReadOnly={readOnlySpreadsheet}
                    columnsWidths={defaultColumnsWidths}
                    documentName={title ?? defaultTextTitle}
                    defaultTextDescription={description}
                    changeDescriptionCallback={changeDescriptionCallback}
                    getSpreadsheet={getSpreadsheet}
                    getDescriptionForMerge={getDescriptionForMerge}
                  />
                )}
              </div>
            </Flex>
          </Flex>
        </Col>

        <CardSidePanel
          isVisible={isSidePanelOpen}
          closeSidePanel={closeCommentsPanel}
        >
          {isSidePanelOpen && (
            <CommentsStartPage
              commentType={COMMENT_TYPE.TOTAL}
              inPanel
              isPanelOpen={isSidePanelOpen}
              parentUUID={docUUID}
              partitionType={defaultPartition}
            />
          )}
        </CardSidePanel>
      </Col>
    </Spin>
  );
}

DocView.propTypes = {
  afterDeleteABookmark: PropTypes.func,
  cardStyle: PropTypes.string,
  cardTitle: PropTypes.func,
  changeDescriptionCallback: PropTypes.func,
  changeDocumentState: PropTypes.func,
  closeBtns: PropTypes.bool,
  closeUnsaveDataConfirm: PropTypes.func,
  defaultPartition: PropTypes.string,
  deleteDocumentCallback: PropTypes.func,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  disableInputs: PropTypes.bool,
  docFetching: PropTypes.bool,
  docStatus: PropTypes.string,
  entityDocType: PropTypes.string,
  getSpreadsheet: PropTypes.func,
  getDescription: PropTypes.func,
  getDescriptionForMerge: PropTypes.func,
  hideElements: PropTypes.array,
  isDisabled: PropTypes.bool,
  isSidePanelOpen: PropTypes.bool,
  lifeTime: PropTypes.string,
  readOnlySpreadsheet: PropTypes.bool,
  restoreEntityCallback: PropTypes.func,
  setIsEditedDescription: PropTypes.func,
  setIsShowHistoryModal: PropTypes.func,
  setIsSidePanelOpen: PropTypes.func,
  setNotSavedDataCallback: PropTypes.func,
  showEditor: PropTypes.bool,
  showHistoryModal: PropTypes.bool,
  spreadsheetEditor: PropTypes.object,
  title: PropTypes.string,
  updateProjectDocument: PropTypes.func,
  updateDocumentsTableCallback: PropTypes.func,
  documentUUID: PropTypes.string,
  handleCreateDocWhenDelete: PropTypes.func,
  hideExpandLayout: PropTypes.array,
  setSaveDocument: PropTypes.func,
  reloadDocumentWithoutSaving: PropTypes.func
};

export default DocView;
