import { bool } from 'prop-types';
import React from 'react';

import PersonalAudit from '../../../../../userFlow/components/PersonalAudit/PersonalAudit';

function PersonalAuditCardRoot({
  otherUser,
}) {
  return (
    <PersonalAudit otherUser={otherUser} />
  );
}

PersonalAuditCardRoot.propTypes = {
  otherUser: bool,
};

export default PersonalAuditCardRoot;
