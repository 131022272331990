import PropTypes from 'prop-types';
import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  Row, Col, Input, Space, Tooltip, Checkbox, Flex,
} from 'antd';
import { mdiChevronDown } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import BaseDropdown from '../../../../components/_ui/BaseDropdown/BaseDropdown';
import DataGridWrapperWithControls from '../dataGrid/DataGridWrapperWithControls';
import AddMoreRowsComponent from './AddMoreRowsComponent';

import { capitalize } from 'lodash';
import BaseSelect from '../../../../components/_ui/BaseSelect/BaseSelect';
import { defaultArrayTypeOfTesting } from '../QaUtils';
import { getProjectParams, getProjectUUID } from '../../../selectors/selectors';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';

const { TextArea } = Input;

const steps = [{}, {}, {}, {}, {}];

function TestCaseForm({
  btnLabel,
  defaultAssignToUsersOption = [],
  defaultComment = '',
  defaultDescription = '',
  defaultStatusPriority = 'low',
  defaultTitle = '',
  defaultTypeOfTesting = [],
  disabledSubmitBtn = false,
  hideElements = [],
  submitAntherOneCallback,
  submitCallback,
  partitionType,
}) {
  const { t, i18n } = useTranslation();

  const projectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);

  const defaultState = {
    actualListStatus: [...defaultArrayTypeOfTesting],
    assignToUsersOption: defaultAssignToUsersOption,
    checkedClone: false,
    comment: defaultComment,
    description: defaultDescription,
    newEmptyRows: 0,
    statusPriority: defaultStatusPriority,
    title: defaultTitle,
    typeOfTesting: defaultTypeOfTesting,
  };

  const [state, changeState] = useState(defaultState);
  const [grid, changeGrid] = useState(steps);
  const [defaultGridFlag, changeDefaultGridFlag] = useState(false);

  const [isAssignOpen, setIsAssignOpen] = useState(false);

  const {
    actualListStatus,
    assignToUsersOption,
    checkedClone,
    comment,
    description,
    newEmptyRows,
    statusPriority,
    title,
    typeOfTesting,
  } = state;

  const setNewState = (param) => {
    changeState({
      ...state,
      ...param,
    });
  };

  const toggleCheckedClone = () => setNewState({ checkedClone: !checkedClone });

  const addMoreRowsCallback = async (data) => {
    await setNewState({ newEmptyRows: 0 });
    await setNewState({ newEmptyRows: data });
  };

  const onFormChange = (e) => {
    const { name, value } = e.target;

    setNewState({ [name]: value });
  };

  const getGrid = useCallback((data) => {
    changeGrid(data);
  }, []);

  const submitCallbackFunc = () => {
    if (submitCallback) {
      submitCallback({ ...state, grid });
    }
    setNewState(defaultState);
  };

  const submitAndAnotherOneFunc = async () => {
    if (!checkedClone) {
      setNewState(defaultState);
      changeGrid(steps);

      await changeDefaultGridFlag(true);
      await changeDefaultGridFlag(false);
    }

    if (submitAntherOneCallback) {
      submitAntherOneCallback({ ...state, grid });
    }
  };

  const valueTypeOfTesting = useMemo(() => {
    const arrayTypeOfTesting = Array.isArray(typeOfTesting) ? typeOfTesting : [typeOfTesting];
    return arrayTypeOfTesting.map((i) => (
      { label: capitalize(t(`wms.options_drap_down.${i}`)), value: i }));
  }, [typeOfTesting?.length, i18n?.language]);

  return (
    <>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          {capitalize(t('wms.labels.title', 'title'))}

          <span style={{ color: 'red' }}>*</span>
          <Input
            maxLength={128}
            showCount
            name="title"
            onChange={onFormChange}
            defaultValue={title}
            value={title}
          />
        </Col>

        <Col span={24}>
          <span>
            {capitalize(t('wms.labels.description', 'description'))}
            :
          </span>

          <TextArea
            row={4}
            name="description"
            style={{ height: '150px' }}
            onChange={onFormChange}
            defaultValue={description}
            value={description}
          />
        </Col>

        <Flex className="w-full">
          <AssigningUsersRoot
            header="Assigning users"
            needUpdateProjectUsers
            isOpen={isAssignOpen}
            setIsOpen={setIsAssignOpen}
            onUpdateUsers={(data) => setNewState({
              assignToUsersOption: data?.users?.map((el) => el?.uuid),
            })}
            globalOptions={{
              uuids: projectParams?.usersSearch ?? [],
              defaultColumn: 'project',
            }}
            placement="left"
            isPopover
            tabs={{
              allConfig: {
                label: 'All',
                value: 'all',
                textSelectButton: 'Add to case',
                showSections: ['button-add-user'],
              },
              projectConfig: {
                label: 'Project',
                value: 'project',
                textSelectButton: 'Add to case',
                defaultItems: projectParams?.users ?? [],
                showSections: ['string-role', 'button-add-user'],
              },
            }}
            usersConfig={{
              label: 'Case users',
              showSections: ['button-remove-user', 'string-role'],
              textDeleteButton: 'Delete users',
              defaultItems: assignToUsersOption ?? [],
            }}
            wrapperProps={{ autoAdjustOverflow: true }}
            partition={partitionType}
            projectUuid={projectUUID}
            popoverText={(<span style={{ color: 'black' }}>Assigned users:</span>)}
          />
          <WrapperAvatarsGroupForProjectWithHook
            actors={assignToUsersOption || []}
            avatarSize={28}
            popoverTrigger="click"
            popoverPlacement="right"
          />
        </Flex>

        <Col span={24}>
          <span>
            {capitalize(t('wms.labels.preconditions', 'preconditions'))}
            :
          </span>

          <TextArea
            row={4}
            name="comment"
            onChange={onFormChange}
            defaultValue={comment}
            value={comment}
          />
        </Col>
      </Row>
      {!hideElements.includes('grid') && (
        <>
          <Row className="mt-2">
            <Col span={24}>
              <DataGridWrapperWithControls
                typeOfParent="suite"
                steps={steps}
                newEmptyRows={newEmptyRows}
                onCellsChangedCallback={getGrid}
                defaultGridFlag={defaultGridFlag}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <AddMoreRowsComponent
                addMoreRowsCallback={addMoreRowsCallback}
              />
            </Col>
          </Row>
        </>
      )}

      <Row gutter={[0, 16]} className="mt-3">
        <Col span={24}>
          <Row gutter={[16, 0]} className="mr-2">
            <Col span={12}>
              <div>
                {capitalize(t('wms.labels.type_of_testing', 'type of testing'))}
                <span style={{ color: 'red' }}>*</span>
              </div>
              <BaseSelect
                optionFilterProp="label"
                // getPopupContainer={() => document.getElementById(id)}
                optionLabelProp="label"
                autoClearSearchValue
                allowClear
                hideSelected
                options={actualListStatus}
                showArrow
                closeMenuOnSelect={false}
                value={valueTypeOfTesting}
                placeholder={capitalize(t('wms.placeholders.type', 'type'))}
                onChange={(value) => setNewState({ typeOfTesting: value })}
                mode="multiple"
                classNamePrefix="react-select"
                className="react-select w-full"
                pathTranslate="wms.options_drap_down"
              />

            </Col>

            <Col>
              {capitalize(t('wms.priority.title', 'priority'))}
              :
              <BaseDropdown
                defaultCurrentItem={statusPriority}
                defaultListOfItems={['low', 'medium', 'high', 'urgent']}
                dropDownCallback={(status) => setNewState({ statusPriority: status })}
                iconPath={mdiChevronDown}
                id="casePriority"
                placement="topLeft"
                translationPath="wms.priority"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="pt-4">
        <Col span={24} className="flex justify-end">
          <Space
            size={[5, 5]}
            wrap
            className="justify-end"
          >
            {!hideElements.includes('cloneBtn') && (
              <div className="flex justify-center items-center pr-2">
                {`${capitalize(t('wms.buttons.clone', 'clone'))}?`}
                <Tooltip title={capitalize(t('wms.labels.clone_information', 'clone test case information?'))}>
                  <Checkbox
                    checked={checkedClone}
                    onChange={toggleCheckedClone}
                    className="ml-1"
                  />
                </Tooltip>
              </div>
            )}
            {!hideElements.includes('anotherOneBtn') && (
              <BaseButton
                className={(title.length !== 0 && !isEmpty(typeOfTesting)) && 'btnPrimary-outline'}
                onClick={submitAndAnotherOneFunc}
                disabled={(
                  disabledSubmitBtn
                  || title.length === 0
                  || isEmpty(typeOfTesting)
                )}
              >
                {btnLabel || capitalize(t('wms.buttons.submit_&_add_another_one', 'submit & add another one'))}
              </BaseButton>
            )}

            <BaseButton
              onClick={submitCallbackFunc}
              className="btnPrimary-"
              disabled={(
                checkedClone
                || disabledSubmitBtn
                || title.length === 0
                || isEmpty(typeOfTesting)
              )}
            >
              {btnLabel || capitalize(t('wms.buttons.submit', 'submit'))}
            </BaseButton>
          </Space>
        </Col>
      </Row>
    </>
  );
}

export default TestCaseForm;

TestCaseForm.propTypes = {
  btnLabel: PropTypes.string,
  defaultAssignToUsersOption: PropTypes.array,
  defaultComment: PropTypes.string,
  defaultDescription: PropTypes.string,
  defaultStatusPriority: PropTypes.string,
  defaultTitle: PropTypes.string,
  defaultTypeOfTesting: PropTypes.string,
  disabledSubmitBtn: PropTypes.bool,
  hideElements: PropTypes.array,
  submitAntherOneCallback: PropTypes.func,
  submitCallback: PropTypes.func,
  partitionType: PropTypes.string,
};
