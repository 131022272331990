// ----------------- COMMON FILTERS -----------------
export const priorityFilterOprions = [
  {
    text: 'Low',
    value: 'low',
  },
  {
    text: 'Medium',
    value: 'medium',
  },
  {
    text: 'High',
    value: 'high',
  },
  {
    text: 'Urgent',
    value: 'urgent',
  },
];

// ----------------- ISSUE FILTERS -----------------
export const trackerFilterOptions = [
  {
    text: 'Ticket',
    value: 'ticket',
  },
  {
    text: 'Bug',
    value: 'bug',
  },
  {
    text: 'Feature',
    value: 'feature',
  },
];

export const estimatedTimeFilterOptions = [
  {
    text: 'Easy',
    value: 'easy',
  },
  {
    text: 'Normal',
    value: 'normal',
  },
  {
    text: 'Difficult',
    value: 'difficult',
  },
  {
    text: 'Very complicated',
    value: 'very_complicated',
  },
];

export const issueStatusFilterOptions = [
  {
    text: 'Validation',
    value: 'validation:section',
    children: [
      {
        text: 'Created',
        value: 'created',
      },
      {
        text: 'Reopened',
        value: 'reopened',
      },
      {
        text: 'Acquainted',
        value: 'acquainted',
      },
      {
        text: 'Accepted',
        value: 'accepted',
      },
      {
        text: 'Rejected',
        value: 'rejected',
      },
    ],
  },
  {
    text: 'Development',
    value: 'development:section',
    children: [
      {
        text: 'In progress',
        value: 'in progress',
      },
      {
        text: 'Ready for review',
        value: 'review',
      },
      {
        text: 'Ready for deploy',
        value: 'ready for deploy',
      },
    ],
  },
  {
    text: 'Testing',
    value: 'testing:section',
    children: [
      {
        text: 'Ready for test',
        value: 'test',
      },
      {
        text: 'Testing',
        value: 'testing',
      },
      {
        text: 'Not reproducible',
        value: 'not reproducible',
      },
    ],
  },
  {
    text: 'Purgatory',
    value: 'purgatory:section',
    children: [
      {
        text: 'Backlog',
        value: 'backlog',
      },
      {
        text: 'Blocked',
        value: 'blocked',
      },
      {
        text: 'Paused',
        value: 'paused',
      },
      {
        text: 'For discuss',
        value: 'discuss',
      },
      {
        text: 'Not enough info',
        value: 'not enough info',
      },
    ],
  },
  {
    text: "Ticket's end",
    value: 'tickets-end:section',
    children: [
      {
        text: 'Closed',
        value: 'closed',
      },
      {
        text: 'Deployed & Closed',
        value: 'deployed',
      },
      {
        text: 'Abandoned',
        value: 'abandoned',
      },
    ],
  },
];

// ----------------- VECTOR FILTERS -----------------
export const vectorTypeFilterOptions = [
  {
    text: 'General',
    value: 'general',
  },
  {
    text: 'Version',
    value: 'version',
  },
  {
    text: 'Deploy',
    value: 'deploy',
  },
];

export const vectorStatusFilterOptions = [
  {
    text: 'Active',
    value: 'active',
  },
  {
    text: 'Created',
    value: 'created',
  },
  {
    text: 'In progress',
    value: 'in progress',
  },
  {
    text: 'Planning',
    value: 'planning',
  },
  {
    text: 'Backlog',
    value: 'backlog',
  },
  {
    text: 'Blocked',
    value: 'blocked',
  },
  {
    text: 'Paused',
    value: 'paused',
  },
  {
    text: 'Protected',
    value: 'protected',
  },
  {
    text: 'Closed',
    value: 'closed',
  },
];

// ----------------- MILESTONE FILTERS -----------------
export const importanceFilterOptions = [
  {
    text: 'Minor',
    value: 'minor',
  },
  {
    text: 'Medium',
    value: 'medium',
  },
  {
    text: 'Significant',
    value: 'significant',
  },
  {
    text: 'Critical',
    value: 'critical',
  }
];

export const milestoneStatusFilterOptions = [
  {
    text: 'Created',
    value: 'created',
  },
  {
    text: 'In progress',
    value: 'in progress',
  },
  {
    text: 'Partially completed',
    value: 'partially completed',
  },
  {
    text: 'Completed',
    value: 'completed',
  },
  {
    text: 'Failed',
    value: 'failed',
  },
  {
    text: 'Draft',
    value: 'draft',
  }
];

// ----------------- GOAL FILTERS -----------------
export const goalStatusFilterOptions = [
  {
    text: 'Created',
    value: 'created',
  },
  {
    text: 'In progress',
    value: 'in progress',
  },
  {
    text: 'Completed',
    value: 'completed',
  },
  {
    text: 'Failed',
    value: 'failed',
  },
  {
    text: 'Backlog',
    value: 'backlog',
  }
];

// ----------------- DOCUMENT FILTERS -----------------
export const documentTypeFilterOptions = [
  {
    text: 'Document',
    value: 'projectDocument',
  },
  {
    text: 'Note',
    value: 'projectIdea',
  },
  {
    text: 'Spreadsheet',
    value: 'projectSpreadsheet',
  },
];

export const documentStatusFilterOptions = [
  {
    text: 'Created',
    value: 'created',
  },
  {
    text: 'Important',
    value: 'important',
  },
  {
    text: 'Outdated',
    value: 'outdated',
  },
];

// ----------------- PROJECT FILTERS -----------------

export const projectTypeFilterOptions = [
  {
    text: 'Document',
    value: 'projectDocument',
  },
  {
    text: 'Note',
    value: 'projectIdea',
  },
  {
    text: 'Spreadsheet',
    value: 'projectSpreadsheet',
  },
  {
    text: 'Goal',
    value: 'goal',
  },
  {
    text: 'Vector',
    value: 'vector',
  },
  {
    text: 'Issue',
    value: 'issue',
  },
  {
    text: 'Ticket',
    value: 'ticket',
  },
  {
    text: 'Test cycle',
    value: 'testcycle',
  },
  {
    text: 'Test suite',
    value: 'testsuite',
  },
]

export const projectModifiedFilterOptions = [
  {
    text: 'Today',
    value: 'today',
  },
  {
    text: 'This week',
    value: 'this_week',
  },
  {
    text: 'This month',
    value: 'this_month',
  },
  {
    text: 'Last month',
    value: 'last_month',
  },
]

// ----------------- PROJECT FLOW FILTERS -----------------

export const projectFlowTypeFilterOptions = [
  {
    text: 'General',
    value: 'general',
  },
  {
    text: 'Personal',
    value: 'personal'
  }
]

export const projectFlowStatusFilterOptions = [

  {
    text: 'Created',
    value: 'created'
  },
  {
    text: 'Active',
    value: 'active'
  },
  {
    text: 'Closed',
    value: 'closed'
  },
  {
    text: 'Paused',
    value: 'paused'
  },
  {
    text: 'Archive',
    value: 'archive',
  },
]

export const projectFlowUsersFilterOptions = [
  {
    text: 'Member',
    value: 'member',
  },
  {
    text: 'Creator',
    value: 'creator'
  },
  {
    text: 'Owner',
    value: 'owner'
  }  
]
export const projectFlowMetricFilterOptions = [
  {
    text: 'No planning',
    value: 'no-planning',
  },
  {
    text: 'Expired milestone',
    value: 'expired-milestone'
  },
  {
    text: 'Dev score',
    value: 'dev-score'
  }
]

// ----------------- PROJECT AUDIT FILTERS -----------------

export const projectAuditTypeFilterOptions = [
  {
    text: 'General',
    value: 'general',
  },
  {
    text: 'Personal',
    value: 'personal'
  }
]

export const projectAuditStatusFilterOptions = [
  {
    text: 'Archive',
    value: 'archive',
  },
  {
    text: 'Created',
    value: 'created'
  }
]

export const projectAuditUsersFilterOptions = [
  {
    text: 'Member',
    value: 'member',
  },
  {
    text: 'Creator',
    value: 'creator'
  },
  {
    text: 'Owner',
    value: 'owner'
  }  
]

export const projectAuditMetricFilterOptions = [
  {
    text: 'No planning',
    value: 'no-planning',
  },
  {
    text: 'Expired milestone',
    value: 'expired-milestone'
  },
  {
    text: 'Extreme dev score',
    value: 'dev-score'
  },
  {
    text: 'Extreme outdated score',
    value: 'outdated-score'
  },
  {
    text: 'Low tests level',
    value: 'low-tests-level'
  },
  {
    text: 'Low tests efficiency',
    value: 'low-tests-efficiency'
  },
  {
    text: 'Low archive level',
    value: 'low-archive-level'
  },  
]

// ----------------- TEST SUITES FILTERS -----------------

export const typeOfTestingOptions = [
  // {
  //   text: "All",
  //   value: 'all',
  // },
  {
    text: "Functional",
    value: 'functional',
  },
  {
    text: "Non-functional",
    value: 'non-functional',
  },
  {
    text: 'Interface',
    value: 'interface',
  },
  {
    text: "Integration",
    value: 'integration',
  },
  {
    text: "Security",
    value: 'security',
  },
  {
    text: "System",
    value: 'system',
  },
  {
    text: "Usability",
    value: 'usability',
  },
  {
    text: "Compatibility",
    value: 'compatibility',
  },
  {
    text: "Smoke & Sanity",
    value: 'smoke & sanity',
  },
  {
    text: "Regression",
    value: 'regression',
  },
  {
    text: "Perfomance",
    value: 'performance',
  },
  {
    text: "Unit",
    value: 'unit',
  },
  {
    text: "Acceptance",
    value: 'acceptance',
  },
];

export const cycleStatusTags = [
  { text: 'Unexecuted', 
    value: 'unexecuted' 
  },
  { text: 'In progress', 
    value: 'in progress' 
  },
  { text: 'Failed', 
    value: 'fail' 
  },
  { text: 'Passed', 
    value:  'pass' 
  },
  { text: 'Blocked', 
    value:  'block' 
  },
];