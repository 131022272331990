import { Col, Tooltip } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';
import { capitalize } from 'lodash';

function DetailedReportInfoIssuesTable({
  issues = [],
}) {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);

  const [pageLimit, setPageLimit] = useState(5);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const currentIssueList = useMemo(() => (issues
    .slice(currentOffset, currentOffset + pageLimit)), [issues, currentOffset, pageLimit]);

  const columns = useMemo(() => ([
    {
      dataIndex: 'title',
      title: capitalize(t('wms.table.headers.title', 'title')),
      render: (cell, row) => (
        <Tooltip
          title={cell || row.ticketTitle}
        >
          {cell.length > 20 ? `${cell.slice(0, 20)}...` : cell || row.ticketTitle}
        </Tooltip>
      ),
    },
    {
      dataIndex: 'tracker',
      title: capitalize(t('wms.table.headers.tracker', 'Tracker')),
      render: (cell) => cell && (
        <EntityParamTag
          param="tracker"
          value={cell}
          type="char"
        />
      ),
    },
    {
      dataIndex: 'priority',
      title: capitalize(t('wms.table.headers.priority', 'Priority')),
      render: (cell) => cell && (
        <EntityParamTag
          param="priority"
          value={cell}
          type="char"
        />
      ),
    },
    {
      dataIndex: 'trackerTime',
      title: t('wms.reports.stats.billable_hours', 'billable_hours'),
      render: (cell) => <span>{cell}</span>,
    },
  ]), [issues]);

  const init = () => {
    setTotalCount(issues.length);
    setCurrentOffset(0);
    setCurrentPage(1);
  };

  useEffect(() => {
    init();
  }, [issues]);

  const onPageChange = ({ pageLimit: newPageLimit, currentPage: newCurrentPage, offset }) => {
    setCurrentPage(newCurrentPage);
    setCurrentOffset(offset);
    setPageLimit(newPageLimit);
  };

  const remotePagination = {
    sizePerPageList: [5, 10, 25, 50],
    defaultPageLimit: 5,
    totalSize: totalCount,
    page: currentPage,
    onChange: onPageChange,
    // onSizePerPage: onSizePerPageTable,
  };

  return (
    <Col span={24} className="collapsedTable-table">
      <BaseTableWithPagination
        pageLimit={pageLimit}
        changePageLimit={setPageLimit}
        useCustomPagination
        columns={columns}
        data={currentIssueList}
        total={get(remotePagination, 'totalSize')}
        pageSizeOptions={get(remotePagination, 'sizePerPageList')}
        disablePagination={get(remotePagination, 'totalSize') <= get(remotePagination, 'defaultPageLimit')}
        newCurrentPage={get(remotePagination, 'page')}
        defaultPageLimit={get(remotePagination, 'defaultPageLimit')}
        getPaginationOptions={get(remotePagination, 'onChange')}
        rowKey="uuid"
        rowClassName="universal_table_row"
        paginationSize="small"
        headerRowClassName="universal_header_table_row"
      />
    </Col>
  );
}

DetailedReportInfoIssuesTable.propTypes = {
  issues: PropTypes.array,
};

export default DetailedReportInfoIssuesTable;
