import React, { useEffect, useState } from "react";
import { Tooltip, Tag, Divider } from "antd";

import { useExpandModeContext } from "../../commonComponents/expandModeLayout/ExpandModeLayout";

import PropTypes from "prop-types";

import WrapperAvatarsGroupForProjectWithHook from "../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook";
import { toolbarOptions } from "./toolbarOptions";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { HelperText } from "./ToolbarHelper/blocks/SyncStatusText";
import ToolbarHelper from "./ToolbarHelper/ToolbarHelper";

const createButton = (props) => {
  const { className, title, value, idx, icon, padding } = props;
  if (title) {
    return (
      <Tooltip title={title} key={`${title}_${idx}`} mouseEnterDelay={0.5}>
        <button
          className={className}
          value={value || null}
          style={{ padding: padding || "auto" }}
        >
          {icon || ""}
        </button>
      </Tooltip>
    );
  }
  return (
    <button
      className={className}
      value={value || null}
      style={{ padding: padding || "auto" }}
    ></button>
  );
};

const createSelect = (props) => {
  const { className, title, value, width, idx } = props;

  if (title) {
    return (
      <Tooltip
        title={title}
        key={`${title}_${title}_${idx}`}
        mouseEnterDelay={0.5}
      >
        <div
          style={{ display: "inline-block", width: width || "auto" }}
          className={idx === 0 ? "quill_toolbar_first" : ""}
        >
          <select className={className}>
            {Array.isArray(value) &&
              className === "ql-size" &&
              value.map((size) => (
                <option
                  key={size}
                  selected={size === "14px" ? true : false}
                  value={size}
                >
                  {size}
                </option>
              ))}
          </select>
        </div>
      </Tooltip>
    );
  }
  return (
    <select className={className} style={{ padding: "0 0 " }}>
      {Array.isArray(value) &&
        className === "ql-size" &&
        value.map((size) => (
          <option
            key={size}
            selected={size === "14px" ? true : false}
            value={size}
          >
            {size}
          </option>
        ))}
    </select>
  );
};

export const QuillToolbar = ({ toolbarRef, isConnected, isSave, allUsers }) => {
  const {
    // showMetaInfo,
    // isBaseMode,
    isWideMode,
    isVerticalMode,
    isFullscreenMode,
  } = useExpandModeContext();

  const [currentHeaderHeight, setCurrentHeaderHeight] = useState("50px");

  useEffect(() => {
    const headerElement = document.querySelector("header.ant-layout-header");
    const height = headerElement ? headerElement.offsetHeight + "px" : "50px";
    setCurrentHeaderHeight(height);
  }, []);

  return (
    <div
      id="quill_toolbar"
      ref={toolbarRef}
      style={{
        // position: isFullscreenMode ? 'absolute' : 'sticky',
        top:
          (isFullscreenMode && "0") ||
          (isWideMode && currentHeaderHeight) ||
          (isVerticalMode && "0") ||
          0,
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
        }}
      >
        <div
          className="quill-toolbar-helper"
          style={{ paddingRight: "8px", borderRight: "1px solid #ccced1" }}
        >
          <ToolbarHelper content={<HelperText />} />
        </div>
        <div
          className="wrapper-title"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            padding: "0 8px",
            borderRight: "1px solid #ccced1",
          }}
        >
          Sync status:{" "}
          {isConnected ? (
            <>
              {isSave ? (
                <Tag
                  icon={<CheckCircleOutlined />}
                  color="success"
                  className="quill-toolbar-sync-status"
                >
                  Saved
                </Tag>
              ) : (
                <Tag
                  icon={<SyncOutlined spin />}
                  color="processing"
                  className="quill-toolbar-sync-status"
                >
                  Saving
                </Tag>
              )}
            </>
          ) : (
            <Tag
              icon={<CloseCircleOutlined />}
              color="error"
              className="quill-toolbar-sync-status"
            >
              Unsync
            </Tag>
          )}
        </div>
        <div
          className="wrapper-title"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            paddingLeft: "8px",
          }}
        >
          Editors:{" "}
          <div>
            <WrapperAvatarsGroupForProjectWithHook
              actors={allUsers}
              avatarSize="small"
              avatarGap={4}
              avatarStyles={{ cursor: "pointer" }}
              groupButtonSize="small"
              checkProjectRole
            />
          </div>
        </div>
      </div>
      <Divider style={{ margin: "0 -8px", width: "calc(100% + 16px)" }} />
      {toolbarOptions.map((optArr, idx) => (
        <>
          <span key={`ql-formats-${idx + 1}`} className="ql-formats">
            {optArr.map((optProps) => {
              const { type } = optProps;
              if (type === "button") {
                return createButton({ ...optProps, idx });
              }
              return createSelect({ ...optProps, idx });
            })}
          </span>
        </>
      ))}
    </div>
  );
};

QuillToolbar.propTypes = {
  toolbarRef: PropTypes.any,
  isConnected: PropTypes.bool,
  isSave: PropTypes.bool,
  allUsers: PropTypes.array,
};

export default QuillToolbar;
