import {
  equals,
  filter,
  find,
  findIndex,
  flatten,
  hasPath,
  head,
  indexOf,
  isEmpty,
  isNil,
  last,
  length,
  map,
  path,
  pathOr,
  prop,
  propEq,
  propOr,
  reverse,

} from 'ramda';
import { createSelector } from 'reselect';

import { get } from 'lodash';
import {
  getListOfActors,
  getUsersEntity,
  getUsersFromGroups,
} from '../../entity/selectors/selectors';
import { listOfIssuesFromStore } from '../reducers/issues/IssuesSlicer';
import { getHeadUUID } from '../../MainUtils';

const checkParams = propOr([], 'params');

// PROJECTS

export const getProject = (state) => pathOr([], ['projectManagementService', 'project', 'projectInfo', 'projectData'], state);

export const getProjectUUID = (state) => prop('uuid', head(getProject(state)));

export const getProjectCreatorPerms = (state) => prop('creator_perms', head(getProject(state)));

export const getProjectFetching = (state) => path(['projectManagementService', 'project', 'projectInfo', 'fetching'], state);

export const getListOfProjectsEntity = (state) => pathOr([], ['projectManagementService', 'project', 'listOfProjects', 'projects'], state);

export const getListOfMyProjects = (state) => pathOr([], ['projectManagementService', 'project', 'listOfProjects'], state);

export const getProjectsEntityUuids = (state) => pathOr([], ['projectManagementService', 'project', 'listOfProjects', 'projectsUuids', 'entity_uuids'], state);

export const getListOfProjectsEntityInQuickIssueModal = (state) => path(['projectManagementService', 'project', 'listOfProjects', 'projectsInQuickIssueModal'], state);

export const getNotificationsSelector = (state) => get(state, 'projectManagementService.notifications', {});

export const getFetchingNotifications = (state) => pathOr([], ['projectManagementService', 'notifications', 'fetching'], state);

export const getViewedNotifications = (state) => pathOr([], ['projectManagementService', 'notifications', 'viewed'], state);

export const getUnseenNotifications = (state) => pathOr([], ['projectManagementService', 'notifications', 'unseen'], state);

export const getAllNotifications = (state) => [
  ...getUnseenNotifications(state),
  ...getViewedNotifications(state),
];

export const getSenderFilterNotification = createSelector(getNotificationsSelector, (notifications) => notifications?.filter_by_sender || []);

export const getMentionedProjects = (state) => pathOr([], ['projectManagementService', 'notifications', 'mentioned_projects'], state);

export const getSenders = (state) => pathOr([], ['projectManagementService', 'notifications', 'senders'], state);

export const getIsFetchingNewProject = (state) => path(['projectManagementService', 'project', 'listOfProjects', 'fetching'], state);

export const getListOfProjects = (state) => {
  const isProjectObj = (n) => prop('description', n);

  const fineProjects = reverse(getListOfProjectsEntity(state).map((entity) => filter(isProjectObj, [entity.data])));

  return flatten(fineProjects);
};
export const isListOfProjectFetching = (state) => path(['projectManagementService', 'project', 'listOfProjects', 'projectsFetching'], state);
export const isListOfPersonalProjectsFetching = (state) => path(['projectManagementService', 'project', 'listOfProjects', 'personalFetching'], state);

export const isListOfProjectError = (state) => path(['projectManagementService', 'project', 'listOfProjects', 'error'], state);

export const getProjectParams = createSelector(getProject, (project) => get(project, '[0].params', []));

export const getProjectMembers = (state) => (getProjectParams(state).members === undefined ? [] : getMembers(state, getProjectParams(state).members));

export const getPojectListForSelect = (state) => {
  const checkTitle = (params) => propOr('Title', 'title', params) || 'Title';
  return (
    getListOfProjectsEntity(state).map((item) => ({
      label: checkTitle(item.params),
      value: item.uuid,
      actors: pathOr([], ['params', 'usersSearch'], item),
    }))
  );
};

export const getCommonFetching = (state) => {
  const listOfVectorFetching = path(['projectManagementService', 'project', 'vectors', 'fetching'], state);
  const listOfissuesFetching = getListOfIssuesFetching(state);
  const projectFetching = getProjectFetching(state);
  const vectorFetching = getVectorFetchingDefaultFalse(state);

  return listOfissuesFetching || projectFetching || listOfVectorFetching || vectorFetching;
};

// GLOBAL

export const globalUserFilterTags = (state) => path(['projectManagementService', 'project', 'userFilter', 'tags'], state);
export const isChangedGlobalUserFilter = (state) => path(['projectManagementService', 'project', 'userFilter', 'isChanged'], state);

// ISSUES

export const getListOfIssuesFetching = (state) => path(['projectManagementService', 'project', 'issues', 'fetching'], state);
export const globalUpdateEntity = (state) => path(['projectManagementService', 'project', 'breadcrumbs', 'globalUpdateEntity'], state);

export const issueEntity = (state) => path(['projectManagementService', 'issue', 'data'], state);

export const getIssueUUID = (state) => prop('uuid', head(issueEntity(state)));

export const getIssueSelector = (state) => get(state, 'projectManagementService.issue', {});

export const getIssue = createSelector(getIssueSelector, (issue) => get(issue, 'data[0]', {}));

export const getIssueParams = (state) => propOr([], ['params'], head(issueEntity(state)));

export const getIssueEntityFetching = (state) => path(['projectManagementService', 'issue', 'fetching'], state);

export const getIssueUpdateFetching = (state) => path(['projectManagementService', 'issue', 'fetchingEdit'], state);

export const selectLastCreatedIssue = createSelector(listOfIssuesFromStore, (issues) => get(issues, [0], []));


// TIMELOGS

export const getTimeLogsFetching = (state) => path(['projectManagementService', 'project', 'timelogs', 'fetching'], state);

export const timeLogs = (state) => path(['projectManagementService', 'project', 'timelogs', 'listTimeLog'], state);

export const getTimelogsTotal = (state) => pathOr(0, ['projectManagementService', 'project', 'timelogs', 'total'], state);

export const fetchingChildrenParent = (state) => path(['projectManagementService', 'project', 'universalStorageChildren', 'fetching'], state);

// VECTORS

export const getVectors = (state) => pathOr([], ['projectManagementService', 'project', 'vectors', 'vectors'], state);

export const getVectorsScoringData = (state) => pathOr([], ['projectManagementService', 'project', 'vectors', 'scoringData'], state);

export const getTemporaryVectors = (state) => reverse(pathOr([], ['projectManagementService', 'project', 'vectors', 'tempVectors'], state));

export const getVectorsCount = (state) => length(getVectors(state));

export const getVectorInfo = (state) => pathOr([], ['projectManagementService', 'vector', 'vectorInfo', 'data'], state);

export const getVectorFetching = (state) => path(['projectManagementService', 'vector', 'vectorInfo', 'fetching'], state);

export const getVectorFetchingDefaultFalse = (state) => path(['projectManagementService', 'vector', 'vectorInfo', 'fetchingDefault'], state);

export const listVectorsFetching = (state) => path(['projectManagementService', 'project', 'vectors', 'fetching'], state);

export const vectorsFetching = (state) => path(['projectManagementService', 'project', 'vectors', 'mainFetching'], state);

export const getReleases = (state) => pathOr([], ['projectManagementService', 'project', 'vectors', 'releases'], state);
export const releasesFetching = (state) => path(['projectManagementService', 'project', 'vectors', 'releaseFetching'], state);

export const flagMultiEditVector = (state) => pathOr([], ['projectManagementService', 'project', 'vectors', 'flagFromMultiEdit'], state);

export const uuidsMultiEditVector = (state) => pathOr([], ['projectManagementService', 'project', 'vectors', 'uuidsFromMultiEdit'], state);

export const getVectorsWithoutClosed = createSelector(
  getVectors,
  (items) => items.filter((item) => item.params.status !== 'closed'
    && !isNil(path(['params', 'title'], item))
    && !isEmpty(path(['params', 'title'], item))),
);

export const getVectorsOpenList = createSelector(
  getVectors,
  (items) => items.filter((item) => item.params.status !== 'closed'
    && item.params.status !== 'active'
    && !isNil(path(['params', 'title'], item))
    && !isEmpty(path(['params', 'title'], item))),
);

export const getClosedVectors = createSelector(
  getVectors,
  (items) => items.filter((item) => path(['params', 'status'], item) === 'closed'),
);

export const getVersionVectors = (state) => filter((item) => item.params.type === 'version', getVectors(state));

export const getOpenVersionVectors = createSelector(
  getVectors,
  (items) => items.filter((version) => path(['params', 'type'], version) === 'version'
    && path(['params', 'status'], version) !== 'closed'
    && path(['params', 'status'], version) !== 'active'),
);

export const getActiveVersionVectors = createSelector(
  getVectors,
  (items) => items.filter((version) => path(['params', 'type'], version) === 'version'
    && path(['params', 'status'], version) === 'active'),
);

export const getDirectionsVectors = (state) => filter((item) => item.params.type === 'direction', getVectors(state));

export const getOpenDirectionVectors = createSelector(
  getVectors,
  (items) => items.filter((version) => path(['params', 'type'], version) === 'direction'
    && path(['params', 'status'], version) !== 'closed'),
);

export const getActiveVectors = (state) => filter((item) => path(['params', 'status'], item) === 'active', getVectors(state));

export const getVersionVectorsCount = (state) => length(getVersionVectors(state));

export const getVectorParams = createSelector(
  getVectorInfo,
  (item) => (
    {
      ...checkParams(head(item)),
      uuid: prop('uuid', head(item)),
      created: prop('created', head(item)),
      modified: prop('modified', head(item)),
      uinfo: prop('uinfo', head(item)),
      actor: prop('actor', head(item)),
      parent: prop('parent', head(item)),
    }
  ),
);

export const getVectorUUID = (state) => propOr('', 'uuid', head(getVectorInfo(state)));

export const getUserUuid = (state) => path(['EntityService', 'userInfo', 'user', 'uuid'], state);

//add selector 
export const selectLastCreatedVector = createSelector(getVectors, (vectors) => get(vectors, [0], []));

// Entity

export const getMembers = (state, members) => {
  const getEntityName = (userID) => getUsersEntity(state)[findIndex(propEq('uuid', userID))(getUsersEntity(state))];

  const userFormatter = (users) => {
    const convertUsers = typeof users === 'string' ? JSON.parse(users) : users;
    const checkName = (param) => (isNil(param) ? '' : param);

    try {
      return (
        convertUsers.filter((item) => !isNil(prop('uinfo', getEntityName(item)))).map((user) => ({
          value: user,
          label: `${checkName(prop('first_name', getEntityName(user).uinfo))} ${checkName(prop('last_name', getEntityName(user).uinfo))}`,
        })));
    } catch (e) {
      // console.log('Error getMembers', e);
      return [];
    }
  };

  return userFormatter(members);
};

// FILES

export const decodeFileSelector = (files) => map(((base64file) => {
  const arr = base64file.file.split(',');
  const mime = base64file.params.mime_type;
  const bstr = atob(arr[0]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const file = new File([u8arr], base64file.params.name, { type: mime });
  return ({ ...base64file, file });
}), files);

// COMMENTS

export const getComments = (state) => pathOr([], ['projectManagementService', 'listOfIssueComments', 'comments'], state);

export const getCommentsFetching = (state) => path(['projectManagementService', 'listOfIssueComments', 'fetching'], state);

export const lastUuidForComments = (state) => path(['projectManagementService', 'listOfIssueComments', 'uuidForComment'], state);

export const needUpdateListComments = (state) => pathOr([], ['projectManagementService', 'listOfIssueComments', 'needUpdateList'], state);

export const getTotalComments = (state) => pathOr(0, ['projectManagementService', 'listOfIssueComments', 'totalComments'], state);

export const getUserComments = (state) => pathOr(0, ['projectManagementService', 'listOfIssueComments', 'userComments'], state);

export const getSystemComments = (state) => pathOr(0, ['projectManagementService', 'listOfIssueComments', 'systemComments'], state);

export const getValidComments = createSelector(
  getComments,
  (data) => {
    if (!data || data.length === 0) {
      return [];
    }

    return (
      map(
        (item) => ({
          ...item,
          partial: pathOr(false, ['params', 'partial'], item),
          system: pathOr(false, ['params', 'system'], item),
          username: item.uinfo && (`${prop('first_name', item.uinfo)} ${prop('last_name', item.uinfo)}`),
        }),
        data,
      )
    );
  },
);

export const getMe = (state) => path(['personalInfoService', 'userInfo', 'user', 'UserInfoReducer', 'user', 'uuid'], state);

export const getMyInfo = (state) => path(['personalInfoService', 'userInfo', 'user', 'UserInfoReducer', 'user'], state);

export const isGettingUserFetching = (state) => path(['personalInfoService', 'userInfo', 'user', 'UserInfoReducer', 'fetching'], state);

// AREAS
export const getAreasFetching = (state) => path(['projectManagementService', 'project', 'areas', 'fetching'], state);

export const getAreas = (state) => path(['projectManagementService', 'project', 'areas', 'areas'], state);

export const getAreasForSelect = createSelector(
  getAreas,
  (items) => items.map((item) => ({ value: item.uuid, label: item.name })),
);

// Profile staff

export const getPublicListOfTickets = (state) => path(['ProfileService', 'profileTickets', 'tickets'], state);

export const getPrivateListOfTickets = (state) => path(['ProfileService', 'profileTickets', 'sharedTickets'], state);

// QA

export const getFetchingNewTestSuite = (state) => path(['projectManagementService', 'qa', 'testSuites', 'fetchingNewSuite'], state);

export const getAllTestSuites = (state) => path(['projectManagementService', 'qa', 'testSuites', 'data'], state);

export const getTestCycles = (state) => path(['projectManagementService', 'qa', 'testCycles', 'data'], state);

export const getAllTestCycles = (state) => path(['projectManagementService', 'qa', 'testCycles', 'allList'], state);

export const getAllTestCasesInSuite = (state) => path(['projectManagementService', 'qa', 'testCases', 'dataSuite'], state);

export const getAllTestCasesInCycle = (state) => path(['projectManagementService', 'qa', 'testCases', 'dataCycle'], state);

export const getListOfCheckLists = (state) => path(['projectManagementService', 'qa', 'checklists', 'data'], state);

export const getAllTestCasesFetching = (state) => path(['projectManagementService', 'qa', 'testCases', 'fetching'], state);

export const getAllTestSuitesFetching = (state) => path(['projectManagementService', 'qa', 'testSuites', 'fetching'], state);

export const getAllTestCyclesFetching = (state) => path(['projectManagementService', 'qa', 'testCycles', 'fetching'], state);

export const getListOfCheckListsFetching = (state) => path(['projectManagementService', 'qa', 'checklists', 'fetching'], state);

export const areQaDataFetching = (state) => getAllTestCasesFetching(state) || getAllTestSuitesFetching(state) || getAllTestCyclesFetching(state);

// One test CASE

export const getTestCase = (state) => path(['projectManagementService', 'qa', 'testCases', 'singleCase'], state);
export const getTestCaseUUID = (state) => getHeadUUID(getTestCase(state));
export const getTestCaseParams = (state) => propOr([], 'params', head(getTestCase(state)));
export const lastActiveTestCaseUuid = (state) => propOr('', 'uuid', head(getTestCase(state)));

// One test CASE in suite
export const singleCaseInSuite = (state) => path(['projectManagementService', 'qa', 'breadCrumbInQa', 'singleTestCaseFromSuite'], state);
export const getTestCaseParamsInSuite = (state) => propOr([], 'params', head(singleCaseInSuite(state)));
export const getTestCaseUuidInSuite = (state) => propOr('', 'uuid', head(singleCaseInSuite(state)));

// One test CASE in cycle
export const singleCaseInCycle = (state) => path(['projectManagementService', 'qa', 'breadCrumbInQa', 'singleTestCaseFromCycle'], state);
export const getTestCaseParamsInCycle = (state) => propOr([], 'params', head(singleCaseInCycle(state)));
export const getTestCaseUuidInCycle = (state) => propOr('', 'uuid', head(singleCaseInCycle(state)));

// One test SUITE
export const getTestSuite = (state) => path(['projectManagementService', 'qa', 'testSuites', 'singleSuite'], state);

export const getTestSuiteParams = (state) => propOr([], 'params', head(getTestSuite(state)));

export const getTestSuiteUUID = (state) => prop('uuid', head(getTestSuite(state)));

// One test CHECKLIST
export const getChecklist = (state) => head(path(['projectManagementService', 'qa', 'checklists', 'singleChecklist'], state));

export const getChecklistParams = (state) => propOr([], 'params', getChecklist(state));

export const getChecklistUUID = (state) => prop('uuid', getChecklist(state));

export const getChecklistFetching = (state) => prop(['projectManagementService', 'qa', 'checklists', 'fetchingChecklist'], getChecklist(state));

export const getIsUpdatingChecklist = (state) => prop(['projectManagementService', 'qa', 'checklists', 'updating'], getChecklist(state));

// One test CYCLE
export const getTestCycle = (state) => path(['projectManagementService', 'qa', 'testCycles', 'singleCycle'], state);

export const getTestCycleParams = (state) => propOr([], 'params', head(getTestCycle(state)));

export const getTestCycleUUID = (state) => prop('uuid', head(getTestCycle(state)));

export const fetchingTestCase = (state) => path(['projectManagementService', 'qa', 'testCases', 'fetchingCase'], state);

export const fetchingTestCaseCycle = (state) => path(['projectManagementService', 'qa', 'testCases', 'fetchingTestCaseCycle'], state);

export const fetchingTestCaseSuite = (state) => path(['projectManagementService', 'qa', 'testCases', 'fetchingTestCaseSuite'], state);

export const allCasesFetching = (state) => path(['projectManagementService', 'qa', 'testCases', 'allCasesFetching'], state);

export const checkTestSuiteSelected = createSelector(getTestSuiteUUID, (uuid) => !isNil(uuid) && !isEmpty(uuid));
export const checkTestCycleSelected = createSelector(getTestCycleUUID, (uuid) => !isNil(uuid) && !isEmpty(uuid));

export const customTestCycles = createSelector(
  getTestCycles,
  getListOfActors,
  (arrData, listOfActors) => arrData.map((item, i) => {
    const parsedItem = item;

    if (hasPath(['params', 'executed_by'], parsedItem)) {
      const executedUuid = path(['params', 'executed_by'], parsedItem);
      const foundActor = find((actor) => equals(prop('uuid', actor), executedUuid), listOfActors);


      parsedItem.executed_by = foundActor
        ? `${path(['uinfo', 'first_name'], foundActor)} ${path(['uinfo', 'last_name'], foundActor)}`
        : '-';
    }

    return {
      ...parsedItem,
      customKey: i,
    };
  }),
);

export const getTestCycleListForSelect = createSelector(getAllTestCycles, (item) => {
  const actualData = item.filter((data) => data.status !== 'pass' && data.status !== 'fail' && data.status !== 'block');

  return map((cycle) => ({
    label: pathOr('No title', ['title'], cycle),
    value: prop('uuid', cycle),
  }), actualData);
});

// QA ARCHIVE

export const listTestCycleArchive = (state) => pathOr([], ['projectManagementService', 'qa', 'archiveTestCycle', 'listTestCycleArchive'], state);

export const oneTestCycleArchive = (state) => path(['projectManagementService', 'qa', 'archiveTestCycle', 'oneTestCycleArchive'], state);

export const getArchiveTestCycleUUID = (state) => prop('uuid', head(oneTestCycleArchive(state)));

export const listTestCaseInCycleFromArchive = (state) => path(['projectManagementService', 'qa', 'testCaseFromArchive', 'listTestCaseInCycleFromArchive'], state);

export const fetchingTestCaseInCycleFromArchive = (state) => path(['projectManagementService', 'qa', 'testCaseFromArchive', 'fetchingTestCaseInCycleFromArchive'], state);

export const oneTestCaseInCycleFromArchive = (state) => path(['projectManagementService', 'qa', 'testCaseFromArchive', 'oneTestCaseInCycleFromArchive'], state);


export const currentSelectedUUID = (state, isArchive) => (isArchive ? getArchiveTestCycleUUID(state) : getTestCycleUUID(state));

// DOCUMENT

export const allProjDocsAndIdea = (state) => path(['projectManagementService', 'docView', 'projectDocuments', 'listDocsAndIdea'], state);

export const getTotalDocsAndIdea = (state) => path(['projectManagementService', 'docView', 'projectDocuments', 'totalDocsAndIdea'], state);

export const areFetchingProjDocs = (state) => path(['projectManagementService', 'docView', 'projectDocuments', 'fetching'], state);

export const getProjDoc = (state) => path(['projectManagementService', 'docView', 'projectDocuments', 'singleDoc'], state);

export const getDocumentContent = (state) => path(['projectManagementService', 'docView', 'projectDocuments', 'documentContent'], state);

export const getDocumentUUID = (state) => prop('uuid', head(getProjDoc(state)));

export const getProjDocFetching = (state) => path(['projectManagementService', 'docView', 'projectDocuments', 'fetchingDoc'], state);

export const getProjDocCopy = (state) => path(['projectManagementService', 'docView', 'projectHistoryDoc', 'singleDocCopy'], state);

export const allProjectHistoryDocs = (state) => path(['projectManagementService', 'docView', 'projectHistoryDoc', 'listOfDocs'], state);

export const areProjectHistoryDocsFetching = (state) => path(['projectManagementService', 'docView', 'projectHistoryDoc', 'fetching'], state);

export const fetchingNewDoc = (state) => path(['projectManagementService', 'docView', 'projectDocuments', 'fetchingNewDoc'], state);

export const getProjectDocumentUUID = (state) => propOr('', 'uuid', head(getProjDoc(state)));

export const getProjectDocumentParams = createSelector(getProjDoc, (doc) => get(doc, '[0].params', []));

export const getProjectDocumentCopyUUID = (state) => propOr('', 'uuid', head(getProjDocCopy(state)));

export const getAllProjectHistoryDocs = (state) => reverse(allProjectHistoryDocs(state).map((item, i) => ({ ...item, customId: i })));

export const getProjDocOrIdeas = createSelector(getProjDoc, (doc) => doc.map((item) => {
  const { entity_type, params, modified } = item;
  // console.log('item', item);

  if (!isNil(params.description && entity_type === 'projectIdea')) {
    return {
      ...item,
      params: {
        text: isNil(params.text)
          ? (`<div>${params.description}</div>`)
          : params.text,
        title: params.title,
        updated: modified,
      },
    };
  }
  return { ...item };
}));

export const selectLastCreatedDocument = createSelector(allProjDocsAndIdea, (doc) => get(doc, '[0]', {}));

// CookBookMenuSection

export const commonCookBookEntityUuid = (state) => path(['projectManagementService', 'project', 'cookBook', 'cookBookEntity'], state);

export const myUserCookBookEntityPublicUuid = (state) => path(['projectManagementService', 'project', 'cookBook', 'cookBookPublicEntityUuid'], state);
export const newPublicCookBookEntityUuid = (state) => pathOr('', ['projectManagementService', 'project', 'mainCookBookTemplateEntity', 'dataPublic', 'uuid'], state);

export const cookBookTemplateFetching = (state) => path(['projectManagementService', 'project', 'cookBook', 'fetching'], state);

export const cookBookFetchingCommon = (state) => path(['projectManagementService', 'project', 'cookBook', 'fetchingCommon'], state);

export const cookBookFetchingPublic = (state) => path(['projectManagementService', 'project', 'cookBook', 'fetchingPublic'], state);

export const cookBookPublicTemplate = (state, type = 'All') => {
  let data = path(['projectManagementService', 'project', 'cookBook', 'listTemplatePublicCookBook'], state);

  if (type === 'vectors' && !isNil(data) && data.length !== 0) {
    data = data.filter((item) => item.entityType === 'vector');
  }

  const templateList = (!isNil(data) && data.length !== 0)
    ? data.map((item) => (
      {
        entityType: pathOr('', ['entityType'], item),
        label: item.label,
        value: item.value,
        uuid: item.uuid,
      }
    ))
    : [];
  return templateList;
};

export const cookBookTemplate = (state, type = 'All') => {
  let data = path(['projectManagementService', 'project', 'cookBook', 'listTemplateCookBook'], state);

  if (type === 'vectors' && !isNil(data) && data.length !== 0) {
    data = data.filter((item) => item.entityType === 'vector');
  }

  const templateList = (!isNil(data) && data.length !== 0)
    ? data.map((item) => (
      {
        entityType: pathOr('', ['entityType'], item),
        label: item.label,
        value: item.value,
        uuid: item.uuid,
      }
    ))
    : [];
  return templateList;
};

// AUDIT

export const getInfoFetching = (state) => getProjectFetching(state)
  || getVectorFetching(state)
  || getIssueEntityFetching(state);
