import { UsersPartitionConstants } from '../../constants/Constants';

const initialState = {
  isLoading: false,
  data: {},
  isError: '',
};

const mainPublicUserReportLogsReducer = (state = initialState, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case UsersPartitionConstants.GET_MAIN_USER_REPORT_LOGS_ENTITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UsersPartitionConstants.GET_MAIN_USER_REPORT_LOGS_ENTITY_SUCCESS:
      return {
        ...state,
        data: Array.isArray(payload) ? payload[0] : payload,
        isLoading: false,
        isError: '',
      };
    case UsersPartitionConstants.GET_MAIN_USER_REPORT_LOGS_ENTITY_FAILURE:
      return {
        ...state,
        data: {},
        isLoading: false,
        isError: payload,
      };
    default: return state;
  }
};

export default mainPublicUserReportLogsReducer;
