import { Col } from 'antd';
import { bool } from 'prop-types';
import React from 'react';

import './personalAudit.scss';

import SectionNotification from './sectionNotification/SectionNotification';
import DailyReportAudit from './sectionReport/DailyReportAudit';
import SectionTasks from './sectionTasks/SectionTasks';

function PersonalAudit({
  otherUser,
}) {
  return (
    <Col span={24} className="mt-3">
      <Col span={13}>
        <SectionNotification otherUser={otherUser} />
        <DailyReportAudit />
        <SectionTasks />
      </Col>
    </Col>
  );
}

PersonalAudit.propTypes = {
  otherUser: bool,
};

export default PersonalAudit;
