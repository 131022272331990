/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
};

const startWorkReportModalSlice = createSlice({
  name: 'startAndFinishWorkSlice',
  initialState,
  reducers: {
    setDataToStartWorkModal: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { setDataToStartWorkModal } = startWorkReportModalSlice.actions;

export default startWorkReportModalSlice.reducer;
