import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  setCurrentQuillInstance,
  setDeletedDocHtml,
} from "../../../reducers/slicers/quillSlicer";

// ------------------ selectors -------------------
import { getUser } from "../../../../entity/selectors/selectors";
import { getConfig } from "../../../../config/selectors/selectors";

// ------------------ Yjs, Quill ------------------
import Quill from "quill";
import "quill/dist/quill.snow.css";
import QuillCursors from "quill-cursors";
import { QuillBinding } from "y-quill";
import * as Y from "yjs";
import { WebsocketProvider } from "y-websocket";

// ------------------ custom modules -----------------
import { CustomImageResize } from "./customModules/CustomImageResize";
import { CustomClipboard } from "./customModules/CustomPasteModule";

// ------------------ components ---------------------
import QuillToolbar from "./QuillToolbar";
import { Modal, Spin } from "antd";
import { ErrorBlock } from "./ErrorBlock/ErrorBlock";

// ------------------  utils, const ------------------
import { toolbarFontSizeArr } from "./toolbarOptions";
import { getSessionTokenFor } from "../../../../api/appConfig";
import { useExpandModeContext } from "../../commonComponents/expandModeLayout/ExpandModeLayout";
import "./QuillEditor.scss";
import "./customModules/blots/DividerBlot";
import {
  handleDivider,
  setupDeleteHandler,
} from "./customModules/blots/DividerBlot";
import { handleQuillImage } from "./customModules/blots/ImageBlot";
import { configUrlEntity } from "../../../../api/appConfig";
import { handleTable } from "./customModules/blots/TableBlot";


const Size = Quill.import("attributors/style/size");

Size.whitelist = toolbarFontSizeArr;
Quill.register(Size, true);
Quill.register("modules/imageResize", CustomImageResize);
Quill.register("modules/cursors", QuillCursors);
Quill.register("modules/clipboard", CustomClipboard, true);

window.Quill = Quill;

const QuillEditor = ({
  defaultPartition,
  docUUID,
  defaultActualState = "0",
  isProjectDescriptionDocument = false,
  deletedDocHtml = "",
  startHtmlString = "",
  startStateVector = "",
  isOnlyVertical = false,
  withoutConnection,
  disabled,
  quillUnmountFunction,
  reloadDocumentWithoutSaving,
}) => {
  const editorRefWrapper = useRef(null);
  const editorRef = useRef(null);
  const providerRef = useRef(null);
  const documentRef = useRef(null);
  const toolbarRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);

  const [update, setUpdate] = useState(0);
  const isUpdate = useRef(false);
  const [isSave, setIsSave] = useState(true);

  const user = useSelector(getUser);
  const config = useSelector(getConfig);

  const dispatch = useDispatch();

  const [allUsers, setAllUsers] = useState([]);

  const [focused, setFocused] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [isErrorBlock, setIsErrorBlock] = useState(false);

  const { isVerticalMode, isFullscreenMode } = !withoutConnection
    ? useExpandModeContext()
    : {};

  const handleReload = () => {
    if (reloadDocumentWithoutSaving) {
      reloadDocumentWithoutSaving();
    }
  };

  const handleCloseByError = async () => {
    setIsErrorModal(true);
    setIsConnected(false);
    editorRef.current.enable(false);
    const message =
      "Something went wrong! You have been disconnected from the server with the document. Try to reload and connect again?";
    Modal.confirm({
      title: "Warning",
      content: message,
      // icon: <ExclamationCircleOutlined />,
      cancelText: "Cancel",
      okText: "Reload",
      maskClosable: true,
      onCancel: () => {
        setIsErrorBlock(true);
        setIsErrorModal(false);
      },
      onOk: () => {
        setIsErrorModal(false);
        handleReload();
      },
    });
  };

  useEffect(() => {
    if (isConnected && update > 0) {
      setIsSave(false);
      const timer = setTimeout(() => setIsSave(true), 1000);
      return () => clearTimeout(timer);
    }
  }, [update, isConnected]);

  const getPartitionName = (config, defaultPartition) => {
    const defaultPartitionName = "project_management";
    if (!Array.isArray(config) || !defaultPartition) {
      return defaultPartitionName;
    }
    const result = config.find(
      (item) => item?.params?.name === defaultPartition
    );
    return result?.partition_name || defaultPartitionName;
  };

  useEffect(() => {
    if (docUUID) {
      // console.log('crdt quill startStateVector', startStateVector);

      const ydoc = new Y.Doc();
      const ytext = ydoc.getText("quill");
      const myName = user?.uinfo?.first_name + user?.uinfo?.last_name;   
      const entityUrl = configUrlEntity;
      const parsedUrl = new URL(entityUrl);
      const wsUrl = `wss://${parsedUrl.hostname}/ws`;

      documentRef.current = ydoc;

      // Настройка Quill редактора
      const editor = new Quill(editorRefWrapper.current, {
        theme: "snow",
        modules: {
          table: true,
          tableUI: true,
          cursors: true, // Активировать модуль курсоров          
          toolbar: withoutConnection
            ? false
            : {
                // Нужен ли тулбар над редактором
                container: "#quill_toolbar",
                handlers: {
                  divider: handleDivider,
                  image: handleQuillImage,
                  table: handleTable
                },
              },
          imageResize: {
            disabled: withoutConnection,
          },
          clipboard: true,
          history: {
            delay: 1000,
            maxStack: 500,
            userOnly: true,
          },
        },
      });

      editor.keyboard.addBinding({
        key: "y",
        ctrlKey: true,
        handler: function () {
          editor.history.redo();
          return false; // предотвращает выполнение возможных других обработчиков
        },
      });

      editor.keyboard.addBinding({
        key: "l",
        ctrlKey: true,
        altKey: true,
        handler: handleDivider,
      });

      setupDeleteHandler(editor);

      editorRef.current = editor;
      dispatch(setCurrentQuillInstance(editor));
      if (startStateVector) {
        // console.log('crdt quill startStateVector', startStateVector)
        Y.applyUpdate(ydoc, startStateVector);
      }

      // console.log('crdt after quill startStateVector', startStateVector);
      ydoc.on("update", () => {
        setUpdate((prev) => prev + 1);
        isUpdate.current = true;
      });
      
      //Логика отключения соединения при переходе на другую вкладку
      const handleTabVisibilityChange = () => {
        if (document.hidden) {
          // Вкладка неактивная - отключаем соединение
          provider.shouldConnect = false;
          provider.disconnect();
        } else {
          // Вкладка активная - подключаем соединение
          provider.shouldConnect = true;
          provider.connect();
        }
      };
  
      document.addEventListener('visibilitychange', handleTabVisibilityChange);
      //

      const partition_name = getPartitionName(config, defaultPartition);
      const provider = new WebsocketProvider(wsUrl, docUUID, ydoc, {
        params: {
          user_name: myName,
          token: getSessionTokenFor.entity(),
          partition_name: partition_name,
        },
        connect: !withoutConnection,
      });

      // provider.on("connection-error", function () {
      //   provider.shouldConnect = false;
      //   if (!isErrorModal) {
      //     handleCloseByError(provider);
      //   }
      // });

      providerRef.current = provider;

      provider.awareness.setLocalStateField("user", {
        name: myName,
        color: "green",
        uuid: user?.uuid,
      });
      // Связывание Yjs и Quill
      if (withoutConnection) {
        new QuillBinding(ytext, editor);
      } else {
        // console.log('crdt QuillBinding ytext', ytext.toDelta())
        new QuillBinding(ytext, editor, provider.awareness);
      }

      if (deletedDocHtml) {
        // console.log('crdt deletedDocHtml', deletedDocHtml.length)
        editor.clipboard.dangerouslyPasteHTML(deletedDocHtml);
        editor.setSelection(deletedDocHtml.length);
        dispatch(setDeletedDocHtml(""));
      }

      if (startHtmlString) {
        // console.log('crdt startHtmlString', startHtmlString.length)
        editor.clipboard.dangerouslyPasteHTML(startHtmlString);
        editor.setSelection(startHtmlString.length);
      }

      return () => {
        try {
          document.removeEventListener('visibilitychange', handleTabVisibilityChange);
          if (!withoutConnection) {
            const newTextLength =
              isUpdate.current && isProjectDescriptionDocument
                ? editorRef?.current.getText()?.length || 0
                : null;
            const userDataArr = Array.from(
              providerRef.current.awareness.getStates()
            );

            quillUnmountFunction({
              docUUID,
              defaultPartition,
              defaultActualState,
              withoutConnection,
              newTextLength,
              userDataArr,
              editorRef,
              providerRef,
              documentRef,
            });
          }
        } catch (e) {
          console.log("crdt error", e);
        }
      };
    }
  }, [docUUID]);

  useEffect(() => {
    if (
      withoutConnection &&
      editorRef.current &&
      documentRef.current &&
      startStateVector
    ) {
      Y.applyUpdate(documentRef.current, startStateVector);
    }
  }, [startStateVector]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.enable(!disabled);
    }
  }, [disabled, editorRef.current]);

  useEffect(() => {
    if (providerRef?.current) {
      providerRef.current.on("status", function ({ status }) {
        setIsConnected(status === "connected");
      });
    }
  }, [providerRef?.current]);

  useEffect(() => {
    const provider = providerRef.current;
    if (provider && !withoutConnection) {
      const updateClients = () => {
        const userDataArr = Array.from(provider.awareness.getStates());

        const allUsers = Array.isArray(userDataArr)
          ? userDataArr.map((arr) => arr[1]?.user?.uuid)
          : [];

        const filteredArray = allUsers.filter(
          (item, pos) => allUsers.indexOf(item) == pos
        );

        setAllUsers(filteredArray);
      };
      provider.awareness.on("update", updateClients);
      updateClients();

      return () => {
        provider.awareness.off("update", updateClients);
      };
    }
  }, [providerRef?.current]);

  const handleFocusIn = () => {
    setFocused(true);
  };

  const handleFocusOut = () => {
    setFocused(false);
  };

  return (
    <>
      <div className="quill-editor-wrapper">
        {!withoutConnection && (
          <QuillToolbar
            toolbarRef={toolbarRef}
            isConnected={isConnected}
            isSave={isSave}
            allUsers={allUsers}
          />
        )}
        <div
          id={docUUID}
          className={`ql-container ql-snow quill-inner-wrapper ${
            (isOnlyVertical || isVerticalMode || isFullscreenMode) && "vertical"
          }`}
          ref={editorRefWrapper}
          onFocus={handleFocusIn}
          onBlur={handleFocusOut}
          style={{
            position: "relative",
            width: "100%",
            border: "1px solid",
            borderColor: focused ? "#1677ff" : "#ccc",
            backgroundColor: "white",
            minHeight: "400px"
          }}
        />
        {!isConnected && !isErrorBlock && <div className="quill-disable-block-wrapper start-loader"><Spin /></div>}
        {isErrorBlock && <ErrorBlock callback={handleReload} />}
      </div>
    </>
  );
};

QuillEditor.propTypes = {
  defaultPartition: PropTypes.string,
  docUUID: PropTypes.string,
  isProjectDescriptionDocument: PropTypes.bool,
  startHtmlString: PropTypes.string, // diff-match-patch parsed initial state (only when you first log in to old documents)
  startStateVector: PropTypes.any, // CRDT parsed initial state
  deletedDocHtml: PropTypes.string, // the initial data for the restored document, after it has been deleted by another user
  parentWrapperRef: PropTypes.any,
  isOnlyVertical: PropTypes.bool,
  withoutConnection: PropTypes.bool,
  disabled: PropTypes.bool,
  quillUnmountFunction: PropTypes.func,
  defaultActualState: PropTypes.string,
  reloadDocumentWithoutSaving: PropTypes.func,
};

export default QuillEditor;
