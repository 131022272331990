import React, { useEffect, useState } from "react";
import { func, number } from "prop-types";

import Icon from "@mdi/react";
import { mdiMinus, mdiPlus } from "@mdi/js";

import { Flex, Input } from "antd";

let timerSearch;

export default function TotalDayHoursButtons({
  availableHours,
  onActionCallback,
}) {
  const [newTime, setNewTime] = useState(availableHours);

  const btnPlusMinusClass = 'pr-1 pl-1 cursor-pointer rounded-r-sm border border-green-500 text-base text-green-500 hover:bg-green-500 hover:text-white';
  const btnPlusMinusStyle = { height: 20 };

  const inputClass = '!text-xs text-green-500 text-center rounded-none border-l-0 border-r-0 border-green-500';

  const onTimeChange = (value) => {
    if (value === '') {
      setNewTime('')
      return;
    }

    const parsedValue = parseInt(value, 10);

    if (parsedValue >= 0 && parsedValue <= 24) {
      setNewTime(parsedValue);

      clearTimeout(timerSearch);

      timerSearch = setTimeout(() => {
        if (parsedValue !== availableHours) {
          onActionCallback(parsedValue, 'day-available-time-update');
        }
      }, 1000);
    }
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      clearTimeout(timerSearch);
      onActionCallback(newTime, 'day-available-time-update');
    }
  };

  const onMinusOrPlusClick = (value) => {
    const parsedValue = parseInt(value, 10);
    if (parsedValue && parsedValue >= 0 && parsedValue <= 24) {
      onActionCallback(parsedValue, 'day-available-time-update');
    }
  }

  useEffect(() => {
    if (availableHours && availableHours !== newTime) {
      setNewTime(availableHours);
    }
  }, [availableHours]);

  return (
    <Flex>
      <Flex
        align='center'
        justify="center"
        style={btnPlusMinusStyle}
        className={btnPlusMinusClass}
        onClick={() => onMinusOrPlusClick(+newTime - 1)}
      >
        <Icon path={mdiMinus} size={0.6} />
      </Flex>
      <Input
        value={newTime}
        onKeyDown={onKeyDown}
        min={0}
        max={24}
        style={{ width: 30, height: 20 }}
        className={inputClass}
        onChange={(e) => onTimeChange(e.target.value)}
        size='small'
      />
      <Flex
        align='center'
        justify="center"
        style={btnPlusMinusStyle}
        className={btnPlusMinusClass}
        onClick={() => onMinusOrPlusClick(+newTime + 1)}
      >
        <Icon path={mdiPlus} size={0.6} />
      </Flex>
    </Flex>)
}

TotalDayHoursButtons.propTypes = {
  availableHours: number,
  onActionCallback: func,
}