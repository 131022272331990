import { get } from "lodash";
import { pathOr } from "ramda";

export const getPublicDailyStorage = (state) =>
  pathOr({}, ["ProfileService", "mainPublicDailyReducer", "data"], state);
export const getMainPublicDailyFetching = (state) =>
  pathOr([], ["ProfileService", "mainPublicDailyReducer", "isLoading"], state);
export const getPublicDailyStorageUUID = (state) =>
  get(getPublicDailyStorage(state), "uuid");

export const getTodayDaily = (state) =>
  get(state, "ProfileService.dailyReducer.data");
export const getDailyUUID = (state) => get(getTodayDaily(state), "uuid");
export const getTodayDailyFetching = (state) =>
  get(state, "ProfileService.dailyReducer.fetching");

export const dailyPlanFetchingForActivityInPrivatePlan = (state) =>
  get(
    state,
    "ProfileService.dailyReducer.fetchingForActivityInPrivatePlanning"
  );

export const dataForDuplicate = (state) =>
  get(state, "ProfileService.dailyReducer.dataForDuplicate", []);

export const draggableElementActivity = (state) =>
  get(state, "ProfileService.dailyReducer.dragAndDropElement", {});

export const needDublicateActivity = (state) =>
  get(state, "ProfileService.dailyReducer.dublicateActivities", []);
export const getSyncData = (state) =>
  get(state, "ProfileService.dailyReducer.syncData", null);

export const getTodayDailyReport = (state) =>
  get(state, "ProfileService.dailyReportReducer.data");

export const getTodayDailyReportFetching = (state) =>
  get(state, "ProfileService.dailyReportReducer.fetching");

export const getTodayDailyReportUUID = (state) =>
  get(getTodayDailyReport(state), "[0].uuid");

export const getDayLog = (state) =>
  pathOr([], ["ProfileService", "dayLogReducer", "dayLogData"], state);
export const getDayLogUUID = (state) =>
  get(state, "ProfileService.dayLogReducer.dayLogData[0].uuid");
export const getDayLogFetching = (state) =>
  pathOr([], ["ProfileService", "dayLogReducer", "isLoadingDayLog"], state);

export const getDayLogList = (state) =>
  pathOr([], ["ProfileService", "dayLogReducer", "dayLogListData"], state);
export const getDayLogListFetching = (state) =>
  pathOr([], ["ProfileService", "dayLogReducer", "isLoadingDayLogList"], state);
