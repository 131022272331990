import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Button,
  Col,
  Dropdown,
  Row,
  Space,
  Tooltip,
} from 'antd';
import {
  isNil, propOr,
} from 'ramda';
import {
  mdiAccountMultipleOutline,
  mdiDotsVertical,
  mdiFilterMultiple,
  mdiIdentifier,
  mdiPlayOutline,
  mdiSquareEditOutline,
  mdiTableOfContents,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useSelector, useDispatch } from 'react-redux';

import {
  get, isEmpty, omit, uniq,
} from 'lodash';
import { func, number, object } from 'prop-types';
import BtnModal from '../../BtnModalComponent';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import NewExecutionForm from '../execution/NewExecutionForm';
import EditTestSuite from './EditTestSuite';
import RemoveTestSuite from './RemoveTestSuite';
import NewTestSuiteModalBtn from './NewTestSuiteModalBtn';
import ExportQaAsJSON from '../exportAsJSON/ExportQaAsJSON';
import ImportFromJSON from '../exportAsJSON/ImportFromJSON';

import { capitalize } from 'lodash';
import { MainCollapseSectionContext, BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import {
  getAllTestSuites,
  getProject,
  getProjectUUID,
  getTestSuiteUUID,
  globalUpdateEntity,
} from '../../../selectors/selectors';
import { removeTestSuiteFetching, testSuiteFetching } from '../../../selectors/qaSelectors';
import AntDesignSearchBox54origins from '../../../../54origins/components/AntDesignSearchBox54origins';
import AddSuiteToFavorite from '../AddSuiteToFavorite';
import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';
import { stopPropagation } from '../../../../54origins/utils54origins';
import WrapperEntityFilterTestSuite from '../componentsForMultipleUse/WrapperEntityFilterTestSuite';
import { getFavoriteSuites, getFavoriteUUID } from '../../../../userFlow/store/selectors/selectors';
import { UsersPartitionConstants } from '../../../../userFlow/constants/Constants';
import UpdateListFavoriteSuiteModal from './UpdateListFavoriteSuiteModal';
import { entityUpdate, entityPartialRead } from '../../../../entity/actions/entityActions';
import { clearGlobalUpdateEntity } from '../../../reducers/slicers/breadcrumbsSlicer';
import EditTestSuiteModalBtn from './EditTestSuiteModalBtn';
import useActorsW54 from '../../../../actors/hooks/useActorsW54';
import WrapperAvatarsGroupForProject from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';
import { partitionNamesConfig } from '../../../../api/appConfig';
import useTableFilter from '../../../../components/_ui/hooks/useTableFilter';

import { suiteColumnsFilterConfig } from '../constants/qaFilterOptions';
import { defaultSuitesFilter } from '../constants/qaFilterOptions';
import { typeOfTestingOptions } from '../../../../constants/allFilterOptions';
import { columnsFilterConfig } from '../constants/qaFilterOptions';
import { getProjectParams } from '../../../selectors/selectors';

import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import {Flex} from 'antd';
import { mdiChevronDown } from '@mdi/js';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import { getUuid } from '../../../../assignUsersModal/utils';

const pageSizeOptions = [5, 25, 50, 100];

function ListOfTestSuites({
  deleteSuiteCallback,
  createEntitiesFromJSON,
  totalCount,
  setNewConfigCallback,
  config,
  selectedTypeOfTesting,
}) {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getCurrentHorizontalCollapseState,
  } = useContext(MainCollapseSectionContext);
  const {
    getListOfTestSuites,
    qaRedirect,
    partitionType,
    getTestSuitePromise,
    getListOfTestCasesInSuite,
    changeQAHistory,
  } = useContext(BugTrackerContext);

  const globalUpdate = useSelector(globalUpdateEntity);
  const testSuiteUUID = useSelector(getTestSuiteUUID);
  const getCustomTestSuites = useSelector(getAllTestSuites);
  const projectUUID = useSelector(getProjectUUID);
  const selectedProject = useSelector(getProject);
  const favoriteSuites = useSelector((state) => getFavoriteSuites(state, projectUUID));
  const favoriteSettingsUUID = useSelector(getFavoriteUUID);
  const projectParams = useSelector(getProjectParams);

  const loading = useSelector(removeTestSuiteFetching);

  const fetchingSuite = useSelector(testSuiteFetching);

  const [selectedSuites, changeSelectedSuites] = useState([]);
  const [selectedRow, changeSelectedRows] = useState([]);
  const [dataForModalFavorite, setDataForModalFavorite] = useState(null);
  const [orderRules, changeOrderRules] = useState({});

  const [sizePerPage, setSizePerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const [isEditAssignUsers, setEditAssignUsers] = useState(false);
  const [usersFilter, setUsersFilter] = useState([]);

  // Получение case пользователей
  const userUUIDs = getCustomTestSuites.flatMap((item) => item?.assignToUser);
  useActorsW54({ actors: uniq(userUUIDs) });

  const currentHorizontalCollapseState = getCurrentHorizontalCollapseState('mainSuiteCollapse');

  const {
    filterConfig: testSuiteFilterConfig,
    changeFilterConfig: changeSuiteFilterConfig,
  } = useTableFilter('testSuite', {typeOfTesting: selectedTypeOfTesting});

  const selectTestSuite = ({ uuid, signal }) => {
    if (!fetchingSuite) {
      getTestSuitePromise(uuid, signal)
        .then(() => {
          getListOfTestCasesInSuite(uuid);
          changeQAHistory({
            entity: 'suite',
            uuid,
          });
        });
    }
  };

  const selectRowCallback = (uuid) => {
    changeSelectedRows(uuid);

    const newController = new AbortController();
    const { signal } = newController;

    if (selectTestSuite) {
      selectTestSuite({ uuid, signal });
    }
  };

  const deleteTestSuiteCallback = () => {
    changeSelectedSuites([]);
  };

  const callbackAfterRemoveSelectedTestSuites = async () => {
    changeSelectedSuites([]);

    if (deleteSuiteCallback) {
      deleteSuiteCallback(selectedSuites);
    }
  };

  const newTestSuiteCallback = (suiteUUID) => {
    getListOfTestSuites();

    qaRedirect('suite', suiteUUID);
  };

  const setSearchIssue = (value) => {
    const data = {
      ...config,
      offset: 0,
      search_data: {
        value,
        ignore_case: true,
        fields: {
          params: ['title', 'customId'],
        },
      },
    };
    setNewConfigCallback('suite', data);
    setCurrentPage(1);
  };

  const searchInTitle = (
    <Col className="flex align-item-end minWidth130">
      <AntDesignSearchBox54origins
        onSearch={setSearchIssue}
        placeholder="Search"
      />
    </Col>
  );

  const updateFavorite = (data) => {
    const constants = [
      UsersPartitionConstants.UPDATE_FAVORITE_SETTINGS_REQUEST,
      {
        type: UsersPartitionConstants.UPDATE_FAVORITE_SETTINGS_SUCCESS,
        payload: () => ({
          projects: get(data, 'params.projects', []),
          publicProjects: get(data, 'params.publicProjects', []),
          testSuites: get(data, 'params.testSuites', []),
        }),
      },
      UsersPartitionConstants.UPDATE_FAVORITE_SETTINGS_FAILURE,
    ];

    const options = {
      partition: partitionNamesConfig.partition2,
    };

    dispatch(entityUpdate({
      data,
      constants,
      options,
    }));
  };

  const getFavorite = async () => {
    const data = {
      entity_type: 'favoriteSettings',
      entity_uuid: favoriteSettingsUUID,
      params_fields: {
        testSuites: 'testSuites',
      },
    };

    const constants = [
      'GET_FAVORITE_SETTINGS_FOR_TEST_SUITES_REQUEST',
      'GET_FAVORITE_SETTINGS_FOR_TEST_SUITES_SUCCESS',
      'GET_FAVORITE_SETTINGS_FOR_TEST_SUITES_FAILURE',
    ];

    const options = {
      partition: partitionNamesConfig.partition2,
    };

    return dispatch(entityPartialRead({
      data,
      constants,
      options,
    }));
  };

  const addToFavorite = async (e, isFavorite, suiteUuid) => {
    e.stopPropagation();

    const res = await getFavorite();

    const list = get(res, '[0].testSuites', []).filter((item) => item?.uuid === projectUUID);

    const currentList = get(list, '[0].suites', []);

    if (!isFavorite && currentList.length >= 15) {
      return setDataForModalFavorite(res);
    }

    const favoriteParams = {
      entity_type: 'favoriteSettings',
      entity_uuid: favoriteSettingsUUID,
      params: {
        ...get(res, '[0]', []),
        // testSuites: []
        testSuites: !isEmpty(list)
          ? get(res, '[0].testSuites', []).map((item) => {
            if (item?.uuid === projectUUID) {
              return ({
                ...item,
                suites: isFavorite
                  ? currentList.filter((item) => item !== suiteUuid)
                  : [...currentList, suiteUuid],
              });
            }
          })
          : [
            ...get(res, '[0].testSuites', []),
            {
              entityType: 'project',
              uuid: projectUUID,
              suites: [suiteUuid],
            },
          ],
      },
    };
    updateFavorite(favoriteParams);
  };

  const handleCancelModal = () => setDataForModalFavorite(null);

  const favoriteFormatter = (cell, row) => (
    <AddSuiteToFavorite
      suiteUuid={get(row, 'uuid')}
      addToFavorite={addToFavorite}
    />
  );

  const columns = [
    {
      dataIndex: ['id'],
      title: (<Icon path={mdiIdentifier} size={1} />),
      render: (cell, row) => (
        <Tooltip
          title={currentHorizontalCollapseState ? row.title : ''}
          className="whitespace-nowrap text-xs"
        >
          {!isNil(cell) ? cell : 'key'}
        </Tooltip>
      ),
    },
    {
      dataIndex: ['title'],
      key: 'title',
      className: currentHorizontalCollapseState ? 'hidden' : '',
      title: searchInTitle,
      render: (cell) => cell && (
        <EntityParamTag
          param="title"
          value={cell}
          type="value"
          maxLength={80}
        />
      ),
    },
    {
      dataIndex: ['typeOfTesting'],
      title: capitalize(t('wms.table.headers.includedTestingTypes', 'Testing Types')),
      className: currentHorizontalCollapseState || !isEmpty(selectedRow) ? 'hidden' : '',
      filters: typeOfTestingOptions,
      ...(columnsFilterConfig && { ...columnsFilterConfig?.typeOfTesting }),
      ...(testSuiteFilterConfig?.typeOfTesting && { defaultFilteredValue: testSuiteFilterConfig?.typeOfTesting }),
    render: (cell) => cell && (
      <EntityParamTag
      param="suite"
      value={cell}
      type="tags"
      maxLength={60}
    />
    ),
    },
    {
      dataIndex: ['description'],
      title: capitalize(t('wms.table.headers.description', 'Description')),
      className: currentHorizontalCollapseState || !isEmpty(selectedRow) ? 'hidden' : '',
      render: (cell) => cell && (
        <EntityParamTag
          param="title"
          value={cell}
          type="value"
          maxLength={60}
        />
      ),
    },
    // {
    //   dataIndex: ['uuid'],
    //   title: (
    //     <Tooltip
    //       placement="top"
    //       title={capitalize(t('wms.table.headers.assigned', 'Assigned'))}
    //     >
    //       <Icon path={mdiAccountMultipleOutline} size={1} />
    //     </Tooltip>
    //   ),
    //   className: currentHorizontalCollapseState ? 'hidden' : '',
    //   sorter: true,
    //   render: (cell, row) => ((!isNil(
    //     row.assignToUser,
    //   ) && row.assignToUser.length !== 0)
    //     ? (
    //       <WrapperAvatarsGroupForProject
    //         actors={get(row, 'assignToUser', [])}
    //         avatarSize="small"
    //         avatarGap={4}
    //         avatarStyles={{ cursor: 'pointer' }}
    //         groupButtonSize="small"
    //         checkProjectRole
    //       />
    //     )
    //     : ''),
    // },
    {
      dataIndex: ['assignToUsers'],
      title: (
        <AssigningUsersRoot
          header="User filter"
          isOpen={isEditAssignUsers}
          setIsOpen={setEditAssignUsers}
          isCheckWindowWidthMode={false}
          // isClosePopoverOnLeaveMode
          isOneColumnMode={true}
          size={'extra small'}
          data={{
            uuids: projectParams?.usersSearch,
          }}
          isPopover
          onUpdateUsers={(usersData) => {
            const newUsers = usersData?.users?.map((el) => getUuid(el));
            setUsersFilter(newUsers);
          }}
          globalOptions={{
            uuids: projectParams?.usersSearch,
            isOneColumnMode: true,
            size: 'extra small',
          }}
          usersConfig={{
            label: 'Users',
            showSections: ['string-role'],
            textSelectButton: 'Select',
            textDeleteButton: 'Deselect',
            defaultItems: usersFilter,
          }}
          popoverChild={(
            <Flex className="ml-1 min-width-60px" align="center">
              <Tooltip
                placement="top"
                onClick={() => setEditAssignUsers(!isEditAssignUsers)}
                style={{
                  cursor: 'pointer',
                }}
                title={capitalize(t('wms.table.headers.assigned', 'Assigned'))}
              >
                <Icon
                  path={mdiAccountMultipleOutline}
                  role="button"
                  size={1}
                  className="text-secondary"
                />
                <Icon
                  path={mdiChevronDown}
                  role="button"
                  className="text-secondary ml-1"
                  size={1}
                />
              </Tooltip>
              <WrapperAvatarsGroupForProjectWithHook
                actors={usersFilter || []}
                maxCount={3}
                avatarSize={22}
                popoverTrigger="click"
                popoverPlacement="right"
                checkProjectRole
              />
            </Flex>
          )}
        />
      ),
      render: (cell, row) => ((!isNil(
            row.assignToUser,
          ) && row.assignToUser.length !== 0)
            ? (
              <WrapperAvatarsGroupForProject
                actors={get(row, 'assignToUser', [])}
                avatarSize="small"
                avatarGap={4}
                avatarStyles={{ cursor: 'pointer' }}
                groupButtonSize="small"
                checkProjectRole
              />
            )
            : ''),
    },
    {
      dataIndex: ['cases_count'],
      key: 'cases_count',
      sorter: true,
      title: (
        <Tooltip
          placement="top"
          title={capitalize(t('wms.table.headers.cases', 'Cases'))}
        >
          <Icon path={mdiTableOfContents} size={1} />
        </Tooltip>
      ),
      className: currentHorizontalCollapseState ? 'hidden' : '',
      render: (cell) => (
        <Badge
          count={cell}
          showZero
          style={{ backgroundColor: '#fa8c16' }}
        />
      ),
    },
    {
      dataIndex: ['favorite'],
      title: capitalize(t('wms.filters.favorites', 'favorites')),
      className: currentHorizontalCollapseState ? 'hidden' : '',
      render: (cell, row) => favoriteFormatter(cell, row),
    },
    {
      dataIndex: ['actions'],
      title: capitalize(t('wms.table.headers.actions', 'actions')),
      render: (cell, row) => (
        <div className="flex" onClick={stopPropagation}>
          
          <BtnModal
            title={capitalize(t('wms.modals.headers.new_test_suite_execution', 'new test suite execution'))}
            width="40%"
            btnComponent={(
              <Tooltip title={!isEmpty(selectedRow) && capitalize(t('wms.buttons.execute', 'execute'))}>
                <BaseButton
                  className={`ant-btn ${row.cases_count !== 0 && 'customAntOutPrimaryBtn'} ant-btn-default ant-btn-sm`}
                  style={currentHorizontalCollapseState
                    ? { borderRadius: ' 4px' }
                    : { borderRight: 'none', borderRadius: '4px 0px 0px 4px' }}
                  disabled={row.cases_count === 0}
                >
                  <span>
                    <Icon path={mdiPlayOutline} size={0.8} />
                  </span>
                  {isEmpty(selectedRow)
                    && (
                      <span className="pl-1">
                        {capitalize(t('wms.buttons.execute', 'execute'))}
                      </span>
                    )}
                </BaseButton>
              </Tooltip>
            )}
          >
            <NewExecutionForm
              parentUuid={row.uuid}
              typeOfParent="suite"
            />
          </BtnModal>

          {!currentHorizontalCollapseState
            && (
              <>
                <EditTestSuiteModalBtn
                  testSuiteData={row}
                />
                <RemoveTestSuite
                  entityUUID={row.uuid}
                  hideLabel
                  styleBtn={{
                    borderRadius: '0px 4px 4px 0px',
                    borderLeft: 'none',
                  }}
                  actionForEntityCallback={deleteTestSuiteCallback}
                />
              </>
            )}
        </div>
      ),
    },
  ];

  const changeLimit = (data) => {
    setSizePerPage(data);
    setCurrentPage(1);

    const param = {
      ...config,
      limit: data,
      offset: 0,
    };
    setNewConfigCallback('suite', param);
  };

  const changePaginationOptions = ({
    pageLimit,
    currentPage,
    offset,
  }) => {
    const data = {
      ...config,
      offset,
      limit: pageLimit,
    };
    setNewConfigCallback('suite', data);
    setCurrentPage(currentPage);
  };

  const currentSortCallback = (dataSort) => {
    if (!get(dataSort, 'order')) {
      changeOrderRules({
        orderBy: 'created',
        order: 'desc',
      });
    } else {
      changeOrderRules(dataSort);
    }
  };

  const rowSelection = {
    type: 'checkbox',
    onChange: (selectedRowKeys) => {
      changeSelectedSuites(selectedRowKeys);
    },
    selectedRowKeys: selectedSuites,
  };

  const onRow = (record) => ({
    onClick: () => {
      const { uuid } = record || {};

      selectRowCallback(uuid);
    },
  });

  const rowClassName = (row) => `not-hide-checkbox select_test_suite_row ${selectedRow === get(row, 'uuid')
    ? 'selected'
    : ''} `;

  const badgeColor = { background: '#fa8c16' };

  const onSelectTagsCallback = (tags) => {
    // setCurrentPage(1);
    let param = {
      ...config,
      limit: sizePerPage,
      offset: 0,
      params: {...testSuiteFilterConfig},
    };
    // console.log('favoriteSuites', favoriteSuites, 'tags', tags);
    if (!isEmpty(tags)) {
      param.entities = get(favoriteSuites, '[0].suites', []);
    } else {
      param = omit(param, ['entities']);
    }

    setNewConfigCallback('suite', param);
  };

  useEffect(() => {
    if(testSuiteFilterConfig){
      let param = {
      ...config,
      ...orderRules,
      limit: sizePerPage,
      offset: 0,
      params: usersFilter.length ? {assignToUser: usersFilter, ...testSuiteFilterConfig} : {...testSuiteFilterConfig},
    };

    setNewConfigCallback('suite', param);
    }
    
  }, [testSuiteFilterConfig, orderRules, usersFilter]);

  useEffect(() => {
    // if (testSuiteUUID) {
    changeSelectedRows(testSuiteUUID);
    
    // }
  }, [testSuiteUUID]);

  useEffect(() => {
    if (
      globalUpdate?.entityType === 'testSuite'
      && globalUpdate?.action === 'delete'
      && selectedSuites?.length
      && selectedSuites?.includes(globalUpdate?.uuid)) {
      changeSelectedSuites((prev) => [...prev.filter((i) => i !== globalUpdate?.uuid)]);
      dispatch(clearGlobalUpdateEntity());
    }
  }, [globalUpdate.entityType]);

  return (
    <Row>
      <Col span={24}>
        <Row>
          {!currentHorizontalCollapseState && (
            <Col span={12}>
              <div className="flex items-center">
                <h6 className="m-0">
                  {capitalize(t('wms.projectflow.qa.suites', 'test suites'))}
                </h6>
                <Badge
                  count={getCustomTestSuites.length}
                  showZero
                  style={badgeColor}
                  className="ml-1"
                />

                <Space
                  size={[5, 5]}
                  wrap
                  className="justify-end ml-1"
                  onClick={stopPropagation}
                >
                  <NewTestSuiteModalBtn
                    newTestSuiteCallback={newTestSuiteCallback}
                  />
                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: (
                            <ImportFromJSON importCallback={createEntitiesFromJSON} />
                          ),
                          key: '0',
                        },
                        {
                          label: (
                            <Badge count={selectedSuites.length ? selectedSuites.length : 0}>
                              <ExportQaAsJSON
                                typeOfEntity="testSuite"
                                customUuids={selectedSuites}
                              />
                            </Badge>
                          ),
                          key: '1',
                        },
                        {
                          label: (
                            <Badge count={selectedSuites.length ? selectedSuites.length : 0}>
                              <RemoveTestSuite
                                disabled={!selectedSuites.length}
                                entityUUID={selectedSuites}
                                actionForEntityCallback={callbackAfterRemoveSelectedTestSuites}
                              />
                            </Badge>
                          ),
                          key: '2',
                        },
                      ],
                    }}
                    onClick={stopPropagation}
                  >
                    <Badge count={selectedSuites.length ? selectedSuites.length : 0}>
                      <Button
                        size="small"
                        type="primary"
                        ghost
                      >
                        <Icon path={mdiDotsVertical} size={0.8} />
                      </Button>
                    </Badge>
                  </Dropdown>
                </Space>
              </div>
            </Col>
          )}
          <Col span={currentHorizontalCollapseState ? 24 : 12} className="flex justify-end">
            <WrapperEntityFilterTestSuite
              onSelectTagsCallback={onSelectTagsCallback}
            />
          </Col>
        </Row>
      </Col>

      <Col span={24} className="wrapper_main_card_table mt-2 mb-3">
        <BaseTableWithPagination
          loading={loading}
          newCurrentPage={currentPage}
          pageLimit={sizePerPage}
          changePageLimit={changeLimit}
          useCustomPagination
          columns={columns}
          data={getCustomTestSuites}
          pageSizeOptions={pageSizeOptions}
          rowKey="uuid"
          rowSelection={rowSelection}
          size="small"
          total={totalCount}
          hideListSizeOption={currentHorizontalCollapseState}
          getPaginationOptions={changePaginationOptions}
          onRow={onRow}
          rowClassName={rowClassName}
           filterCallback={changeSuiteFilterConfig}
          currentSortCallback={currentSortCallback}
          headerRowClassName="not-hide-checkbox header-table-test-suite"
        />
      </Col>

      <BtnModal
        width="50%"
        title="Favorite projects"
        openFromOuterComponent={dataForModalFavorite}
        handleCancelCallbackFunc={handleCancelModal}
      >

        <UpdateListFavoriteSuiteModal
          dataFavorite={dataForModalFavorite}
          partition={partitionType}
          projectUUID={projectUUID}
          updateFavorite={updateFavorite}
        />
      </BtnModal>
    </Row>
  );
}

ListOfTestSuites.propTypes = {
  deleteSuiteCallback: func,
  createEntitiesFromJSON: func,
  totalCount: number,
  setNewConfigCallback: func,
  config: object,
};

export default ListOfTestSuites;
