import { combineReducers } from 'redux';

import ptcProject from '../projectFlow/reducers/projectReducer';
import profileTickets from '../projectFlow/reducers/profile/profileTicketReducer';

import issuesSlicer from '../projectFlow/reducers/issues/IssuesSlicer';
import quickIssuesReducer from '../projectFlow/reducers/QuickIssuesReducer';
import fullIssueEntityReducer from '../projectFlow/reducers/issues/fullIssueEntityReducer';

import listOftimelogs from '../projectFlow/reducers/listOfTimelogReducer';
import fullProjectReducer from '../projectFlow/reducers/fullProjectReducer';
import listOfIssueComments from '../projectFlow/reducers/listOfIssueCommentsReducer';
import auditReducer from '../projectFlow/reducers/auditReducer';

import listOfAreas from '../projectFlow/reducers/listOfAreas';
import notificationsReducer from '../projectFlow/reducers/notificationsReducer';
import testSuitesReducer from '../projectFlow/reducers/qa/testSuitesReducer';
import checklistsReducer from '../projectFlow/reducers/qa/checklistsReducer';
import reportsQaReducer from '../projectFlow/reducers/qa/reportsQaReducer';
import projectDocumentsReducer from '../projectFlow/reducers/projectDocumentsReducer';
import projectHistoryOfDocumentsReducer from '../projectFlow/reducers/projectHistoryOfDocumentsReducer';

import vectorReducer from '../projectFlow/reducers/vectorReducer';
import fullVectorReducer from '../projectFlow/reducers/fullVectorReducer';
import mainAreaReducer from '../projectFlow/reducers/areas/mainAreaReducer';

import testCyclesReducer from '../projectFlow/reducers/qa/testCyclesReducer';

import Error430Reducer from '../auth/reducers/Error430Reducer';

import ActorsReducer from '../reducers/ActorsReducer';
import LocalesReducer from '../reducers/LocalesReducer';
import ListOfEntityPermsReducer from '../reducers/ListOfEntityPermsReducer';
import EntityTreeReducer from '../entity/reducers/EntityTreeReducer';
import EntityChildTreeReducer from '../entity/reducers/EntityChildTreeReducer';
import EntityFilterReducer from '../entity/reducers/EntityFilterReducer';
import GetActorReducer from '../reducers/GetActorReducer';
import UserInfoReducer from '../entity/reducers/UserInfoReducer';
import GetEntityPermissions from '../entity/reducers/GetEntityPermissions';
import ViewNotification from '../entity/reducers/ViewNotification';

import GetPartition from '../config/reducers/GetPartition';
import GetConfigReducer from '../config/reducers/GetConfigReducer';

import GetAllPermissions from '../entity/reducers/GetAllPermissions';
import EntityPermsAllActors from '../entity/reducers/EntityPermsAllActors';
import CreatePerm from '../entity/reducers/CreatePerm';
import HardDeletePermReducer from '../entity/reducers/HardDeletePermReducer';
import testCasesReducer from '../projectFlow/reducers/qa/testCasesReducer';

import GetUsersEntities from '../entity/reducers/GetUsersEntities';
import newMainArchiveStateReducer from '../projectFlow/reducers/settings/newMainArchiveStateReducer';
import listOfArchiveStateReducer from '../projectFlow/reducers/settings/listOfArchiveStateReducer';
import archiveStateReducer from '../projectFlow/reducers/settings/archiveStateReducer';
import testCasesAttachFileReducer from '../projectFlow/reducers/qa/testCasesAttachFileReducer';
import ArchiveTestCycleReducers from '../projectFlow/reducers/qa/ArchiveTestCycleReducers';
import testCaseFromArchiveReducers from '../projectFlow/reducers/qa/testCaseFromArchiveReducers';

import { AuthConstants } from '../auth/Constants';
import BreadCrumbInQaReducers from '../projectFlow/reducers/qa/BreadCrumbInQaReducers';
import cookBookReducers from '../projectFlow/reducers/cookbook/cookBookReducers';
import mainCookBookTemplateEntityReducer from '../projectFlow/reducers/cookbook/mainCookBookTemplateEntityReducer';

import UniversalStorageChildren from '../projectFlow/reducers/UniversalStorageChildren';
import publicUsersEntities from '../publicPartition/reducers/publicUsersEntities';
import projectReportPerUserSlice from '../toolsFlow/reducers/projectReportPerUserSlice';
import allEntitiesAuditReducer from '../projectFlow/reducers/allEntitiesAuditReducer';

// SLICERS
import unSaveDataSlicer from '../components/un-save-data-confirm/unSaveDataSlicer';
import breadcrumbsReducer from '../projectFlow/reducers/slicers/breadcrumbsSlicer';
import dragTasksSlicer from '../projectFlow/reducers/slicers/dragTasksSlicer';
import projectGoalsSlicer from '../projectFlow/reducers/slicers/projectGoalsSlicer';
import projectMilestonesSlicer from '../projectFlow/reducers/slicers/projectMilestonesSlicer';
import projectMainEntitySlicer from '../projectFlow/reducers/slicers/projectMainEntitySlicer';
import userFilterSlicer from '../projectFlow/reducers/slicers/userFilterSlicer';
import dayUserReportFilterSlice from '../toolsFlow/reducers/slices/dayUserReportFilterSlice';
import dayUserReportUserInfoParamsSlice from '../toolsFlow/reducers/slices/dayUserReportUserInfoParamsSlice';
import userDaylogForDayReportReducer from '../toolsFlow/reducers/userDaylogForDayReportReducer';
import getAuthSessionModalSlicer from '../admin/slicers/getAuthSessionModalSlicer';
import getDefaultGroupsSlicer from '../actors/slices/defaultGroupsSlice';
import actorsW54Slice from '../actors/slices/actorsW54Slice';
import projectIssueScoringSlicer from '../projectFlow/reducers/slicers/projectIssueScoringSlicer';
import projectAllScoringSlicer from '../projectFlow/reducers/slicers/projectAllScoringSlicer';
import projectsKPMSlice from '../projectFlow/reducers/slicers/projectsKPMSlice';
import planningSlice from '../userFlow/store/reducers/slices/planingSlice';
import permissionsSlice from '../permissions/slices/permissionsSlice';
import quillSlicer from '../projectFlow/reducers/slicers/quillSlicer';

// USER FLOW STORE
import {
  // REDUCERS
  profileSectionReducer,
  privatePartitionReducer,
  dailyReducer,
  getDailyReportReducer,
  startWorkReportModalSlice,
  mainPublicDailyReducer,
  mainPublicUserReportLogsReducer,
  dayLogReducer,
  userProfile,
  publicSettingsReducer,
  mainPublicIssueReducer,

  // SLICES
  dailyFilterSlice,
  mainPublicUserLogsSlice,
  userTimelogsReducer,
  dashboardTimelineSlice,
  periodReportsSlice,
  workConfigSlice,
  workConfigCommentSlice,
  mainUserProjectLogsSlice,
  rootEntitiesDeliverySlice,
} from '../userFlow/store';
import myProjectPermsSlicer from '../projectFlow/reducers/slicers/myProjectPermsSlicer';
import chatGptSlice from '../GPT/slices/chatGptSlice';
import userBoardStorageSlice from '../myBoard/slices/userBoardStorageSlice';
import userBoardSlice from '../myBoard/slices/userBoardSlice';
import userBoardsSlice from '../myBoard/slices/userBoardsSlice';

export const reducers = {
  quickIssuesReducer,
  Error430Reducer,
};

const appReducer = combineReducers({
  ...reducers,
  Delivery: combineReducers({
    rootEntitiesDelivery: rootEntitiesDeliverySlice,
  }),
  projectManagementService: combineReducers({
    project: combineReducers({
      issues: issuesSlicer,
      quickIssues: quickIssuesReducer,
      timelogs: listOftimelogs,
      listOfProjects: ptcProject,
      projectInfo: fullProjectReducer,
      vectors: vectorReducer,
      areas: listOfAreas,
      audit: auditReducer,
      goals: projectGoalsSlicer,
      milestones: projectMilestonesSlicer,
      allProjectsIssuesScoring: projectIssueScoringSlicer,
      projectAllScoringSlicer,
      allEntitiesAudit: allEntitiesAuditReducer,
      mainArchiveState: newMainArchiveStateReducer,
      listOfArchiveState: listOfArchiveStateReducer,
      archiveState: archiveStateReducer,
      universalStorageChildren: UniversalStorageChildren,
      cookBook: cookBookReducers,
      mainCookBookTemplateEntity: mainCookBookTemplateEntityReducer,
      breadcrumbs: breadcrumbsReducer,
      userFilter: userFilterSlicer,
      dragTasks: dragTasksSlicer,
      myProjectPerms: myProjectPermsSlicer,
      projectMainEntity: projectMainEntitySlicer,
      unSaveData: unSaveDataSlicer,
    }),
    vector: combineReducers({
      vectorInfo: fullVectorReducer,
    }),
    qa: combineReducers({
      testSuites: testSuitesReducer,
      testCases: testCasesReducer,
      testCycles: testCyclesReducer,
      checklists: checklistsReducer,
      reportsQa: reportsQaReducer,
      breadCrumbInQa: BreadCrumbInQaReducers,
      testCasesAttachFileReducer,
      archiveTestCycle: ArchiveTestCycleReducers,
      testCaseFromArchive: testCaseFromArchiveReducers,
    }),
    docView: combineReducers({
      projectDocuments: projectDocumentsReducer,
      projectHistoryDoc: projectHistoryOfDocumentsReducer,
    }),
    area: combineReducers({
      mainArea: mainAreaReducer,
    }),
    issue: fullIssueEntityReducer,
    listOfIssueComments,
    notifications: notificationsReducer,
  }),
  ProjectsKPM: projectsKPMSlice,
  personalInfoService: combineReducers({
    userInfo: combineReducers({
      user: combineReducers({
        UserInfoReducer,
      }),
    }),
  }),
  EntityService: combineReducers({
    GetUsersEntities,
    actorList: ActorsReducer,
    actorReducer: GetActorReducer,
    createPerm: CreatePerm,
    entityChildTree: EntityChildTreeReducer,
    entityPerms: GetEntityPermissions,
    entityPermsAllActors: EntityPermsAllActors,
    entityTree: EntityTreeReducer,
    hardDelete: HardDeletePermReducer,
    listOfEntityPerms: ListOfEntityPermsReducer,
    listOfPermissions: GetAllPermissions,
    userInfo: UserInfoReducer,
    entityFilter: EntityFilterReducer,
    viewNotification: ViewNotification,
  }),
  config: combineReducers({
    configReducer: GetConfigReducer,
    partitions: GetPartition,
  }),
  ProfileService: combineReducers({
    personalDashboard: combineReducers({
      dashboardTimeline: dashboardTimelineSlice,
    }),
    workConfig: workConfigSlice,
    workConfigComment: workConfigCommentSlice,
    profileSection: profileSectionReducer,
    profileTickets,
    userProfile,
    publicSettings: publicSettingsReducer,
    mainPublicIssue: mainPublicIssueReducer,
    mainPublicDailyReducer,
    dailyReducer,
    dailyFilterSlice,
    mainPublicUserLogs: mainPublicUserLogsSlice,
    mainPublicUserReportLogs: mainPublicUserReportLogsReducer,
    mainUserProjectLogs: mainUserProjectLogsSlice,
    dayLogReducer,
    dailyReportReducer: getDailyReportReducer,
    startWorkReportModal: startWorkReportModalSlice,
    userTimelogs: userTimelogsReducer,
  }),
  ReportService: combineReducers({
    periodReports: periodReportsSlice,
    projectReportPerUser: projectReportPerUserSlice,
  }),
  DayUserReportService: combineReducers({
    dayUserReportFilter: dayUserReportFilterSlice,
    dayUserReportUserInfoParams: dayUserReportUserInfoParamsSlice,
  }),
  privatePartition: privatePartitionReducer,
  locales: LocalesReducer,
  PublicPartition: combineReducers({
    publicUsersEntities,
    userDaylogForDayReport: userDaylogForDayReportReducer,
    planningSlice,
  }),
  Admin: combineReducers({
    getAuthSessionModalSlicer,
  }),
  Auth: combineReducers({
    defaultGroupsSlice: getDefaultGroupsSlicer,
  }),
  ActorsW54: combineReducers({
    actorsW54: actorsW54Slice,
  }),
  Permissions: combineReducers({
    perms: permissionsSlice,
  }),
  ChatGPT: combineReducers({
    ChatGPT: chatGptSlice,
  }),
  Quill: combineReducers({
    Quill: quillSlicer
  }),
  MyBoard: combineReducers({
    userBoardStorage: userBoardStorageSlice,
    userBoard: userBoardSlice,
    userBoards: userBoardsSlice,
  }),
});

const rootReducer = (state, action) => {
  if (action.type === AuthConstants.LOGOUT_SUCCESS) {

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
