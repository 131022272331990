import React, { useEffect, useState } from 'react';
import { array, func, number, object } from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { capitalize, get, uniq } from 'lodash';

import { Tooltip, Flex } from 'antd';

import Icon from '@mdi/react';
import {
  mdiHelpCircleOutline,
  mdiWeight,
  mdiAccountMultipleOutline,
} from '@mdi/js';

import useActorsW54 from '../../../actors/hooks/useActorsW54';
import { getProjectParams } from '../../selectors/selectors';
import { getIssuesScoringData } from '../../reducers/issues/IssuesSlicer';
import { getColorByPriority, getColorByStatus } from './utils';
import { addTagsForTitle } from '../../../entity/entityUtils';
import { entityProjectWeight, priorityWeightFormatter } from '../issuesView/utils';

import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import ChunksComponent from './ChunksComponent';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import WrapperAvatarsGroupForProject from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';
import TotalIssueChunksButtons from './commonComponents/TotalIssueChunksButtons';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import { stopPropagation } from '../../../54origins/utils54origins';
import { columnsFilterConfig } from '../issuesView/constants/issueFilterOptions';

function SuggestedDayListOfIssues({
  data,
  onActionCallback,
  onChangeRowCallback,
  totalCount,
  onChangePageCallback,
  limit,
  setLimit,
  getIssuesRequestSetting,
  newCurrentPage,
  issueFilterConfig,
  changeIssueFilterConfig,
}) {
  const { t } = useTranslation();

  const today = dayjs().format('YYYY-MM-DD');
  const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');

  // Получение issue пользователей
  const userUUIDs = data?.flatMap((item) => item?.usersSearch);
  useActorsW54({ actors: uniq(userUUIDs) });

  const projectParams = useSelector(getProjectParams);
  const issuesScoringData = useSelector(getIssuesScoringData);

  const [paginationOptions, changePaginationOptions] = useState({});

  const onSearchData = (value) => {
    onChangePageCallback(1)
    getIssuesRequestSetting({ offset: 0, search: value });
  };

  const changeLimit = (size) => {
    setLimit?.(size);
  };

  const TimeTitle = () => {
    return <Tooltip
      color="white"
      title={(
        <Flex
          vertical
          className='text-black'
        >
          One step is equal to 0.5 hours.
          <Flex align='center'>
            <ChunksComponent
              total={1}
              chunks={[{
                count: 1,
                color: "orange",
              }]}
            />
            <span className='-mt-1.5 ml-1'> - context switching time</span>
          </Flex>
          <Flex align='center'>
            <ChunksComponent
              total={1}
              chunks={[{
                count: 1,
                color: "blue",
              }]}
            />
            <span className='-mt-1.5 ml-1'>- working time</span>
          </Flex>
        </Flex>)
      }
    >
      <b>
        Time
      </b>
      <Icon
        path={mdiHelpCircleOutline}
        className='ml-1'
        size={0.9}
      />
    </Tooltip>
  }

  const TimeRow = ({ issue = {} }) => {
    const chunks = get(issue, 'data.chunk', 0);
    const uuid = get(issue, 'uuid', '');

    return (
      <Flex
        vertical
      >
        <ChunksComponent
          chunks={[
            {
              count: chunks,
              color: "blue",
            },
          ]}
        />
        <Flex>
          <TotalIssueChunksButtons
            uuid={uuid}
            issue={issue}
            chunks={chunks}
            onActionCallback={(value) => onActionCallback(value, 'issue-update-total-time')}
          />
          <span className='ml-1 -mt-0.5 text-blue-500'>
            hours
          </span>
        </Flex>
      </Flex>
    )
  }

  const devScoreTitle = (
    <Tooltip
      placement="top"
      title={(
        <span>
          <strong>Dev score</strong>
          (weight index)
          - an index that shows the weight of the active issue in the project.
        </span>
      )}
    >
      <div>
        {/* <Icon path={mdiWeight} size={1} className="text-secondary" /> */}
        <b>Score</b>
      </div>
    </Tooltip>
  );

  const renderIssueDevScore = (row) => {
    const { priority, status } = row;
    const issuesIndexesSum = issuesScoringData?.sum || projectParams?.scoringData?.indexesSum || 0;
    return (
      <span className="whitespace-nowrap text-base font-bold text-green-500">
        {issuesIndexesSum ? entityProjectWeight(priority, issuesIndexesSum, status, true) : '-'}
      </span>
    );
  };

  const columns = [
    {
      dataIndex: 'uuid',
      width: '15%',
      key: 'uuid',
      render: (cell, row) => (
        <Flex
          vertical
          className='w-100'
        >
          <BaseButton
            id="addForTodayButton"
            className="btnPrimary-outline text-xs"
            size="small"
            style={{ maxWidth: 150, maxHeight: 20 }}
            onClick={(e) => onActionCallback?.(row, 'issue-add', e, today)}
          >
            Add for today
          </BaseButton>
          <BaseButton
            id="addForTomorrowButton"
            className="btnPrimary-outline text-xs mt-0.5"
            size="small"
            style={{ maxWidth: 150, maxHeight: 20 }}
            onClick={(e) => onActionCallback?.(row, 'issue-add', e, tomorrow)}
          >
            Add for tomorrow
          </BaseButton>
        </Flex>
      ),
    },
    {
      dataIndex: 'weight',
      key: 'weight',
      width: '5%',
      sorter: (row1, row2) => priorityWeightFormatter(row1?.priority, row1?.status) - priorityWeightFormatter(row2?.priority, row2?.status),
      title: devScoreTitle,
      render: (cell, row) => renderIssueDevScore(row),
    },
    {
      dataIndex: 'title',
      width: '40%',
      key: 'title',
      ...(columnsFilterConfig && { ...columnsFilterConfig?.status }),
      ...(issueFilterConfig?.title && { defaultFilteredValue: issueFilterConfig?.title }),
      title: (
        <div className="minWidth130">
          <AntDesignSearchBox54origins onSearch={onSearchData} />
        </div>
      ),
      render: (cell, row) => {
        const tags = get(row, 'tags', '');
        const status = get(row, 'status', '');
        const statusColor = getColorByStatus(row);
        const priority = get(row, 'priority', '');
        const priorityColor = getColorByPriority(row);
        const description = get(row, 'description', '');

        return (
          <Tooltip
            overlayStyle={{
              maxWidth: '500px',
              width: description.length > 200 ? '500px' : '250px'
            }}
            title={(<Flex
              onClick={stopPropagation}
              className='overflow-scroll'
              vertical
            >
              <span
                className='text-black mb-1'
              >
                What needs to be done:
              </span>
              <p
                className='text-gray-500 ml-2'
                style={{
                  maxHeight: '300px',
                  overflowY: 'scroll'
                }}
              >
                {description}
              </p>
            </Flex>)}
            color='white'
          >
            <Flex
              vertical
            >
              <EntityParamTag
                param="title"
                value={addTagsForTitle(tags, cell)}
                type="value"
              />
              <Flex
                className='mt-0.5'
              >
                <Tooltip
                  title="Status"
                  color={statusColor}
                >
                  <EntityParamTag
                    param="status"
                    value={status}
                    type="iconWithStatus"
                  />
                </Tooltip>
                <Tooltip
                  title="Priority"
                  color={priorityColor}
                >
                  <EntityParamTag
                    param="priority"
                    value={priority}
                    type="valueWithBorder"
                  />
                </Tooltip>
              </Flex>
            </Flex>
          </Tooltip>)
      },
    },
    {
      dataIndex: 'time',
      key: 'time',
      width: '25%',
      title: <TimeTitle />,
      render: (cell, row) => <TimeRow issue={row} />
    },
    {
      dataIndex: 'usersSearch',
      width: '15%',
      title: (
        <Tooltip
          placement="top"
          title={capitalize(t('wms.table.headers.assigned', 'Assigned'))}
        >
          <Icon
            path={mdiAccountMultipleOutline}
            role="button"
            size={1}
            className="text-secondary"
          />
        </Tooltip>
      ),
      render: (cell) => (
        cell?.length > 0 && (
          <WrapperAvatarsGroupForProject
            actors={cell}
            avatarSize="small"
            avatarGap={4}
            avatarStyles={{ cursor: 'pointer' }}
            groupButtonSize="small"
            checkProjectRole
          />
        )
      ),
    },
  ].filter((el) => !el.hidden);

  const onRow = (record) => ({
    onClick: () => {
      const { uuid } = record || {};

      if (onChangeRowCallback) {
        onChangeRowCallback(uuid);
      }
    },
  });

  const rowClassName = (row) => `${get(row, 'done', false) ? 'bg-green-100' : ''}`

  useEffect(() => {
    if (getIssuesRequestSetting) {
      getIssuesRequestSetting({
        ...paginationOptions,
      });
    }
  }, [paginationOptions]);

  return (
    <BaseTableWithPagination
      columns={columns}
      data={data}
      rowKey="uuid"
      size="small"
      total={totalCount}
      newCurrentPage={newCurrentPage}
      headerRowClassName="universal_header_table_row color-gray"
      onRow={onRow}
      rowClassName={rowClassName}
      getPaginationOptions={changePaginationOptions}
      pageLimit={limit}
      useCustomPagination
      changePageLimit={changeLimit}
      disablePagination={false}
      onChangePageCallback={onChangePageCallback}
      filterCallback={changeIssueFilterConfig}
    />
  );
}

export default SuggestedDayListOfIssues;

SuggestedDayListOfIssues.propTypes = {
  data: array,
  newCurrentPage: number,
  onActionCallback: func,
  onChangeRowCallback: func,
  totalCount: number,
  onChangePageCallback: func,
  issueFilterConfig: object,
  limit: number,
  setLimit: func,
  changeIssueFilterConfig: func,
  getIssuesRequestSetting: func,
};
