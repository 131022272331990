/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Space } from 'antd';
import {
  mdiAutorenew,
  mdiCheckBold,
  mdiChevronDown,
  mdiChevronUp,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  propOr,
  head,
} from 'ramda';

import {
  getProjectUUID,
  getProjectParams,
} from '../../../selectors/selectors';
import { sendNotification } from '../../../../api/notificationsAPI';
import { pathOr } from 'ramda';
import { createComment } from '../../../actions/projectFlowActions';
import { maxLengthInput } from '../../comment/utils';

import { get } from 'lodash';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import DataGridWrapperWithControls from '../dataGrid/DataGridWrapperWithControls';
import TestCaseComments from '../TestCaseComments';
import AddMoreRowsComponent from './AddMoreRowsComponent';
import EndExecutionTestCaseBtn from '../dataGrid/EndExecutionTestCaseBtn';

import { getMe, getTestCycle } from '../../../selectors/selectors';
import { formatDateTimeForWms } from '../../../../MainUtils';
import { capitalize } from 'lodash';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import { saveUUIDForComment } from '../../../actions/QaActions';
import WrapperAddIssue from '../../issues/newIssue/WrapperAddIssue';
import useURLParams from '../../../../hooks/useURLParams';

function TestCaseWithCommentsAndButtons({
  typeOfParent,
  testCaseParams,
  testCaseUUID,
  testCaseData,
  updateTestCaseGrid,
  updateTestCycles,
  onSuccessCallback,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getURLParams } = useURLParams();

  const userUUID = useSelector(getMe);
  const testCycle = useSelector(getTestCycle);

  const { activeProject } = getURLParams();

  const { partitionType } = useContext(BugTrackerContext);

  const [testCaseIsEdit, changeTestCaseIsEdit] = useState(false);
  const [commentBtnFlag, changeCommentBtnFlag] = useState(false);
  const [gridChanges, saveGridChanges] = useState([]);
  const [newEmptyRows, addNewEmptyRows] = useState(0);
  const [defaultGridFlag, changeDefaultGridFlag] = useState(false);
  const [localTestCaseStatus, changeLocalTestCaseStatus] = useState('');
  const [lastStepStatus, changeLastStepStatus] = useState('unexecuted');
  const [newIssueModalFlag, changeNewIssueModalFlag] = useState(false);
  const [dataForIssue, saveDataForIssue] = useState();
  
  const caseStatusWhenDisable = ['pass', 'fail', 'block'];
  const disableCaseFromCycleStatus = caseStatusWhenDisable.includes(get(testCycle, '[0].params.status'));
  const caseIsClosed = propOr(false, 'closedCase', testCaseParams);
  const steps = propOr([], 'steps', testCaseParams);

  const showEndExecutionTestCaseBtn = (
    typeOfParent === 'cycle'
    && !caseIsClosed
    && !disableCaseFromCycleStatus
    && (localTestCaseStatus === 'fail' || localTestCaseStatus === 'block' || lastStepStatus === 'pass')
  );

  //history comment logic 
  const projectUUID = useSelector(getProjectUUID);

  const projectParams = useSelector(getProjectParams);

  const entity = projectUUID;

    const initialReplyStatus = {
      actorUUID: '',
      actorName: '',
      text: '',
    };
    const [replyStatus, setReplyStatus] = useState(initialReplyStatus);

    const initialNewCommentData = {
      text: 'Changed state',
      value: 'Changed state',
      entity: entity?.entity_type || entity?.affix_type || entity,
      files: [],
      audio: [],
      mentions: [],
    };
  
  const [newCommentData, setNewCommentData] = useState(initialNewCommentData);
  const [testCaseChanges, setTestCaseChanges] = useState([])

  const colNames = ["Order", "Step", "Description", "Test Data", "Expected result", "Actions"]

  const checkParams = () => {
    const {
      entity_type, uuid, parent, params,
    } = entity;

    const old_data = {
      uuid,
      entity_type,
      projectUUID,
    };
    const default_data = {
      ...old_data,
      project_uuid: projectUUID,
      project_id: projectParams?.id,
      project_title: projectParams?.title,
      assigned_entity_type: entity_type || 'project',
      assigned_entity_title: params?.title || projectParams?.title,
      assigned_entity_id: params?.id || projectParams?.id,
      assigned_entity_uuid: uuid || projectUUID,
    };

    return default_data;
  };

  const sendNewNotification = (type, actors, additionalData) => {
    const entityData = {
      ...additionalData,
      projectUUID,
      partition: partitionType,
    };

    if (partitionType === partitionNamesConfig.partition3) {
      entityData.user = myPublicUUID || '';
    }

    dispatch(
      sendNotification(
        type,
        actors,
        newCommentData.text,
        { entity: entityData },
      ),
    );
  };

  const sendNewCommentSuccess = () => {
    setNewCommentData(initialNewCommentData);

    if (newCommentData.mentions.length > 0) {
      sendNewNotification(
        'mention',
        newCommentData.mentions.map(({ id }) => id),
        checkParams(),
      );
    }
  };
  //history comment logic 

  const toggleNewIssueModal = () => changeNewIssueModalFlag(!newIssueModalFlag);

  const getListOfComments = () => {
    changeCommentBtnFlag(!commentBtnFlag);
    dispatch(saveUUIDForComment(testCaseUUID));
  };

  const findCurrentTestCaseStatus = (arrayOfStatuses) => {
    let status;

    const lastTestStepStatus = arrayOfStatuses[arrayOfStatuses.length - 1];

    changeLastStepStatus(lastTestStepStatus);

    if (arrayOfStatuses.includes('block')) {
      status = 'block';
      changeLastStepStatus('block');
    }

    if (arrayOfStatuses.includes('fail') && !status) {
      status = 'fail';
      changeLastStepStatus('fail');
    }

    if (arrayOfStatuses.includes('pass') && !status) {
      if (lastTestStepStatus === 'pass') {
        status = 'pass';
      } else {
        status = 'in progress';
      }
    }

    changeLocalTestCaseStatus(status);

    return status;
  };

  const updateTestCaseFromCycle = (grid) => {    
    const rawData = [...grid].map((item, i) => ({
      // step: item[0].value,
      // description: item[1].value,
      // test_data: item[2].value,
      // expected: item[3].value,
      // actual: null,
      // status: item[4].value,
      step: `#${i+1}`,
      description: item[2].value,
      test_data: item[3].value,
      expected: item[4].value,
      actual: null,
      status: item[5].value,
    }));

    // change status of test case
    const listOfStatuses = grid.map((item) => item[5].value);

    findCurrentTestCaseStatus(listOfStatuses);

    const params = {
      steps: rawData,
      status: 'in progress',
    };

    updateTestCaseGrid(params);
  };

  const onCellsChangedCallback = (grid, changes) => {
    const cutToMax = (val) => val.slice(0, maxLengthInput);
    const defaultCommentText = "Changed state";

    //history comment logic 
    if(changes){
      setTestCaseChanges((prev) => ([
        ...prev,
        ...changes,
      ]))
      setNewCommentData((prev) => ({
        ...prev,
        text: cutToMax("Changed state" + `${[...testCaseChanges, ...changes]?.map((item) => {return `\n#${item?.row+1} - ${colNames[item?.col]} - ${item?.value}`})}`),
        value: cutToMax("Changed state"),
      }));
    }else{
      setNewCommentData((prev) => ({
        ...prev,
        text: defaultCommentText,
        value: defaultCommentText,
      }));
    }
    //history comment logic 
          
    changeTestCaseIsEdit(true);
    saveGridChanges(grid)
    // if (typeOfParent === 'cycle') {
    //   updateTestCaseFromCycle(grid);
    // } else {
    //   saveGridChanges(grid);
    // }
  };

  const saveBtn = () => {
    const rawData = [...gridChanges].map((item) => ({
      step: item[1].value,
      description: item[2].value,
      test_data: item[3].value,
      expected: item[4].value,
      actual: null,
      status: item[5].value,
    }));
  
    const updateParams = {
      steps: rawData,
    };

    //history comment logic
    let subscribers = pathOr([], ['params', 'subscribers'], entity);    

    if (replyStatus.actorUUID) {
      subscribers = subscribers.filter(
        (item) => item !== replyStatus.actorUUID,
      );
    }

    if (newCommentData.mentions.length > 0) {
      subscribers = subscribers.filter(
        (item) => !newCommentData.mentions.some(({ id }) => id === item),
      );
    }

    const params = {
      comment: `${newCommentData.text.trim()}`,
      entity: entity?.entity_type || entity?.affix_type || entity,
    };
    

    if (replyStatus.actorUUID) {
      params.reply = {
        ...replyStatus,
      };

      sendNewNotification(
        'mention',
        replyStatus.actorUUID,
        checkParams(),
        // pick(['entity_type', 'uuid'], entity),
      );
      
      setReplyStatus(initialReplyStatus);
    }

    if (subscribers.length > 0) {
      sendNewNotification(
        'comment',
        subscribers,
        checkParams(),
        // pick(['entity_type', 'uuid'], entity),
      );
    }

    const data = {
      parent: testCaseUUID,
      files: [...newCommentData.files, ...newCommentData.audio],
      partition: partitionType,
      params,
      onSuccess: sendNewCommentSuccess,
    };
    onSuccessCallback?.(newCommentData.text);

    if(typeOfParent === 'cycle'){
      updateTestCaseFromCycle(gridChanges)
    }

    dispatch(createComment(data));
    setTestCaseChanges([]);
    //history comment logic
    
    updateTestCaseGrid(updateParams);
    saveGridChanges([]);
    changeTestCaseIsEdit(false);
  };

  const endExecutionCallback = () => {
    const params = {
      status: localTestCaseStatus,
      closedCase: true,
      executed_by: userUUID,
      executed_on: formatDateTimeForWms(moment()),
    };

    updateTestCaseGrid(params);
    changeLocalTestCaseStatus('');
  };

  const updateCaseCounter = () => {
    let caseCounterBug = propOr(0, 'counterBug', testCaseParams);

    caseCounterBug += 1;

    const params = {
      counterBug: caseCounterBug,
    };

    updateTestCaseGrid(params);
  };

  const newIssueSuccessCallback = () => {
    toggleNewIssueModal();
    updateCaseCounter();

    updateTestCycles({
      counterBug: +get(testCycle, '[0].params.counterBug', 0) + 1,
    });
  };

  const updateStatusCallback = (changesOfStatus) => {
    const { value, row } = head(changesOfStatus);

    if (value === 'fail' || value === 'block') {
      const issueData = {
        case: testCaseUUID,
        cycle: propOr('', 'cycle', testCaseParams),
        step: propOr([], 'steps', testCaseParams)[row],
        allSteps: propOr([], 'steps', testCaseParams),
        title: propOr('', 'title', testCaseParams),
      };
          
      saveDataForIssue(issueData);

      toggleNewIssueModal();
    }
  };
  const hideViewCreateIssueModal = ['assignedProject'];

  const defaultIssueState = {
    assignedProject: activeProject || '',
    testCaseData: dataForIssue,
    tracker: 'bug',
    description: steps.map((step, index) => step.description && `${index + 1}. ${step.description}\n`).join(''),
  };

  useEffect(() => {
    // clear newEmptyRows after update newEmptyRows
    addNewEmptyRows(0);
  }, [newEmptyRows]);

  useEffect(() => {
    // set defaultGridFlag to initial position after his changed
    changeDefaultGridFlag(false);
    changeTestCaseIsEdit(false);
  }, [defaultGridFlag]);

  useEffect(() => {
    // set current test case status for finish execution btn
    if (!localTestCaseStatus) {
      const arr = propOr([], 'steps', testCaseParams).map(({ status }) => status);

      findCurrentTestCaseStatus(arr);
    }
  }, [testCaseParams]);

  return (
    <>
      <DataGridWrapperWithControls
        typeOfParent={typeOfParent}
        steps={steps}
        caseIsClosed={caseIsClosed}
        defaultGridFlag={defaultGridFlag}
        newEmptyRows={newEmptyRows}
        onCellsChangedCallback={onCellsChangedCallback}
        updateStatusCallback={updateStatusCallback}
      />
      <Row>
        <Col span={24} className="flex justify-end pt-2">
          <Space
            size={[5, 5]}
            wrap
            className="justify-end"
          >
            {/* {typeOfParent === 'suite' && ( */}
              <AddMoreRowsComponent
                addMoreRowsCallback={addNewEmptyRows}
              />
            {/* )} */}
            {testCaseIsEdit && (
              <>
                <BaseButton
                  className="btnPrimary-outline"
                  size="small"
                  onClick={saveBtn}
                >
                  <Icon
                    path={mdiCheckBold}
                    size={0.7}
                    className="mr-1"
                  />
                  {capitalize(t('wms.buttons.save_changes', 'save changes'))}
                </BaseButton>
                <BaseButton
                  className="btnWarning-outline"
                  size="small"
                  onClick={() => changeDefaultGridFlag(true)}
                >
                  <Icon
                    path={mdiAutorenew}
                    size={0.7}
                    className="mr-1"
                  />
                  {capitalize(t('wms.buttons.reset', 'reset'))}
                </BaseButton>
              </>
            )}

            {showEndExecutionTestCaseBtn && (
              <EndExecutionTestCaseBtn
                finishStatusCase={lastStepStatus}
                callbackUpdateStateAndRequest={endExecutionCallback}
              />
            )}
            <BaseButton
              onClick={getListOfComments}
              size="small"
              className="btnPrimary-outline"
            >
              <Icon
                path={commentBtnFlag ? mdiChevronUp : mdiChevronDown}
                size={0.7}
                className="mr-1"
              />
              {capitalize(t('wms.projectflow.qa.case.comments', 'comments'))}
            </BaseButton>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ marginTop: 8 }}>
          {commentBtnFlag && (
            <TestCaseComments
              testCaseUUID={testCaseUUID}
              partitionType={partitionType}
              setClickComment={changeCommentBtnFlag}
              parentUUID={testCaseData.case}
              typeOfParent={typeOfParent}
            />
          )}
        </Col>
      </Row>

      {newIssueModalFlag && (
        <WrapperAddIssue
          partitionType={partitionType}
          hideView={hideViewCreateIssueModal}
          defaultIssueState={defaultIssueState}
          openFromOuterComponent={newIssueModalFlag}
          hideAddIssueBtn
          closeModalCallback={() => changeNewIssueModalFlag(false)}
          createIssueCallback={newIssueSuccessCallback}
        />
      )}
    </>
  );
}

TestCaseWithCommentsAndButtons.propTypes = {

};
export default TestCaseWithCommentsAndButtons;
