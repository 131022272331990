import { head } from 'lodash';
import {func, number, object, string} from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import MainCollapseSection from '../../../containers/qaView/MainCollapseSection';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import { checkTestSuiteSelected, getAllTestCasesInSuite, getTestSuite } from '../../../selectors/selectors';
import ListOfTestCases from '../case/ListOfTestCases';
import ListOfTestSuites from './ListOfTestSuites';
import MainTestSuites from './MainTestSuites';

export default function SuiteSection({
  totalCountSuite,
  configSuite,
  configsCase,
  totalCountCase,
  setNewConfigCaseCallback,
  setNewConfigCallback,
  createEntitiesFromJSON,
  partition,
  selectedTypeOfTesting,
}) {
  const { t } = useTranslation();
  const { changeQAHistory } = useContext(BugTrackerContext);

  const testSuiteSelected = useSelector(checkTestSuiteSelected);
  const customTestCasesInSuite = useSelector(getAllTestCasesInSuite);
  const testSuite = useSelector(getTestSuite);

  return (
    <MainCollapseSection
      id="mainSuiteCollapse"
      classNameBtn="btnWarning-outline"
      typeCard="dangerCard"
      splitSection={testSuiteSelected}
      changeQAHistory={changeQAHistory}
      titleCollapseBtn={capitalize(t('wms.projectflow.qa.suites', 'test suites'))}
      firstComponent={(
        <ListOfTestSuites
          totalCount={totalCountSuite}
          config={configSuite}
          setNewConfigCallback={setNewConfigCallback}
          createEntitiesFromJSON={createEntitiesFromJSON}
          selectedTypeOfTesting={selectedTypeOfTesting}
        />
      )}
      secondComponent={(
        <MainTestSuites
          testSuite={head(testSuite)}
          partition={partition}
        />
      )}
      thirdComponent={(
        <ListOfTestCases
          dataTable={customTestCasesInSuite}
          typeOfParent="suite"
          colorTable="#fa8c16"
          customFilterStyle={{ marginLeft: -25, marginTop: -25 }}
          mainClassName="dangerCard"
          totalCount={totalCountCase}
          config={configsCase}
          parentData={head(testSuite)}
          setNewConfigCallback={setNewConfigCaseCallback}
        />
      )}
    />
  );
}

SuiteSection.propTypes = {
  totalCountSuite: number,
  configSuite: object,
  configsCase: object,
  totalCountCase: number,
  setNewConfigCaseCallback: func,
  setNewConfigCallback: func,
  createEntitiesFromJSON: func,
  partition: string,
};
