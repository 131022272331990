import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiQrcodeScan } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import {
  Col, Row, Card, Tabs,
} from 'antd';
import QRCode from 'react-qr-code';
import InfoTip from '../../components/infoTip/InfoTip';
import { antNotification } from '../../MainUtils';
import BaseButton from '../../components/_ui/BaseButton/BaseButton';
import { appLabel, configUrlAuth } from '../../api/appConfig';
import '../styles/toolsStyles.scss'
import BaseCard from "../../components/_ui/BaseCard/BaseCard";

const { TabPane } = Tabs;

export default function PwaAppInstruction() {
  const { t } = useTranslation();
  const copyAndNotify = async (text) => {
    try {
      // console.log(window.location.origin);
      await navigator.clipboard.writeText(text || window.location.origin);
      antNotification('success', 'Copied');
    } catch (e) {
      console.log('e', e);
    }
  };

  const pwa_app_url = 'https://w54.pwa.54market.p3.54origins.com/install';
  const auth_service_data = JSON.stringify({ auth_service: configUrlAuth });

  return (
    <BaseCard className="h-full  borderR10 step-item-wrapper">
      <Row>
        <Col className="text-center w-full">
          <h4>PWA-приложение Ecosystem54wms</h4>
          <p>Инструкция по установке и описание функционала приложения</p>
        </Col>
      </Row>
      <Row className="mt-3 w-full">
        <h6>
          PWA-приложение Ecosystem54wms &ndash; агрегатор сообщений и
          уведомлений
          {' '}
          {appLabel}
          , который ускорит взаимодействие и облегчит
          коммуникацию пользователей в проектах.
        </h6>
      </Row>
      <Row className="mt-3 w-full">
        <h6>01 Установка приложения</h6>
        <ol className="w-full">
          <li>
            Приложение доступно для установки на устройствах с браузерами Chrome
            и Safari.
          </li>
          <li>
            Воспользуйтесь ссылкой либо QR-кодом для установки приложения на
            устройство:
            <Row className="my-2 w-full">
              <Col
                span={12}
                className="flex items-center justify-start"
              >
                <Link to={pwa_app_url} target="_blank">
                  {pwa_app_url}
                </Link>
                <BaseButton
                  size="small"
                  type="primary"
                  className="ml-2"
                  onClick={() => copyAndNotify(pwa_app_url)}
                >
                  Copy
                </BaseButton>
              </Col>
              <Col span={12} className="flex justify-center">
                <QRCode value={pwa_app_url} size={112} />
              </Col>
            </Row>
          </li>
          <li>
            Для использования приложения необходимо наличие аккаунта в биоме:
            зарегистрируйте новый аккаунт или авторизируйтесь в приложении
            (загрузите backup-файл или восстановите аккаунт в приложении).
          </li>
        </ol>
      </Row>

      <Row className="my-5 w-full">
        <Col span={24}>
          <h6>02 Регистрация и авторизация в приложениии</h6>
          <p>
            Для использования приложения необходимо наличие аккаунта. В системе
            возможна классическая регистрация (по логину и паролю) и регистрация
            по QR коду.
          </p>
          <p>
            Подробнее о регистрации аккаунта в системе можно узнать в разделе 01
            {' '}
            <Link to="/startpage">здесь</Link>
            .
          </p>

          <Tabs defaultActiveKey="1">
            <TabPane tab="Авторизация по email и паролю" key="1">
              <p>
                В случае, если регистрация аккаунта произведена через форму Sign
                up в {appLabel}:
              </p>
              <ol>
                <li>
                  Откройте приложение Ecosystem54wms на мобильном устройстве.
                </li>
                <li>
                  На странице "Accounts" нажмите на кнопку "Sign up \ Sign in",
                  выберите пункт "Restore a clasic account".
                </li>
                <li>
                  <ul>
                    <div>
                      Заполните данные формы:
                    </div>
                    <li>
                      Service url - укажите адрес auth-сервиса системы. Для
                      этого нажмите в форме на кнопку
                      <Icon
                        path={mdiQrcodeScan}
                        size={1}
                        style={{ margin: '0 6px', color: '#1976d2' }}
                      />
                      и отсканируйте QR код ниже.
                      <Col
                        span={24}
                        className="flex justify-center my-3"
                      >
                        <QRCode value={auth_service_data} size={112} />
                      </Col>
                    </li>
                    <li>
                      Email - email аккаунта, указанный при регистрации (можно
                      узнать в профиле аккаунта в {appLabel}).
                    </li>
                    <li>Password - пароль, указанный при регистрации.</li>
                  </ul>
                </li>
                <li>Нажмите на кнопку "Add an account".</li>
              </ol>
            </TabPane>
            <TabPane tab="Авторизация при помощи backup файла" key="2">
              <p>
                В случае, если регистрация аккаунта произведена через QR код:
              </p>
              <ol>
                <li>
                  Откройте приложение Ecosystem54wms на мобильном устройстве.
                </li>
                <li>
                  На странице "Accounts" нажмите на кнопку "Sign up \ Sign in",
                  выберите пункт "Upload backup file".
                </li>
                <li>
                  Выберите и загрузите backup-файл на мобильном устройстве,
                  укажите пароль к данному файлу в форме и нажмите кнопку
                  "Restore".
                </li>
                <li>
                  В случае, если Вы забыли пароль или не можете найти
                  backup-файл, создайте новый backup-файл через приложение
                  Ecosystem54wms: перейдите на страницу "Accounts", нажмите
                  "Open dashboard", нажмите кнопку "Account backup" и заполните
                  форму.
                </li>
              </ol>
            </TabPane>
          </Tabs>
        </Col>
      </Row>

      <Row className="mt-5 w-full">
        <h6>
          03 Добавление чатов с пользователями и новых проектов в Ecosystem54wms
        </h6>
        <ol>
          <li>
            Для коммуницирования через Ecosystem54wms будут доступны
            пользователи, которые отметили ("тегнули") Вас при написании
            комментария в любом месте проекта {appLabel}.
          </li>
          <li>
            Для добавления чата с Вами в приложении у других пользователей,
            отметьте пользователей в проекте при написании комментария.
          </li>
          <li>
            Базовая фильтрация сообщений с пользователями осуществляется при
            помощи выбора проектов из списка на странице "Settings": в чатах с
            пользователями будут отображаться сообщения в рамках списка
            выбранных проектов и связанных с ними сущностей.
          </li>
          <li>
            Новый проект появляется в списке после того, как Вас отметят в этом
            проекте либо в одной из связанных с ним сущностей.
          </li>
          <li>
            Одновременно может быть выбрано не более 10 актуальных проектов, в
            рамках которых будет осуществляться фильтрация в чатах.
          </li>
        </ol>
      </Row>
      <Row className="mt-3">
        <h6>04 Push-уведомления</h6>
        <ol>
          <li>
            Приложение поддерживает функцию Push-уведомлений на устройствах с ОС
            Android и установленными сервисами Google, а также на устройствах с
            ОС iOS версии 16.4 и выше.
          </li>
          <li>
            Для получения уведомлений необходимо:
            {' '}
            <ul>
              <li>
                разрешить отображений уведомлений браузером в настройках
                мобильного устройства,
              </li>
              <li>
                разрешить отображение уведомлений в самом приложении - в случае,
                если Вы запретили отображение уведомлений, будет необходимо
                вручную выставить разрешения в браузере для приложения
                Ecosystem54wms,
              </li>
              <li>
                подписаться на получение push-уведомлений в приложении
                Ecosystem54wms на странице "Settings".
              </li>
            </ul>
          </li>
          <li>
            Вы будете получать push-уведомления в следующих случаях:
            <ul>
              <li>Вас отметили ("тегнули") в комментарии,</li>
              <li>Вам назначили новую задачу,</li>
              <li>Вам отправили сообщение в чате приложения.</li>
            </ul>
          </li>
        </ol>
      </Row>
      <Row className="mt-3">
        <h6>05 Отправка сообщений из Ecosystem54wms</h6>
        <ol>
          <li>
            Ecosystem54wms поддерживает ответ на комментарий {appLabel} и отправку
            нового комментария к сущности (проект и задача).
          </li>
          <li>
            При отправке ответа на комментарий в чате, пользователю будет
            отправлено push-уведомление, комментарий появится в связанной
            сущности в {appLabel}.
          </li>
          <li>
            При создании нового комментария из формы в Ecosystem54wms, выберите
            пользователей из списка доступных для отправки уведомления.
          </li>
        </ol>
      </Row>
      <Row className="mt-3">
        <h6>06 Week planning</h6>
        <ol>
          <li>
            Страница "Week planning" в Ecosystem54wms представляет собой
            мобильную версию страницы из {appLabel}.
          </li>
          <li>
            Оба приложения синхронизированы, Вы можете редактировать список
            задач прямо из PWA-приложения.
          </li>
        </ol>
      </Row>
    </BaseCard>
  );
}
