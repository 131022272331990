import PropTypes, { array } from 'prop-types';
import React, { useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, DatePicker, Flex, Input, Row,
} from 'antd';
import { isNil } from 'ramda';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { get, omit } from 'lodash';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import ListOfVectorsDropDown from '../componentsForMultipleUse/ListOfVectorsDropDown';

import { antNotification, getHeadUUID } from '../../../../MainUtils';
import { capitalize } from 'lodash';
import {
  getAllTestSuites, getProjectParams, getProjectUUID, getTestCycleUUID,
} from '../../../selectors/selectors';
import {
  clearListTestCaseInCycleInStore,
  updateListOfTestCaseInCycle,
  createTestCaseForCycle,
  updateTestCycle,
  getListOfTestCasesInCycleFunc,
} from '../../../actions/QaActions';
import { BugTrackerContext, ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { incrementAndGetCount } from '../../../../entity/actions/entityActions';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import { capitalizeFirstChar } from '../../../../MainUtils';
import BaseSelect from '../../../../components/_ui/BaseSelect/BaseSelect';
import { defaultArrayTypeOfTesting } from '../QaUtils';

const { TextArea } = Input;

function TestCycleForm({
  defaultTitle = '',
  disabledSbmtBtn,
  entityUUID,
  defaultDescription = '',
  defaultStartDate = '',
  defaultDueDate = '',
  defaultTypeOfTesting = [],
  typeOfForm,
  submitLabelBtn,
  selectTestCycleCollback,
  handleOk,
  selectTestCycleCallback,
  reRun = false,
  selectedVectorDefault = [],
  selectVectorDefault,
  importListOfTestCasesCallback,
  assignToUser = [],
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const disabledSumbit = useRef(false);

  const getCustomTestSuites = useSelector(getAllTestSuites);
  const testCycleUUID = useSelector(getTestCycleUUID);
  const projectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);

  const {
    sendAssignedNotifications,
  } = useContext(ProjectMainLayerContext);

  const {
    createPromiseNewTestCycle,
    getListOfTestCycles,
    partitionType,
  } = useContext(BugTrackerContext);

  const defaultState = {
    title: defaultTitle,
    description: defaultDescription,
    start_date: defaultStartDate,
    end_date: defaultDueDate,
    selectVector: selectedVectorDefault,
    typeOfTesting: defaultTypeOfTesting,
    casesCount: 0,
    assignToUsersOption: assignToUser,
  };
  const [state, changeState] = useState(defaultState);

  const [isAssignOpen, setIsAssignOpen] = useState(false);

  const {
    title,
    description,
    start_date,
    end_date,
    assignToUsersOption,
    casesCount,
    selectVector,
    typeOfTesting,
  } = state;

  const setNewState = (param) => {
    changeState({
      ...state,
      ...param,
    });
  };

  const onFormChange = (event) => {
    const { name, value } = event.target;

    setNewState({ [name]: value });
  };

  const onDaysChange = (param, day) => {
    setNewState({ [param]: isNil(day) ? '' : day });
  };

  const submitNewTestCycle = async (customCountCase) => {
    dispatch(clearListTestCaseInCycleInStore());

    const { increment } = await dispatch(incrementAndGetCount({
      entity_uuid: projectUUID,
      field_name: 'suite',
    }, partitionType));

    const params = {
      title,
      description,
      start_date: start_date ? dayjs(start_date).format('YYYY-MM-DD') : '',
      end_date: end_date ? dayjs(end_date).format('YYYY-MM-DD') : '',
      status: 'in progress',
      typeOfTesting,
      executed_by: null,
      executed_on: null,
      cases_count: customCountCase || casesCount,
      order: [],
      helperInStatus: false,
      archive: false,
      assignToUser: assignToUsersOption,
      id: `C${increment}`,
      vector: selectVector,
      progress: {
        pass: 0,
        block: 0,
        fail: 0,
        unexecuted: customCountCase || 0,
        progress: 0,
        quantityCase: 0,
      },
    };

    const newTestCycle = await createPromiseNewTestCycle(params);
    const newTestCycleUUID = getHeadUUID(newTestCycle);

    const assignedUsers = get(params, 'assignToUser');

    sendAssignedNotifications({
      entity_type: 'testCycle',
      assignedUsers,
      eventName: 'assigned_test_cycle',
      entityUUID: get(newTestCycle, '[0].uuid'),
      message: get(newTestCycle, '[0].params.title', ''),
      entityUUIDsFields: {
        testCycleUUID: get(newTestCycle, '[0].uuid'),
        projectUUID,
      },
    });

    if (newTestCycleUUID) {
      setNewState(defaultState);

      getListOfTestCycles();
    }

    if (selectTestCycleCallback) {
      selectTestCycleCallback(newTestCycleUUID, false, true);
    }

    return Promise.resolve(newTestCycle);
  };

  const submitNewTestCycleAndCloseModal = async () => {
    if (handleOk) {
      handleOk();
    }

    await submitNewTestCycle();
    disabledSumbit.current = false;
  };

  const submitAndImport = async () => {
    const submitNewCycle = await submitNewTestCycle();

    if (importListOfTestCasesCallback) {
      importListOfTestCasesCallback(get(submitNewCycle, '[0]'));
    }

    if (handleOk) {
      handleOk();
    }
  };
  const updateTestCycleFunc = async () => {
    const currentUud = entityUUID || testCycleUUID;
    const data = {
      uuid: currentUud,
      partition: partitionType,
      params: {
        archive: false,
      },
    };

    data.params.title = !isNil(title) ? title : '';
    data.params.description = !isNil(description) ? description : '';
    data.params.start_date = dayjs(start_date).format('YYYY-MM-DD');
    data.params.end_date = dayjs(end_date).format('YYYY-MM-DD');
    data.params.assignToUser = assignToUsersOption;
    data.params.vector = selectVector;
    data.params.typeOfTesting = typeOfTesting;

    dispatch(updateTestCycle(data)).then((response) => {
      if (response) {
        antNotification('success', t('wms.noun.success', 'Success'));
        const assignedUsers = get(data, 'params.assignToUser');

        sendAssignedNotifications({
          entity_type: 'testCycle',
          assignedUsers,
          eventName: 'assigned_test_cycle',
          entityUUID: get(response, '[0].uuid'),
          message: get(response, '[0].params.title', ''),
          entityUUIDsFields: {
            testCycleUUID: get(response, '[0].uuid'),
            projectUUID,
          },
        });

        dispatch({
          type: ProjectTypeCommonConstants.UPDATE_TEST_CYCLE_IN_STORE,
          payload: response,
        });
      } else {
        antNotification('error', t('notifications.text.error.base', 'Error'));
      }

      getListOfTestCycles();

      if (handleOk) {
        handleOk();
      }
      disabledSumbit.current = false;
    });
  };

  const createNewCycleAndCase = async () => {
    const res = await dispatch(getListOfTestCasesInCycleFunc({
      entity_type: 'testCase',
      parent: entityUUID,
      params: {},
      partition: partitionType,
      customConstants: ['REQUEST', 'SUCCESS', 'FAILURE'],
    }));

    const submitNewCycle = await submitNewTestCycle(get(res, 'data', []).length);

    const newCycleUUID = getHeadUUID(submitNewCycle);

    const list = await Promise.all(
      get(res, 'data', []).map(async (item) => {
        const { increment } = await dispatch(incrementAndGetCount({
          entity_uuid: newCycleUUID,
          field_name: 'case',
        }, partitionType));

        return ({
          ...item.params,
          status: 'unexecuted',
          steps: item.params.steps.map(
            (i) => ({ ...i, status: 'unexecuted' }),
          ),
          closedCase: false,
          counterBug: 0,
          id: `${get(submitNewCycle, '[0].params.id', 0)}C${increment}`,
          order: increment,
        });
      }),
    );

    const data = {
      parent: newCycleUUID,
      partition: partitionType,
      params: list,
    };

    const response = await dispatch(createTestCaseForCycle(data));

    if (selectTestCycleCollback) {
      selectTestCycleCollback(newCycleUUID, true);
    }
    dispatch(updateListOfTestCaseInCycle(response.map((item) => ({ ...omit(item, ['params']), ...item.params }))));

    if (handleOk) {
      handleOk();
    }
    disabledSumbit.current = false;
  };

  const selectVectorCollback = (item) => {
    setNewState({
      selectVector: item,
    });
  };

  const assignToFunc = (value) => {
    setNewState({
      assignToUsersOption: value,
    });
  };

  const finalSubmitFunc = () => {
    if (!disabledSumbit.current) {
      disabledSumbit.current = true;
      switch (typeOfForm) {
        case 'newForm':
          return submitNewTestCycleAndCloseModal();
        case 'editForm':
          return updateTestCycleFunc();
        case 'reRun':
          return createNewCycleAndCase();
        default:
          return '';
      }
    }
  };

  const disabledDate = (current) => current && current < dayjs().startOf('day');
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {capitalize(t('wms.labels.title', 'title'))}
        <span style={{ color: 'red' }}>*</span>
        <Input
          maxLength={128}
          showCount
          name="title"
          onChange={onFormChange}
          defaultValue={defaultTitle}
        />
      </Col>
      <Col span={24}>
        {capitalize(t('wms.labels.description', 'description'))}
        :
        <TextArea
          row={10}
          name="description"
          defaultValue={defaultDescription}
          onChange={onFormChange}
        />
      </Col>

      <Flex className='flex-col ml-2 w-[100%]'>
        <>
          {capitalizeFirstChar(t('wms.labels.type_of_testing', 'type of testing'))}
        </>
        <BaseSelect
          optionFilterProp="label"
          // getPopupContainer={() => document.getElementById(id)}
          onChange={(value) => changeState((prev) => ({
            ...prev,
            typeOfTesting: value,
          }))}
          optionLabelProp="label"
          autoClearSearchValue
          allowClear
          hideSelected
          options={defaultArrayTypeOfTesting}
          showArrow
          closeMenuOnSelect={false}
          value={typeOfTesting}
          placeholder={capitalizeFirstChar(t('wms.placeholders.type', 'type'))}
          mode="multiple"
          classNamePrefix="react-select"
          className="react-select w-[246px]"
          pathTranslate="wms.options_drap_down"
        />
      </Flex>

      <Col span={24}>
        <Row>
          <Col span={12}>
            <ListOfVectorsDropDown
              partition={partitionType}
              selectVectorCallback={selectVectorCollback}
              selectVectorDefault={selectedVectorDefault}
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Flex className="w-full">
          <AssigningUsersRoot
            header="Assigning users"
            needUpdateProjectUsers
            isOpen={isAssignOpen}
            setIsOpen={setIsAssignOpen}
            onUpdateUsers={(data) => assignToFunc(data?.users?.map((el) => el?.uuid))}
            globalOptions={{
              uuids: projectParams?.usersSearch ?? [],
              defaultColumn: 'project',
            }}
            placement="left"
            isPopover
            tabs={{
              allConfig: {
                label: 'All',
                value: 'all',
                textSelectButton: 'Add to cycle',
                showSections: ['button-add-user'],
              },
              projectConfig: {
                label: 'Project',
                value: 'project',
                textSelectButton: 'Add to cycle',
                defaultItems: projectParams?.users ?? [],
                showSections: ['string-role', 'button-add-user'],
              },
            }}
            usersConfig={{
              label: 'Cycle users',
              showSections: ['button-remove-user', 'string-role'],
              textDeleteButton: 'Delete users',
              defaultItems: assignToUsersOption ?? [],
            }}
            wrapperProps={{ autoAdjustOverflow: true }}
            partition={partitionType}
            projectUuid={projectUUID}
            popoverText={(<span style={{ color: 'black' }}>Assigned users:</span>)}
          />
          <WrapperAvatarsGroupForProjectWithHook
            actors={assignToUsersOption || []}
            avatarSize={28}
            popoverTrigger="click"
            popoverPlacement="right"
          />
        </Flex>
      </Col>

      <Col span={8} className="flex flex-col">
        <span>{`${capitalize(t('wms.labels.date.start', 'start date'))}:`}</span>
        <DatePicker
          format="YYYY-MM-DD"
          value={dayjs(start_date).toString() === 'Invalid Date' ? '' : dayjs(start_date)}
          placeholder={t('wms.placeholders.date.format')}
          disabledDate={disabledDate}
          onChange={(day) => onDaysChange('start_date', day)}
        />
      </Col>
      <Col span={8} className="flex flex-col">
        <span>
          {capitalize(t('wms.labels.date.end', 'end date'))}
          :
        </span>
        <DatePicker
          format="YYYY-MM-DD"
          value={dayjs(end_date).toString() === 'Invalid Date' ? '' : dayjs(end_date)}
          placeholder={t('wms.placeholders.date.format')}
          disabledDate={disabledDate}
          onChange={(day) => onDaysChange('end_date', day)}
        />
      </Col>
      <Col span={24} className="flex justify-end">
        {(typeOfForm === 'newForm' && !reRun) && (
          <BaseButton
            className="btnPrimary"
            onClick={submitAndImport}
            disabled={disabledSbmtBtn || getCustomTestSuites.length === 0 || title.length === 0}
          >
            {capitalize(t('wms.buttons.Submit_and_import', 'Submit and import test cases ?'))}
          </BaseButton>
        )}
        <BaseButton
          className="btnPrimary ml-2"
          onClick={finalSubmitFunc}
          disabled={disabledSbmtBtn || title.trim().length === 0}
        >
          {submitLabelBtn || capitalize(t('wms.buttons.submit', 'submit'))}
        </BaseButton>
      </Col>
    </Row>
  );
}

TestCycleForm.propTypes = {
  defaultDescription: PropTypes.string,
  defaultDueDate: PropTypes.string,
  defaultStartDate: PropTypes.string,
  defaultTitle: PropTypes.string,
  defaultTypeOfTesting: PropTypes.array,
  disabledSbmtBtn: PropTypes.any,
  entityUUID: PropTypes.string,
  handleOk: PropTypes.func,
  importListOfTestCasesCallback: PropTypes.func,
  reRun: PropTypes.bool,
  selectTestCycleCallback: PropTypes.func,
  selectTestCycleCollback: PropTypes.func,
  selectVectorDefault: PropTypes.func,
  selectedVectorDefault: PropTypes.array,
  submitLabelBtn: PropTypes.any,
  typeOfForm: PropTypes.string,
  assignToUser: array,
};

export default TestCycleForm;
