import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next/';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { capitalize } from 'lodash';

export default function CustomProgress({
  cell = {
    pass: 0, block: 0, fail: 0, unexecuted: 0, progress: 0, quantityCase: 0,
  },
}) {
  const { t } = useTranslation();
  const fail = (cell.fail * 100 / cell.quantityCase) || 0;
  const pass = (cell.pass * 100 / cell.quantityCase) || 0;
  const block = (cell.block * 100 / cell.quantityCase) || 0;
  // const progres = (!isNil(cell) && !isNil(cell.progress) && cell.progress !== 0) ?
  //   (cell.progress*100/cell.quantityCase) : 0;
  const progres = (cell.progress * 100 / cell.quantityCase);

  return (
    <Tooltip title={
      Object.keys(cell).map((m) => {
        if (m === 'block') {
          return (
            <div key={m}>
              {' '}
              {capitalize(t('wms.status.blocked', 'blocked'))}
              {' '}
              :
              {' '}
              {cell.block || 0}
            </div>
          );
        } if (m === 'pass') {
          return (
            <div key={m}>
              {' '}
              {capitalize(t('wms.status.passed', 'passed'))}
              {' '}
              :
              {' '}
              {cell.pass || 0}
            </div>
          );
        } if (m === 'fail') {
          return (
            <div key={m}>
              {' '}
              {capitalize(t('wms.status.failed', 'failed'))}
              {' '}
              :
              {' '}
              {cell.fail || 0}
            </div>
          );
        } if (m === 'unexecuted') {
          return (
            <div key={m}>
              {' '}
              {capitalize(t('wms.status.unexecuted', 'unexecuted'))}
              {cell.unexecuted}
              {' '}
              :
              {' '}
              {cell.unexecuted || 0}
            </div>
          );
        } if (m === 'progress') {
          return (
            <div key={m}>
              {' '}
              {capitalize(t('wms.status.progress', 'in progress'))}
              {' '}
              :
              {' '}
              {cell.progress || 0}
            </div>
          );
        }
      })
    }
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '200px',
          height: '12px',
          backgroundColor: 'grey',
          borderRadius: '50px',
          border: '2px solid transparent',
        }}
        >

          <div style={{
            width: `${progres}%`, height: '100%', borderRadius: '50px 0 0 50px', backgroundColor: '#de7f03',
          }}
          />
          <div style={{ width: `${pass}%`, height: '100%', backgroundColor: 'green' }} />
          <div style={{ width: `${fail}%`, height: '100%', backgroundColor: 'red' }} />
          <div style={{
            width: `${block}%`, height: '100%', borderRadius: '0 50px 50px 0', backgroundColor: 'blue',
          }}
          />
        </div>
        <div className="ml-1 fontSize20 whitespace-nowrap">
          {`${(fail + pass + block).toFixed(0)}%`}
        </div>
      </div>
    </Tooltip>
  );
}

CustomProgress.propTypes = {
  // cell: PropTypes.Object,
};
