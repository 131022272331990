import { QaConstants } from '../../constants/qaConstants';

export const defaultArrayTypeOfTesting = [
  { value: 'functional', label: 'functional' },
  { value: 'non-functional', label: 'non-functional' },
  { value: 'interface', label: 'interface' },
  { value: 'system', label: 'system' },
  { value: 'integration', label: 'integration' },
  { value: 'security', label: 'security' },
  { value: 'usability', label: 'usability' },
  { value: 'compatibility', label: 'compatibility' },
  { value: 'smoke & sanity', label: 'smoke & sanity' },
  { value: 'regression', label: 'regression' },
  { value: 'performance', label: 'performance' },
  { value: 'unit', label: 'unit' },
  { value: 'none', label: 'none' },
  { value: 'acceptance', label: 'acceptance' },
];

export const defaultArrayStatus = [
  { value: 'unexecuted', label: 'unexecuted' },
  { value: 'in progress', label: 'in progress' },
  { value: 'pass', label: 'pass' },
  { value: 'block', label: 'block' },
  { value: 'fail', label: 'fail' },
];

export const getStatusTranslateForBtn = (localStatus) => {
  switch (localStatus?.toLowerCase()) {
    case 'in progress':
      return 'wms.status.in progress';
    case 'block':
      return 'wms.status.blocked';
    case 'pass':
      return 'wms.status.passed';
    case 'fail':
      return 'wms.status.failed';
    default:
      return 'wms.status.unexecuted';
  }
};

export const getButtonStylesForDropdown = (localStatus) => {
  switch (localStatus) {
    case 'fail': return { color: ' #eb5757', border: '1px solid #eb5757' };
    case 'pass': return { color: '#59af61', border: '1px solid #59af61' };
    case 'block': return { color: '#1890ff', border: '1px solid #1890ff' };
    case 'in progress': return { color: '#f2994a', border: '1px solid #f2994a' };
    case 'unexecuted': return { color: '#4f4f4f', border: '1px solid #4f4f4f' };
    default: return { color: '#eb5757', border: '1px solid #eb5757' };
  }
};

export const constantsForDeleteCycle = [
  QaConstants.REMOVE_TEST_CASE_FROM_CYCLE_REQUEST,
  QaConstants.REMOVE_TEST_CASE_FROM_CYCLE_SUCCESS,
  QaConstants.REMOVE_TEST_CASE_FROM_CYCLE_FAILURE,
];

export const constantsForDeleteSuite = [
  QaConstants.REMOVE_TEST_CASE_FROM_SUITE_REQUEST,
  QaConstants.REMOVE_TEST_CASE_FROM_SUITE_SUCCESS,
  QaConstants.REMOVE_TEST_CASE_FROM_SUITE_FAILURE,
];

export const statusName = (name) => (name === 'in progress' ? 'progress' : name);

export const colorStatus = {
  block: '#1890ff',
  pass: '#59af61',
  fail: '#eb5757',
};
export const defaultProjectTypesOfTesting = [
  'functional', 'smoke & sanity', 'regression', 'acceptance', 'interface',
];
export const defaultSelectedTypeOfTesting = [
  'functional', 'non-functional', 'interface', 'integration', 'security', 'system', 'usability', 'compatibility',
  'smoke & sanity', 'regression', 'performance', 'unit', 'acceptance',
];
export const descriptionTypeOFTesting = [
  {
    value: 'all',
    label: 'Все типы тестирования',
    description: 'Выбор всех типов тестирования'
  },
  {
    value: 'functional',
    label: 'Функциональное тестирование',
    description: 'Проверка соответствия программного обеспечения его функциональным спецификациям. Это может включать тестирование отдельных функций, API, пользовательских интерфейсов, установки, совместимости и др.',
  },
  {
    value: 'non-functional',
    label: 'Нефункциональное тестирование',
    description: 'Оценка аспектов программного обеспечения, которые не связаны с определенной функцией, например, производительность, масштабируемость, безопасность и надежность.',
  },
  {
    value: 'interface',
    label: 'Тестирование UI',
    description: 'Деятельность, направленная на проверку качества пользовательского интерфейса, а также его соответствия всем нормам и требованиям.',
  },
  {
    value: 'integration',
    label: 'Интеграционное тестирование',
    description: 'Тестирование комбинаций модулей программного обеспечения и взаимодействия между ними.',
  },
  {
    value: 'security',
    label: 'Тестирование безопасности',
    description: 'Выявление уязвимостей и оценка защищенности программного обеспечения от атак или несанкционированного доступа.',
  },
  {
    value: 'system',
    label: 'Системное тестирование',
    description: 'Проверка комплексной системы, чтобы удостовериться, что она соответствует спецификациям и требованиям.',
  },
  {
    value: 'usability',
    label: 'Юзабилити тестирование',
    description: 'Оценка удобства и интуитивности пользовательского интерфейса.',
  },
  {
    value: 'compatibility',
    label: 'Тестирование совместимости',
    description: 'Проверка работоспособности приложения в различных средах (браузеры и их версии, операционные системы, их типы, версии и разрядность).',
  },
  {
    value: 'smoke & sanity',
    label: 'Дымовое & Санитарное тестирование',
    description: 'Дымовые тесты выполняются каждый раз, когда мы получаем новый билд (версию), проекта (системы) на тестирование, при этом считая её относительно нестабильной. Санитарное тестирование используется каждый раз, когда мы получаем относительно стабильный билд ПО, чтобы определить работоспособность в деталях.',
  },
  {
    value: 'regression',
    label: 'Регрессионное тестирование',
    description: 'Проводится после изменений в программном обеспечении для убедительности в том, что новые ошибки не были внесены в уже существующие функции.',
  },
  {
    value: 'performance',
    label: 'Тестирование производительности',
    description: 'Оценка скорости и эффективности программного обеспечения под различными нагрузками.',
  },
  {
    value: 'unit',
    label: 'Юнит-тестирование (модульное тестирование)',
    description: 'Тестирование отдельных частей программы, например, функций или методов, для проверки их корректной работы.',
  },
  {
    value: 'acceptance',
    label: 'Приемочное тестирование',
    description: 'Конечная оценка программного обеспечения перед его релизом, чтобы убедиться, что оно удовлетворяет бизнес-требованиям и потребностям пользователей.',
  },
];
