/* eslint-disable import/prefer-default-export */
import keyMirror from "keymirror";

export const UsersPartitionConstants = keyMirror({
  GET_LIST_BOOKMARK_REQUEST: null,
  GET_LIST_BOOKMARK_SUCCESS: null,
  GET_LIST_BOOKMARK_FAILURE: null,

  GET_MY_PROFILE_PUBLIC_ENTITY_REQUEST: null,
  GET_MY_PROFILE_PUBLIC_ENTITY_SUCCESS: null,
  GET_MY_PROFILE_PUBLIC_ENTITY_FAILURE: null,

  GET_DAY_LOG_ENTITY_REQUEST: null,
  GET_DAY_LOG_ENTITY_SUCCESS: null,
  GET_DAY_LOG_ENTITY_FAILURE: null,

  CLEAR_DAY_LOG_ENTITY: null,

  GET_DAY_LOG_LIST_ENTITY_REQUEST: null,
  GET_DAY_LOG_LIST_ENTITY_SUCCESS: null,
  GET_DAY_LOG_LIST_ENTITY_FAILURE: null,

  CLEAR_DAY_LOG_LIST_ENTITY: null,

  CREATE_DAY_LOG_ENTITY_REQUEST: null,
  CREATE_DAY_LOG_ENTITY_SUCCESS: null,
  CREATE_DAY_LOG_ENTITY_FAILURE: null,

  CREATE_USER_TIMELOG_REQUEST: null,
  CREATE_USER_TIMELOG_SUCCESS: null,
  CREATE_USER_TIMELOG_FAILURE: null,

  MULTI_CREATE_USER_TIMELOGS_FROM_ACTIVITIES_REQUEST: null,
  MULTI_CREATE_USER_TIMELOGS_FROM_ACTIVITIES_SUCCESS: null,
  MULTI_CREATE_USER_TIMELOGS_FROM_ACTIVITIES_FAILURE: null,

  MULTI_CREATE_USER_TIMELOGS_FROM_ISSUES_REQUEST: null,
  MULTI_CREATE_USER_TIMELOGS_FROM_ISSUES_SUCCESS: null,
  MULTI_CREATE_USER_TIMELOGS_FROM_ISSUES_FAILURE: null,

  GET_USER_TIMELOGS_REQUEST: null,
  GET_USER_TIMELOGS_SUCCESS: null,
  GET_USER_TIMELOGS_FAILURE: null,

  CLEAR_USER_TIMELOGS: null,

  GET_USER_ACTIVITIES_TIMELOGS_REQUEST: null,
  GET_USER_ACTIVITIES_TIMELOGS_SUCCESS: null,
  GET_USER_ACTIVITIES_TIMELOGS_FAILURE: null,

  CLEAR_USER_ACTIVITIES_TIMELOGS: null,

  GET_USER_ISSUES_TIMELOGS_REQUEST: null,
  GET_USER_ISSUES_TIMELOGS_SUCCESS: null,
  GET_USER_ISSUES_TIMELOGS_FAILURE: null,

  CLEAR_USER_ISSUES_TIMELOGS: null,

  UPDATE_DAY_LOG_ENTITY_REQUEST: null,
  UPDATE_DAY_LOG_ENTITY_SUCCESS: null,
  UPDATE_DAY_LOG_ENTITY_FAILURE: null,

  GET_MAIN_USER_REPORT_LOGS_ENTITY_REQUEST: null,
  GET_MAIN_USER_REPORT_LOGS_ENTITY_SUCCESS: null,
  GET_MAIN_USER_REPORT_LOGS_ENTITY_FAILURE: null,

  GET_OTHER_USER_PROFILE_PUBLIC_ENTITY_REQUEST: null,
  GET_OTHER_USER_PROFILE_PUBLIC_ENTITY_SUCCESS: null,
  GET_OTHER_USER_PROFILE_PUBLIC_ENTITY_FAILURE: null,

  CLEAR_OTHER_USER_PROFILE_PUBLIC_ENTITY: null,

  GET_MAIN_PRIVATE_ISSUE_REQUEST: null,
  GET_MAIN_PRIVATE_ISSUE_SUCCESS: null,
  GET_MAIN_PRIVATE_ISSUE_FAILURE: null,

  GET_PARENT_PRIVATE_PLANNING_REQUEST: null,
  GET_PARENT_PRIVATE_PLANNING_SUCCESS: null,
  GET_PARENT_PRIVATE_PLANNING_FAILURE: null,

  GET_SESSION_FOR_PHANTOM_USER_REQUEST: null,
  GET_SESSION_FOR_PHANTOM_USER_SUCCESS: null,
  GET_SESSION_FOR_PHANTOM_USER_FAILURE: null,

  GET_PUBLIC_PERSONAL_SETTINGS_REQUEST: null,
  GET_PUBLIC_PERSONAL_SETTINGS_SUCCESS: null,
  GET_PUBLIC_PERSONAL_SETTINGS_FAILURE: null,

  UPDATE_PUBLIC_PERSONAL_SETTINGS_REQUEST: null,
  UPDATE_PUBLIC_PERSONAL_SETTINGS_SUCCESS: null,
  UPDATE_PUBLIC_PERSONAL_SETTINGS_FAILURE: null,

  CREATE_PERSONAL_SETTINGS_REQUEST: null,
  CREATE_PERSONAL_SETTINGS_SUCCESS: null,
  CREATE_PERSONAL_SETTINGS_FAILURE: null,

  GET_PERSONAL_PRIORITY_SETTING_REQUEST: null,
  GET_PERSONAL_PRIORITY_SETTING_SUCCESS: null,
  GET_PERSONAL_PRIORITY_SETTING_FAILURE: null,

  UPDATE_PERSONAL_PRIORITY_SETTING_REQUEST: null,
  UPDATE_PERSONAL_PRIORITY_SETTING_SUCCESS: null,
  UPDATE_PERSONAL_PRIORITY_SETTING_FAILURE: null,

  CREATE_PERSONAL_PRIORITY_SETTING_REQUEST: null,
  CREATE_PERSONAL_PRIORITY_SETTING_SUCCESS: null,
  CREATE_PERSONAL_PRIORITY_SETTING_FAILURE: null,

  CREATE_PUBLIC_PERSONAL_PRIORITY_SETTING_REQUEST: null,
  CREATE_PUBLIC_PERSONAL_PRIORITY_SETTING_SUCCESS: null,
  CREATE_PUBLIC_PERSONAL_PRIORITY_SETTING_FAILURE: null,

  GET_PUBLIC_PERSONAL_PRIORITY_SETTING_REQUEST: null,
  GET_PUBLIC_PERSONAL_PRIORITY_SETTING_SUCCESS: null,
  GET_PUBLIC_PERSONAL_PRIORITY_SETTING_FAILURE: null,

  UPDATE_PUBLIC_PERSONAL_PRIORITY_SETTING_REQUEST: null,
  UPDATE_PUBLIC_PERSONAL_PRIORITY_SETTING_SUCCESS: null,
  UPDATE_PUBLIC_PERSONAL_PRIORITY_SETTING_FAILURE: null,

  MAIN_PUBLIC_PROJECT: null,
  GET_MAIN_PUBLIC_PROJECT_SUCCESS: null,
  CLEAR_MAIN_PUBLIC_PROJECT: null,

  GET_MAIN_PUBLIC_DAILY_REQUEST: null,
  GET_MAIN_PUBLIC_DAILY_SUCCESS: null,
  GET_MAIN_PUBLIC_DAILY_FAILURE: null,

  GET_DAILY_ENTITY_REQUEST: null,
  GET_DAILY_ENTITY_SUCCESS: null,
  GET_DAILY_ENTITY_FAILURE: null,

  CLEAR_DAILY_ENTITY: null,

  GET_WEEKLY_DAILY_ENTITY_REQUEST: null,
  GET_WEEKLY_DAILY_ENTITY_SUCCESS: null,
  GET_WEEKLY_DAILY_ENTITY_FAILURE: null,
  SET_FETCHING_WEEKLY_DAILY_ENTITY_REQUEST: null,

  CLEAR_REPORT_FOR_PERIOD_ENTITY: null,

  SET_DAILY_FETCHING: null,

  SET_DRAG_AND_DROP_ELEMENT: null,
  CLEAR_DRAG_AND_DROP_ELEMENT: null,

  SET_DUPLICATE_ACTIVITY: null,
  CLEAR_DUPLICATE_ACTIVITY: null,
  SYNC_DATA: null,
  CLEAR_SYNC_DATA: null,

  GET_DAILY_REPORT_ENTITY_REQUEST: null,
  GET_DAILY_REPORT_ENTITY_SUCCESS: null,
  GET_DAILY_REPORT_ENTITY_FAILURE: null,

  CREATE_DAILY_REPORT_ENTITY_REQUEST: null,
  CREATE_DAILY_REPORT_ENTITY_SUCCESS: null,
  CREATE_DAILY_REPORT_ENTITY_FAILURE: null,

  CLEAR_DAILY_REPORT_ENTITY: null,

  UPDATE_DAILY_REPORT_ENTITY_REQUEST: null,
  UPDATE_DAILY_REPORT_ENTITY_SUCCESS: null,
  UPDATE_DAILY_REPORT_ENTITY_FAILURE: null,

  // Private Partition Settings

  GET_PRIVATE_USER_PROFILE_REQUEST: null,
  GET_PRIVATE_USER_PROFILE_SUCCESS: null,
  GET_PRIVATE_USER_PROFILE_FAILURE: null,

  CREATE_PRIVATE_SETTINGS_REQUEST: null,
  CREATE_PRIVATE_SETTINGS_FAILURE: null,

  GET_PRIVATE_SETTINGS_REQUEST: null,
  GET_PRIVATE_SETTINGS_SUCCESS: null,
  GET_PRIVATE_SETTINGS_FAILURE: null,

  GET_CHILD_FROM_PRIVATE_SETTINGS_REQUEST: null,
  GET_CHILD_FROM_PRIVATE_SETTINGS_SUCCESS: null,
  GET_CHILD_FROM_PRIVATE_SETTINGS_FAILURE: null,

  CREATE_OR_UPDATE_CHILD_IN_PRIVATE_SETTINGS_REQUEST: null,
  CREATE_OR_UPDATE_CHILD_IN_PRIVATE_SETTINGS_SUCCESS: null,
  CREATE_OR_UPDATE_CHILD_IN_PRIVATE_SETTINGS_FAILURE: null,

  UPDATE_FAVORITE_SETTINGS_REQUEST: null,
  UPDATE_FAVORITE_SETTINGS_SUCCESS: null,
  UPDATE_FAVORITE_SETTINGS_FAILURE: null,
});
