import { mdiAlertCircleOutline, mdiCheckCircleOutline, mdiEyeOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Alert, Badge, Space, Spin,
} from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';

import { getPartitionPMUUID } from '../../../../config/selectors/selectors';
import { getListOfCustomIssues, getListOfProjectRequest } from '../../../../projectFlow/actions/projectFlowActions';
import { getMe, getProjectsEntityUuids } from '../../../../projectFlow/selectors/selectors';
import { capitalize } from 'lodash';
import { getUserPublicProfileUUID } from '../../../store/selectors/selectors';
import { partitionNamesConfig } from '../../../../api/appConfig';

export default function SectionTasks() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const defaultData = {
    all: [],
    low: [],
    medium: [],
    high: [],
    urgent: [],
  };

  const myPublicEntityUUID = useSelector(getUserPublicProfileUUID);
  const actorUUID = useSelector(getMe);
  const pmPartition = useSelector(getPartitionPMUUID);
  const myProjectsUuids = useSelector(getProjectsEntityUuids);

  const [type, setType] = useState('success');
  const [loading, setLoading] = useState(true);

  const [tasks, setTasks] = useState(defaultData);

  const viewCounter = (length) => (
    <Badge
      count={length}
      showZero
      style={
        type === 'warning' ? { backgroundColor: '#ffa940' } : { backgroundColor: '#ff7875' }
      }
    />
  );

  const sectionPriority = (
    <div className="p-2 flex mt-1 ml-1">
      <h7>Priority:</h7>
      <div className="flex flex-col w-full ml-2">
        <div className="flex mb-2">
          <EntityParamTag
            param="priority"
            value="low"
            type="charNoCut"
          />
          {/* <span className="inline-flex mx-1">-</span> */}
          <span className="inline-flex ml-3">{viewCounter(get(tasks, 'low').length)}</span>
        </div>
        <div className="flex mb-2">
          <EntityParamTag
            param="priority"
            value="medium"
            type="charNoCut"
          />
          {/* <span className="inline-flex mx-1">-</span> */}
          <span className="inline-flex ml-3">{viewCounter(get(tasks, 'medium').length)}</span>
        </div>
        <div className="flex mb-2">
          <EntityParamTag
            param="priority"
            value="high"
            type="charNoCut"
          />
          {/* <span className="inline-flex mx-1">-</span> */}
          <span className="inline-flex ml-3">{viewCounter(get(tasks, 'high').length)}</span>
        </div>
        <div className="flex">
          <EntityParamTag
            param="priority"
            value="urgent"
            type="charNoCut"
          />
          {/* <span className="inline-flex mx-1">-</span> */}
          <span className="inline-flex ml-3 color-red">{viewCounter(get(tasks, 'urgent').length)}</span>
        </div>
      </div>
    </div>
  );

  const overdueTasks = (
    <div className="py-1 px-2 flex items-center">
      <h7>Overdue tasks:</h7>
      <div className="ml-3 mr-1 color-red">
        {viewCounter(8)}
      </div>

      <BaseButton
        className="btnPrimary-outline ml-2"
        size="small"
      >
        <Icon path={mdiEyeOutline} size={0.75} className="mr-1" />
        view
      </BaseButton>
    </div>
  );

  const description = {
    success: "You don't have tasks",
    warning: (
      <div>
        <div className="inline-flex">
          You have
          <div className="ml-1 mr-1 color-red">
            <Badge count={tasks.all.length} showZero />
          </div>
          tasks
        </div>
        {sectionPriority}
        {overdueTasks}
      </div>
    ),
    error: (
      <div>
        <div className="inline-flex">
          You have
          <div className="ml-1 mr-1 color-red">
            <Badge count={tasks.all.length} showZero />
          </div>
          tasks. We advise you to pay attention
        </div>
        {sectionPriority}
        {overdueTasks}
      </div>
    ),
  };

  const icon = {
    success: <Icon size={1.5} path={mdiCheckCircleOutline} />,
    warning: <Icon size={1.5} path={mdiAlertCircleOutline} />,
    error: <Icon size={1.5} path={mdiAlertCircleOutline} />,
  };

  //   const initFunc = () => {
  //     if (unseen.length === 0) {
  //       return setState('success');
  //     }
  //     if (unseen.length > 10) {
  //       return setState('error');
  //     }

  //     setState('warning');
  //   };

  const getDataTasks = (list) => {
    const sorted = list.reduce((prev, curr) => {
      if (get(curr, 'priority', '') === 'low') {
        return ({ ...prev, low: [...prev.low, curr] });
      }
      if (get(curr, 'priority', '') === 'medium') {
        return ({ ...prev, medium: [...prev.medium, curr] });
      }
      if (get(curr, 'priority', '') === 'high') {
        return ({ ...prev, high: [...prev.high, curr] });
      }

      return ({ ...prev, urgent: [...prev.urgent, curr] });
    }, defaultData);

    // console.log('sorted', sorted);
    sorted.all = list;

    setTasks(sorted);

    setLoading(false);

    if (list.length <= 10) {
      return setType('warning');
    }

    return setType('error');
  };

  const initFunc = async () => {
    if (!loading) {
      setLoading(true);
    }

    const config = {
      data: {
        entity_type: 'issue',
        entities: myProjectsUuids,
        depth: 0,
        entity_type__not: ['archiveEntity'],
        parent: pmPartition,
        params: {
          status__not: ['closed', 'deployed', 'abandoned'],
          usersSearch: [actorUUID],
        },
      },
      with_files: false,
      customPartition: partitionNamesConfig.partition1,
      customConstants: ['REQUEST', 'SUCCESS', 'FAILURE'],
    };
    const res = await dispatch(getListOfCustomIssues(config));

    if (res?.total === 0) {
      setLoading(false);
      setType('success');
      return;
    }

    getDataTasks(res?.data);
  };

  const getListProjects = async () => {
    const params = {
      usersSearch: [actorUUID],
      // adminsMembers__or: [actorUUID],
      archive: false,
    };
    const res = await dispatch(getListOfProjectRequest(pmPartition, params));

    if (res?.length === 0) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (actorUUID) {
      getListProjects();
    }
  }, [actorUUID]);

  useEffect(() => {
    if (!isEmpty(myProjectsUuids)) {
      initFunc();
    }
  }, [pmPartition, myProjectsUuids]);

  return (
    <Alert
      message={capitalize(t('wms.labels.tasks', 'tasks'))}
      description={<Spin spinning={loading}>{description[type]}</Spin>}
      type={type}
      showIcon
      icon={icon[type]}
      action={(
        <Space direction="vertical">
          <Link to={`/public/user/${myPublicEntityUUID}/dashboard/tasks`}>
            <BaseButton
              size="small"
              className="btnPrimary-outline"
            >
              Go to Tasks
            </BaseButton>
          </Link>

          <BaseButton
            size="small"
            className="btnPrimary-outline"
          // onClick={toggleReloadInfo}
          >
            reload info
          </BaseButton>
        </Space>
      )}
    />
  );
}
