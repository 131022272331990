import { get, isEmpty } from 'lodash';
import moment from 'moment';
import {
  array, bool, func, object, string,
} from 'prop-types';
import { head } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import NewIssueForm from './NewIssueForm';

import { sendNotification } from '../../../../api/notificationsAPI';
import { entityCreate, incrementAndGetCount } from '../../../../entity/actions/entityActions';
import { getUserPublicProfileUUID } from '../../../../userFlow/store/selectors/selectors';
import { antNotification, getFirstStringOfText } from '../../../../MainUtils';
import useURLParams from '../../../../hooks/useURLParams';
import { getProjectScoring, updateProjectScoring } from '../../../actions/projectFlowActions';
import { getIssuesScoringData } from '../../../reducers/issues/IssuesSlicer';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { setGlobalUpdateEntity } from '../../../reducers/slicers/breadcrumbsSlicer';
import { updateEntityScoring } from '../../issuesView/utils';
import { partitionNamesConfig } from '../../../../api/appConfig';

export default function NewIssueComponents({
  isAssignOpen,
  setIsAssignOpen,
  defaultIssueConfig,
  partitionType,
  hideView,
  disabledView,
  customParentIssue,
  createIssueCallback,
  handleOk,
  setIsChanged = () => {},
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const issuesScoringData = useSelector(getIssuesScoringData);

  const { getURLParams } = useURLParams();
  const { activeProject } = getURLParams() || {};

  const initialFormState = {
    title: '',
    description: '',
    status: 'created',
    tracker: 'ticket',
    priority: 'low',
    partition: null,
    lifeTime: moment().add(2, 'week').format('YYYY-MM-DD'),
    estimated_time: 'easy',
    assignedProject: '',
    usersSearch: [],
    assignedVector: '',
    files: [],
    audioFiles: [],
    tags: [],
    links: [],
    selectedVectorData: {},
    selectedProjectData: {},
    usersUuidsForAssigned: [],
    testCaseData: '',
    isGlobalCreateModal: false,
  };

  const [formState, setFormState] = useState(initialFormState);
  const [loading, setLoading] = useState(true);

  const {
    title,
    description,
    status,
    tracker,
    priority,
    estimated_time,
    partition,
    assignedProject,
    assignedVector,
    files,
    audioFiles,
    lifeTime,
    tags,
    links,
    usersSearch,
    isGlobalCreateModal,
    selectedProjectData,
    selectedVectorData,
    usersUuidsForAssigned,
    testCaseData,
  } = formState || {};

  useEffect(() => {
    if (title === ''
    && description === ''
    && status === 'created'
    && tracker === 'ticket'
    && priority === 'low'
    && estimated_time === 'easy'
    && isEmpty(usersSearch)
    && assignedVector === ''
    && isEmpty(files)
    && isEmpty(audioFiles)
    && isEmpty(tags)
    && isEmpty(links)
    && testCaseData === '') {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  }, [JSON.stringify(formState)]);

  const parentIssue = customParentIssue || assignedVector || assignedProject;

  const onChangeField = (data) => {
    setFormState((prev) => ({ ...prev, ...data }));
  };

  const getIdIssue = async () => {
    const countIssue = await dispatch(incrementAndGetCount({
      entity_uuid: parentIssue,
      field_name: 'issue',
    }, partitionType === partitionNamesConfig.partition2 ? partitionType : partition || partitionType));

    let idVector = '';

    if (assignedVector) {
      idVector = get(formState, 'selectedVectorData.id', 'V');
    }
    return `${idVector}T${get(countIssue, 'increment')}`;
  };

  const sendAssignNotifications = (ticket) => {
    const { uuid, params } = ticket;
    const { vector, project } = params;

    if (params?.usersSearch?.length > 0) {
      const data = {
        projectUUID: project,
        vectorUUID: vector || '',
        entity_type: 'issue',
        uuid,
        partition: partitionType,

        project_uuid: project,
        project_id: defaultIssueConfig?.selectedProjectData?.params?.id,
        project_title: defaultIssueConfig?.selectedProjectData?.params?.title,
        // bug_title: defaultIssueConfig?.bugTrackedTitle,
        assigned_entity_type: 'issue',
        assigned_entity_title: params?.title,
        assigned_entity_id: params?.id,
        assigned_entity_uuid: uuid,
      };

      if (partitionType === partitionNamesConfig.partition3) {
        data.user = myPublicUUID || '';
      }

      dispatch(sendNotification('assigned', usersSearch, title, { entity: data }));
    }
  };

  const getTitle = useMemo(() => getFirstStringOfText(description, 128), [description]);

  const getParamsForSubmit = async () => {
    const id = await getIdIssue();
    // console.log('submit data', formState, 'id', id);
    const listFiles = [...audioFiles, ...files];

    const data = {
      entity_type: 'issue',
      parent: parentIssue,
      params: {
        project: assignedProject,
        vector: assignedVector,
        usersSearch,
        title: getTitle,
        description,
        tags,
        id,
        links,
        status,
        priority,
        tracker,
        estimated_time,
        lifeTime: lifeTime || moment().add('2', 'weeks').format('YYYY-MM-DD'),
        partitionType,
      },
    };

    if (testCaseData) {
      data.params.testCaseUuid = testCaseData?.case;
      data.params.testCycleUuid = testCaseData?.cycle;
    }

    const formData = new FormData();

    for (let i = 0; i < listFiles.length; i += 1) {
      formData.append('file', listFiles[i]);
    }

    formData.append('data', JSON.stringify(data));

    return formData;
  };

  const updateProject = async (props) => {
    const { avgVal: issuesScoringAVG, sum: indexesSum, total: totalCount } = props;
    const data = {
      uuid: activeProject,
      params: { scoringData: { issuesScoringAVG: Number(issuesScoringAVG), indexesSum, totalCount } },
      partition: partitionType,
    };
    // console.log('updateProjectScoring data', data);
    dispatch(updateProjectScoring(data))
      // .then((resp) => {
      //   console.log('updateProjectScoring resp', resp);
      // })
      .catch(() => antNotification('error', 'Access denied!'));
  };

  const updateIssuesScoringData = async (priorityVal, statusVal) => {
    // actionType, total, prevSum, currentPriority, oldPriority
    try {
      dispatch(getProjectScoring(
        activeProject,
        (resp) => {
          const oldScoringData = resp?.[0]?.params?.scoringData;
          // console.log('oldScoringData', oldScoringData);
          const data = {
            actionType: 'create',
            sum: oldScoringData?.indexesSum || issuesScoringData?.sum,
            total: oldScoringData?.totalCount || issuesScoringData?.total,
            currentPriority: priorityVal,
            currentStatus: statusVal,
          };
          const newScoringData = updateEntityScoring(data);
          if (newScoringData) {
            dispatch({
              type: ProjectTypeCommonConstants.UPDATE_ISSUES_PRIORITY_INDEXES_DATA,
              payload: newScoringData,
            });
            // console.log('activeProject', activeProject);
            updateProject(newScoringData);
          }
        },
        partitionType,
      ));
    } catch (e) {
      console.log('e', e);
    }
  };

  const handleFormSubmit = async () => {
    setLoading(true);

    const params = await getParamsForSubmit();

    const constants = ['NEW_ISSUE_REQUEST', 'NEW_ISSUE_SUCCESS', 'NEW_ISSUE_FAILURE']
      .map((item) => ProjectTypeCommonConstants[item]);

    const options = {
      partition: partitionType,
    };

    let res = await dispatch(entityCreate({
      data: params,
      constants,
      options,
    }));

    res = head(res);

    // console.log('handleFormSubmit', res);

    // if (!res) {
    //   antNotification('error', t('notifications.text.error.base', 'Error'));
    // }

    if (isGlobalCreateModal) {
      dispatch(setGlobalUpdateEntity({ entityType: 'issue', action: 'create', uuid: get(res, 'uuid') }));
    }

    sendAssignNotifications(res);

    updateIssuesScoringData(res?.params?.priority, res?.params?.status);

    antNotification('success', t(
      'notifications.text.success.new_issue_created',
      'New issue has been successfully added',
    ));

    if (createIssueCallback) {
      createIssueCallback(res?.uuid, res);
    }

    if (handleOk) {
      handleOk();
    }
  };

  const selectedProjectCallback = (value) => {
    onChangeField({
      ...value,
      assignedVector: '',
      selectedVectorData: {},
      usersSearch: [],
      partition: get(value, ['selectedProjectData', 'partition']) === 'public' ? partitionNamesConfig.partition3 : partitionNamesConfig.partition1,
      usersUuidsForAssigned: value?.selectedProjectData?.usersSearch,
    });
  };

  const selectedVectorCallback = (value) => {
    if (get(value, ['assignedVector']) === '' && isEmpty(get(value, ['assignedVector']))) {
      onChangeField({
        ...value,
        partition: null,
        usersUuidsForAssigned: [],
      });
    } else {
      onChangeField({
        ...value,
        partition: get(value, ['selectedVectorData', 'partition']) === 'public' ? partitionNamesConfig.partition3 : partitionNamesConfig.partition1,
        usersUuidsForAssigned: get(value, 'assignedVector')
          ? value?.selectedVectorData?.usersSearch
          : selectedProjectData?.params?.usersSearch,
      });
    }
  };

  const selectedUsersCallback = (value) => onChangeField({
    usersSearch: value,
  });

  // const onChangeLifeTime = (date) => onChangeField({ lifeTime: date });
  const onChangeFiles = (filesData) => onChangeField(filesData);

  const getInputValueAndChangeField = (e) => {
    const { target: { value, id } } = e || {};

    onChangeField({ [id]: value });
  };

  const onChangeEstimatedTime = (e) => {
    const { estimated_time } = e;

    onChangeField({ estimated_time });
  };

  const setInitData = () => {
    if (defaultIssueConfig) {
      setFormState((prev) => ({
        ...prev,
        ...defaultIssueConfig,
      }));
    }
  };

  useEffect(() => {
    setInitData();
    setLoading(false);
  }, []);

  // console.log('formState', formState);

  return (
    <NewIssueForm
      bugTrackedTitle={defaultIssueConfig?.testCaseData?.step?.description}
      testSteps={defaultIssueConfig?.testCaseData?.allSteps}
      isAssignOpen={isAssignOpen}
      setIsAssignOpen={setIsAssignOpen}
      hideView={hideView}
      disabledView={disabledView}
      partitionType={partitionType}
      title={title}
      getTitle={getTitle}
      description={description}
      status={status}
      tracker={tracker}
      priority={priority}
      links={links}
      projectUsers={get(selectedProjectData, 'params.users', [])}
      estimated_time={estimated_time}
      usersSearch={usersSearch}
      assignedProject={assignedProject}
      assignedVector={assignedVector}
      usersUuidsForAssigned={usersUuidsForAssigned}
      lifeTime={lifeTime}
      selectedVectorData={selectedVectorData}
      files={files}
      audioFiles={audioFiles}
      loading={loading}
      setLoading={setLoading}
      onChangeField={onChangeField}
      handleFormSubmit={handleFormSubmit}
      onChangeFiles={onChangeFiles}
      onChangeEstimatedTime={onChangeEstimatedTime}
      getInputValueAndChangeField={getInputValueAndChangeField}
      selectedUsersCallback={selectedUsersCallback}
      selectedVectorCallback={selectedVectorCallback}
      selectedProjectCallback={selectedProjectCallback}
    />
  );
}

NewIssueComponents.propTypes = {
  isAssignOpen: bool,
  setIsAssignOpen: func,
  defaultIssueConfig: object,
  partitionType: string,
  hideView: array,
  createIssueCallback: func,
  handleOk: func,
  customParentIssue: string,
  disabledView: array,
  setIsChanged: func,
};
