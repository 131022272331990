import React from 'react';
import { array, bool, func, object, string } from 'prop-types';

import { get } from 'lodash';
import cn from 'classnames';

import { Dropdown, Flex, Space, Spin } from 'antd';

import Icon from '@mdi/react';
import { mdiDotsVertical } from '@mdi/js';

import { stopPropagation } from '../../../54origins/utils54origins';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";
import SuggestedDayListOfIssues from './SuggestedDayListOfIssues';

function SuggestedPlan({
  issues,
  loading,
  onActionCallback,
  paginationSettings = {},
  mode,
  changeMode,
  showClosed,
  setShowClosed,
}) {
  const {
    limit,
    total,
    setLimit,
    setPartOfConfig,
    setNewPage,
    newCurrentPage,
    issueFilterConfig,
    changeIssueFilterConfig,
  } = paginationSettings;

  const btnBlue = "border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white";
  const btnGreen = "border border-green-500 text-green-500 hover:bg-green-500 hover:text-white";
  const btnRed = "border border-red-500 text-red-500 hover:bg-red-500 hover:text-white";

  const btnDropdownStyle = { height: 25 };

  const DropDownBtn = () => {
    return <Dropdown
      menu={{
        items: [{
          key: '0',
          label: <BaseButton
            className={cn(`w-full`, {
              [btnGreen]: !showClosed,
              [btnRed]: showClosed,
            })}
            size="small"
            style={btnDropdownStyle}
            onClick={() => setShowClosed(prev => !prev)}
          >
            {showClosed ? 'Hide completed issues' : 'Show completed issues'}
          </BaseButton>
        },
        ].filter(el => !el.hidden),
      }}
    >
      <BaseButton
        className={cn(`${btnBlue}`)}
        size="small"
        onClick={(e) => stopPropagation(e)}
      >
        <Icon
          path={mdiDotsVertical}
          className='-mx-1'
          size={0.8}
        />
      </BaseButton>
    </Dropdown>
  }

  const titleRender = () => {
    const totalHours = issues.reduce((acc, el) => acc + get(el, 'data.chunk'), 0) / 2;

    const isUserMode = 'user' === mode;
    const isAllMode = 'all' === mode;

    return (
      <Flex justify='space-between' align='center' className='my-2'>
        <Flex align='center'>
          <h6 className='mt-2 mr-1'>
            {isUserMode && 'My unplanned issues'}
            {isAllMode && 'All unplanned issues'}
          </h6>
          <Space.Compact>
            <BaseButton
              id="showMyIssuesButton"
              className="btnPrimary-outline ml-2"
              size="small"
              onClick={() => {
                if (isUserMode) changeMode?.('all');
                if (isAllMode) changeMode?.('user');
              }}
            >
              {isUserMode && 'Show all issues'}
              {isAllMode && 'Show my issues'}
            </BaseButton>
            <DropDownBtn />
          </Space.Compact>
        </Flex>
        {totalHours >= 0 ? <span className='text-sm text-blue-500'>
          These issues will be completed in {totalHours} hours (~{Math.ceil(totalHours / 8)} days)
        </span> : null}
      </Flex>
    )

  }

  return (
    <BaseCard
      title={titleRender()}
      className={"issue-card-issues-view"}
    >
      <Spin spinning={loading}>
        <SuggestedDayListOfIssues
          data={issues}
          onChangeRowCallback={(uuid) => onActionCallback(uuid, 'q-view')}
          onActionCallback={onActionCallback}
          totalCount={total}
          newCurrentPage={newCurrentPage}
          onChangePageCallback={setNewPage}
          limit={limit}
          setLimit={setLimit}
          getIssuesRequestSetting={setPartOfConfig}
          issueFilterConfig={issueFilterConfig}
          changeIssueFilterConfig={changeIssueFilterConfig}
        />
      </Spin>
    </BaseCard >

  );
}

SuggestedPlan.propTypes = {
  issues: array,
  loading: bool,
  mode: string,
  changeMode: func,
  onRowClickCallback: func,
  onActionCallback: func,
  paginationSettings: object,
  showClosed: bool,
  setShowClosed: func,
}

export default SuggestedPlan;
