import { Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bool, string } from 'prop-types';

import CustomCardComponent from '../../../../../projectFlow/components/commonComponents/CustomCardComponent';
import ExpandModeLayout from '../../../../../projectFlow/components/commonComponents/expandModeLayout/ExpandModeLayout';
import ListOfGoals from '../../../../../projectFlow/components/goals/ListOfGoals';
import ProjectGoalView from '../../../../../projectFlow/components/goals/ProjectGoalView';

import { clearProjectGoal, getProjectGoalRequest } from '../../../../../projectFlow/actions/projectGoalsActions';
import { useGetProjectsGoals } from '../../../../../userFlow/hooks/personalDashboardHooks/useGetProjectsGoals';
import useURLParams from '../../../../../hooks/useURLParams';
import useMouseMiddleRedirect from '../../../../../hooks/useMouseMiddleRedirect';
import { partitionNamesConfig } from '../../../../../api/appConfig';

const hideElements = ['createGoal', 'deleteGoal'];
const disabledElements = ['textarea', 'title', 'status', 'responsible', 'options'];

function DashboardGoalsCardWrapper({
  defaultPartition = partitionNamesConfig.partition1,
  readOnlyComments = false,
}) {
  const dispatch = useDispatch();
  const { getURLParams, clearSearchParams, setSearchParams } = useURLParams();
  const { goal } = getURLParams();
  const { onMouseMiddleClick } = useMouseMiddleRedirect();

  const [paginationWidthSortAndSearchGoals, setPaginationWidthSortAndSearchGoals] = useState({});

  const clearGoal = () => clearSearchParams(['goal']);

  const selectGoal = (uuid) => {
    if (uuid === goal) {
      clearSearchParams(['goal']);
    } else {
      setSearchParams({ goal: uuid });
    }
  };

  const onMouseMiddleClickHandler = (e, record) => {
    const { uuid: goalUUID } = record;
    const searchParams = { activeCard: 'goals', goal: goalUUID };
    onMouseMiddleClick(e, searchParams);
  };

  useEffect(() => {
    if (goal) {
      dispatch(getProjectGoalRequest({
        uuid: goal,
        partition: defaultPartition,
      }));
    } else {
      dispatch(clearProjectGoal);
    }
  }, [goal]);

  useGetProjectsGoals(paginationWidthSortAndSearchGoals);

  return (
    <Col span={24} className="flex">
      <ExpandModeLayout localStorageTarget="planningView">
        <ExpandModeLayout.Table>
          <ListOfGoals
            className="mb-3"
            partition={defaultPartition}
            hideElements={hideElements}
            onMouseMiddleClick={onMouseMiddleClickHandler}
            getPaginationAndSearch={setPaginationWidthSortAndSearchGoals}
            selectedGoal={goal}
            selectRowCallback={selectGoal}
          />
        </ExpandModeLayout.Table>

        <ExpandModeLayout.Card>
          {goal && (
            <CustomCardComponent type="goal">
              <ProjectGoalView
                disabledElements={disabledElements}
                backBtnCallback={clearGoal}
                defaultPartition={defaultPartition}
                readOnlyComments={readOnlyComments}
              />
            </CustomCardComponent>
          )}
        </ExpandModeLayout.Card>
      </ExpandModeLayout>
    </Col>
  );
}

DashboardGoalsCardWrapper.propTypes = {
  defaultPartition: string,
  readOnlyComments: bool,
};

export default DashboardGoalsCardWrapper;
