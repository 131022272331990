import { UsersPartitionConstants } from '../../constants/Constants';

const initialState = {
  isLoadingDayLog: false,
  dayLogData: [],
  isErrorDayLog: '',

  isLoadingDayLogList: false,
  dayLogListData: [],
  isErrorDayLogList: '',
};

const dayLogReducer = (state = initialState, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case UsersPartitionConstants.GET_DAY_LOG_ENTITY_REQUEST:
    case UsersPartitionConstants.CREATE_DAY_LOG_ENTITY_REQUEST:
    case UsersPartitionConstants.UPDATE_DAY_LOG_ENTITY_REQUEST:
      return {
        ...state,
        isLoadingDayLog: true,
      };
    case UsersPartitionConstants.GET_DAY_LOG_ENTITY_SUCCESS:
    case UsersPartitionConstants.CREATE_DAY_LOG_ENTITY_SUCCESS:
    case UsersPartitionConstants.UPDATE_DAY_LOG_ENTITY_SUCCESS:
      return {
        ...state,
        dayLogData: payload,
        isLoadingDayLog: false,
        isErrorDayLog: '',
      };
    case UsersPartitionConstants.GET_DAY_LOG_ENTITY_FAILURE:
    case UsersPartitionConstants.CREATE_DAY_LOG_ENTITY_FAILURE:
    case UsersPartitionConstants.UPDATE_DAY_LOG_ENTITY_FAILURE:
      return {
        ...state,
        dayLogData: [],
        isLoadingDayLog: false,
        isErrorDayLog: payload,
      };
    case UsersPartitionConstants.CLEAR_DAY_LOG_ENTITY:
      return {
        ...state,
        dayLogData: [],
        isLoadingDayLog: false,
        isErrorDayLog: '',
      };

    case UsersPartitionConstants.GET_DAY_LOG_LIST_ENTITY_REQUEST:
      return {
        ...state,
        isLoadingDayLogList: true,
      };
    case UsersPartitionConstants.GET_DAY_LOG_LIST_ENTITY_SUCCESS:
      return {
        ...state,
        dayLogListData: payload,
        isLoadingDayLogList: false,
        isErrorDayLogList: '',
      };
    case UsersPartitionConstants.GET_DAY_LOG_LIST_ENTITY_FAILURE:
      return {
        ...state,
        dayLogListData: [],
        isLoadingDayLogList: false,
        isErrorDayLogList: payload,
      };
    case UsersPartitionConstants.CLEAR_DAY_LOG_LIST_ENTITY:
      return {
        ...state,
        dayLogData: [],
        isLoadingDayLog: false,
        isErrorDayLog: '',
      };
    default: return state;
  }
};

export default dayLogReducer;
