/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mainWorkConfigData: [],
  mainWorkConfigIsLoading: false,
  mainWorkConfigError: '',

  workConfigData: [],
  workConfigIsLoading: false,
  workConfigError: '',

  workConfigListData: [],
  workConfigListIsLoading: false,
  workConfigListError: '',
};

const workConfigSlice = createSlice({
  name: 'workConfig',
  initialState,
  reducers: {
    GET_OR_CREATE_MAIN_WORK_CONFIG_REQUEST: (state) => {
      state.mainWorkConfigIsLoading = true;
    },
    GET_OR_CREATE_MAIN_WORK_CONFIG_SUCCESS: (state, action) => {
      state.mainWorkConfigData = action.payload;
      state.mainWorkConfigIsLoading = false;
      state.mainWorkConfigError = '';
    },
    GET_OR_CREATE_MAIN_WORK_CONFIG_FAILURE: (state, action) => {
      state.mainWorkConfigData = [];
      state.mainWorkConfigIsLoading = true;
      state.mainWorkConfigError = action.payload;
    },

    GET_WORK_CONFIG_REQUEST: (state) => {
      state.workConfigIsLoading = true;
    },
    GET_WORK_CONFIG_SUCCESS: (state, action) => {
      state.workConfigData = action.payload;
      state.workConfigIsLoading = false;
      state.workConfigError = '';
    },
    GET_WORK_CONFIG_FAILURE: (state, action) => {
      state.workConfigData = [];
      state.workConfigIsLoading = true;
      state.workConfigError = action.payload;
    },

    CREATE_WORK_CONFIG_REQUEST: (state) => {
      state.workConfigIsLoading = true;
    },
    CREATE_WORK_CONFIG_SUCCESS: (state, action) => {
      state.workConfigData = action.payload;
      state.workConfigIsLoading = false;
      state.workConfigError = '';
    },
    CREATE_WORK_CONFIG_FAILURE: (state, action) => {
      state.workConfigData = [];
      state.workConfigIsLoading = true;
      state.workConfigError = action.payload;
    },

    UPDATE_WORK_CONFIG_REQUEST: (state) => {
      state.workConfigIsLoading = true;
    },
    UPDATE_WORK_CONFIG_SUCCESS: (state, action) => {
      state.workConfigData = action.payload;
      state.workConfigIsLoading = false;
      state.workConfigError = '';
    },
    UPDATE_WORK_CONFIG_FAILURE: (state, action) => {
      state.workConfigData = [];
      state.workConfigIsLoading = true;
      state.workConfigError = action.payload;
    },

    GET_WORK_CONFIG_LIST_REQUEST: (state) => {
      state.workConfigListIsLoading = true;
    },
    GET_WORK_CONFIG_LIST_SUCCESS: (state, action) => {
      state.workConfigListData = action.payload;
      state.workConfigListIsLoading = false;
      state.workConfigListError = '';
    },
    GET_WORK_CONFIG_LIST_FAILURE: (state, action) => {
      state.workConfigListData = [];
      state.workConfigListIsLoading = false;
      state.workConfigListError = action.payload;
    },

    CLEAR_WORK_CONFIG: (state) => {
      state.workConfigData = [];
      state.workConfigIsLoading = false;
      state.workConfigError = '';
    },

    CLEAR_WORK_CONFIG_LIST: (state) => {
      state.workConfigListData = [];
      state.workConfigListIsLoading = false;
      state.workConfigListError = '';
    },
  },
});

export const workConfigActions = workConfigSlice.actions;

export default workConfigSlice.reducer;
