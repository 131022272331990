
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import {
  path, pathOr, prop, propOr,
} from 'ramda';
import Icon from '@mdi/react';
import {
  mdiCircleSmall, mdiDownload, mdiFileOutline, mdiReplyOutline,
} from '@mdi/js';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Col, Image, Tooltip,
} from 'antd';

import { get, isNil } from 'lodash';
import HardDeleteEntityContainer from '../../../entity/containers/HardDeleteEntityContainer';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import CommentReply from './CommentReply';

import { parseLinks } from '../../../MainUtils';
import { capitalize } from 'lodash';
import { getProjectCreatorPerms, getProjectUUID } from '../../selectors/selectors';
import { getLocale } from '../../../locale/selectors';
import WrapperModalForViewVideo from '../../../components/video/WrapperModalForViewVideo';
import { getUserPublicProfileUUID } from '../../../userFlow/store/selectors/selectors';
import { bytesToSize } from '../../../components/uploader/utils';
import { getInitialsNameOfActor } from '../../../54origins/utils54origins';
import WrapperAvatarsGroupForProject from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';
import { getActorsW54 } from '../../../actors/selectors/actorsW54Selector';
import useGetUserPerm from '../../../permissions/hooks/useGetUserPerm';
import { isAdminOrRoot } from '../../../entity/selectors/selectors';
import { partitionNamesConfig } from '../../../api/appConfig';

function CommentTemplate({
  entityType,
  afterRemoveComment,
  item,
  onReply,
  partitionType,
  parentUUID,
  saveFile,
  readOnlyComments,
  itsMe,
  t,
}) {
  const projectUUID = useSelector(getProjectUUID);
  const locale = useSelector(getLocale);
  const publicUserUUID = useSelector(getUserPublicProfileUUID);
  const actorsW54Object = useSelector(getActorsW54);
  const adminOrRoot = useSelector(isAdminOrRoot)

  const projectCreatorPerms = useSelector(getProjectCreatorPerms);

  const [showDeleteButton, setShowDeleteButton] = useState(false);

  const { perm } = useGetUserPerm({
    entityUUID: projectUUID,
    partitionType,
  });

  useEffect(() => {
    if (itsMe) {
      setShowDeleteButton(perm?.delete || adminOrRoot || projectCreatorPerms?.affix_creator_delete);
    } else {
      setShowDeleteButton(perm?.delete || adminOrRoot);
    }
  }, [itsMe,
    perm?.delete,
    projectCreatorPerms?.affix_creator_delete
  ])

  const compare = (fileA, fileB) => prop('content_length', fileA) - prop('content_length', fileB);

  const imageFiles = propOr([], 'files', item)
    .filter(
      ({ content_type }) => content_type && content_type.includes('image'),
    )
    .sort(compare);

  const otherFiles = propOr([], 'files', item)
    .filter(
      ({ content_type }) => content_type
        && !content_type.includes('audio')
        && !content_type.includes('image'),
    )
    .sort(compare);

  const audioFiles = propOr([], 'files', item)
    .filter(
      ({ content_type }) => content_type && content_type.includes('audio'),
    )
    .sort(compare);

  const viewName = useCallback((filename, name, fileSize) => (
    <Tooltip placement="bottom" title={`${filename || name} (${fileSize})`}>
      <p className="text-upload-file">
        {`${filename || name} (${fileSize})`}
      </p>

    </Tooltip>
  ));

  const renderImages = (
    <Image.PreviewGroup>
      {!isNil([...imageFiles, ...otherFiles])
        && [...imageFiles, ...otherFiles].map((item, i) => {
          const fileSize = bytesToSize(get(item, 'content_length', 0)
            || get(item, 'upload.total', '')
            || get(item, 'size', ''));

          if (item.content_type.indexOf('video') !== -1) {
            return (
              <Col className="relative flex flex-col items-center m-1" key={`${item?.uid}${i}`}>
                <WrapperModalForViewVideo
                  file={item}
                />
                {viewName(get(item, 'filename', ''), get(item, 'name', 'file'), fileSize)}
              </Col>
            );
          }

          return (
            <Col className="relative flex flex-col items-start m-1" key={`${item?.uid}${i}`}>
              {(get(item, 'content_type') && item.content_type.indexOf('image') !== -1)
                || (get(item, 'type') && item.type.indexOf('image') !== -1)
                ? (
                  <div className="wrapper-file-icon">
                    <Image
                      width={37}
                      height={37}
                      src={get(item, 'type')
                        ? URL.createObjectURL(item)
                        : `data:${item?.content_type};base64, ${item?.file}`}
                    // fallback={defaultImg}
                    />
                  </div>
                ) : (
                  <div
                    className="flex items-center fileAttached"
                    onClick={(e) => saveFile(e, `data:${item.content_type};base64, ${item.file}`, item.filename)}
                  >
                    <Icon
                      className="fileIcon"
                      color="#bfbfbf"
                      path={mdiFileOutline}
                      size={2}
                    />
                    <Icon
                      className="fileLoad"
                      color="#bfbfbf"
                      path={mdiDownload}
                      size={1}
                    />
                  </div>
                )}
              {viewName(get(item, 'filename', ''), get(item, 'name', 'file'), fileSize)}
            </Col>
          );
        })}
    </Image.PreviewGroup>
  );

  const renderAudio = (
    audioFiles.map((file, id) => {
      const contentTypeData = prop('content_type', file);
      const key = propOr(id, 'uuid', file);
      const src = `data:${contentTypeData};base64, ${prop('file', file)}`;
      return (
        <div key={key} className="relative w-full">
          <div className="audio-box-in-popup">
            <div>
              <div className="audio-box__label">{get(file, 'filename', 'audio')}</div>
              <audio
                className="audio-box__source-wrap"
                controls
                key={id}
              >
                <source src={src} type={contentTypeData} />
                <source src={src} type="audio/mpeg" />
                {t(
                  'wms.audio.no_support',
                  'Your browser does not support the audio element',
                )}
                .
              </audio>
            </div>
          </div>
        </div>
      );
    })
  );

  const getTime = () => capitalize(
    moment(prop('created', item)).locale(locale).format('MMM. D, LT'),
  );

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const deleteButton = showDeleteButton && (
    <HardDeleteEntityContainer
      partitionType={partitionType}
      name={entityType}
      labelMode
      item={item}
      disabled={readOnlyComments}
      entityUUID={item.uuid}
      actionForEntity={afterRemoveComment}
      checkHardDelete={false}
      entityType="comment"
      type="affix"
      hideLabel
      typeBtn="danger"
      className="comment-button-delete"
      iconSize={0.75}
      tooltipPlacement="left"
    />
  );

  const localizeSystem = (comment = {}, disabled) => {
    if (typeof comment === 'string') {
      return comment;
    }

    const {
      action, param, value, entity, descriptionComment,
    } = comment;

    switch (action) {
      case 'changed': {
        const getMessage = (changedParam, changedValue) => {
          let valueText;

          switch (changedParam) {
            case 'dateEnd':
            case 'datestart':
              valueText = changedValue;
              break;
            case 'users':
              valueText = changedValue?.map?.((userUuid = '') => {
                const currentUser = actorsW54Object?.[userUuid];
                return `${currentUser?.uinfo?.first_name} ${currentUser?.uinfo?.last_name}`;
              }).join(', ') ?? '';
              break;
            default:
              valueText = t(`wms.comments.values.${changedValue}`, changedValue);
              break;
          }

          const actionText = capitalize(t(`wms.comments.actions.${action}`, action));
          const paramText = t(`wms.comments.params.${changedParam?.toLowerCase()}`, `the ${changedParam} to`);
          // const valueText = valueIsExclusion ? changedValue
          //   : t(`wms.comments.values.${changedValue}`, changedValue);

          return (
            <>
              {`${actionText} ${paramText}: ${valueText}`}
              <br />

              {descriptionComment
                ? (
                  <>
                    <span className="inline-flex ml-2">
                      {descriptionComment}
                    </span>
                    <br />
                  </>
                )
                : ''}
            </>
          );
        };

        if (Array.isArray(param)) {
          return param.map((item, id) => getMessage(item, value[id]));
        }

        return getMessage(param, value);
      }

      case 'created': {
        const text = capitalize(t('wms.comments.issue_created', 'issue created'));

        return (
          <Link
            to={{
              pathname: partitionType === partitionNamesConfig.partition3 ? `/public/user/${publicUserUUID}/projects/issuesnew` : '/pm/projects/issuesnew',
              search: `?activeProject=${projectUUID}&activeIssue=${value}`,
            }}
            onClick={disabled && preventDefault}
            disabled={disabled}
            rel="noreferrer"
            target="_blank"
          >
            {`${text}: ${param}`}
          </Link>
        );
      }

      case 'created_bulk_issues': {
        const text = capitalize(t('wms.comments.issue_created', 'issue created'));

        return (
          <div className="flex flex-col">
            <div>{`${text}:`}</div>
            <div className="flex flex-col">
              {value.map((issue, i) => (
                <div className="flex">
                  <span>{`${i + 1}. `}</span>
                  <Link
                    to={get(issue, 'link')}
                    rel="noreferrer"
                    target="_blank"
                    onClick={disabled && preventDefault}
                    disabled={disabled}
                  >
                    {get(issue, 'title')}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        );
      }

      case 'created vector': {
        const text = capitalize(t('wms.comments.vector_created', 'vector created'));

        return (
          <Link
            to={{
              pathname: partitionType === partitionNamesConfig.partition3 ? `/public/user/${publicUserUUID}/projects/issuesnew` : '/pm/projects/issuesnew',
              search: `?activeProject=${projectUUID}&activeVector=${value}`,
            }}
            rel="noreferrer"
            target="_blank"
            onClick={disabled && preventDefault}
            disabled={disabled}
          >
            {`${text}: ${param}`}
          </Link>
        );
      }

      case 'important': {
        return (
          <span>
            {`Marked this ${entity} as important`}
          </span>
        );
      }

      default:
        return '';
    }
  };

  const localizeSystemInReply = (comment = {}) => {
    if (typeof comment === 'string') {
      return comment;
    }
    const { action, param, value } = comment;

    switch (action) {
      case 'changed': {
        const getMessage = (changedParam, changedValue) => {
          let valueText;

          switch (changedParam) {
            case 'dateEnd':
            case 'datestart':
              valueText = changedValue;
              break;
            case 'users':
              valueText = changedValue?.map?.((userUuid = '') => {
                const currentUser = actorsW54Object?.[userUuid];
                return `${currentUser?.uinfo?.first_name} ${currentUser?.uinfo?.last_name}`;
              }).join(', ') ?? '';
              break;
            default:
              valueText = t(`wms.comments.values.${changedValue}`, changedValue);
              break;
          }

          const actionText = capitalize(t(`wms.comments.actions.${action}`, action));
          const paramText = t(`wms.comments.params.${changedParam?.toLowerCase()}`, `the ${changedParam} to`);

          return `${actionText} ${paramText}: ${valueText}`;
        };

        if (Array.isArray(param)) {
          return param.map((item, id) => getMessage(item, value[id]));
        }

        return getMessage(param, value);
      }

      case 'created': {
        const text = capitalize(t('wms.comments.issue_created', 'issue created'));

        return `${text}: ${param}`;
      }

      case 'created vector': {
        const text = capitalize(t('wms.comments.vector_created', 'vector created'));

        return `${text}: ${param}`;
      }

      default:
        return '';
    }
  };

  const clickToReply = (comment) => {
    const otherData = () => {
      const system = propOr(false, 'system', item);
      // const system = false
      return system ? localizeSystemInReply(pathOr('', ['params', 'comment'], comment)) : '';
    };

    const data = {
      actorUUID: comment.actor_uuid,
      actorName: comment.username,
      text: typeof comment.params.comment === 'string'
        ? comment.params.comment : otherData(),
    };

    onReply(data);
  };

  const replyButton = (
    <BaseButton
      className="btnSecondary-link comment-button-reply"
      disabled={readOnlyComments}
    >
      <div
        className="flex items-center justify-center w-full h-full"
        id={`reply_button_${item.uuid}`}
        onClick={() => clickToReply(item)}
      >
        <Tooltip
          title={capitalize(t('wms.buttons.reply', 'reply'))}
          placement="left"
          getPopupContainer={() => document.getElementById(`reply_button_${item.uuid}`)}
        >
          <Icon path={mdiReplyOutline} size={0.7} />
        </Tooltip>
      </div>
    </BaseButton>
  );

  const viewComment = () => {
    const system = propOr(false, 'system', item);

    const comment = system
      ? pathOr({}, ['params', 'comment'], item)
      : pathOr('', ['params', 'comment'], item);

    if (!comment) {
      return null;
    }

    return (
      <p className="comment-text">
        {system ? localizeSystem(comment, readOnlyComments) : parseLinks(comment, readOnlyComments)}
      </p>
    );
  };

  const renderComment = () => {
    const reply = path(['params', 'reply'], item);
    return (
      <>
        {reply && (
          <CommentReply
            actorName={reply.actorName}
            actorUUID={reply.actorUUID}
            text={reply.text}
            isComment
          />
        )}
        <div className="w-full">
          {viewComment()}
          <div className="flex flex-wrap">
            {[...imageFiles, ...otherFiles].length > 0 && renderImages}
            {/* {otherFiles.length > 0 && renderFiles} */}
            {audioFiles.length > 0 && renderAudio}
          </div>
        </div>
      </>
    );
  };

  const renderTimeLogComment = () => (
    <div>

      {pathOr('', ['params', 'description'], item) && (
        <div className="flex items-start">
          <div className="description_comment_text">description:</div>
          <p className="comment-text ml-1">
            {pathOr('', ['params', 'description'], item)}
          </p>
        </div>
      )}

      <div className="flex items-center">
        <span>time:</span>
        <p className="comment-text ml-1">
          {pathOr('', ['params', 'time'], item) || pathOr('', ['params', 'trackerTime'], item)}
        </p>
      </div>
    </div>

  );

  const styleUserName = { fontSize: '14px', color: getInitialsNameOfActor(item.actor_uuid) };
  const styleTime = { color: '#bfbfbf' };
  const hideReplyButton = entityType === 'timelog';
  const username = prop('username', item) ?? '';
  return (
    <>
      <div className="comment-wrapper">
        <div className="comment-header">
          <Tooltip
            title={username}
            placement="topLeft"
          >
            {username?.length <= 19 ? (
              <h6 style={styleUserName}>
                {username}
              </h6>
            ) : (
              <h6 style={styleUserName}>
                {username.slice(0, 19)}
                ...
              </h6>
            )}
          </Tooltip>
          <p>
            <span>
              <Icon path={mdiCircleSmall} size={1} color="#d9d9d9" />
            </span>
            <span style={styleTime}>{getTime()}</span>
          </p>
        </div>
        {entityType === 'timelog'
          ? renderTimeLogComment()
          : renderComment()}

      </div>
      <div className="comment-actions">
        {deleteButton}
        {!hideReplyButton && replyButton}
      </div>
    </>
  );
}
CommentTemplate.propTypes = {
  afterRemoveComment: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  onReply: PropTypes.func.isRequired,
  partitionType: PropTypes.string,
  saveFile: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  entityType: PropTypes.string,
  parentUUID: PropTypes.bool,
  itsMe: PropTypes.bool,
};

export default withTranslation()(CommentTemplate);
