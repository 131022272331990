
import { documentTypeFilterOptions, documentStatusFilterOptions } from "../../../../../constants/allFilterOptions";

const typeColumnFilter = {
    filters: documentTypeFilterOptions,
    filterMode: 'tree',
    filterType: 'badge',
}

const statusColumnFilter = {
    filters: documentStatusFilterOptions,
    filterMode: 'tree',
    filterType: 'iconWithStatus',
  };
  

export const defaultDocumentFilter = {
    priority: undefined,
    entity_type: ['projectDocument', 'projectIdea', 'projectSpreadsheet'],
    status: ['created', 'important']
}

export const columnsFilterConfig = {
    type: typeColumnFilter,
    status: statusColumnFilter
}