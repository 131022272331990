import { Col, Row } from 'antd';
import { bool, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ExpandModeLayout from '../../../../../projectFlow/components/commonComponents/expandModeLayout/ExpandModeLayout';
import ListOfDocuments from '../../../../../projectFlow/components/docView&ideas/ListOfDocuments';
import DashboardDocOrIdeaView from '../DocsCard/DashboardDocOrIdeaView';

import { areFetchingProjDocs } from '../../../../../projectFlow/selectors/selectors';
import { getDocumentOrIdea } from '../../../../../projectFlow/actions/DocumentsActions';
import { useGetProjectsDocsOrIdeas } from '../../../../../userFlow/hooks/personalDashboardHooks/useGetProjectsDocsOrIdeas';
import { clearDocument } from '../../../../../projectFlow/actions/projectFlowActions';
import useURLParams from '../../../../../hooks/useURLParams';
import useMouseMiddleRedirect from '../../../../../hooks/useMouseMiddleRedirect';
import { partitionNamesConfig } from '../../../../../api/appConfig';

const hiddenElements = ['createBtns', 'docsFilter', 'deleteIcon'];

function DashboardIdeasTableWrapper({
  defaultPartition = partitionNamesConfig.partition1,
  readOnlyComments = false,
}) {
  const dispatch = useDispatch();

  const { getURLParams, clearSearchParams, setSearchParams } = useURLParams();
  const { onMouseMiddleClick } = useMouseMiddleRedirect();

  const { activeNote } = getURLParams();
  const docsOfIdeasFetching = useSelector(areFetchingProjDocs);

  const [ideasConfig, setIdeasConfig] = useState({});
  const [showEditor, setShowEditor] = useState(false);

  useGetProjectsDocsOrIdeas('projectIdea', ideasConfig);

  const onMouseMiddleClickHandler = (e, record) => {
    const { uuid: noteUUID } = record;
    const searchParams = { activeCard: 'notes', activeNote: noteUUID };
    onMouseMiddleClick(e, searchParams);
  };

  const selectIdea = (uuid) => {
    if (activeNote === uuid) {
      clearSearchParams(['activeNote']);
    } else {
      setSearchParams({ activeNote: uuid });
    }
  };

  useEffect(() => {
    if (activeNote) {
      setShowEditor(false);
      dispatch(getDocumentOrIdea({ uuid: activeNote }));
    } else {
      dispatch(clearDocument());
    }
  }, [activeNote]);

  return (
    <Row className="w-full">
      <Col span={24} className="flex">
        <ExpandModeLayout localStorageTarget="documents">
          <ExpandModeLayout.Table>
            <ListOfDocuments
              fetching={docsOfIdeasFetching}
              partitionType={defaultPartition}
              hiddenElements={hiddenElements}
              onMouseMiddleClick={onMouseMiddleClickHandler}
              setCurrentConfigAndGetRequest={setIdeasConfig}
              selectDocumentCallback={selectIdea}
            />
          </ExpandModeLayout.Table>

          <ExpandModeLayout.Card>
            {activeNote && (
              <DashboardDocOrIdeaView
                readOnlyComments={readOnlyComments}
                showEditor={showEditor}
                setShowEditor={setShowEditor}
              />
            )}
          </ExpandModeLayout.Card>
        </ExpandModeLayout>
      </Col>
    </Row>
  );
}

DashboardIdeasTableWrapper.propTypes = {
  defaultPartition: string,
  readOnlyComments: bool,
};

export default DashboardIdeasTableWrapper;
