import React from 'react';
import PropTypes, { string } from 'prop-types';

function StatElem({
  title = '',
  data = '',
  className = '',
}) {
  return (
    <div className={`flex items-center reportTimeBlock ${className}`}>
      <span className="ml-1">{title}</span>
      <div className="px-2 reportTimeBlockText">
        {data}
      </div>
    </div>
  );
}

StatElem.propTypes = {
  title: PropTypes.string,
  data: PropTypes.string,
  className: string,
};

export default StatElem;
