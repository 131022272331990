/* eslint-disable import/prefer-default-export */
// export const docsEntityTypesDropdown = ['document', 'idea'];

// export const docsEntityTypesOptions = {
//   document: 'createdBlue',
//   idea: 'createdBlue',
// };

export const getDocumentTypeInfo = (documentType) => {
  let documentTypeInfo = {};

  switch (documentType) {
    case 'projectDocument': {
      documentTypeInfo = {
        text: 'document',
        label: 'wms.labels.document',
        // className: 'orange',
        className: 'skyBlue',
      };
      break;
    }
    case 'historyOfIdeaChanges':
    case 'projectIdea': {
      documentTypeInfo = {
        text: 'note',
        label: 'wms.labels.note',
        // className: 'yellow',
        className: 'skyBlue',
      };
      break;
    }
    case 'projectSpreadsheet': {
      documentTypeInfo = {
        text: 'spreadsheet',
        label: 'wms.labels.spreadsheet',
        // className: 'green',
        className: 'skyBlue',
      };
      break;
    }
    case 'projectGoal': {
      documentTypeInfo = {
        text: 'goal',
        label: 'wms.noun.goal.0',
        // className: 'green',
        className: 'skyBlue',
      };
      break;
    }
    case 'projectMilestone': {
      documentTypeInfo = {
        text: 'milestone',
        label: 'wms.noun.milestone.0',
        // className: 'green',
        className: 'skyBlue',
      };
      break;
    }
    default: {
      documentTypeInfo = {
        text: 'unknown',
        label: 'unknown',
        className: 'lightGrey',
      };
    }
  }
  return documentTypeInfo;
};
