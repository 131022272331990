import { UsersPartitionConstants } from '../../constants/Constants';

const initialState = {
  isLoading: false,
  data: [],
  isError: '',
};

const mainPublicDailyReducer = (state = initialState, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case UsersPartitionConstants.GET_MAIN_PUBLIC_DAILY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UsersPartitionConstants.GET_MAIN_PUBLIC_DAILY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
        isError: '',
      };
    case UsersPartitionConstants.GET_MAIN_PUBLIC_DAILY_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: [],
        isError: payload,
      };
    default: return state;
  }
};

export default mainPublicDailyReducer;
