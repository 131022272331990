import { head } from "lodash";
import { UsersPartitionConstants } from "../../constants/Constants";

const initialState = {
  fetching: true,
  data: [],
  error: "",
  fetchingForActivityInPrivatePlanning: false,
  dragAndDropElement: {
    date: "",
    item: "",
    type: "",
    delete: false,
  },
  dublicateActivities: [],
  syncData: [],
};

export default function dailyReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UsersPartitionConstants.GET_DAILY_ENTITY_REQUEST:
      return {
        ...state,
        fetching: true,
        fetchingForActivityInPrivatePlanning: true,
      };
    case UsersPartitionConstants.GET_DAILY_ENTITY_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetchingForActivityInPrivatePlanning: false,
        data: Array.isArray(payload) ? head(payload) : payload,
      };
    case UsersPartitionConstants.GET_DAILY_ENTITY_FAILURE:
      return {
        ...state,
        fetching: false,
        fetchingForActivityInPrivatePlanning: false,
        data: [],
        error: payload,
      };
    case UsersPartitionConstants.CLEAR_DAILY_ENTITY:
      return {
        ...state,
        data: [],
      };

    case UsersPartitionConstants.SET_DAILY_FETCHING:
      return {
        ...state,
        fetching: true,
      };

    case UsersPartitionConstants.SET_DRAG_AND_DROP_ELEMENT:
      return {
        ...state,
        dragAndDropElement: payload,
      };
    case UsersPartitionConstants.CLEAR_DRAG_AND_DROP_ELEMENT:
      return {
        ...state,
        dragAndDropElement: {},
      };
    case UsersPartitionConstants.SET_DUPLICATE_ACTIVITY:
      return {
        ...state,
        dublicateActivities: payload,
      };
    case UsersPartitionConstants.CLEAR_DUPLICATE_ACTIVITY:
      return {
        ...state,
        dublicateActivities: [],
      };
    case UsersPartitionConstants.SYNC_DATA:
      return {
        ...state,
        syncData: payload,
      };
    case UsersPartitionConstants.CLEAR_SYNC_DATA:
      return {
        ...state,
        syncData: [],
      };

    default:
      return state;
  }
}
