import { mdiClose, mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Col,
  Flex,
  Modal,
  Row,
  Tooltip,
} from 'antd';
import {
  capitalize, difference, get, isEmpty, isEqual, isNil, keys, omit,
} from 'lodash';
import {
  array, func, object, string, arrayOf, objectOf, number, bool,
} from 'prop-types';
import React, {
  useEffect,
  useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import UploaderWrapperInView from '../../../../components/uploader/UploaderWrapperInView';
import HeaderSectionButtons from './components/HeaderSectionButtons';
import IssueStatuses from './components/IssueStatuses';
import SpecialTextArea from '../../commonComponents/SpecialTextArea/SpecialTextArea';
import TitleWithTags from './components/TitleWithTags';
import EditButton from '../../commonComponents/EditButton';
import UnSaveDataConfirmNew from '../../../../components/un-save-data-confirm/UnSaveDataConfirmNew';
import WrapperActivitiesDashboard from '../../commonComponents/activities/activitiesDashboard/WrapperActivitiesDashboard';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import AudioUploader from '../../../../components/uploader/uploaderForIssue/AudioUploader';
import LineWithTextButton from '../../commonComponents/LineWithTextButton';
import AntUploaderFilesForIssueInfo from '../../../../components/uploader/uploaderForIssue/AntUploaderFilesForIssueInfo/AntUploaderFilesForIssueInfo';
import { IssuePriorityRadioButtons, IssueTypeRadioButtons } from '../IssueRadioButtons';
import ListOfVectorsDropDownForIssueInfo from '../ListOfVectorsDropDownForIssueInfo';
import DividerForInfoCard from '../../../../components/DividerForInfoCard';
import IssueEstimatedTimeDropDown from '../IssueEstimatedTimeDropDown';

import { defaultStateBtn } from '../../issuesView/utils';
import { antNotification } from '../../../../MainUtils';
import { removeFile } from '../../../actions/issues-actions';
import useURLParams from '../../../../hooks/useURLParams';
// import { getProjectRequest } from '../../commonComponents/AssignedUsers/actions';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import { getUuid } from '../../../../assignUsersModal/utils';
import { getActorsW54 } from '../../../../actors/selectors/actorsW54Selector';
import useActorsW54 from '../../../../actors/hooks/useActorsW54';

const hideViewInUploadFile = ['dragDropAndPaste'];

const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export default function IssueInfoView({
  defaultData,
  uuid,
  activitiesTabs = [],
  defaultCommentsCount,
  result,
  defaultLinks,
  getTitle,
  title,
  vectorUuid,
  projectUuid,
  expirationDateDev,
  expirationDateQa,
  lifeTime,
  estimated_time: estimatedTime,
  descriptionComment,
  selectedIssue,
  tags,
  description,
  priority,
  tracker,
  completed,
  status,
  disabledView,
  hiddenView,
  partitionType,
  usersUuidsForAssigned,
  onChangeFieldCallback,
  resetIssueCallback,
  afterDeleteABookmark,
  restoreEntityCallback,
  deleteIssueCallback,
  usersSearch,
  users,
  projectUsers,
  selectedVectorData,
  getListUsersFromAssignedUsersCallback,
  creatorIssue,
  createIssueCallback,
  onChangeFilesCallback,
  clickActivitiesHandler,
  clickDashboardButtonHandler,
  disabledCloneIssue,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const refDescription = useRef({});
  const refResult = useRef({});

  const { getURLParams } = useURLParams();

  const { activeIssue } = getURLParams();

  const [stateBtnDescription, setStateBtnDescription] = useState(defaultStateBtn);
  const [stateBtnResult, setStateBtnResult] = useState(defaultStateBtn);
  const [stateBtnTitle, setStateBtnTitle] = useState(defaultStateBtn);
  const [audioList, setAudio] = useState([]);
  const [filesList, setFiles] = useState([]);
  const [showDescription, setShowDescription] = useState(true);

  const [isEditAllMode, setIsEditAllMode] = useState(false);
  const [allEditData, setAllEditData] = useState({});

  const [disabledSaveAll, setDisabledSaveAll] = useState(true);

  const [links, setLinks] = useState(defaultLinks);
  const [valueLinks, setValueLinks] = useState('');
  const [isEditAssignUsers, setEditAssignUsers] = useState(false);

  const selectedIssueUUID = selectedIssue?.uuid;

  const onEditAll = () => {
    setIsEditAllMode(true);
    setStateBtnDescription(defaultStateBtn);
    setStateBtnResult(defaultStateBtn);
    setStateBtnTitle(defaultStateBtn);
  };

  const onCloseAllEdit = () => {
    setLinks(defaultLinks);
    setValueLinks('');
    setIsEditAllMode(false);
  };

  const onSaveAllEdit = () => {
    const dataForUpdate = { ...allEditData };
    const list = [...links];
    if (valueLinks && isValidUrl(valueLinks?.trim())) {
      const newLink = { link: valueLinks, label: valueLinks };
      list.push(newLink);
    }
    if (!isEqual(list, defaultLinks)) {
      dataForUpdate.links = list;
    }
    setValueLinks('');
    onChangeFieldCallback(dataForUpdate);
    setIsEditAllMode(false);
  };

  const onChangeAllEditData = (changedData) => setAllEditData((prev) => ({
    ...prev,
    ...changedData,
  }));

  const setIsEdit = (e, closeEdit) => {
    const { id } = e.currentTarget;
    switch (id) {
      case 'editNeedToBeDoneButton':
        if (stateBtnDescription.isEdit && !closeEdit) {
          onChangeFieldCallback({ description: refDescription.current?.trim() });
        }
        refDescription.current = description;
        setStateBtnDescription((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      case 'editResultButton':
        if (stateBtnResult.isEdit && !closeEdit) {
          onChangeFieldCallback({ result: refResult.current?.trim() });
        }
        refResult.current = result;
        setStateBtnResult((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      default: break;
    }
  };

  const onChangeTextArea = ({ id, value }) => {
    // console.log('value value value', value);
    switch (id) {
      case 'description':
        refDescription.current = value;
        if (!stateBtnDescription?.isValid) {
          setStateBtnDescription((prev) => ({ ...prev, isValid: true }));
        }
        if (isEditAllMode) {
          onChangeAllEditData({ description: value });
        }
        break;
      case 'result':
        refResult.current = value;
        if (!stateBtnResult?.isValid) {
          setStateBtnResult((prev) => ({ ...prev, isValid: true }));
        }
        if (isEditAllMode) {
          onChangeAllEditData({ result: value });
        }
        break;
      default: break;
    }
  };

  const {
    disabledTypeIssue,
    disabledDropFile,
    disabledPriority,
    disabledEstimate,
    disabledAssignVector,
    disabledFull,
  } = useMemo(() => ({
    disabledTypeIssue: disabledView.includes('typeIssue'),
    disabledDropFile: disabledView.includes('dropFile'),
    disabledPriority: disabledView.includes('priorityIssue'),
    disabledEstimate: disabledView.includes('estimated_time'),
    disabledAssignVector: disabledView.includes('vector'),
    disabledFull: disabledView.includes('fullEdit'),
  }), [disabledView]);

  const deleteFile = async (id, file) => {
    const newBase64List = [...audioList];

    newBase64List.splice(id, 1);

    await dispatch(removeFile(activeIssue, file.uuid, partitionType));
    setAudio(newBase64List);
    antNotification('success', 'Success');
  };

  const showConfirmDeleteAudio = (id, file) => Modal.confirm({
    title: capitalize(
      t('wms.modals.headers.removing_process', 'removing process'),
    ),
    width: '600px',
    content: capitalize(t(
      'wms.modals.body.removing_process',
      'you\'re going to remove this file. Are you sure?',
    )),
    okText: capitalize(
      t('wms.buttons.removing_process.yes', 'yes, I am'),
    ),
    okType: 'danger',
    cancelText: capitalize(
      t('wms.buttons.removing_process.no', 'no, I\'m not'),
    ),
    open: false,
    centered: true,
    maskClosable: true,
    okButtonProps: { className: 'dangerButton' },

    onOk() {
      deleteFile(id, file);
    },
  });

  const onChangeUsers = (data) => {
    const newUsers = data?.users?.map((el) => el?.uuid)
      .filter(el => !usersSearch?.includes(el));

    onChangeFieldCallback?.({
      users: data?.users?.map((el) => ({ uuid: el?.uuid })) ?? [],
      usersSearch: data?.users?.map((el) => el?.uuid) ?? [],
      completed: completed.filter(uuid => !newUsers?.includes(uuid))
    });

    // dispatch(getProjectRequest(projectUuid, partitionType));
  };

  useEffect(() => {
    if (selectedIssueUUID) {
      if (stateBtnDescription.isEdit) {
        setStateBtnDescription(defaultStateBtn);
      }
      if (stateBtnResult.isEdit) {
        setStateBtnResult(defaultStateBtn);
      }
    }
  }, [selectedIssueUUID]);

  useEffect(() => {
    const disabledSaveAll = keys(allEditData)
      .every((key) => isEqual(allEditData?.[key], defaultData?.[key]))
      && defaultLinks.length === links.length
      && !isValidUrl(valueLinks?.trim());

    setDisabledSaveAll(disabledSaveAll);
  }, [
    JSON.stringify(allEditData),
    JSON.stringify(defaultData),
    valueLinks,
    JSON.stringify(links),
  ]);

  return (
    <UploaderWrapperInView
      disabled={disabledDropFile}
      onChangeFileCallback={onChangeFilesCallback}
      files={filesList}
    >
      <HeaderSectionButtons
        hiddenView={hiddenView}
        partitionType={partitionType}
        resetIssueCallback={resetIssueCallback}
        afterDeleteABookmark={afterDeleteABookmark}
        restoreEntityCallback={restoreEntityCallback}
        deleteIssueCallback={deleteIssueCallback}
        createIssueCallback={createIssueCallback}
        openSidePanel={clickActivitiesHandler}
        onSaveAllEdit={onSaveAllEdit}
        onEditAll={onEditAll}
        onCloseAllEdit={onCloseAllEdit}
        isEditAllMode={isEditAllMode}
        disabledSaveAll={disabledSaveAll}
        disabledCloneIssue={disabledCloneIssue}
      />

      <DividerForInfoCard />

      <TitleWithTags
        disabled={disabledFull}
        title={title}
        isEditAllMode={isEditAllMode}
        onChangeAllEditDataCallback={onChangeAllEditData}
        defaultSelectedTags={tags}
        entityUUID={selectedIssueUUID}
        onChangeFieldCallback={onChangeFieldCallback}
        stateBtnTitle={stateBtnTitle}
        setStateBtnTitle={setStateBtnTitle}
      />

      <DividerForInfoCard />

      <Flex className="px-1" vertical>
        <Flex className="wrapper-title">
          <span className="mr-2">{`${capitalize(t('wms.labels.need_done', 'what needs to be done'))}:`}</span>
          <UnSaveDataConfirmNew
            dataIsNotSave={stateBtnDescription.isValid && stateBtnDescription.isEdit}
          >
            <EditButton
              id="editNeedToBeDoneButton"
              isHidden={isEditAllMode}
              isEditFlag={stateBtnDescription.isEdit}
              disabled={
                (!stateBtnDescription.isValid && stateBtnDescription.isEdit) || disabledFull
              }
              onClick={setIsEdit}
            />
          </UnSaveDataConfirmNew>
          <AudioUploader
            partition={partitionType}
            issueUUID={selectedIssueUUID}
            disabled={disabledView}
            hideView={hideViewInUploadFile}
            onChangeFilesCallback={onChangeFilesCallback}
            audioList={audioList}
            setAudio={setAudio}
            onInitCallback={setShowDescription}
          />
        </Flex>
        <div className={cn('grow pl-2 wrapper-description w-full', { 'mt-1': stateBtnDescription.isEdit })}>
          {(!isNil(audioList) && !isEmpty(audioList)) && (
            <Row
              className="mt-2 w-full"
            >
              <Col span={24} className="flex flex-wrap">
                {audioList.map((item, i) => {
                  const dataAudio = get(item, 'type')
                    ? URL.createObjectURL(item)
                    : `data:${item.content_type};base64, ${item.file}`;
                  return (

                    <Col
                      span={11}
                      className="audio-box mr-2"
                      key={`${i + selectedIssueUUID}`}
                    >
                      <div className="audio-box__remove">
                        <Icon
                          path={mdiClose}
                          size={0.8}
                          onClick={() => showConfirmDeleteAudio(i, item)}
                        />

                      </div>
                      <div>
                        <div className="audio-box__label">{get(item, 'filename', 'audio')}</div>
                        <audio
                          className="audio-box__source-wrap"
                          controls
                          key={i}
                        >
                          <source
                            src={dataAudio}
                            type={item?.content_type}
                          />
                          <source
                            src={dataAudio}
                            type="audio/mpeg"
                          />
                          {t(
                            'wms.audio.no_support',
                            'Your browser does not support the audio element',
                          )}
                          .
                        </audio>
                      </div>
                    </Col>
                  );
                })}
              </Col>
            </Row>
          )}
          {showDescription || audioList.length === 0 ? (
            <SpecialTextArea
              id="description"
              className={cn('pt-2', { 'min-height-50': stateBtnDescription.isEdit, 'min-h-0': !stateBtnDescription.isEdit })}
              defaultValue={description}
              isEdit={stateBtnDescription.isEdit || isEditAllMode}
              onChange={onChangeTextArea}
            />
          ) : null}
          {audioList.length > 0
            ? (
              <LineWithTextButton
                defaultMessage="Show description"
                expandedMessage="Hide description"
                isExpanded={showDescription}
                onClickCallback={() => {
                  setShowDescription((prev) => !prev);
                }}
                wrapperStyle={{
                  marginTop: -16,
                  marginBottom: -16,
                }}
              />

            ) : null}
        </div>
      </Flex>

      <DividerForInfoCard />

      <Row justify="space-between" gutter={10} className="px-1 pt-1 pb-2">
        <Col>
          <Flex vertical>
            <span className="wrapper-title pb-0">
              {`${capitalize(t('wms.tracker.type', 'type'))}:`}
            </span>
            <IssueTypeRadioButtons
              defaultValue={tracker || 'empty'}
              onChange={(value) => onChangeFieldCallback({ tracker: value })}
              disabled={disabledTypeIssue || disabledFull}
            />
          </Flex>
        </Col>
        <Col>
          <Flex vertical>
            <span className="wrapper-title pb-0">
              {`${capitalize(t('wms.priority.title', 'priority'))}:`}
            </span>
            <IssuePriorityRadioButtons
              disabled={disabledPriority || disabledFull}
              defaultValue={priority || 'empty'}
              onChange={(value) => onChangeFieldCallback({ priority: value })}
            />
          </Flex>
        </Col>

        <Col className="flex items-stretch">
          <IssueEstimatedTimeDropDown
            disabledDropDown={disabledEstimate || disabledFull}
            currentSelect={estimatedTime}
            dropDownCallback={(value) => onChangeFieldCallback(
              { estimated_time: value.estimated_time },
            )}
          />
        </Col>

        <Col>
          <ListOfVectorsDropDownForIssueInfo
            disabled={disabledAssignVector || disabledFull}
            partitionType={partitionType}
            onChangeFieldCallback={onChangeFieldCallback}
            projectUsers={projectUsers}
            projectUuid={projectUuid}
            ticketUuid={selectedIssueUUID}
            vectorTitle={selectedVectorData?.params?.title}
            vectorUuid={vectorUuid}
          />
        </Col>
      </Row>

      <DividerForInfoCard />

      <IssueStatuses
        projectUuid={projectUuid}
        users={users}
        lifeTime={lifeTime}
        currentStatus={status}
        usersSearch={usersSearch}
        projectUsers={projectUsers}
        creatorIssue={creatorIssue}
        disabledView={disabledView}
        partitionType={partitionType}
        descriptionComment={descriptionComment}
        usersUuidsForAssigned={usersUuidsForAssigned}
        onChangeFieldCallback={onChangeFieldCallback}
        isAssignedVector={!isEmpty(selectedVectorData)}
        vectorData={selectedVectorData}
        getListUsersFromAssignedUsersCallback={getListUsersFromAssignedUsersCallback}
        expirationDateDev={expirationDateDev}
        expirationDateQa={expirationDateQa}
      />

      <DividerForInfoCard />

      <Flex className="mb-2 wrapper-title" align="center">
        <span
          className="mr-2"
          onClick={() => (!disabledFull && setEditAssignUsers(true))}
        >
          {`${capitalize(t('wms.labels.assigned_users', 'assigned users'))}:`}
        </span>
        <EditButton
          id='editAssignedUsersButton'
          disabled={disabledFull}
          onClick={() => setEditAssignUsers(true)}
        />

        <AssigningUsersRoot
          header="Assigning users"
          isOpen={isEditAssignUsers}
          setIsOpen={setEditAssignUsers}
          onUpdateUsers={onChangeUsers}
          projectUuid={projectUuid}
          partition={partitionType}
          needUpdateProjectUsers
          isModal
          globalOptions={{
            uuids: projectUsers?.map((el) => getUuid(el)),
            roles: projectUsers,
            defaultColumn: selectedVectorData?.params?.usersSearch?.length ? 'vector' : 'project',
          }}
          tabs={{
            allConfig: {
              label: 'All',
              value: 'all',
              textSelectButton: 'Add to project/issue',
              showSections: ['button-add-user'],
            },
            projectConfig: {
              label: 'Project',
              value: 'project',
              textSelectButton: 'Add to issue',
              defaultItems: projectUsers ?? [],
              showSections: ['string-role', 'button-add-user'],
            },
            vectorConfig: {
              label: 'Vector',
              value: 'vector',
              textSelectButton: 'Add to issue',
              defaultItems: selectedVectorData?.params?.usersSearch ?? [],
              showSections: ['string-role', 'button-add-user'],
            },
          }}
          usersConfig={{
            label: 'Issue users',
            showSections: ['string-role', 'button-remove-user'],
            textDeleteButton: 'Remove users',
            defaultItems: usersSearch ?? [],
          }}
        />
        <WrapperAvatarsGroupForProjectWithHook
          actors={usersSearch || []}
          avatarSize={28}
          popoverTrigger="click"
          popoverPlacement="right"
          checkProjectRole
        />
      </Flex>

      <DividerForInfoCard />

      <div className="mb-2 wrapper-title">
        <AntUploaderFilesForIssueInfo
          partition={partitionType}
          issueUUID={selectedIssueUUID}
          disabled={disabledView}
          hideView={hideViewInUploadFile}
          onChangeFilesCallback={onChangeFilesCallback}
          filesList={filesList}
          setFiles={setFiles}
        />
      </div>

      <DividerForInfoCard />

      <Flex vertical>
        <Flex className="wrapper-title pb-0" align="center">
          <span className="mr-2">{`${capitalize('result')}:`}</span>
          <UnSaveDataConfirmNew dataIsNotSave={stateBtnResult.isValid && stateBtnResult.isEdit}>
            <EditButton
              id="editResultButton"
              isHidden={isEditAllMode}
              isEditFlag={stateBtnResult.isEdit}
              disabled={(
                !stateBtnResult.isValid && stateBtnResult.isEdit) || disabledFull}
              onClick={setIsEdit}
            />
          </UnSaveDataConfirmNew>
        </Flex>
        <div className="wrapper-description pl-0 w-full">
          <SpecialTextArea
            id="result"
            className={cn({
              'min-height-50 mt-2': stateBtnResult.isEdit,
              'min-h-0': !stateBtnResult.isEdit,
            })}
            defaultValue={result}
            isEdit={stateBtnResult.isEdit || isEditAllMode}
            onChange={onChangeTextArea}
          />
        </div>
      </Flex>

      <DividerForInfoCard />

      <WrapperActivitiesDashboard
        defaultCommentsCount={defaultCommentsCount}
        entityType="issue"
        parentUUID={uuid}
        partition={partitionType}
        tabs={activitiesTabs}
        clickDashboardButton={clickDashboardButtonHandler}
      />

    </UploaderWrapperInView>
  );
}
IssueInfoView.propTypes = {
  defaultData: object,
  expirationDateDev: string,
  expirationDateQa: string,
  uuid: string,
  defaultCommentsCount: objectOf(number),
  activitiesTabs: arrayOf(object),
  result: string,
  partitionType: string,
  hiddenView: array,
  disabledView: array,
  resetIssueCallback: func,
  afterDeleteABookmark: func,
  restoreEntityCallback: func,
  vectorUuid: string,
  projectUuid: string,
  lifeTime: string,
  estimated_time: string,
  selectedIssue: object,
  title: string,
  description: string,
  onChangeFilesCallback: func,
  priority: string,
  tracker: string,
  status: string,
  usersUuidsForAssigned: array,
  onChangeFieldCallback: func,
  deleteIssueCallback: func,
  usersSearch: array,
  users: array,
  projectUsers: array,
  selectedVectorData: object,
  getListUsersFromAssignedUsersCallback: func,
  descriptionComment: array,
  creatorIssue: string,
  createIssueCallback: func,
  tags: array,
  completed: array,
  clickActivitiesHandler: func,
  clickDashboardButtonHandler: func,
  disabledCloneIssue: bool,
};
