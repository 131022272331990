import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { mdiContentCopy } from '@mdi/js';

import { capitalize } from 'lodash';
import DataGridActionButton from './DataGridActionButton';

function CloneStepComponent({ onClone, t }) {
  return (
    <DataGridActionButton
      toolTipText={capitalize(
        t('wms.tooltips.clone_step', 'clone step'),
      )}
      iconPath={mdiContentCopy}
      onClick={onClone}
      hideBorder="right"
    />
  );
}

CloneStepComponent.propTypes = {
  onClone: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(CloneStepComponent);
