import { partitionNamesConfig } from '../../../api/appConfig';
import {
  entityRead,
  entityCreate,
  entityUpdate,
  getListAndPartialReadEntities,
  getOrCreateSingle,
} from '../../../entity/actions/entityActions';
import { workConfigActions } from '../reducers/slices/workConfigSlice';

const getOrCreateMainWorkConfigActionCreator = ({
  constants,
  data,
  partition = partitionNamesConfig.partition3,
  doNotCheckError = true,
}) => {
  const consts = constants || [
    workConfigActions.GET_OR_CREATE_MAIN_WORK_CONFIG_REQUEST,
    workConfigActions.GET_OR_CREATE_MAIN_WORK_CONFIG_SUCCESS,
    workConfigActions.GET_OR_CREATE_MAIN_WORK_CONFIG_FAILURE,
  ];

  const config = {
    data: {
      entity_type: 'mainWorkConfig',
      ...data,

    },
    constants: consts,
    partition,
    doNotCheckError,
  };
  return (dispatch) => dispatch(getOrCreateSingle(config));
};

const getWorkConfigActionCreator = (data, customConstants) => {
  const config = {
    entity_type: 'workConfig',
    ...data,
  };

  const constants = customConstants || [
    workConfigActions.GET_WORK_CONFIG_REQUEST,
    workConfigActions.GET_WORK_CONFIG_SUCCESS,
    workConfigActions.GET_WORK_CONFIG_FAILURE,
  ];

  const options = {
    partition: partitionNamesConfig.partition3,
  };

  return entityRead({
    data: config,
    constants,
    options,
  });
};

const getWorkConfigListActionCreator = (data, constants) => {
  const config = {
    data: {
      entity_type: 'workConfig',
      ...data,
      params_fields: {
        title: 'title',
      },
      fields: {
        modified: 'modified',
        'entity.created': 'created',
      },
    },
    constants: constants || [
      workConfigActions.GET_WORK_CONFIG_LIST_REQUEST,
      workConfigActions.GET_WORK_CONFIG_LIST_SUCCESS,
      workConfigActions.GET_WORK_CONFIG_LIST_FAILURE,
    ],
    partition: partitionNamesConfig.partition3,
  };

  return getListAndPartialReadEntities(config);
};

const createWorkConfigActionCreator = (config) => {
  const data = {
    entity_type: 'workConfig',
    ...config,
  };

  const constants = [
    workConfigActions.CREATE_WORK_CONFIG_REQUEST,
    workConfigActions.CREATE_WORK_CONFIG_SUCCESS,
    workConfigActions.CREATE_WORK_CONFIG_FAILURE,
  ];

  const options = {
    partition: partitionNamesConfig.partition3,
  };

  return entityCreate({
    data,
    constants,
    options,
  });
};

const updateWorkConfigActionCreator = (config) => {
  const data = {
    entity_type: 'workConfig',
    ...config,
  };

  const constants = [
    workConfigActions.UPDATE_WORK_CONFIG_REQUEST,
    workConfigActions.UPDATE_WORK_CONFIG_SUCCESS,
    workConfigActions.UPDATE_WORK_CONFIG_FAILURE,
  ];

  const options = {
    partition: partitionNamesConfig.partition3,
  };

  return entityUpdate({
    data,
    constants,
    options,
  });
};

const clearWorkConfigActionCreator = () => (dispatch) => dispatch({
  type: workConfigActions.CLEAR_WORK_CONFIG.type,
});

export {
  getOrCreateMainWorkConfigActionCreator,
  createWorkConfigActionCreator,
  updateWorkConfigActionCreator,
  getWorkConfigActionCreator,
  getWorkConfigListActionCreator,

  clearWorkConfigActionCreator,
};
