import React, {
  useCallback, useContext, useEffect, useMemo,
  useState,
} from 'react';
import Icon from '@mdi/react';
import {
  mdiArrowDown,
  mdiArrowUp,
} from '@mdi/js';

import './style/ChatGpt.scss';
import './style/ChatGptMedia.scss';

import ChatGpt from './ChatGpt';
import ExpandModeLayout from '../projectFlow/components/commonComponents/expandModeLayout/ExpandModeLayout';
import BaseButton from '../components/_ui/BaseButton/BaseButton';

import { appLabel, getSessionTokenFor } from '../api/appConfig';
import useGptRequestsHook from './hooks/useGptRequestsHook';
import useFetchReducerHook from '../hooks/useFetchReducerHook';
import GetGPTSessionTokenCard from './GetGPTSessionTokenCard';
import { GPTContext } from './GptContext';
import { SocketContext } from '../socket/SocketProvider';

function ChatGptBtn() {
  const { subscribeOnGptService, gptSocket } = useContext(SocketContext);

  const {
    requestGetUserOnGPT,
    requestCreateUserOnGPT,
  } = useGptRequestsHook();

  const {
    setLoading, setData, setError, data: userInfoOnGpt, loading: userFetching, error, setClearData,
  } = useFetchReducerHook();

  const [showChat, setShowChat] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [userCreatedOnGpt, setUserCreatedOnGpt] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);

  const changeChatMessages = (mes) => {
    setChatMessages(mes);
  };

  const addNewMessage = (messageData) => {
    setChatMessages((prev) => [...prev, messageData]);
  };

  const toggle = useCallback(() => {
    if (showChat) {
      setClearData();
    }

    setShowChat(!showChat);
  }, [showChat]);

  const getUserOnGpt = () => {
    setLoading();
    requestGetUserOnGPT()
      .then(setData)
      .catch((err) => {
        if (err?.status === 404 && !userCreatedOnGpt) {
          createUserOnGPT();
        } else {
          setError(err);
        }
      });
  };

  const createUserOnGPT = () => {
    requestCreateUserOnGPT().then((data) => {
      // console.log('new user on gpt', data);
      setUserCreatedOnGpt(true);
      getUserOnGpt();
    }).catch(setError);
  };

  const getSelectedChatCallback = (data) => {
    // console.log('data data', data);
    setSelectedChat(data);
  };

  const closeGptSubModule = () => {
    getUserOnGpt();
  };

  useEffect(() => {
    if (showChat && !userInfoOnGpt) {
      getUserOnGpt();
    }

    if (showChat && !gptSocket?.connected) {
      subscribeOnGptService();
    }
  }, [showChat]);

  const contextValue = useMemo(() => ({
    addNewMessage,
    changeChatMessages,
    chatMessages,
  }), [chatMessages.length]);

  return (
    <GPTContext.Provider value={contextValue}>
      <div
        className="chat-gpt"
      >
        {showChat && userInfoOnGpt ? (
          <ExpandModeLayout localStorageTarget="wmsHelper">
            <ChatGpt
              showChat={showChat}
              toggleChat={toggle}
              userInfo={userInfoOnGpt}
              getSelectedChatCallback={getSelectedChatCallback}
              defaultSelectedChat={selectedChat}
            />
          </ExpandModeLayout>
        ) : null}

        {showChat && (error || !getSessionTokenFor.proxyGPT())
        && <GetGPTSessionTokenCard successCallback={closeGptSubModule} />}

        <BaseButton
          id='gptToggleButton'
          loading={userFetching}
          className={(showChat || !getSessionTokenFor.proxyGPT()) ? 'btnWarning' : 'btnPrimary'}
          shape="round"
          onClick={toggle}
          style={{
            marginBottom: '10px',
            marginTop: '10px',
            marginLeft: 'auto',
          }}
        >
          <Icon
            path={showChat ? mdiArrowDown : mdiArrowUp}
            size={0.8}
            className="mr-1"
          />
          {appLabel}
          {' '}
          Helper
          {' '}
          {!getSessionTokenFor.proxyGPT() && '(Not authorized)'}
        </BaseButton>
      </div>
    </GPTContext.Provider>
  );
}

export default ChatGptBtn;
