import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import {
  mdiSpeedometer,
  mdiCalendarCheckOutline,
  mdiAccountGroupOutline,
  mdiFormatListCheckbox,
  mdiChartLine,
  mdiCodeJson,
  mdiMonitorDashboard,
  mdiBookmarkMultipleOutline,
  mdiAccountDetails,
  mdiAccountOutline,
  mdiAccountMultipleOutline,
  mdiCellphoneMessage,
  mdiNewBox,
  mdiHumanMaleBoardPoll,
} from '@mdi/js';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';

import { useAppMode, AppModeSwitcher } from '../../context/AppModeProvider';

import { isAdminOrRoot } from '../../entity/selectors/selectors';
import {
  getUserPublicFetching,
  getUserPublicProfileUUID,
} from '../../userFlow/store/selectors/selectors';
import BaseTag from "../_ui/BaseTag/BaseTag";
import BaseIcon from "../_ui/BaseIcon/BaseIcon";
import BaseMenu from "../_ui/BaseMenu/BaseMenu";

function SidebarMenu({
  collapsed,
}) {
  const { t } = useTranslation();
  const userIsAdmin = useSelector(isAdminOrRoot);
  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const myPublicUUIDFetching = useSelector(getUserPublicFetching);

  const [selectedKey, setSelectedKey] = useState([]);

  const location = useLocation();
  const { debug, changeDebug } = useAppMode();

  const checkActiveLink = (linkRoute) => (location.pathname.includes(linkRoute) ? 'ant-menu-item-selected' : '');

  const menuItems = [
    {
      label: t('wms.sidemenu.userflow.header.full', 'user flow'),
      key: 'user_flow',
      type: 'group',
    },
    debug
      ? {
          label: <>
                  <Link id="debugLink" to={myPublicUUIDFetching ? '/public/user' : `/public/user/${myPublicUUID}/dashboard`}
                        className='base-menu-title-text'>
                    {myPublicUUIDFetching ? 'Loading user info...' : t('wms.sidemenu.userflow.dashboard', 'Dashboard')}
                  </Link>
                  <BaseTag className="m-0 base-menu-tag" color="gold" bordered={false}>alpha</BaseTag>
                </>,
          key: myPublicUUIDFetching ? '/public/user' : `/public/user/${myPublicUUID}/dashboard`,
          icon: <BaseIcon path={mdiSpeedometer} size={1} />,
          className: `${checkActiveLink(myPublicUUIDFetching ? '/public/user' : `/public/user/${myPublicUUID}/dashboard`)}`,
      }
      : null,
    {
      label: <Link id="personalProjectsLink" to={myPublicUUIDFetching ? '/public/user/' : `/public/user/${myPublicUUID}/projects`} className='base-menu-title-text'>
              {myPublicUUIDFetching ? 'Loading user info...' : t('wms.sidemenu.userflow.personal_projects', 'Personal projects')}
            </Link>,
      key: myPublicUUIDFetching ? '/public/user/' : `/public/user/${myPublicUUID}/projects`,
      icon: <BaseIcon path={mdiAccountDetails} size={1} />,
      className: `${checkActiveLink(myPublicUUIDFetching ? '/public/user/' : `/public/user/${myPublicUUID}/projects`)}`
    },
    {
      label: <Link id="planningLink" to='/public/user/planning' className='base-menu-title-text' className='base-menu-title-text'>
                {t('wms.sidemenu.userflow.week_planning')}
              </Link>,
      key: '/public/user/planning',
      icon: <BaseIcon path={mdiCalendarCheckOutline} size={1} />,
      className: `${checkActiveLink('/public/user/planning')}`
    },
    {
      label: <Link id="bookmarkLink" to='/user/bookmarks' className='base-menu-title-text'>
              {t('wms.sidemenu.userflow.bookmarks', 'Bookmarks')}
            </Link>,
      key: '/user/bookmarks',
      icon: <BaseIcon path={mdiBookmarkMultipleOutline} size={1} />,
      className: `${checkActiveLink('/user/bookmarks')}`
    },
    {
      label: t('wms.sidemenu.projectflow.header.full', 'project flow'),
      ley: 'project_flow',
      type: 'group'
    },
    {
      label: <Link id="projectLink" to='/pm/projects' className='base-menu-title-text'>
              {t('wms.sidemenu.projectflow.projects', 'Projects')}
            </Link>,
      key: '/pm/projects',
      icon: <BaseIcon path={mdiFormatListCheckbox} size={1} />,
      className: `${checkActiveLink('/pm/projects')}`
    },
    {
      label: <Link id="auditLink" to='/pm/audit' className='base-menu-title-text'>
              {t('wms.sidemenu.projectflow.audit', 'Audit')}
            </Link>,
      key: '/pm/audit',
      icon: <BaseIcon path={mdiChartLine} size={1} />,
      className: `${checkActiveLink('/pm/audit')}`
    },
    {
      label: <Link id="cookbookLink" to='/pm/cookbook' className='base-menu-title-text'>
              {capitalize(t('wms.sidemenu.projectflow.cookbook', 'cookbooks'))}
            </Link>,
      key: '/pm/cookbook',
      icon: <BaseIcon path={mdiCodeJson} size={1} />,
      className: `${checkActiveLink('/pm/cookbook')}`
    },
    {
      label: capitalize(t('wms.sidemenu.tools.header', 'tools')),
      key: 'tools',
      type: 'group'
    },
    {
      label: <Link id="appInstructionLink" to='/tools/pwaAppInstruction' className='base-menu-title-text'>
              {t('wms.sidemenu.tools.pwa_app')}
            </Link>,
      key: '/tools/pwaAppInstruction',
      icon: <BaseIcon path={mdiCellphoneMessage} size={1} />,
      className: `${checkActiveLink('/tools/pwaAppInstruction')}`,
    },
    {
      label: <Link id="userReportLink" to='/tools/userReport' className='base-menu-title-text'>
              {t('wms.sidemenu.tools.user_report')}
            </Link>,
      key: '/tools/userReport',
      icon: <BaseIcon path={mdiAccountOutline} size={1} />,
      className: `${checkActiveLink('/tools/userReport')}`
    },
    // {
    //   label: <Link to='/tools/usersReports' className='base-menu-title-text'>
    //           {t('wms.sidemenu.tools.users_reports')}
    //         </Link>,
    //   key: '/tools/usersReports',
    //   icon: <BaseIcon path={mdiAccountMultipleOutline} size={1} />,
    //   className: `${checkActiveLink('/tools/usersReports')}`
    // },
    {
     label: <Link id="projectReportLink" to='/tools/projectReports' className='base-menu-title-text'>
              {t('wms.project_reports.header', 'header')}
            </Link>,
      key: '/tools/projectReports',
      icon: <BaseIcon path={mdiFormatListCheckbox} size={1} />,
      className: `${checkActiveLink('/tools/projectReports')}`
    },
    {
      label: <Link id="whatsnewLink" to='/tools/whatsnew' className='base-menu-title-text'>
              {t('whatsnew', 'What\'s new')}
            </Link>,
      key: '/tools/whatsnew',
      icon: <BaseIcon path={mdiNewBox} size={1} />,
      className: `${checkActiveLink('/tools/whatsnew')}`
    },
    userIsAdmin
      ? {
          label: capitalize(t('wms.sidemenu.admin.admin', 'admin only')),
          key: 'admin_only',
          type: 'group'
        }
      : null,
    userIsAdmin
      ? {
          label: <Link id="dashboardLink" to='/admin/dashboard' className='base-menu-title-text'>
                  {t('wms.sidemenu.admin.dashboard', 'Admin dashboard')}
                </Link>,
          key: '/admin/dashboard',
          icon: <BaseIcon path={mdiMonitorDashboard} size={1} />,
          className: `${checkActiveLink('/admin/dashboard')}`
        }
      : null,
  ];

  const onClick = (e) => {
      setSelectedKey([e.key]);
  };

  useEffect(() => {
    setSelectedKey([location.pathname]);
  }, [location]);

  const betaData = [
    {
      name: capitalize(
        t(`wms.sidemenu.teamflow.header.${collapsed ? 'short' : 'full'}`, collapsed ? 'team' : 'team flow'),
      ),
      submenu: [
        {
          linkText: t('wms.sidemenu.teamflow.members', 'Members'),
          icon: mdiAccountGroupOutline,
          route: '/admin/dashboard/public/user',
          version: 'prod',
          onlyAdmin: true,
        },
        // {
        //   linkText: t('wms.sidemenu.teamflow.boards', 'Team boards'),
        //   icon: <BaseIcon path={mdiAccountGroupOutline} size={0.9} />,
        //   route: '/pm/team/teamboards',
        //   version: 'alpha',
        // },
      ],
      checkAdmin: true,
    },
  ];

  return (
    <BaseMenu
      inlineIndent={30}
      onClick={onClick}
      theme="light"
      mode="inline"
      selectedKeys={selectedKey}
      menuItems={menuItems}
    />
  );
}

SidebarMenu.propTypes = {
  collapse: PropTypes.bool,
};

export default SidebarMenu;
