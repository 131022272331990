import { head } from 'lodash';
import { bool, func } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearDocument } from '../../../../../projectFlow/actions/projectFlowActions';

import DocViewWrapper from '../../../../../projectFlow/containers/docView&ideas/DocViewWrapper';

import { getProjDoc } from '../../../../../projectFlow/selectors/selectors';
import CustomCardComponent from '../../../../../projectFlow/components/commonComponents/CustomCardComponent';
import { partitionNamesConfig } from '../../../../../api/appConfig';

function DashboardDocOrIdeaView({
  showEditor,
  setShowEditor,
}) {
  const dispatch = useDispatch();

  const doc = useSelector(getProjDoc);

  const docHead = head(doc);

  useEffect(() => () => {
    dispatch(clearDocument());
  }, []);
  return (
    <CustomCardComponent
      id="document"
      type="document"
      params={{
        closeBtns: true,
        isDisabled: true,
      }}
    >
      <DocViewWrapper
        currentSelected={docHead}
        defaultPartition={partitionNamesConfig.partition1}
        showEditor={showEditor}
        setShowEditor={setShowEditor}
      />
    </CustomCardComponent>
  );
}

DashboardDocOrIdeaView.propTypes = {
  showEditor: bool,
  setShowEditor: func,
};

export default DashboardDocOrIdeaView;
