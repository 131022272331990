import { Col, Row } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DetailedReportInfoActivitiesTable from './DetailedReportInfoActivitiesTable';
import DetailedReportInfoIssuesTable from './DetailedReportInfoIssuesTable';

function IssuesAndActivitiesTables({
  currentDayData = {},
  customClass,
}) {
  const { t } = useTranslation();

  const {
    activityDailyReport,
    dailyReportIssues,
  } = currentDayData;

  return (
    <Row className={customClass}>
      <Col span={24}>
        <h5>
          Detailed report activities & issues:
        </h5>
      </Col>
      <Col span={24} className="flex justify-center items-center">
        <Col span={12} className="pr-2">
          <Col className="reportTimeBlockText reportTimeBlock flex justify-start w-full">
            {`${t('wms.reports.table.report_activities', 'report_activities')}:`}
          </Col>
        </Col>
        <Col span={12} className="pl-2">
          <Col className="reportTimeBlockText reportTimeBlock flex justify-start w-full">
            {`${t('wms.reports.table.report_issues', 'report_issues')}:`}
          </Col>
        </Col>
      </Col>
      <Col span={24} className="flex justify-center items-start">
        <Col span={12} className="pr-2">
          <DetailedReportInfoActivitiesTable
            activities={activityDailyReport}
          />
        </Col>
        <Col span={12} className="pl-2">
          <DetailedReportInfoIssuesTable
            issues={dailyReportIssues}
          />
        </Col>
      </Col>
    </Row>
  );
}

IssuesAndActivitiesTables.propTypes = {
  customClass: PropTypes.string,
  currentDayData: PropTypes.object,
};

export default IssuesAndActivitiesTables;
