import PropTypes, { array } from 'prop-types';
import Icon from '@mdi/react';
import { Col } from 'antd';
import React, { memo } from 'react';
import {
  mdiApplicationCogOutline,
  mdiBugOutline,
  mdiCodeJson,
  mdiHistory,
  mdiLan,
  mdiNotebookOutline,
  mdiSproutOutline,
  mdiFileDocumentOutline,
  mdiCalendarAccount,
} from '@mdi/js';
import { useParams } from 'react-router-dom';

import './css/ProjectSystemTabs.scss';

import SystemTabs from './SystemTabs';

const icon = (path) => <Icon path={path} size={0.7} />;

function ProjectSystemTabs({
  // switchViewFunc,
  hide = [],
  getPathForLink,
  onClickCallback,
}) {
  const { activeTab } = useParams();

  const tabs = [
    {
      icon: icon(mdiApplicationCogOutline),
      name: 'wms.noun.project.0',
      showType: true,
      type: 'tab-general',
      view: 'project',
    },
    {
      icon: icon(mdiCalendarAccount),
      // name: 'wms.tabs.views.myboard',
      name: 'wms.tabs.views.plans',
      showType: false,
      type: 'tab-general',
      view: 'myboard',
    },
    {
      icon: icon(mdiLan),
      name: 'workblocks',
      showType: false,
      type: 'tab-general',
      view: 'workblocks',
    },
    {
      icon: icon(mdiLan),
      name: 'wms.noun.issues',
      showType: false,
      type: 'tab-general',
      view: 'issuesnew',
    },
    // {
    //   icon: icon(mdiHistory),
    //   name: 'wms.tabs.views.releases',
    //   showType: false,
    //   type: 'tab-general',
    //   view: 'releases',
    //   isHide: hide.includes('releases'),
    // },
    {
      // name: 'wms.tabs.views.planning',
      name: 'wms.tabs.views.scrum',
      icon: icon(mdiNotebookOutline),
      showType: false,
      type: 'tab-general',
      view: 'planning',
    },
    {
      name: 'wms.tabs.views.documents',
      icon: icon(mdiFileDocumentOutline),
      showType: false,
      type: 'tab-general',
      view: 'docs',
    },
    {
      icon: icon(mdiBugOutline),
      name: 'wms.tabs.views.qa',
      showType: true,
      type: 'tab-functional',
      view: 'qa',
    },
    {
      icon: icon(mdiCodeJson),
      name: 'wms.tabs.views.cookbook',
      showType: false,
      type: 'tab-functional',
      view: 'cookbook',
    },
  ];
  return (
    <Col flex="auto">
      <SystemTabs
        viewMode={activeTab}
        getPathForLink={getPathForLink}
        onClickCallback={onClickCallback}
        // switchViewFunc={switchViewFunc}
        tabs={tabs}
      />
    </Col>
  );
}

export default memo(ProjectSystemTabs);

ProjectSystemTabs.propTypes = {
  // switchViewFunc: PropTypes.func,
  hide: array,
  getPathForLink: PropTypes.func,
  onClickCallback: PropTypes.func
};
