import { mdiAlertCircleOutline, mdiCheckCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Alert, Badge, Col, Row, Space,
} from 'antd';
import { groupBy } from 'lodash';
import { object } from 'prop-types';
import { propOr } from 'ramda';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { Socket } from '../../../../socket';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import { getNotifyData } from '../../../../notifications/utilesNotifications';
import { markAsViewedAllNotifications } from '../../../../projectFlow/actions/PtcActionsForHook';
import { getMe, getUnseenNotifications } from '../../../../projectFlow/selectors/selectors';
import { timeToCalendar } from '../../../../MainUtils';
import { capitalize } from 'lodash';
import { getOtherUserPublicProfileActor, getUserPublicProfileUUID } from '../../../store/selectors/selectors';
import { SocketContext } from '../../../../socket/SocketProvider';

export default function SectionNotification({
  otherUser,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { sendNotifyEmit } = useContext(SocketContext);

  const myPublicEntityUUID = useSelector(getUserPublicProfileUUID);
  const unseen = useSelector(getUnseenNotifications);
  const getMeUUID = useSelector(getMe);
  const publicUserEntityActor = useSelector(getOtherUserPublicProfileActor);

  const actorUUID = otherUser ? publicUserEntityActor : getMeUUID;

  const [type, setState] = useState('success');

  const toggleMarkAsViewedAll = () => {
    dispatch(markAsViewedAllNotifications);
    sendNotifyEmit(
      'all_status',
      actorUUID,
    );
  };

  const viewCounter = (length) => <Badge count={length} showZero style={type === 'warning' ? { backgroundColor: '#ffa940' } : { backgroundColor: '#ff7875' }} />;

  const listCounter = (
    <div className="flex flex-col ml-2">
      {Object.entries((groupBy(unseen.map((i) => propOr('', 'event_name', i))))).map((p) => (
        <div className="mb-2 flex items-center text-secondary" key={p[0]}>
          <Icon path={getNotifyData(p[0])?.path} size={0.8} color="#1890ff" className="mr-2" />

          {capitalize(t(getNotifyData(p[0])?.title))}

          <span className="inline-flex ml-2 color-red">
            {' '}
            {viewCounter(p[1].length)}
          </span>
        </div>
      ))}
    </div>
  );

  const description = {
    success: 'You have no unread notifications',
    warning: (
      <div>
        <div className="inline-flex mb-1">
          You have
          <div className="ml-1 mr-1 color-red">
            <Badge count={unseen.length} showZero />
          </div>
          unread notifications
        </div>
        {listCounter}
      </div>),
    error: (
      <div>
        <div className="inline-flex mb-1">
          You have
          <div className="ml-1 mr-1 color-red">
            <Badge count={unseen.length} showZero />
          </div>
          unread notifications. We advise you to pay attention
        </div>
        {listCounter}
      </div>),
  };

  const icon = {
    success: <Icon size={1.5} path={mdiCheckCircleOutline} />,
    warning: <Icon size={1.5} path={mdiAlertCircleOutline} />,
    error: <Icon size={1.5} path={mdiAlertCircleOutline} />,
  };

  const initFunc = () => {
    if (unseen.length === 0) {
      return setState('success');
    }
    if (unseen.length > 10) {
      return setState('error');
    }

    setState('warning');
  };
  useEffect(() => {
    initFunc();
  }, [unseen.length]);

  return (
    <Alert
      message={capitalize(t('wms.labels.notifications', 'notifications'))}
      description={description[type]}
      type={type}
      showIcon
      icon={icon[type]}
      action={(
        <Space direction="vertical">
          <BaseButton
            size="small"
            className="btnPrimary-outline"
            disabled={!unseen.length}
            onClick={toggleMarkAsViewedAll}
          >
            {capitalize(t('notifications.buttons.mark_all_read', 'mark all as read'))}
          </BaseButton>

          <Link to={`/public/user/${myPublicEntityUUID}/dashboard/notifications`}>
            <BaseButton
              size="small"
              className="btnPrimary-outline"
            >
              Go to notifications
            </BaseButton>
          </Link>
        </Space>
      )}
    />
  );
}
