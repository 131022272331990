import { getMD5HashFromString } from "../../MainUtils";
import {
    entityUpdate,
    getListAndReadEntities,
    getOrCreateSingle
} from "../../entity/actions/entityActions";
import {
    GET_OR_CREATE_USER_BOARD_REQUEST,
    GET_OR_CREATE_USER_BOARD_SUCCESS,
    GET_OR_CREATE_USER_BOARD_FAILURE,
    UPDATE_USER_BOARD_REQUEST,
    UPDATE_USER_BOARD_SUCCESS,
    UPDATE_USER_BOARD_FAILURE,
} from "../slices/userBoardSlice";
import {
    GET_USER_BOARDS_FAILURE,
    GET_USER_BOARDS_REQUEST,
    GET_USER_BOARDS_SUCCESS
} from "../slices/userBoardsSlice";

export const getOrCreateUserBoard = ({ parent, partition, actor }) => (dispatch) => dispatch(getOrCreateSingle({
    data: {
        entity_type: 'userBoard',
        parent: parent,
        actor: actor,
        key: getMD5HashFromString(`${parent}${actor}`),
    },
    partition: partition,
    constants: [
        GET_OR_CREATE_USER_BOARD_REQUEST,
        GET_OR_CREATE_USER_BOARD_SUCCESS,
        GET_OR_CREATE_USER_BOARD_FAILURE,
    ],
}));

export const updateUserBoard = (data, options) => (dispatch) => dispatch(entityUpdate({
    data,
    options,
    constants: [
        UPDATE_USER_BOARD_REQUEST,
        UPDATE_USER_BOARD_SUCCESS,
        UPDATE_USER_BOARD_FAILURE,
    ],
}));

export const getListOfUserBoards = ({ data, partition, constants }) => {
    return getListAndReadEntities({
        data,
        partition,
        constants: constants || [
            GET_USER_BOARDS_REQUEST,
            GET_USER_BOARDS_SUCCESS,
            GET_USER_BOARDS_FAILURE,
        ]
    })
} 