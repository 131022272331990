/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  userData: [],
  myData: [],
  userError: '',
  myError: '',
};

const mainPublicUserLogsSlice = createSlice({
  name: 'mainPublicUserLogs',
  initialState,
  reducers: {
    GET_MAIN_USER_LOGS_ENTITY_REQUEST: (state) => {
      state.isLoading = true;
    },
    GET_MAIN_USER_LOGS_ENTITY_SUCCESS: (state, action) => {
      state.isLoading = false;
      state.userData = action.payload;
      state.userError = '';
    },
    GET_MAIN_MY_LOGS_ENTITY_SUCCESS: (state, action) => {
      state.isLoading = false;
      state.myData = action.payload;
      state.myError = '';
    },
    GET_MAIN_USER_LOGS_ENTITY_FAILURE: (state, action) => {
      state.isLoading = false;
      state.userData = [];
      state.userError = action.payload;
    },
    GET_MAIN_MY_LOGS_ENTITY_FAILURE: (state, action) => {
      state.isLoading = false;
      state.myData = '';
      state.myError = action.payload;
    },

    CLEAR_MAIN_USER_LOGS_ENTITY_FAILURE: (state) => {
      state.isLoading = false;
      state.userData = [];
      state.userError = '';
    },
  },
});

export const mainPublicUserLogsActions = mainPublicUserLogsSlice.actions;

export default mainPublicUserLogsSlice.reducer;
