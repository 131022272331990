import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card, Col, Modal, Row, Space, Spin, Tooltip, Typography,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {cloneDeep, uniq, xor} from 'lodash';
import Icon from '@mdi/react';
import {
  mdiCheckboxMarkedCircleOutline, mdiChevronUp, mdiCircleOutline, mdiInformationOutline,
} from '@mdi/js';
import { defaultProjectTypesOfTesting, descriptionTypeOFTesting } from '../QaUtils';
import { getListOfEntities } from '../../../../entity/actions/entityActions';
import { getProjectParams, getProjectUUID } from '../../../selectors/selectors';
import { getTestCase } from '../../../actions/QaActions';
import BugTrackerTemplate from '../BugTrackerTemplate';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import CommonInfo from '../../commonComponents/CommonInfo';
import AntDesignSearchBox54origins from '../../../../54origins/components/AntDesignSearchBox54origins';
import { updateProjectRequest } from '../../../actions/projectFlowActions';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import { getAllTestSuites } from '../../../selectors/selectors';
import { getListAndPartialReadEntities } from '../../../../entity/actions/entityActions';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';

const {
  Paragraph, Text, Link,
} = Typography;

export default function GeneralOverview({
  partition,
  switchViewFunc,
  changeSelectedTypeOfTesting,
  getListOfTestSuitesFunc,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const splitPath = location.pathname.split('/');
  const userUUIDFromUrl = splitPath[splitPath.indexOf('user') + 1];

  const projectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);

  const {
    typeOfTesting = defaultProjectTypesOfTesting,
  } = projectParams;

  const [selectedCards, setSelectedCards] = useState([]);
  const [unselectedCards, setUnselectedCards] = useState([]);
  const [loading, changeLoading] = useState(true);
  const [showAllCards, setShowAllCards] = useState(false);
  const [suitesByTypesData, setSuitesByTypesData] = useState({})
  const [totalSuitesNumber, setTotalSuitesNumber] = useState(0)

  const { changeIsCreatorCycleByType } = useContext(BugTrackerContext);

  const createLink = (entityUUID, parentUUID) => {
    if (partition === 'PM') {
      return `s/pm/projects/qa?activeProject=${projectUUID}&activeTab=suites&activeSuite=${parentUUID}&activeCaseSuite=${entityUUID}`;
    }
    return `pb/u=${userUUIDFromUrl}/ca[su]=${entityUUID}`;
  };

  const getListOfSuitesByTestingType = () => {
    let suitesByTypesObjData = {}

    descriptionTypeOFTesting.map(type => {
      suitesByTypesObjData[type.value] = []
    })

    const list = getListOfTestSuitesFunc({parent: projectUUID})
    list.then(({data}) => {      
      if(!data?.length) changeLoading(false) 

      setTotalSuitesNumber(data?.length)

      data.map(item => {
        suitesByTypesObjData['all'].push(item)
        if(item.typeOfTesting?.length){
          item.typeOfTesting.map(type => {
            suitesByTypesObjData[type].push(item)
          })
        }
      })

    })

    setSuitesByTypesData(suitesByTypesObjData)
  }

  // const getListOfTestCasesByType = async (typeItem) => {
  //   const constantsList = [
  //     'GET_LIST_OF_TEST_CASES_BY_TYPE_REQUEST',
  //     'GET_LIST_OF_TEST_CASES_BY_TYPE_SUCCESS',
  //     'GET_LIST_OF_TEST_CASES_BY_TYPE_FAILURE',
  //   ];
  //   const options = {
  //     partition,
  //   };
  //   const data = {
  //     parent: projectUUID,
  //     entity_type: 'testCase',
  //     depth: 0,
  //     order_by: 'modified',
  //     order: 'desc',
  //     params: {
  //       template: true,
  //     },
  //   };

  //   const totalUUIDsInSuites = await dispatch(getListOfEntities({
  //     constants: constantsList, options, data,
  //   }));

  //   console.log("totalUUIDsInSuites", totalUUIDsInSuites);

  //   data.params.typeOfTesting = [typeItem?.value];

  //   const typeOfTestingUUIDsInSuites = await dispatch(getListOfEntities({
  //     constants: constantsList, options, data,
  //   }));

  //   console.log("typeOfTestingUUIDsInSuites", typeOfTestingUUIDsInSuites);

  //   const constantsCase = [
  //     'GET_TEST_CASE_BY_TYPE_REQUEST',
  //     'GET_TEST_CASE_BY_TYPE_SUCCESS',
  //     'GET_TEST_CASE_BY_TYPE_FAILURE',
  //   ];

  //   let testCaseInfoInSuites = [];

  //   if (typeOfTestingUUIDsInSuites?.entity_uuids.length) {
  //     testCaseInfoInSuites = await dispatch(getTestCase({
  //       constants: constantsCase, uuid: typeOfTestingUUIDsInSuites?.entity_uuids[0], partition,
  //     }));
  //   }

  //   return {
  //     lastTest: testCaseInfoInSuites[0] || null,
  //     total: totalUUIDsInSuites?.total,
  //     totalByTypeOFTesting: typeOfTestingUUIDsInSuites?.total,
  //     type: typeItem?.value,
  //   };
  // };

  const onAllTestSuitesAndCyclesByType = (type) => {
    switchViewFunc('suites');
    type !== 'all' ? changeSelectedTypeOfTesting([type]) : changeSelectedTypeOfTesting([])
  };

  const onAllTestCasesByType = (type) => {
    switchViewFunc('cycles');
    // changeIsCreatorCycleByType(type);
  };

  const searchFunc = (searchData) => {
    if (searchData) {
      setSelectedCards(descriptionTypeOFTesting
        .filter((item) => item.label.toLowerCase().indexOf(searchData.toLowerCase()) !== -1));
    } else {
      setSelectedCards(descriptionTypeOFTesting);
    }
  };

  const onCheckMark = (newTypesOfTesting) => {
    dispatch(updateProjectRequest({
      uuid: projectUUID,
      params: {
        typeOfTesting: newTypesOfTesting.map((type) => type?.value),
      },
      partition,
    }));
  };

  const renderTabs = () => {
    return(
      <>
        {[...selectedCards, ...unselectedCards].map((el) => {
          // console.log(el?.isSelected, el?.value, el);
          if (!showAllCards && !el?.isSelected) return null;

          if(el?.value !== 'all'){

          return (
            <Col span={12} key={el?.value}>
              <Card
                title={(
                  <Row>
                    <span style={{
                      color: '#038fde',
                      fontSize: '28px',
                      marginRight: 6,
                    }}
                    >
                      {el?.label}
                    </span>
                    <CommonInfo
                      placement="bottomLeft"
                      info={el?.description}
                    />
                    {el?.isSelected ? (
                      <Tooltip
                        title="Удалить из избранных"
                        placement="bottomLeft"
                        color="#32cd32"
                        // color="blue"
                        onClick={() => onCheckMark(selectedCards.filter((item) => item?.value !== el?.value))}
                      >
                        <BaseButton
                          id="link"
                          shape="circle"
                          className="view_btn_link__success pb-1 ml-1"
                        >
                          <Icon
                            // path={mdiInformationVariant}
                            color="#32cd32"
                            path={mdiCheckboxMarkedCircleOutline}
                            size={1}
                          />
                        </BaseButton>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Добавить в избранные"
                        placement="bottomLeft"
                        color="#808080"
                        onClick={() => onCheckMark([...selectedCards, el])}
                        // color="blue"
                      >
                        <BaseButton
                          id="link"
                          shape="circle"
                          className="view_btn_link__grey pb-1 ml-1"
                        >
                          <Icon
                            // path={mdiInformationVariant}
                            // color="#32cd32"
                            color="#808080"
                            path={mdiCircleOutline}
                            size={1}
                          />
                        </BaseButton>
                      </Tooltip>
                    )}
                  </Row>
              )}
                style={{
                  lineHeight: 1,
                  height: 155,
                  minWidth: 820,
                }}
              >

                  <Paragraph style={{ marginTop: -10 }}>
                    Всего
                    {' '}
                    {el?.value === 'all' ? totalSuitesNumber : suitesByTypesData[el.value].length}
                    {' '}
                    из
                    {' '}
                    {totalSuitesNumber}
                    {' '}
                    тест сьютов
                    {' '}
                    имеют тип
                    {' '}
                    {el?.value}
                    .
                  </Paragraph>

                  {(totalSuitesNumber === 0 && loading) ? <Spin style={{ marginLeft: '46%' }} spinning /> : null}

                <Space
                  className="d-flex justify-content-end align-items-end"
                  style={{
                    position: 'absolute',
                    bottom: 16,
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => onAllTestSuitesAndCyclesByType(el?.value)}
                  >
                    Открыть список тест сьютов
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => onAllTestCasesByType(el?.value)}
                  >
                    Открыть список тест циклов
                  </Button>
                </Space>
              </Card>
            </Col>
          );}
        })}
      </>
    )
  }

  // useEffect(() => {
  //   changeLoading(true);
  //   const fetchAllStatistic = async () => {
  //     Promise.all(descriptionTypeOFTesting.map(async (el) => {
  //       const data = await getListOfTestCasesByType(el);
  //       return data;
  //     })).then((allStatistic) => {
  //       setStatistic(allStatistic);
  //       changeLoading(false);
  //     });
  //   };
  //   fetchAllStatistic();
  // }, []);

  useEffect(() => {
    const unCards = [];
    const cards = cloneDeep(descriptionTypeOFTesting).reduce((acc, el) => {
      const isSelected = typeOfTesting
        .some((type) => el?.value === type);
      if (isSelected) {
        el.isSelected = true;
        acc.push(el);
      } else {
        el.isSelected = false;
        unCards.push(el);
      }
      return acc;
    }, []);
    setSelectedCards(cards);
    setUnselectedCards(unCards);
    getListOfSuitesByTestingType()
  }, [projectUUID, typeOfTesting]);

  return (
    <>
      <Row
        className="d-flex align-item-end mb-2 p-2"
        style={{
          background: 'white',
          borderRadius: 8,
        }}
      >
        <AntDesignSearchBox54origins
          onSearch={searchFunc}
          placeholder="Search"
        />
      </Row>

      <Row gutter={[16, 16]} className="w-100">
        <Col span={12}>
          <Card
            title={(
              <Row>
                <span style={{
                  color: '#038fde',
                  fontSize: '28px',
                  marginRight: 6,
                }}
                >
                  {descriptionTypeOFTesting[0].label}
                </span>
                <CommonInfo
                  placement="bottomLeft"
                  info={descriptionTypeOFTesting[0].description}
                />
              </Row>
            )}
              style={{
                lineHeight: 1,
                height: 155,
                minWidth: 820,
              }}
              >

                <Paragraph style={{ marginTop: -10 }}>
                  Всего
                  {' '}
                  {totalSuitesNumber}
                  {' '}
                  из
                  {' '}
                  {totalSuitesNumber}
                  {' '}
                  тест сьютов
                  {' '}
                  имеют тип
                  {' '}
                  {descriptionTypeOFTesting[0].value}
                  .
                </Paragraph>

                {(totalSuitesNumber === 0 && loading) ? <Spin style={{ marginLeft: '46%' }} spinning /> : null}

                <Space
                  className="d-flex justify-content-end align-items-end"
                  style={{
                    position: 'absolute',
                    bottom: 16,
                  }}
                >
              <Button
                type="primary"
                onClick={() => onAllTestSuitesAndCyclesByType('all')}
              >
                Открыть список тест сьютов
              </Button>
              <Button
                type="primary"
                onClick={() => onAllTestCasesByType('all')}
              >
                Открыть список тест циклов
              </Button>
            </Space>
          </Card>
        </Col>

        {
          renderTabs()
        }

      </Row>
      <div
        onClick={() => setShowAllCards((prev) => !prev)}
        className="wrapper_horizontal_collapse_btn general_style mt-3"
        style={{
          marginTop: 40,
          color: '#166fd4',
        }}
      >
        {showAllCards ? 'Спрятать невыбранные типы тестирования' : 'Показать остальные типы тестирования'}
        <Icon
          path={mdiChevronUp}
          size={1.2}
          color="#166fd4"
          rotate={!showAllCards ? 540 : 0}
        />

      </div>
    </>
  );
}
GeneralOverview.propTypes = {
  partition: PropTypes.string,
  switchViewFunc: PropTypes.func,
  changeSelectedTypeOfTesting: PropTypes.func,
};
