import {
  mdiDownload,
  mdiEyeOutline,
  mdiFileOutline,
} from '@mdi/js';
import { PlusOutlined } from '@ant-design/icons';
import Icon from '@mdi/react';
import {
  Col,
  Image,
  Modal,
  Spin,
  Tooltip,
  Upload,
} from 'antd';
import { capitalize, get, isEmpty } from 'lodash';
import { array, func, string } from 'prop-types';
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { saveAs } from 'file-saver';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  bytesToSize,
  defaultImg,
  errorMaxSize,
} from '../../utils';
import { getIssueNew } from '../../../../projectFlow/actions/projectFlowActions';
import { removeFile } from '../../../../projectFlow/actions/issues-actions';
import ViewVideo from '../../../video/ViewVideo';
import WrapperModalForViewVideo from '../../../video/WrapperModalForViewVideo';
import { antNotification, capitalizeFirstChar } from '../../../../MainUtils';
import './AntUploaderFilesForIssueInfo.scss';

function FileIcon({ item }) {
  const viewName = useCallback((filename, name, fileSize) => {
    const fullName = `${filename || name} (${fileSize})`;
    return (
      <Tooltip placement="bottom" title={fullName}>
        <span
          className="text-upload-file"
          style={{
            fontSize: '14px',
            marginTop: -24,
          }}
        >
          {fullName.length > 7 ? `${fullName.slice(0, 6)}...` : fullName}
        </span>
      </Tooltip>
    );
  }, []);

  const {
    content_length = 0,
    content_type: contentType,
    size = '',
    filename = '',
    type,
    name = 'file',
    file,
    uuid,
    upload: {
      total = '',
    } = {},
  } = item || {};
  const i = uuid;

  const fileSize = bytesToSize(content_length || total || size);

  if ((contentType && contentType.indexOf('video') !== -1) || (type && type.indexOf('video') !== -1)) {
    return (
      <Col className="relative flex flex-col items-center justify-center" key={`${uuid}${i}`}>
        {/* {iconDeleteFile(i)} */}
        <WrapperModalForViewVideo
          file={item}
          withContainer={false}
        />
        {viewName(filename, name, fileSize)}
      </Col>
    );
  }
  return (
    <Col className="relative flex flex-col items-center" key={`${uuid}${i}`}>
      {/* {iconDeleteFile(i)} */}

      {(contentType && contentType.indexOf('image') !== -1) || (type && type.indexOf('image') !== -1) ? (
        <div className="wrapper-file-icon">
          <Image
            width={37}
            height={37}
            src={type ? URL.createObjectURL(item) : `data:${contentType};base64, ${file}`}
            fallback={defaultImg}
          />
        </div>
      ) : (
        <div
          className="flex items-center fileAttached"
          onClick={(e) => saveFile?.(e, `data:${contentType};base64, ${file}`, filename)}
        >
          <Icon
            className="fileIcon"
            color="#bfbfbf"
            path={mdiFileOutline}
            size={2}
          />
          <Icon
            className="fileLoad"
            color="#bfbfbf"
            path={mdiDownload}
            size={1}
          />
        </div>
      )}
      {viewName(filename, name, fileSize)}

    </Col>
  );
}

export default function AntUploaderFilesForIssueInfo({
  partition,
  disabled = [],
  filesList,
  setFiles,
  issueUUID,
  onChangeFilesCallback,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const issueFilesFetching = useSelector((state) => (
    state.projectManagementService.issue.fetchingFiles));

  const [fetching, setFetching] = useState(false);
  const [defaultAllList, setDefaultAllList] = useState([]);
  const [previewFile, setPreviewFile] = useState(null);

  const initFunc = (customFiles) => {
    const { files, audioFiles } = (customFiles || []).reduce((acc, curr) => {
      curr.name = curr.filename;
      if (curr?.content_type && !curr?.content_type.includes('audio')) {
        curr.url = `data:${curr?.content_type};base64, ${curr?.file}`;
        curr.status = 'done';
        return { ...acc, files: [...acc.files, curr] };
      }
      if (curr?.content_type && curr?.content_type.includes('audio')) {
        return { ...acc, audioFiles: [...acc.audioFiles, curr] };
      }
      return acc;
    }, { files: [], audioFiles: [] });

    setFiles(files);
    setDefaultAllList(customFiles);
  };

  const getFilesForIssue = async () => {
    setFetching(true);
    const constants = [
      'FILES_REQUEST', 'FILES_SUCCESS', 'FILES_FAILURE',
    ];

    const res = await dispatch(getIssueNew({
      uuid: issueUUID,
      params: {},
      constants,
      withFiles: true,
      partition,
    }));

    initFunc(get(res, '[0].files', []));

    setFetching(false);
  };

  const previewUploadIcon = (file) => {
    if ((get(file, 'content_type') && file.content_type.indexOf('image') !== -1)
      || (get(file, 'type') && file.type.indexOf('image') !== -1)
      || (get(file, 'content_type') && file.content_type.indexOf('video') !== -1)
      || (get(file, 'type') && file.type.indexOf('video') !== -1)) {
      return (
        <Icon
          path={mdiEyeOutline}
          color="white"
          size={0.75}
          style={{
            marginTop: -3,
          }}
        />
      );
    }
  };

  const onRemoveFunc = (file) => {
    // console.log('onRemove');
    const arrNeedDelete = defaultAllList.filter(
      (p) => get(file, 'uuid', '') === p.uuid,
    );
    const newList = [...filesList.filter((el) => el?.uid !== file?.uid)];
    setFiles(newList);

    return Promise.all(arrNeedDelete.map((i) => dispatch(
      removeFile(issueUUID, i.uuid, partition),
    ))).then(() => {
      setFiles(newList);
      antNotification('success', 'Success');
    });
  };

  const showConfirmDelete = (file) => Modal.confirm({
    title: capitalize(
      t('wms.modals.headers.removing_process', 'removing process'),
    ),
    width: '600px',
    content: capitalize(t(
      'wms.modals.body.removing_file',
      'you\'re going to remove this file. Are you sure?',
    )),
    okText: capitalize(
      t('wms.buttons.removing_process.yes', 'yes, I am'),
    ),
    okType: 'danger',
    cancelText: capitalize(
      t('wms.buttons.removing_process.no', 'no, I\'m not'),
    ),
    open: false,
    centered: true,
    maskClosable: true,
    okButtonProps: { className: 'dangerButton' },
    onOk() {
      onRemoveFunc(file);
    },
  });

  const propsUpload = useMemo(() => ({
    beforeUpload: (file, files) => {
      const sizeFiles = filesList.reduce((acc, el) => el?.size || el?.content_length || 0 + acc, 0) + file.size;
      // + files.reduce((acc, el) => el.size + acc, 0);
      if (sizeFiles >= 10485760) {
        errorMaxSize();
      } else {
        file.url = URL.createObjectURL(file);
        file.status = 'done';
        // files.forEach((el) => {
        //   el.url = URL.createObjectURL(el);
        //   el.status = 'done';
        // });
        // setFiles([...filesList, ...files]);
        onChangeFilesCallback({ files: [...filesList, file] });
        // setFiles([...filesList, file]);
        // onChangeFilesCallback({ files: [...filesList, ...files] });

        antNotification('success', 'Success');
      }
      return false;
    },
    onPreview: (file) => {
      setPreviewFile(file);
    },
    onDownload: (file) => {
      saveAs(`data:${file?.content_type};base64, ${file?.file}`, file?.filename || file?.name);
    },
    onRemove: async (file) => {
      showConfirmDelete(file);
    },
    fileList: filesList,
    multiple: true,
    listType: 'picture-card',
    showUploadList: {
      previewIcon: previewUploadIcon,
      showDownloadIcon: true,
    },
    className: 'file-for-issue',
    iconRender: (item) => <FileIcon item={item} />,
  }), [filesList]);

  useEffect(() => {
    if (issueUUID && !issueFilesFetching) {
      getFilesForIssue();
    }
  }, [issueUUID, issueFilesFetching]);

  useEffect(() => () => setFiles([]), []);

  return (
    <Spin spinning={fetching}>
      <div className="w-full relative px-1">
        <div className="flex items-center w-full mb-2">
          Files:
        </div>
        <Upload 
          {...propsUpload}
          id='uploadFileButton'
        >
          <div >
            <PlusOutlined style={{ color: '#1890ff' }} />
            <div
              style={{
                marginTop: 8,
                color: '#1890ff',
              }}
            >
              Upload
            </div>
          </div>
        </Upload>
      </div>
      {((get(previewFile, 'content_type') && previewFile.content_type.indexOf('video') !== -1)
        || (get(previewFile, 'type') && previewFile.type.indexOf('video') !== -1))
        && (
          <Modal
            open={previewFile}
            centered
            footer={null}
            onCancel={() => setPreviewFile(null)}
            destroyOnClose
            width={750}
          >
            <ViewVideo
              file={previewFile}
            />
          </Modal>
        )}

      {((get(previewFile, 'content_type') && previewFile.content_type.indexOf('image') !== -1)
        || (get(previewFile, 'type') && previewFile.type.indexOf('image') !== -1))
        && (
          <Image
            width={1}
            src={get(previewFile, 'type')
              ? URL.createObjectURL(previewFile)
              : `data:${previewFile.content_type};base64, ${previewFile.file}`}
            fallback={defaultImg}
            preview={{
              visible: true,
              onVisibleChange: (visible) => {
                if (!visible) {
                  setPreviewFile(null);
                }
              },
            }}
          />

        )}
    </Spin>
  );
}

AntUploaderFilesForIssueInfo.propTypes = {
  disabled: array,
  partition: string,
  issueUUID: string,
  onChangeFilesCallback: func,
  filesList: array,
  setFiles: func,
};
