import { isNil } from 'lodash';
import {
  expiredTag,
  issuesDevStatuses,
  issuesPurgatoryStatuses,
  issuesStatusesTags,
  issuesTestingStatuses,
  issuesValidationsStatuses,
  trackerTags,
} from '../../../constants/issuesConstants';
import { vectorStatusesTags, vectorTypeTags } from '../../../constants/vectorsConstants';

export const defaultNotSavedData = {
  notSaved: false,
  confirmNotSaved: false,
};

export const defaultIssuesPagination = {
  currentPage: 1,
  limit: 25,
  offset: 0,
};

export const defaultVectorsPagination = {
  currentPage: 1,
  limit: 5,
  offset: 0,
};

export const defaultOrderRules = {
  order: 'desc',
  orderBy: 'created',
};

// Unused
export const allListVectorTags = [
  {
    tags: vectorTypeTags,
    isRow: true,
    rowText: 'wms.table.headers.type',
  },
  {
    tags: vectorStatusesTags.map((i) => ({ ...i, ruleOfTag: 'off' })),
    isRow: true,
    rowText: 'wms.table.headers.status',
  },
  {
    tags: expiredTag,
    isRow: true,
    rowText: 'wms.labels.expiration_date',
  },
];

export const allListIssueTags = [
  {
    tags: issuesValidationsStatuses,
    isRow: true,
    rowText: 'wms.noun.validation',
  },
  {
    tags: issuesDevStatuses,
    isRow: true,
    rowText: 'wms.labels.development',
  },
  {
    tags: issuesTestingStatuses,
    isRow: true,
    rowText: 'wms.labels.testing',
  },
  {
    tags: issuesPurgatoryStatuses,
    isRow: true,
    rowText: 'wms.noun.purgatory',
  },
  {
    tags: issuesStatusesTags.filter((i) => i.keySection === 'end').map((i) => ({ ...i, ruleOfTag: 'off' })),
    isRow: true,
    rowText: 'wms.labels.tickets_end',
  },
  {
    tags: trackerTags,
    isRow: true,
    rowText: 'wms.table.headers.type',
  },
  {
    tags: expiredTag,
    isRow: true,
    rowText: 'wms.labels.expiration_date',
  },
];

// export const defaultSelectedIssuesTag = [
//   {
//     tags: issuesStatusesTags
//       .filter((i) => i.value === 'closed' || i.value === 'deployed' || i.value === 'abandoned')
//       .map((i) => ({ ...i, ruleOfTag: 'excluded' })),
//   },
// ];

// export const defaultSelectedVectorsTag = [
//   {
//     tags: vectorStatusesTags
//       .filter((i) => i.value === 'active' || i.value === 'created' || i.value === 'in progress')
//       .map((i) => ({ ...i, ruleOfTag: 'included' })),
//   },
// ];

export const defaultStateBtn = { isEdit: false, isValid: false };

export const estimatedStatusWeight = (status) => {
  switch (status) {
    case 'easy':
      return 1;
    case 'normal':
      return 2;
    case 'difficult':
      return 3;
    case 'very_complicated':
      return 4;
    default:
      return 0;
  }
};

// ---------------  SCORING FOR PROJECT ENTITIES -----------------------------

export const notActiveStatusesArr = ['backlog', 'rejected', 'closed', 'deployed', 'abandoned'];

export const statusWeightIndexRatio = (entityStatus) => {
  const checkIsNullableStatus = notActiveStatusesArr.some((status) => status === entityStatus);
  return checkIsNullableStatus ? 0 : 1;
};

export const priorityWeight = (priority) => {
  switch (priority) {
    case 'low':
      return 1;
    case 'medium':
      return 2;
    case 'high':
      return 3;
    case 'urgent':
      return 4;
    default:
      return 0;
  }
};

export const importanceWeight = (importance) => {
  switch (importance) {
    case 'minor':
      return 1;
    case 'medium':
      return 2;
    case 'significant':
      return 3;
    case 'critical':
      return 4;
    default:
      return 0;
  }
};

export const priorityWeightFormatter = (priority, status) => {
  const ratio = statusWeightIndexRatio(status);
  const weight = priorityWeight(priority);
  const finalVal = weight * ratio;
  // console.log('finalVal', finalVal);
  return finalVal;
};

export const entityProjectWeight = (priority, prioritySum, status) => {
  // console.log('priority, prioritySum, status', priority, prioritySum, status);
  const entityPriorityIndex = priorityWeightFormatter(priority, status);
  const weight = isNil(entityPriorityIndex) ? 0 : (Math.round((entityPriorityIndex / prioritySum) * 1000) / 1000).toFixed(2);
  // console.log('entityProjectWeight', isNil(Number(weight)), weight);
  return isNil(Number(weight)) || !Number(weight) ? 0 : weight;
};

export const projectScoringBackground = (val) => {
  // console.log("projectIssueScoring", 123123123);
  if (val >= 3) {
    return 'error';
  }
  if (val >= 2) {
    return 'warning';
  }
  if (val >= 1) {
    return 'processing';
  }
  if (val >= 0 && val < 1) {
    return 'default';
  }
};

// ---------------  get start Dev scoring -----------------------------

export const projectEntityScoring = (data, total) => {
  // const totalCount = data.filter((item) => !(notActiveStatusesArr.some((defStatus) => defStatus === item?.status)));
  const prioritySum = data.reduce((accumulator, item) => accumulator + priorityWeightFormatter(item?.priority, item?.status), 0);
  const startScoringVal = (Math.round((prioritySum / total) * 1000) / 1000);
  const projectIssueScoringVal = startScoringVal ? startScoringVal.toFixed(2) : 0;
  // console.log("projectIssueScoringVal", projectIssueScoringVal);
  const background = projectScoringBackground(Number(projectIssueScoringVal));
  // console.log("background", background);
  const finalData = {
    sum: prioritySum,
    avgVal: !projectIssueScoringVal ? 0 : projectIssueScoringVal,
    background,
    total,
  };
  return finalData;
};

// ---------------  update scoring -----------------------------

const handleTotalCount = (total, oldStatus, currentStatus, isDelete) => {
  const checkCurrentStatus = statusWeightIndexRatio(currentStatus);
  if (isDelete) {
    return checkCurrentStatus ? total - 1 : total;
  }
  if (!oldStatus) {
    return checkCurrentStatus ? total + 1 : total;
  }
  const checkOldStatus = statusWeightIndexRatio(oldStatus);
  return total - checkOldStatus + checkCurrentStatus;
};

const handleFinalScoringData = (props) => {
  const { currentPrioritySum, total } = props;
  // console.log('handleFinalScoringData', currentPrioritySum, total);
  const startScoringVal = (Math.round((currentPrioritySum / total) * 1000) / 1000);
  const projectIssueScoringVal = startScoringVal ? startScoringVal.toFixed(2) : total ? 0 : '-';
  const background = projectScoringBackground(currentPrioritySum);
  const finalData = {
    sum: currentPrioritySum,
    avgVal: !projectIssueScoringVal ? 0 : projectIssueScoringVal,
    background,
    total,
  };
  return finalData;
};

const createScoringFunc = (props) => {
  const {
    sum: prevSum, total, currentPriority, currentStatus,
  } = props;
  const currentPriorityIndex = priorityWeightFormatter(currentPriority, currentStatus);
  const currentPrioritySum = prevSum + currentPriorityIndex;
  const newTotal = handleTotalCount(total, null, currentStatus);
  return handleFinalScoringData({ currentPrioritySum, total: newTotal });
};

const updateScoringFunc = (props) => {
  const {
    sum: prevSum, total, currentPriority, currentStatus, oldPriority, oldStatus,
  } = props;
  const oldPriorityIndex = priorityWeightFormatter(oldPriority, oldStatus);
  const currentPriorityIndex = priorityWeightFormatter(currentPriority, currentStatus);
  const currentPrioritySum = prevSum - oldPriorityIndex + currentPriorityIndex;
  const newTotal = handleTotalCount(total, oldStatus, currentStatus);
  return handleFinalScoringData({ currentPrioritySum, total: newTotal });
};

const deleteScoringFunc = (props) => {
  const {
    sum: prevSum, total, currentPriority, currentStatus,
  } = props;
  const currentPriorityIndex = priorityWeightFormatter(currentPriority, currentStatus);
  const currentPrioritySum = prevSum - currentPriorityIndex;
  const newTotal = handleTotalCount(total, null, currentStatus, true);
  return handleFinalScoringData({ currentPrioritySum, total: newTotal });
};

export const updateEntityScoring = (props) => {
  // actionType, total, prevSum, currentPriority, currentStatus, oldPriority, oldStatus
  switch (props?.actionType) {
    case 'create': {
      return createScoringFunc(props);
    }
    case 'update': {
      return updateScoringFunc(props);
    }
    case 'delete': {
      return deleteScoringFunc(props);
    }
    default: {
      return null;
    }
  }
};
