import { UsersPartitionConstants } from '../../constants/Constants';

const initialState = {
  fetching: true,
  data: [],
  error: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UsersPartitionConstants.GET_DAILY_REPORT_ENTITY_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case UsersPartitionConstants.GET_DAILY_REPORT_ENTITY_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload,
      };
    case UsersPartitionConstants.GET_DAILY_REPORT_ENTITY_FAILURE:
      return {
        ...state,
        fetching: false,
        data: [],
        error: payload,
      };

    case UsersPartitionConstants.CLEAR_DAILY_REPORT_ENTITY:
      return {
        ...state,
        data: [],
      };

    default:
      return state;
  }
};
