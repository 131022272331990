
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  head, pathOr, prop, propOr,
} from 'ramda';
import { useLocation, useParams } from 'react-router-dom';
import cn from 'classnames';


import ArchiveStateAlert from '../archiveState/ArchiveStateAlert';
import AuthLoader from '../../../auth/components/AuthLoader';
import BugTrackerTemplate from '../qaView/BugTrackerTemplate';
import CookBookStartPage from '../cookbook/CookBookStartPage';
import DocsStartPage from '../docView&ideas/DocsStartPage';
import IssuesView from '../issuesView/IssuesView';

// import vectors
import WorkBlocksView from '../workBlocksView/WorkBlocksView'
import PlanningView from '../planningView/PlanningView';
import ProjectView from './projectView/ProjectView';
import ProjectSystemTabs from './ProjectSystemTabs';
import ProjectBreadcrumbs from '../../../components/breadcrumb/ProjectBreadcrumbs';

import './css/ProjectMainLayer.scss';
import '../releasesView/release.scss';

import {
  getProject,
  getProjectFetching,
  getProjectParams,
  getProjectUUID,
} from '../../selectors/selectors';
import { getUuidsfromParamsUsers } from '../../../MainUtils';
import { capitalize } from 'lodash';
import { ProjectMainLayerContext } from '../../context/ProjectFlowListOfContexts';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import axiosRequest from '../../../api/apiAxios';
import { viewNotification, getUserName } from '../../../entity/selectors/selectors';
import {
  resetArchiveState,
  clearVector,
  clearProject,
  clearDocuments,
  clearIssue,
  clearListOfIssues,
  clearListOfIdeas,
  clearStorageIssues,
  clearIssuesScoringData,
  clearVectorsScoringData,
} from '../../actions/PtcActionsForHook';
import {
  getIssue,
  getListOfIssues,
  getListOfVectors,
  getProjectRequest,
} from '../../actions/projectFlowActions';
import { clearCurrentProjectScoreData } from '../../reducers/slicers/projectAllScoringSlicer';
import { entityRead, getOrCreateSingle } from '../../../entity/actions/entityActions';
import { getUsersByUUID } from '../../../userFlow/store/action-creators/userActions';
import { viewNotificationFalse } from '../../../entity/actions/EntityActionsForHook';
import { sendNotification } from '../../../api/notificationsAPI';
import { getUserPublicProfileUUID } from '../../../userFlow/store/selectors/selectors';
import {
  getOneDocument,
  getDocumentStateRequest,
  updateDocumentStateRequest,
  setDocumentContent,
} from '../../actions/DocumentsActions';
import { clearGlobalUserFilterTags } from '../../reducers/slicers/userFilterSlicer';
import { CLEAR_ALL_PROJECT_GOALS } from '../../reducers/slicers/projectGoalsSlicer';
import { checkEntityPerm } from '../../../entity/actions/entityPermActions';
import { setPerms } from '../../reducers/slicers/myProjectPermsSlicer';
import useURLParams from '../../../hooks/useURLParams';

import { getStateID } from '../docView&ideas/helpers/statesAndPatches/idHelpers';
// import buildContent from '../docView&ideas/helpers/statesAndPatches/buildContent';
import { useQuill } from '../docView&ideas/QuillEditor/useQuill/useQuill';

import { PROJECT_TABS } from './projectView/projectTabs';
import useGetUserPerm from '../../../permissions/hooks/useGetUserPerm';
import MyBoardRoot from '../myBoard/MyBoardRoot';
import { partitionNamesConfig } from '../../../api/appConfig';
import { setAllDocPerms, setQuillDocReady } from '../../reducers/slicers/quillSlicer';




function ProjectMainLayer({
  defaultPartition = partitionNamesConfig.partition1,
  otherUser = false,
  initialPathForHistory = '/pm/projects/',
}) {
  const dispatch = useDispatch();

  const { activeTab } = useParams();

  const location = useLocation();
  let fromPath = location?.state?.from?.pathname;

  const { getURLParams, routeNavigateAndClearParams, transformSearchParams } = useURLParams();

  const projectFetching = useSelector(getProjectFetching);
  const selectedProject = useSelector(getProject);
  const projectParams = useSelector(getProjectParams);
  const seeNotification = useSelector(viewNotification);
  const projectUUID = useSelector(getProjectUUID);
  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const userName = useSelector(getUserName);

  const [viewMode, changeViewMode] = useState('generaloverview');

  const { activeProject } = getURLParams();

  const { reloadPerm, getPerms } = useGetUserPerm({
    entityUUID: projectUUID,
    partitionType: defaultPartition,
    needCheckPerm: false,
  });

  const getClearProject = (uuid) => new Promise((resolve) => {
    if (uuid) {
      dispatch(getProjectRequest(uuid, (data) => resolve(data), defaultPartition));
    }
  });

  const backToProjects = () => {
    routeNavigateAndClearParams(initialPathForHistory);
  };

  const getDocument = (uuid, entityType) => dispatch(getOneDocument(uuid, entityType, defaultPartition));

  const getDocumentState = (uuid, stateID) => {
    const optionalDataConfig = {
      uuid,
      stateID,
      partition: defaultPartition,
    };

    if (stateID && stateID !== '0') {
      return dispatch(getDocumentStateRequest(optionalDataConfig));
    }

    return null;
  };

  const createNewDocumentState = (uuid, data) => {
    const optionalDataConfig = {
      uuid,
      stateID: data.stateID,
      stateData: {
        createdBy: userName,
        timestamp: new Date().getTime(),
        text: data.content,
      },
      partition: defaultPartition,
    };

    if (data.stateID && data.stateID !== '0') {
      return dispatch(updateDocumentStateRequest(optionalDataConfig));
    }

    return null;
  };

  const {parseOldDocumentStructure, parseCrdtDocumentStructure} = useQuill();

  const getDocumentWithOptionalData = async (uuid, type) => {
    try {
      const document = await getDocument(uuid, type);
      // console.log('DOCUMENT:', document);

      const hasOptionalData = propOr(false, 'optional_data', head(document));
      // console.log('OPTIONAL DATA:', hasOptionalData);
      // code for build description
      // console.log('crdt hasOptionalData', hasOptionalData);
      if (hasOptionalData) {
        // console.log('crdt HAS OPTIONAL DATA');
        const documentParams = propOr('', 'params', head(document));

        const currentActualState = prop('actualState', documentParams);
        const stateID = getStateID(`${currentActualState}`);
        // console.log('ACTUAL STATE:', currentActualState);
        // console.log('crdt STATE ID:', stateID);
        const state = await getDocumentState(uuid, stateID);
        // console.log('crdt state', state)
        const checkIsOldStructure = !Array.isArray(state);
        if (checkIsOldStructure) {
          const { text, patches: statePatches } = state || {};
          // console.log('crdt text', text);
          // console.log('crdt statePatches', statePatches);
          const startHtmlString = parseOldDocumentStructure(text, statePatches, type);
          // console.log('crdt startHtmlString', startHtmlString)
          dispatch(setDocumentContent({startHtmlString, startStateVector: ''}));
        } else {
          const startStateVector = parseCrdtDocumentStructure(state);
          // console.log('crdt parseCrdtDocumentStructure', startStateVector)
          // console.log('crdt startStateVector', startStateVector)
          dispatch(setDocumentContent({startHtmlString: '', startStateVector}));
        }
      } else {
        const paramsText = document?.params?.text;
        const checkParamsText = paramsText?.length;
        if (checkParamsText) {
          const startHtmlString = parseOldDocumentStructure(paramsText, [], type);
          dispatch(setDocumentContent({startHtmlString, startStateVector: ''}));
        }
      }
      // dispatch(setDocumentContent(null));
      // return [null, null];
    } catch(e) {
      console.log('getDocumentWithOptionalData error', e)
    } finally {
      dispatch(setQuillDocReady(true));
    }
  };

  const getClearIssue = (uuid, signal) => {
    axiosRequest.abort('', ProjectTypeCommonConstants.ISSUE_ENTITY_REQUEST);

    const config = {
      uuid,
      partition: defaultPartition,
      signal,
      withFiles: false,
      params: {},
    };

    return dispatch(getIssue(config));
  };

  const getClearListOfIssues = (param) => {
    const config = {
      depth: 0,
      partition: defaultPartition,
      constants: [
        ProjectTypeCommonConstants.LIST_OF_ISSUES_REQUEST,
        ProjectTypeCommonConstants.LIST_OF_ISSUES_SUCCESS,
        ProjectTypeCommonConstants.LIST_OF_ISSUES_FAILURE,
      ],
      fields: {
        modified: 'modified',
      },
      params_fields: {
        id: 'id',
        title: 'title',
        description: 'description',
        result: 'result',
        tracker: 'tracker',
        priority: 'priority',
        status: 'status',
        assigned_users: 'assigned_users',
        estimated_time: 'estimated_time',
        lifeTime: 'lifeTime',
        users: 'users',
        usersSearch: 'usersSearch',
        tags: 'tags',
        expirationDateDev: 'expirationDateDev',
        expirationDateQa: 'expirationDateQa',
      },
      ...param,
    };

    return dispatch(getListOfIssues(config));
  };

  const getClearVector = (uuid, signal, customConstants) => {
    const data = {
      entity_type: 'vector',
      entity_uuid: uuid,
    };

    const constants = customConstants || [
      ProjectTypeCommonConstants.GET_VECTOR_REQUEST,
      ProjectTypeCommonConstants.GET_VECTOR_SUCCESS,
      ProjectTypeCommonConstants.GET_VECTOR_FAILURE,
    ];

    const options = {
      partition: defaultPartition,
      aborting: true,
      signal,
    };

    return dispatch(
      entityRead({
        data,
        constants,
        options,
      }),
    );
  };

  const getClearVectors = (data) => {
    const config = {
      params_fields: {
        id: 'id',
        versionNumber: 'versionNumber',
        title: 'title',
        type: 'type',
        priority: 'priority',
        status: 'status',
        users: 'users',
        description: 'description',
        lifeTime: 'lifeTime',
      },
      ...data,
      depth: 1,
      partition: defaultPartition,
    };

    return dispatch(getListOfVectors(config));
  };

  const getProjectMembers = (uuid) => {
    const uuids = uuid || getUuidsfromParamsUsers(propOr([], 'users', projectParams));
    dispatch(
      getUsersByUUID({
        uuid: uuids,
        userType: 'project',
      }),
    );
  };

  const checkMainArchiveStateEntity = async (parent) => {
    const config = {
      data: {
        entity_type: 'mainArchiveEntity',
        parent,
      },
      partition: defaultPartition,
      constants: [
        ProjectTypeCommonConstants.GET_MAIN_ARCHIVE_ENTITY_REQUEST,
        ProjectTypeCommonConstants.GET_MAIN_ARCHIVE_ENTITY_SUCCESS,
        ProjectTypeCommonConstants.GET_MAIN_ARCHIVE_ENTITY_FAILURE,
      ],
    };

    dispatch(getOrCreateSingle(config));
  };

  const getPathForLink = (param) => {
    const searchParams = { activeProject };
    if (param === 'project') searchParams.tab = PROJECT_TABS.INFO;
    const customSearchParams = transformSearchParams({ ...searchParams });
    return `${initialPathForHistory}${param}?${customSearchParams}`;
  };

  const onTabClick = () => {
    if(activeTab === 'qa') changeViewMode('generaloverview')
    reloadPerm(1);
  }

  const switchViewFunc = (param) => {
    if (activeTab !== param && param !== activeProject) {
      const searchParams = { activeProject };
      if (param === 'project') searchParams.tab = PROJECT_TABS.INFO;

      routeNavigateAndClearParams(`${initialPathForHistory}${param}`, { ...searchParams });
    }
  };

  const switchDefaultView = (param) => {
    if (activeTab !== param && param !== activeProject) {
      const searchParams = { activeProject };
      if (param === 'project') searchParams.tab = PROJECT_TABS.INFO;

      routeNavigateAndClearParams(`${initialPathForHistory}${param}`, { ...searchParams });
    }
  };

  // sendAssignNotifications
  const sendAssignedNotifications = ({
    assignedUsers,
    entityUUIDsFields,

    entityType,
    message,
    entityUUID,
    eventName,
  }) => {
    // console.log('projectUUID',projectUUID)
    const finalEventName = eventName || 'assigned';
    if (assignedUsers && assignedUsers.length > 0) {
      const data = {
        entity: {
          entityType,
          partition: defaultPartition,
          projectUUID,
          user: myPublicUUID || '',
          uuid: entityUUID,
          ...entityUUIDsFields,
        },
      };

      dispatch(sendNotification(finalEventName, assignedUsers, message, data));
    }
  };

  const routFunction = async () => {
    const project = await getClearProject(activeProject);
    const projectMembers = pathOr([], ['params', 'users'], head(project));

    dispatch(
      getUsersByUUID({
        uuid: getUuidsfromParamsUsers(projectMembers),
        userType: 'project',
      }),
    );

    if (['/pm/audit', '/pm/projects', `/public/user/${myPublicUUID}/projects`].includes(fromPath)) {
      const lastTab = localStorage.getItem('lastProjecTab');
      switchDefaultView(lastTab || 'issuesnew');
    } else {
      switch (activeTab) {
        case 'project':
        case 'myboard':
        case 'issuesnew':
        case 'planning':
        case 'docs':
        case 'issues':
        case 'cookbook':
        case 'historical':
        case 'qa':
        case 'documentsideas':
        case 'versions':
        case 'permissions':
        case 'workblocks':
          switchDefaultView(activeTab);
          break;
        case 'vector':
          switchDefaultView('issues');
          break;
        default:
          switchDefaultView('project');
      }
    }

    checkMainArchiveStateEntity(propOr([], 'uuid', head(project)));
  };

  const checkComponent = (component) => activeTab === component && component;

  const isGlobalDisabled = projectParams?.type === 'ACTIVITIES_USER';

  const checkMyPerms = () => {
    const data = {
      entity_uuid: activeProject,
      partition: defaultPartition,
      returnPermsList: true,
    };

    dispatch(checkEntityPerm(data)).then((res) => dispatch(setPerms(res)));
  };

  const checkUserProjectPermsForDocs = async() => {
    const config = {
      entity_uuid: activeProject,
      perm: ['create', 'delete', 'list', 'read', 'set', 'update'],
      partition: defaultPartition,
    };
    const permData = await getPerms(config);
    dispatch(setAllDocPerms(permData));
  }

  const projectMainLayerValuesContext = useMemo(
    () => ({
      backToProjects,
      createNewDocumentState,
      defaultPartition,
      getClearIssue,
      getClearListOfIssues,
      getClearProject,
      getClearVector,
      getClearVectors,
      getDocument,
      getDocumentState,
      getDocumentWithOptionalData,
      getProjectMembers,
      initialPathForHistory,
      otherUser,
      sendAssignedNotifications,
      switchDefaultView,
      switchViewFunc,
      isGlobalDisabled,
    }),
    [isGlobalDisabled],
  );

  useEffect(() => {
    if (activeProject) {
      routFunction();
    }
    return () => {
      // console.log('clearVectorsScoringData');
      dispatch(resetArchiveState);
      dispatch(clearVector);
      dispatch(clearProject);
      dispatch(clearDocuments);
      dispatch(clearIssue);
      dispatch(clearListOfIssues);
      dispatch(clearListOfIdeas);
      dispatch(clearStorageIssues);
      dispatch(clearIssuesScoringData);
      dispatch(clearVectorsScoringData);
      dispatch(clearGlobalUserFilterTags());
      dispatch(CLEAR_ALL_PROJECT_GOALS());
      dispatch(clearCurrentProjectScoreData());
    };
  }, [activeProject]);

  useEffect(() => {
    if (seeNotification) {
      dispatch(viewNotificationFalse);
    }
  }, [seeNotification]);


  useEffect(() => {
    if (projectFetching && activeProject) {
      checkMyPerms();
      checkUserProjectPermsForDocs();
    }
  }, [projectFetching, activeProject]);

  useEffect(() => {
    if (!projectFetching && activeProject) {
      document.title = `${capitalize(projectParams.title)} | W54`;
    }
    return () => {
      document.title = 'W54';
    };
  }, [projectFetching, activeProject]);

  useEffect(() => {
    return () => {
      localStorage.setItem('lastProjecTab', activeTab);
    }
  }, [activeTab])

  return (
    <ProjectMainLayerContext.Provider
      value={projectMainLayerValuesContext}
    >
      <Col className={cn('w-full', 'project-wrapper')}>
        <ArchiveStateAlert />
        <Row wrap={false} className={cn('pt-2 mb-2', { 'hidden': isGlobalDisabled })}>
          <ProjectSystemTabs
            //  switchViewFunc={switchViewFunc}
            onClickCallback={onTabClick}
            getPathForLink={getPathForLink}
          />
        </Row>
        <div className={cn('w-full mb-3 flex items-end', { 'hidden': isGlobalDisabled })}>
          {/* <FilterByUsersRoot projectUUID={projectUUID} partitionType={defaultPartition} /> */}
          <ProjectBreadcrumbs isGlobalDisabled={isGlobalDisabled} />
        </div>
        {projectFetching ? <AuthLoader /> : (
          <Row className="project-content">
            <Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
              {checkComponent('project') && (
                <ProjectView defaultPartition={defaultPartition} />
              )}

              {checkComponent('myboard') && (
                <MyBoardRoot partition={defaultPartition} />
              )}

              {checkComponent('workblocks') && <WorkBlocksView defaultPartition={defaultPartition}/>}

              {checkComponent('issuesnew') && <IssuesView defaultPartition={defaultPartition} />}

              {checkComponent('docs') && (
                <DocsStartPage defaultPartition={defaultPartition} pageUrl="docs" />
              )}

              {checkComponent('planning') && <PlanningView defaultPartition={defaultPartition} />}

              {checkComponent('qa') && (
                <BugTrackerTemplate
                  partition={defaultPartition}
                  getProjectMembers={getProjectMembers}
                  viewMode={viewMode}
                  changeViewMode={changeViewMode}
                />
              )}

              {/* {checkComponent('documentsideas') && (
                <DocsStartPage
                  hideElements={['goals', 'vectorTableAndInfoCard']}
                  defaultPartition={defaultPartition}
                  backToProjects={backToProjects}
                />
              )} */}

              {checkComponent('cookbook') && (
                <CookBookStartPage
                  otherUser={otherUser}
                  customParent={head(selectedProject)}
                  currentPartition={defaultPartition}
                  entityType="vector"
                />
              )}

              {/* {checkComponent('audit') && <ActivityView />} */}
              {/* {checkComponent('settings') && (
                <SettingsTemplate
                  switchViewFunc={switchViewFunc}
                  backToProjects={backToProjects}
                />
              )} */}
            </Col>
          </Row>
        )}
      </Col>
    </ProjectMainLayerContext.Provider>
  );
}

ProjectMainLayer.propTypes = {
  defaultPartition: PropTypes.string,
  initialPathForHistory: PropTypes.string,
  otherUser: PropTypes.bool,
};

export default ProjectMainLayer;
