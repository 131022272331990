import PropTypes, {
  bool, func, number, string, object,
} from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { capitalize } from 'lodash';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import ListOfVectorsAntDesignTable from '../vector/ListOfVectorsAntDesignTable';
import MultiEditVectorView from '../multiEdit/MultiEditVectorView';
import WrapperAddVector from '../../containers/vector/WrapperAddVector';

import useURLParams from '../../../hooks/useURLParams';

import {
  getProjectUUID, getVectors,
} from '../../selectors/selectors';
import { DownOutlined } from '@ant-design/icons';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { columnsFilterConfig } from './constants/vectorFilterOptions';

function ListOfVectorsForIssueView({
  partitionType,
  newCurrentPage,
  getVectorsRequestSetting,
  countOfData,
  hideElements,
  typeOfVector,
  isGlobalDisabled,
  getVectorsRequest,
  getVector,
  disabledMultiEdit,
  filterConfig,
  filterCallback,
  onChangeRowCallback,
  newVectorSuccessCallback,
  hideVectors,
  setHideVectors,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const vectors = useSelector(getVectors);
  const projectUUID = useSelector(getProjectUUID);

  const { getURLParams } = useURLParams();
  const { activeVector } = getURLParams() || {};
  const [isMultiEdit, setIsMultiEdit] = useState(false);
  const [limit, setLimit] = useState(5);

  const hideAddVector = useMemo(
    () => hideElements.includes('AddVector'),
    [hideElements],
  );

  const pageSizeOptions = useMemo(() => [5, 10, 25, 50], []);

  const toggleMultiEdit = () => setIsMultiEdit((prev) => {
    dispatch({ type: ProjectTypeCommonConstants.SET_FLAG_FOR_MULTI_EDIT_VECTORS, payload: !prev });
    return !prev;
  });

  const backEditCallback = () => {
    toggleMultiEdit();
    getVectorsRequest();
    if (activeVector) {
      getVector(activeVector);
    }
  };

  return (
    <>
      <div className="flex justify-between flex-wrap mb-1">
        <div className="flex">
          <h6 className="mb-0 mr-2">
            {capitalize(t(`${typeOfVector}s`))}
          </h6>
          <WrapperAddVector
            vectorType="general"
            hideBtnAddVector={hideAddVector}
            disabled={isGlobalDisabled}
            partitionType={partitionType}
            parentUUID={projectUUID}
            newVectorSuccessCallback={newVectorSuccessCallback}
            titleButton={(t(`Add ${typeOfVector}`))}
          />
          {!hideElements.includes('multiEdit') &&
                <BaseButton
                id="multiEditButton"
                size="small"
                className="ml-1 mr-1"
                onClick={toggleMultiEdit}
                type="primary"
                ghost
                disabled={disabledMultiEdit || isGlobalDisabled || !vectors.length}
              >
                {capitalize(t('wms.buttons.multi_edit'))}
              </BaseButton>
          }

        </div>
      </div>
      {!isMultiEdit ? (
            <div>
          <ListOfVectorsAntDesignTable
          data={hideVectors && vectors.length > 1 ? vectors.filter(vector => vector.uuid.includes(activeVector)) : vectors}
          totalCount={countOfData}
          limit={limit}
          selectedVector={activeVector}
          newCurrentPage={newCurrentPage}
          pageSizeOptions={pageSizeOptions}
          setLimit={setLimit}
          hideElements={hideElements}
          filterColumnsConfig={columnsFilterConfig}
          filterConfig={filterConfig}
          filterCallback={filterCallback}
          getVectorsRequestSetting={getVectorsRequestSetting}
          onChangeRowCallback={onChangeRowCallback}
          hideVectors={hideVectors}
          disablePagination={hideVectors ? hideVectors : false}
        />
        {(!hideElements.includes("hideVectors")) &&
            (activeVector &&
              <div style={{
                backgroundColor: "rgba(192, 229, 248, 0.4)",
                margin: "10px -13px -13px -13px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#096dd9"
              }} 
              onClick={() => setHideVectors(prev => !prev)}>
                 <DownOutlined  style={{color: "#096dd9", marginTop: "1px", marginRight: "3px"}} rotate={hideVectors ? 0 : 180} />
                 {hideVectors ? <p>Show vectors</p> : <p>Hide vectors</p>}
              </div>
            )
        }
            </div>
      ) : (
        <MultiEditVectorView
          currentPage={newCurrentPage}
          totalCount={countOfData}
          defaultData={vectors}
          pageLimit={limit}
          partitionType={partitionType}
          getIssuesRequestSetting={getVectorsRequestSetting}
          backEditCallback={backEditCallback}
        />
      )}
    </>
  );
}

export default ListOfVectorsForIssueView;

ListOfVectorsForIssueView.propTypes = {
  countOfData: PropTypes.number,
  getVectorsRequestSetting: PropTypes.func,
  hideElements: PropTypes.array,
  newVectorSuccessCallback: PropTypes.func,
  onChangeRowCallback: PropTypes.func,
  partitionType: PropTypes.string,
  typeOfVector: string,
  isGlobalDisabled: bool,
  newCurrentPage: number,
  getVectorsRequest: func,
  getVector: func,
  disabledMultiEdit: bool,
  filterConfig: object,
  filterCallback: func,
  hideVectors: bool,
  setHideVectors: func
};
