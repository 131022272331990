import { getListAndReadAffix } from '../../../affix/affixActions';
import axiosRequest from '../../../api/apiAxios';
import { partitionNamesConfig } from '../../../api/appConfig';
import {
  getListAndReadEntities,
  entityCreate, entityUpdate,
  getOrCreateSingle,
} from '../../../entity/actions/entityActions';
import { UsersPartitionConstants } from '../../constants/Constants';

const daylyEntityConstants = [
  UsersPartitionConstants.GET_DAILY_ENTITY_REQUEST,
  UsersPartitionConstants.GET_DAILY_ENTITY_SUCCESS,
  UsersPartitionConstants.GET_DAILY_ENTITY_FAILURE,
];

export const getPublicDailyStorage = (uuid, constants = [
  UsersPartitionConstants.GET_MAIN_PUBLIC_DAILY_REQUEST,
  UsersPartitionConstants.GET_MAIN_PUBLIC_DAILY_SUCCESS,
  UsersPartitionConstants.GET_MAIN_PUBLIC_DAILY_FAILURE,
]) => {
  const config = {
    data: {
      entity_type: 'publicDailyStorage',
      parent: uuid,
    },
    partition: partitionNamesConfig.partition3,
    constants,
  };
  return getOrCreateSingle(config);
};

export const getOrCreateDaily = (data, constants) => {
  const config = {
    data: {
      entity_type: 'daily',
      ...data,
    },
    partition: partitionNamesConfig.partition3,
    constants: constants || daylyEntityConstants,
  };

  return getOrCreateSingle(config);
};

export const updateDaily = (data, constants) => {
  const config = {
    data: {
      entity_type: 'daily',
      ...data,
    },
    options: { partition: partitionNamesConfig.partition3 },
    constants: constants || daylyEntityConstants,
  };

  return entityUpdate(config);
};

export const createDayLogRequest = (config) => entityCreate({
  data: { entity_type: 'dayLog', ...config },
  options: { partition: partitionNamesConfig.partition3 },
  constants: [
    UsersPartitionConstants.CREATE_DAY_LOG_ENTITY_REQUEST,
    UsersPartitionConstants.CREATE_DAY_LOG_ENTITY_SUCCESS,
    UsersPartitionConstants.CREATE_DAY_LOG_ENTITY_FAILURE,
  ],
});

export const getDayLogRequest = (data, constants) => {
  const config = {
    data: {
      entity_type: 'dayLog',
      ...data,
    },
    partition: partitionNamesConfig.partition3,
    constants: constants || [
      UsersPartitionConstants.GET_DAY_LOG_ENTITY_REQUEST,
      UsersPartitionConstants.GET_DAY_LOG_ENTITY_SUCCESS,
      UsersPartitionConstants.GET_DAY_LOG_ENTITY_FAILURE,
    ],
  };

  return getListAndReadEntities(config);
};

export const createProjectLogRequest = (data) => {
  const config = {
    affix_type: 'metaLog',
    ...data,
  };

  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'entity/affix/create',
        [
          'CREATE_PROJECT_LOG_REQUEST',
          'CREATE_PROJECT_LOG_SUCCESS',
          'CREATE_PROJECT_LOG_FAILURE',
        ],
        config,
        {
          partition: partitionNamesConfig.partition3,
          onSuccess: resolve,
          onFailure: reject,
        },
      ),
    );
  });
};

export const getProjectLogsRequest = (data) => {
  const config = {
    data: {
      ...data,
      // with_files: false,
    },
    partition: partitionNamesConfig.partition3,
    constants: [
      'GET_PROJECT_LOGS_REQUEST',
      'GET_PROJECT_LOGS_SUCCESS',
      'GET_PROJECT_LOGS_FAILURE',
    ],
  };

  return getListAndReadAffix(config);
};
