import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row, Col, Card, Tabs, Form, Input,
} from 'antd';
import QRCode from 'react-qr-code';
import BaseButton from './_ui/BaseButton/BaseButton';
import { antNotification } from '../MainUtils';
import { appLabel } from '../api/appConfig';
import BaseCard from "./_ui/BaseCard/BaseCard";

const { TabPane } = Tabs;

function StartPage() {
  const pwa_app_url = 'https://wms-pwa.wms54.com/install';

  const wms_classic_registration_text = `Инструкция по регистрации в системе\n
   1. Ссылка на приложение ${appLabel}: ${window.location.origin} \n
   2. Перейдите по ссылке, нажмите на кнопку "Sign up".\n
   3. Заполните и отправьте форму.`;
  const wms_ecosystem_registration_text = `Инструкция по установке Ecosystem54wms и регистрации в системе\n
  1. Ссылка на PWA-приложение: ${pwa_app_url}\n
  2. На Вашем мобильном устройстве: скопируйте ссылку, откройте
  браузер (Chrome для Android, Safari для Ios) и перейдите по
  скопированному адресу.\n
  3. Дождитесь установки приложения, закройте браузер и откройте
  установленное приложение.\n
  4. На странице "Accounts" нажмите на кнопку "Sign up \ Sign in",
  выберите пункт "Create an Ecosystem account".\n
  5. Откройте приложение ${appLabel} по адресу ${window.location.origin} на
  Вашем компьютере, нажмите кнопку "Sign up", выберите вкладку
  "QR code".\n
  6. Сканируйте мобильным приложением Ecosystem54wms QR-код и
  заполните форму, после нажмите на кнопку "Create an account".\n
  7.  Важно! Обязательно создайте backup-файл Вашего нового аккаунта
  в Ecosystem54wms: перейдите на страницу "Accounts", нажмите
  "Open dashboard", нажмите кнопку "Account backup" и заполните
  форму. `;

  const copyAndNotify = async (text) => {
    try {
      await navigator.clipboard.writeText(text || window.location.origin);

      antNotification('success', 'Copied');
    } catch (e) {
      console.log('e', e);
    }
  };

  return (
    <BaseCard className="h-full  borderR10 step-item-wrapper">
      <Row>
        <Col className="text-center">
          <h4>ДОБРО ПОЖАЛОВАТЬ В WMS54</h4>
          <p>
            Для того, чтобы начать работу в системе быстро и просто,
            придерживайтесь следующих рекомендаций
          </p>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col span={24}>
          <h6>01 Добавьте сотрудников в систему</h6>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Регистрация по логину и паролю" key="1">
              <p>
                Скопируйте инструкцию, пришлите информацию пользователям для
                регистрации в системе. Текст инструкции:
              </p>
              <BaseCard>
                <p style={{ textAlign: 'center' }}>
                  Инструкция по регистрации в системе
                </p>
                <ol>
                  <li>
                    Ссылка на приложение $
                    {appLabel}
                    :
                    {' '}
                    <Link to={window.location.origin}>
                      {window.location.origin}
                    </Link>
                  </li>
                  <li>Перейдите по ссылке, нажмите на кнопку "Sign up".</li>
                  <li>Заполните и отправьте форму.</li>
                </ol>
                <Row className="flex justify-center">
                  <BaseButton
                    size="small"
                    type="primary"
                    className="ml-2"
                    onClick={() => copyAndNotify(wms_classic_registration_text)}
                  >
                    Копировать текст инструкции
                  </BaseButton>
                </Row>
              </BaseCard>
            </TabPane>
            <TabPane tab="Регистрация по QR-коду" key="2">
              <p>
                Скопируйте инструкцию, пришлите информацию пользователям для
                установки PWA-приложения Ecosystem54wms и регистрации в системе.
                Текст инструкции:
              </p>
              <BaseCard>
                <p style={{ textAlign: 'center' }}>
                  Инструкция по установке Ecosystem54wms и регистрации в системе
                </p>
                <ol>
                  <li>
                    Ссылка на PWA-приложение:
                    {' '}
                    <Link to={pwa_app_url} target="_blank">
                      {pwa_app_url}
                    </Link>
                  </li>
                  <li>
                    На Вашем мобильном устройстве: скопируйте ссылку, откройте
                    браузер (Chrome для Android, Safari для Ios) и перейдите по
                    скопированному адресу.
                  </li>
                  <li>
                    Дождитесь установки приложения, закройте браузер и откройте
                    установленное приложение.
                  </li>
                  <li>
                    На странице "Accounts" нажмите на кнопку "Sign up \ Sign
                    in", выберите пункт "Create an Ecosystem account".
                  </li>
                  <li>
                    Откройте приложение $
                    {appLabel}
                    {' '}
                    по адресу
                    {' '}
                    {window.location.origin}
                    {' '}
                    на Вашем компьютере, нажмите кнопку "Sign up", выберите
                    вкладку "QR code".
                  </li>
                  <li>
                    Сканируйте мобильным приложением Ecosystem54wms QR-код и
                    заполните форму, после нажмите на кнопку "Create an
                    account".
                  </li>
                  <li>
                    Важно! Обязательно создайте backup-файл Вашего нового
                    аккаунта в Ecosystem54wms: перейдите на страницу "Accounts",
                    нажмите "Open dashboard", нажмите кнопку "Account backup" и
                    заполните форму.
                  </li>
                </ol>
                <Row className="flex justify-center">
                  <BaseButton
                    size="small"
                    type="primary"
                    className="ml-2"
                    onClick={() => copyAndNotify(wms_ecosystem_registration_text)}
                  >
                    Копировать текст инструкции
                  </BaseButton>
                </Row>
              </BaseCard>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Row className="mt-5">
        <h6>02 Назначьте права доступа в WMS54</h6>
        <p>
          После регистрации пользователей
          {' '}
          <Link to="/pm/permissions/pmpartition">
            назначьте им права доступа в WMS54.
          </Link>
        </p>
        <p>
          Подробнее о том, как и зачем назначать права в WMS54, Вы можете
          почитать
          {' '}
          <Link>здесь.</Link>
        </p>
      </Row>
      <Row className="mt-5">
        <Col span={24}>
          <h6>
            03 Запустите и адаптируйте рабочий цикл в ${appLabel} для себя и своей
            компании
          </h6>
          <Tabs defaultActiveKey="4">
            <TabPane tab="Проекты" key="1">
              <ol>
                <li>
                  Добавьте пользователей в проект, создайте векторы и задачи,
                  назначьте ответственных.
                </li>
                <li>Перенесите все общение и документы в систему.</li>
                <li>
                  Проверяйте результат выполненной задачи и оценивайте его прямо
                  в системе.
                </li>
                <li>
                  Отправляйте задачи на корректировку при неудовлетворительном
                  результате.
                </li>
                <li>Отслеживайте прогресс выполнения проектов.</li>
                <li>Отправляйте проекты в архив после их выполнения.</li>
              </ol>
            </TabPane>
            <TabPane tab="Команды" key="2">
              <ol>
                <li>Создайте группы пользователей - команды в ${appLabel}.</li>
                <li>
                  Занимайтесь эффективным планированием в отдельном пространстве
                  для команд, где каждый пользователь может принять участие.
                </li>
              </ol>
            </TabPane>
            <TabPane tab="Отдельные пользователи" key="3">
              <ol>
                <li>
                  Создавайте задачи пользователям как в проекте, так и вне
                  проектов.
                </li>
                <li>
                  Назначайте задачи с конкретными метриками отдельным
                  участникам.
                </li>
                <li>
                  Следите за процессом выполнения задач, закрепленных за
                  отдельными пользователями, и контролируйте их нагрузку.
                </li>
                <li>Оценивайте результаты задач, выполненных пользователем.</li>
              </ol>
            </TabPane>
            <TabPane tab="Коммуникация" key="4">
              <ol>
                <li>
                  PWA-приложение Ecosystem54wms &ndash; агрегатор сообщений и
                  уведомлений ${appLabel}, который ускорит взаимодействие и облегчит
                  общение пользователей в проектах.
                </li>
                <li>
                  Воспользуйтесь ссылкой либо QR-кодом для установки приложения
                  на мобильных устройствах:
                  <Row className="my-3">
                    <Col span={12} className="flex items-center">
                      <Link to={pwa_app_url} target="_blank">
                        {pwa_app_url}
                      </Link>
                      <BaseButton
                        size="small"
                        type="primary"
                        className="ml-2"
                        onClick={() => copyAndNotify(pwa_app_url)}
                      >
                        Copy
                      </BaseButton>
                    </Col>
                    <Col span={12} className="flex justify-center">
                      <QRCode value={pwa_app_url} size={144} />
                    </Col>
                  </Row>
                </li>
                <li>
                  Для коммуницирования через Ecosystem54wms будут доступны
                  пользователи, которые отметили ("тегнули") Вас при написании
                  комментария в любом месте проекта ${appLabel}.
                </li>
                <li>
                  Ecosystem54wms уведомит пользователей о новых сообщениях при
                  помощи push-уведомлений: отмечайте пользователей в
                  комментариях и назначайте им задачи.
                </li>
                <li>
                  Выберите свой список актуальных проектов (не более 10) в
                  Ecosystem54wms для фильтрации сообщений от пользователей.
                </li>
                <li>
                  Более подробно о возможностях приложения Вы можете почитать
                  {' '}
                  <Link to="/tools/pwaAppInstruction">здесь.</Link>
                </li>
              </ol>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </BaseCard>
  );
}

export default StartPage;
