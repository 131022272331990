import React, { useEffect, useState } from 'react';
import {
  any,
  bool, func, object, string,
} from 'prop-types';
import {
  Flex, Modal, Popover, Typography,
} from 'antd';
import { omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AssigningUsersComponent from './AssigningUsersComponent';
import EditButton from '../projectFlow/components/commonComponents/EditButton';
import { createEntityPermissions } from '../entity/actions/entityPermActions';
import { updateProjectRequest } from '../projectFlow/actions/projectFlowActions';
import { useResize } from '../hooks/useResize';
import useGetUserPerm from '../permissions/hooks/useGetUserPerm';
import { isAdminOrRoot } from '../entity/selectors/selectors';
import { defaultPopoverStyle, extraSmallPopoverStyle, largePopoverStyle, middlePopoverStyle, smallPopoverStyle } from './utils';
import { stopPropagation } from '../54origins/utils54origins';

export default function AssigningUsersRoot({
  wrapperProps = {},
  header = '',
  isOpen = false,
  globalOptions,
  setIsOpen,
  onUpdateUsers,
  tabs = {},
  needCheckUserPerm = true,
  isOneColumnMode,
  isGroupMode,
  isClosePopoverOnLeaveMode,
  data = {},
  usersConfig = {},
  isModal,
  size,
  isPopover,
  placement = 'left',
  popoverText = null,
  popoverChild,
  needUpdateProjectUsers,
  projectUuid,
  partition,
  isCheckWindowWidthMode = true,
}) {
  const dispatch = useDispatch();

  const adminOrRoot = useSelector(isAdminOrRoot);

  const { isScreenXxl, width } = useResize();

  const [filteredTabs, setFilteredTabs] = useState(tabs);
  const [containerStyles, setContainerStyles] = useState({});
  const [countOfActions, setCountOfActions] = useState(0);
  const [currentSize, setCurrentSize] = useState(size ?? globalOptions?.size);

  const { perm: myPerms } = useGetUserPerm({
    entityUUID: projectUuid,
    partitionType: partition,
  });

  const addAction = () => setCountOfActions((prev) => prev + 1);

  const onClose = () => {
    if (!countOfActions) {
      setIsOpen(false);
      return;
    }
    Modal.confirm({
      title: 'Warning!',
      content: `You have ${countOfActions} unsaved actions. Do you really want to exit without saving?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'No',
      cancelText: 'Yes',
      onOk: () => { },
      onCancel: () => {
        setIsOpen(false);
        setCountOfActions(0);
      },
    });
  };

  const updateProject = async (params) => dispatch(updateProjectRequest({
    uuid: projectUuid,
    params: { ...params },
    partition,
  }));

  const onChangeProjectUsers = (usersData) => {
    const {
      allUuids = [],
      allUsers = [],
      newPerms = [],
    } = usersData;

    if (newPerms.length > 0) {
      const data = {
        entity_uuid: projectUuid,
        data: [
          ...newPerms.map(({ uuid: userUuid, perms }) => ({
            actor: userUuid,
            ...perms,
          })),
        ],
      };

      dispatch(
        createEntityPermissions({
          data,
          partition,
        }),
      );
    }

    const defaultUuids = globalOptions?.uuids ?? [];
    const defaultRoles = globalOptions?.roles ?? [];

    const hasChanges = allUsers?.some(el => !defaultUuids?.includes(el?.uuid)
      || defaultRoles?.find(i => i?.uuid === el?.uuid)?.role !== el?.role);

    const params = { users: allUsers, usersSearch: allUuids };

    if (hasChanges) {
      updateProject(params);
    }
  };

  const onFinish = async (users) => {    
    if (needUpdateProjectUsers) {
      await onChangeProjectUsers(users);
    }
    if (onUpdateUsers) {
      onUpdateUsers(users);
    }
    setIsOpen(false);
    setCountOfActions(0);
  };

  useEffect(() => {
    let newTabs = { ...tabs };
    if (!myPerms.set && !adminOrRoot && needCheckUserPerm && !globalOptions?.isNewProjectMode) {
      newTabs = omit(newTabs, 'allConfig');
    }
    setFilteredTabs(newTabs);
  }, [
    JSON.stringify(tabs),
    JSON.stringify(myPerms),
    JSON.stringify(globalOptions),
    adminOrRoot,
  ]);

  const onInitPopoverStyle = () => {
    switch (currentSize) {
      case 'large': {
        setContainerStyles(largePopoverStyle);
        break;
      }
      case 'middle': {
        setContainerStyles(middlePopoverStyle);
        break;
      }
      case 'small': {
        setContainerStyles(smallPopoverStyle);
        break;
      }
      case 'extra small': {
        setContainerStyles(extraSmallPopoverStyle);
        break;
      }
      default: {
        setContainerStyles(defaultPopoverStyle);
        break;
      }
    }
  }

  useEffect(() => {
    if (isPopover) onInitPopoverStyle();
  }, [currentSize, isModal, isPopover]);

  useEffect(() => {
    if (!isCheckWindowWidthMode || isModal) return;
    if (width > 1650) {
      setCurrentSize('large')
    } else if (width > 1450) {
      setCurrentSize('middle')
    } else if (width > 1350) {
      setCurrentSize('small')
    }
  }, [width, isCheckWindowWidthMode, isModal])

  return (
    <>
      {isModal && (
        <Modal
          open={isOpen}
          onCancel={onClose}
          width={765}
          style={{
            ...containerStyles,
          }}
          transitionName=""
          destroyOnClose
          footer={null}
          {...wrapperProps}
        >
          <AssigningUsersComponent
            addAction={addAction}
            actions={countOfActions}
            onFinish={onFinish}
            headerText={header}
            hidePerms={(!myPerms.set && !adminOrRoot && !globalOptions.isNewProjectMode)}
            tabs={filteredTabs}
            globalOptions={globalOptions}
            onClose={onClose}
            isGroupMode={isGroupMode}
            usersConfig={usersConfig}
            projectUuid={projectUuid}
            partition={partition}
            disabledSave={countOfActions === 0}
          />
        </Modal>
      )}
      {isPopover && (
        <Popover
          trigger='click'
          onClick={stopPropagation}
          open={isOpen}
          overlayStyle={{
            overflow: 'scroll',
            overflowX: 'hidden',
            ...containerStyles,
          }}
          overlayInnerStyle={{
            border: '1px solid #ccc',
          }}
          onVisibleChange={(isOpen) => {
            if (isOpen) {
              return
            }
            setIsOpen(false)
          }}
          onOpenChange={() => {
            if (isClosePopoverOnLeaveMode && isOpen) {
              setIsOpen(false);
            }
          }}
          destroyTooltipOnHide
          // placement={isScreenXxl ? placement : 'right'}
          placement={'right'}
          content={isOpen && (
            <AssigningUsersComponent
              addAction={addAction}
              isGroupMode={isGroupMode}
              actions={countOfActions}
              onFinish={onFinish}
              headerText={header}
              hidePerms={!myPerms.set && !adminOrRoot && !globalOptions.isNewProjectMode}
              tabs={filteredTabs}
              globalOptions={{
                ...globalOptions,
                size: currentSize,
              }}
              onClose={onClose}
              usersConfig={usersConfig}
              projectUuid={projectUuid}
              partition={partition}
              disabledSave={countOfActions === 0}
            />
          )}
          {...wrapperProps}
        >
          {popoverChild || (
            <Flex>
              <Typography
                onClick={() => setIsOpen(true)}
                style={{ color: '#6b6b6b' }}
                className="mr-2"
              >
                {popoverText}
              </Typography>
              <EditButton
                id='editAssignedUsersButton'
                onClick={() => setIsOpen(true)}
              />
            </Flex>
          )}
        </Popover>
      )}
    </>
  );
}

AssigningUsersRoot.propTypes = {
  header: string,
  onUpdateUsers: func,
  isOpen: bool,
  setIsOpen: func,
  isPopover: bool,
  isModal: bool,
  needCheckUserPerm: bool,
  tabs: object,
  size: string,
  popoverChild: any,
  usersConfig: object,
  isOneColumnMode: bool,
  placement: string,
  popoverText: string,
  wrapperProps: object,
  data: object,
  needUpdateProjectUsers: bool,
  projectUuid: string,
  isGroupMode: bool,
  partition: string,
  globalOptions: object,
  isCheckWindowWidthMode: bool,
  isClosePopoverOnLeaveMode: bool,
};
