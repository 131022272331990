import {
  mdiContentSaveOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Empty, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { capitalize, get, isEmpty } from 'lodash';
import { prop } from 'ramda';
import React, {
  useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './style/TimeLog.scss';

import BaseButton from '../../components/_ui/BaseButton/BaseButton';
import InputTimeWork from '../../userFlow/components/StartWorkRecord/InputTimeWork';
import CommentList from './comment/CommentList';
import ShowMoreBtn from './comment/ShowMoreBtn';

import {
  antNotification, getMD5HashFromString, timeConvertForMoment,
} from '../../MainUtils';
import { createTimeLog, getAffixTimeLog } from '../actions/projectFlowActions';
// import { ProjectTypeCommonConstants } from '../constants/Constants';
import { getTimeLogsFetching, getTimelogsTotal, timeLogs } from '../selectors/selectors';
// import { getPublicDailyStorageUUID } from '../../userFlow/store/selectors/dailySectionSelectors';
// import { entityBackendURL } from '../../api/api';
// import { getOrCreateDaily } from '../../userFlow/store/action-creators/reportAndPlanningAction';
// import { affixDelete, affixUpdate } from '../../affix/affixActions';
// import { entityUpdate } from '../../entity/actions/entityActions';

import { dateFormat2 } from '../../54origins/dateFormats54origins';
import { partitionNamesConfig } from '../../api/appConfig';

const arrTime = [
  { type: 'normal', number: '15' },
  { type: 'normal', number: '30' },
  { type: 'normal', number: '45' },
  { type: 'normal', number: '60' },
  { type: 'normal', number: '2h' },
  { type: 'normal', number: '4h' },
  { type: 'normal', number: '8h' },
];

export default function TimeLogStartPage({
  inPanel = false,
  isPanelOpen = false,
  partitionType = partitionNamesConfig.partition1,
  parentUUID,
  parentType,
  targetData,
  currentDay,
}) {
  const { t } = useTranslation();
  const disabled = useRef(false);

  const dispatch = useDispatch();

  const firstLoad = useRef(true);

  const timeLogsData = useSelector(timeLogs);
  const timelogsFetching = useSelector(getTimeLogsFetching);
  const timelogsTotal = useSelector(getTimelogsTotal);
  // const publicDailyStorageUUID = useSelector(getPublicDailyStorageUUID);

  const defaultConfig = {
    entity_uuid: parentUUID,
    limit: 20,
    offset: 0,
  };

  const [currentConfig, setCurrentConfig] = useState(defaultConfig);
  const [newTime, setNewTime] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [resetInput, setResetInput] = useState(false);
  const [loading, setLoading] = useState(false);

  // const getDailyForDay = () => {
  //   const day = currentDay || dateFormat2();
  //   const config = {
  //     parent: publicDailyStorageUUID,
  //     params: {
  //       date: day,
  //     },
  //     key: getMD5HashFromString(`${publicDailyStorageUUID}${day}`),
  //   };
  //   const constants = [
  //     'GET_DAILY_REQUEST',
  //     'GET_DAILY_SUCCESS',
  //     'GET_DAILY_FAILURE',
  //   ];
  //
  //   if (entityBackendURL()) {
  //     return dispatch(getOrCreateDaily(config, constants));
  //   }
  //   return null;
  // };

  const timeIsNotZero = (time) => {
    const convertTme = timeConvertForMoment(time);
    const split = convertTme.split(':');

    return (split[0].length <= 2 && +split[0] !== 0) || (split[0].length <= 2 && +split[1] !== 0);
  };

  const saveNewTimeCallback = (data) => {
    if (data.isValid && timeIsNotZero(data.newTime)) {
      setNewTime(data.newTime);
    } else {
      setNewTime('');
    }
  };

  const onChangeTextarea = (e) => setTextareaValue(e.target.value);

  const getTimelogsRequestWrapper = async (config) => dispatch(
    getAffixTimeLog(config, partitionType),
  );

  const toggleShowMore = () => {
    setCurrentConfig((state) => ({ ...state, limit: +state.limit + defaultConfig.limit }));
  };

  const showBtn = (prop('limit', currentConfig) + prop('offset', currentConfig)) < timelogsTotal;

  const createTimeLogForIssue = async () => {
    const newParams = {
      type: parentType,
      title: get(targetData, 'params.title', ''),
      description: textareaValue,
      priority: get(targetData, 'params.priority', ''),
      status: get(targetData, 'params.status', ''),
      tracker: get(targetData, 'params.tracker', ''),
      trackerTime: newTime,
      date: currentDay || dateFormat2(),

      typeForReport: get(targetData, 'params.typeForReport', 'issue'),
      projectUUID: get(targetData, 'params.project', ''),
      entityUUID: get(targetData, 'uuid', ''),
      entityPartition: partitionType,
    };

    // const resDaily = await getDailyForDay();

    // const configForDailyTimeLog = {
    //   entityUUID: get(resDaily, 'uuid'),
    //   params: newParams,
    //   partition: partitionType,
    // };

    // const resDailyTimeLog = await dispatch(createTimeLog(configForDailyTimeLog));

    const configForEntityTimeLog = {
      entityUUID: parentUUID,
      params: {
        ...newParams,
        // affixLinkUUID: get(resDailyTimeLog, '[0].uuid'),
      },
      partition: partitionType,
    };

    const resEntityTimeLog = await dispatch(createTimeLog(configForEntityTimeLog));

    // const configForUpdate = {
    //   data: {
    //     affix_uuid: get(resDailyTimeLog, '[0].uuid'),
    //     affix_type: 'timeLog',
    //     params: {
    //       affixLinkUUID: get(resEntityTimeLog, '[0].uuid'),
    //     },
    //   },
    //   partition: 'PUBLIC',
    // };
    // dispatch(affixUpdate(configForUpdate));

    // if (!get(resDaily, 'params.isSubmittedDetailedRecord')) {
    //   const configForUpdateDaily = {
    //     data: {
    //       entity_uuid: get(resDaily, 'uuid'),
    //       entity_type: 'daily',
    //       params: {
    //         isSubmittedDetailedRecord: true,
    //       },
    //     },
    //     options: { partition: 'PUBLIC' },
    //   };
    //   return dispatch(entityUpdate(configForUpdateDaily));
    // }
  };

  const createNewtimelogWrapper = () => {
    if (parentType === 'issue') {
      return createTimeLogForIssue();
    }

    const { params, uuid, parent } = targetData || {};
    const {
      title,
      project,
    } = params || {};

    const newLogConfig = {
      entityUUID: parentUUID,
      params: {
        type: parentType,
        title,
        projectUUID: project || parentType === 'vector' ? parent : uuid,
        trackerTime: newTime,
        date: currentDay || dateFormat2(),
        description: textareaValue,
        uuid,
      },
      partition: partitionType,
    };

    return dispatch(createTimeLog(newLogConfig));
  };

  const submitData = async () => {
    if (!disabled.current) {
      disabled.current = true;
      try {
        setLoading(true);

        await createNewtimelogWrapper();
        antNotification('success', 'Timelog was created successfully');
        await getTimelogsRequestWrapper(currentConfig);

        setLoading(false);
      } catch {
        setLoading(false);
      }
      setResetInput(true);
    }
  };

  // const afterRemoveComment = async (item) => {
  //   if (item?.affix_type === 'timeLog' && get(item, 'params.affixLinkUUID')) {
  //     const configForDailyTimeLog = {
  //       data: {
  //         affix_type: 'timeLog',
  //         affix_uuid: get(item, 'params.affixLinkUUID'),
  //       },
  //       constants: ['DELETE_AFFIX_REQUEST', 'DELETE_AFFIX_SUCCESS', 'DELETE_AFFIX_FAILURE'],
  //       partition: 'PUBLIC',
  //     };
  //
  //     dispatch(affixDelete(configForDailyTimeLog))
  //       .catch(() => antNotification('error', 'Something went wrong, contact your administrator'));
  //   }
  //   getTimelogsRequestWrapper(currentConfig);
  // };

  useEffect(() => {
    getTimelogsRequestWrapper(currentConfig);
  }, [currentConfig]);

  useEffect(() => {
    if (parentUUID && parentUUID !== currentConfig.entity_uuid) {
      setCurrentConfig((prev) => ({ ...prev, entity_uuid: parentUUID }));
    }
  }, [parentUUID]);

  useEffect(() => {
    if (resetInput) {
      setResetInput(false);
      setNewTime('');
      setTextareaValue('');
      disabled.current = false;
    }
  }, [resetInput]);

  useEffect(() => {
    if (!firstLoad.current && inPanel && isPanelOpen) {
      getTimelogsRequestWrapper(currentConfig);
    }
    return () => {
      if (firstLoad.current) {
        firstLoad.current = false;
      }
    };
  }, [isPanelOpen]);

  // ???
  // useEffect(() => () => dispatch({ type: ProjectTypeCommonConstants.CLEAR_LIST_TIME_LOG }), []);

  return (
    <div className={cn({ 'h-full flex flex-col': inPanel })}>
      <div className="wrapper_log mb-1">
        <div className="ml-1">
          <TextArea
            value={textareaValue}
            onChange={onChangeTextarea}
          />
        </div>

        <div style={{ gap: '10px' }} className="ml-1 mt-1 flex flex-wrap items-center justify-between">
          <InputTimeWork
            inPanel={inPanel}
            saveNewTimeCallback={saveNewTimeCallback}
            defaultValueInput="00h00m"
            currentValueInput="00h00m"
            customUpdate={resetInput}
            arrTime={arrTime}
          />

          <div className={cn('button_submitted', { 'w-full': inPanel })}>
            <BaseButton
              className="ml-1 mr-1 px-2"
              onClick={submitData}
              size="small"
              type="primary"
              disabled={!newTime}
            >
              <Icon
                size={0.7}
                className="mr-1"
                path={mdiContentSaveOutline}
              />
              {capitalize(t('wms.buttons.submit', 'submit'))}
            </BaseButton>
          </div>
        </div>
      </div>

      <div className={cn('wrapper_log', { 'overflow-auto flex-auto': inPanel })}>
        {!isEmpty(timeLogsData) ? (
          <Spin spinning={timelogsFetching || loading}>
            <CommentList
              entityType="timelog"
              listData={timeLogsData}
              // afterRemoveComment={afterRemoveComment}
              partitionType={partitionType}
            />
          </Spin>
        )
          : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

        <ShowMoreBtn
          show={showBtn}
          toggleShowMoreCallback={toggleShowMore}
        />
      </div>
    </div>
  );
}

TimeLogStartPage.propTypes = {
  inPanel: PropTypes.bool,
  isPanelOpen: PropTypes.bool,
  partitionType: PropTypes.string,
  parentUUID: PropTypes.string,
  parentType: PropTypes.string,
  targetData: PropTypes.object,
  currentDay: PropTypes.string,
};
