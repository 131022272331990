import React, { useEffect, useState } from 'react';
import { arrayOf, string, number, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get, uniq, isEqual, isEmpty } from 'lodash';

import {
  Flex,
} from 'antd';

import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import CreateProjectGoalBtn from '../docView&ideas/CreateProjectGoalBtn';
import CustomAvatar from "../../../components/CustomAvatar/CustomAvatar";
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import GoalsPriorityBtns from "./GoalsPriorityBtns";
import HardDeleteEntityContainer from '../../../entity/containers/HardDeleteEntityContainer';
import WrapperAvatarsGroupForProject from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';

import useActorsW54 from '../../../actors/hooks/useActorsW54';
import useMouseMiddleRedirect from '../../../hooks/useMouseMiddleRedirect';
import { useResize } from '../../../hooks/useResize';

import { getProjectUUID } from '../../selectors/selectors';
import { getProjectGoals, getProjectGoalsFetching, getTotalGoals } from '../../reducers/slicers/projectGoalsSlicer';

import {
  capitalizeAndTranslateMsg,
  dateWithTooltipTime,
  renderTableID,
} from '../../../MainUtils';
import { getNameForActor } from '../../../54origins/utils54origins';



function ListOfGoals({
  className,
  partition,
  totalCount,
  hideElements = [],
  selectedGoal,
  defaultOrderConfig,
  defaultPaginationConfig,
  filterColumnsConfig,
  filterConfig,
  sizePerPageList = [10, 25, 50, 100],
  getConfigAndMakeRequest,
  priorityBtnCallback,
  createProjectGoalCallback,
  deleteEntityCallback,
  filterCallback,
  selectRowCallback,
}) {

  const { t } = useTranslation();

  const { width } = useResize();
  const { onMouseMiddleClick } = useMouseMiddleRedirect();

  const projectUUID = useSelector(getProjectUUID);
  const projectGoals = useSelector(getProjectGoals);
  const projectGoalsFetching = useSelector(getProjectGoalsFetching);
  const totalGoals = useSelector(getTotalGoals);

  // Получение goal пользователей
  const userUUIDs = projectGoals.flatMap((item) => item?.responsible);
  useActorsW54({ actors: uniq(userUUIDs) });


  const [config, setConfig] = useState({
    ...defaultOrderConfig,
    ...defaultPaginationConfig,
    ...filterConfig,
    search: '',
  });
  const [isConfigChanged, setIsConfigChanged] = useState(true);

  const changeConfig = (newConfig) => {
    const updatedConfig = {
      ...config,
      ...newConfig,
    }

    if (!isEqual(config, updatedConfig)) {
      setConfig(updatedConfig);
      setIsConfigChanged(true);
    }
  }

  const {
    currentPage,
    limit,
  } = config || {};

  const rowSelection = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectColumn: true,
    style: { background: '#e5f4fc' },
    selected: [selectedGoal],
  };

  const currentSortCallback = (dataSort) => {
    // const orderBy = get(dataSort, 'orderBy');
    const order = get(dataSort, 'order');

    if (!order) changeConfig(defaultOrderConfig);
    else changeConfig(dataSort);
  };

  const changePageLimit = (size) => {
    changeConfig({ currentPage: 1, limit: size, offset: 0 });
  };

  const onSearchData = (value) => {
    changeConfig({
      currentPage: 1,
      offset: 0,
      limit,
      search: value,
    });
  };

  const rowClassName = (row) => `universal_table_row ${get(row, 'uuid') === selectedGoal ? 'selected' : ''}`;

  const onRow = (record) => ({
    onClick: () => setClickGoal(record),
    onMouseDown: (e) => {
      const searchParams = { activeProject: projectUUID, goal: record?.uuid };
      onMouseMiddleClick(e, searchParams);
    },
  });

  const userFormatter = (cell, row) => {
    const fullName = getNameForActor(row);

    if (!cell) return null;

    return (
      <div className="flex flex-wrap items-center">
        <CustomAvatar
          fullName={fullName}
        />
      </div>
    );
  };

  const setClickGoal = (row) => {
    const { uuid } = row;
    selectRowCallback(uuid);
  };

  const actionFormatter = (cell, row) => cell !== 'goalsScope' && (
    <Flex align={'center'} justify={'center'} gap={4}>
      <GoalsPriorityBtns
        size={'small'}
        goalUUID={cell}
        defaultPartition={partition}
        onClickCallback={priorityBtnCallback}
        currentPage={currentPage}
      />
      <div
        role="button"
        tabIndex={0}
        onClick={((e) => e.stopPropagation())}
      >
        <HardDeleteEntityContainer
          sizeBtn="small"
          name="goal"
          entityUUID={cell}
          entityType="goal"
          type="entity"
          actionForEntity={() => deleteEntityCallback?.()}
          checkHardDelete={false}
          hideLabel
          hideTooltip
          partitionType={partition}
        />
      </div>
    </Flex>
  );

  const columns = [
    {
      dataIndex: 'id',
      title: 'ID',
      key: 'id',
      sorter: true,
      render: (cell) => cell && renderTableID(cell, 'G'),
    },
    {
      dataIndex: 'title',
      sorter: true,
      key: 'title',
      title: <AntDesignSearchBox54origins onSearch={onSearchData} />,
      render: (cell) => cell && (
        <EntityParamTag
          param="title"
          value={cell}
          type="value"
          maxLength={width > 1500 ? 0 : 45}
        />
      ),
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: capitalizeAndTranslateMsg('wms.table.headers.status', 'status'),
      // sorter: (a, b, c) => setSortSettings({ sortValue: c, sortType: 'entity_type' }),
      sorter: true,
      ...(filterColumnsConfig && { ...filterColumnsConfig?.status }),
      ...(filterConfig?.status && { defaultFilteredValue: filterConfig?.status }),
      render: (cell) => cell && (
        <EntityParamTag
          value={cell}
          param="status"
          type={width < 1500 ? 'icon' : 'iconWithStatus'}
        />
      ),
    },
    {
      dataIndex: 'uinfo',
      key: 'owner',
      // key: 'uinfo',
      title: capitalizeAndTranslateMsg('wms.table.headers.owner', 'owner'),
      render: userFormatter,
    },
    {
      dataIndex: 'responsible',
      key: 'responsible',
      title: capitalizeAndTranslateMsg('wms.adjective.responsible', 'responsible'),
      // sorter: true,
      render: (cell) => cell?.length > 0 && (
        <WrapperAvatarsGroupForProject
          actors={cell}
          avatarSize="small"
          avatarGap={4}
          avatarStyles={{ cursor: 'pointer' }}
          groupButtonSize="small"
          checkProjectRole
        />
      ),
    },
    {
      dataIndex: 'modified',
      title: capitalizeAndTranslateMsg('wms.table.headers.modified', 'modified'),
      key: 'modified',
      sorter: true,
      hidden: width < 1400,
      render: (cell) => cell && dateWithTooltipTime(cell, 'DD-MM-YY'),
    },
    {
      dataIndex: 'uuid',
      hidden: hideElements.includes('deleteGoal'),
      title: capitalizeAndTranslateMsg('wms.table.headers.actions', 'actions'),
      render: actionFormatter,
    },
  ].filter((el) => !el.hidden);

  const finalData = projectGoals.length ? [{
    actor_type: "",
    modified: "",
    status: "",
    title: "PROJECT GOALS SCOPE",
    // uinfo: {first_name: 'r_admin', groups: Array(2), internal_user: true, last_name: 'gpt'},
    uuid: "goalsScope",
  }, ...projectGoals] : [];

  useEffect(() => {
    // console.log('effect 1 goal');
    if (!isEmpty(filterConfig)) {
      changeConfig({
        ...filterConfig,
        currentPage: 1,
        offset: 0,
        limit,
      });
    }
  }, [filterConfig]);

  useEffect(() => {
    if (isConfigChanged) {
      // console.log('effect 2 goal');
      getConfigAndMakeRequest?.(config);
      setIsConfigChanged(false);
    }
  }, [isConfigChanged]);

  return (
    <BaseCard className={`primaryCard ${className}`}>
      <Flex align="center" justify="space-between">
        <Flex align="center" gap={8}>
          <h6>
            {`${capitalizeAndTranslateMsg('wms.noun.project.3', 'Project')} ${t('wms.noun.goal.1', 'goals')}`}
          </h6>
          {!hideElements.includes('createGoal') && (
            <CreateProjectGoalBtn
              partition={partition}
              createProjectGoalCallback={createProjectGoalCallback}
            />
          )}
        </Flex>
      </Flex>
      <div className="collapsedTable-table">
        <BaseTableWithPagination
          headerRowClassName="universal_header_table_row"
          rowClassName={rowClassName}
          rowSelection={rowSelection}
          columns={columns}
          data={finalData}
          total={totalGoals || totalCount}
          loading={projectGoalsFetching}
          useCustomPagination
          paginationSize="small"
          newCurrentPage={currentPage}
          pageSizeOptions={sizePerPageList}
          pageLimit={limit}
          // disablePagination={(totalGoals || totalCount) <= defaultPageLimit}
          onRow={onRow}
          changePageLimit={changePageLimit}
          getPaginationOptions={changeConfig}
          currentSortCallback={currentSortCallback}
          filterCallback={filterCallback}
        />
      </div>
    </BaseCard>
  );
}

ListOfGoals.propTypes = {
  className: string,
  partition: string,
  hideElements: arrayOf(string),
  totalCount: number,
  selectedGoal: string,
  defaultOrderConfig: object,
  defaultPaginationConfig: object,
  filterColumnsConfig: object,
  filterConfig: object,
  sizePerPageList: arrayOf(number),
  getConfigAndMakeRequest: func,
  createProjectGoalCallback: func,
  deleteEntityCallback: func,
  filterCallback: func,
  selectRowCallback: func,
};

export default ListOfGoals;
