import { typeOfTestingOptions, cycleStatusTags } from "../../../../constants/allFilterOptions";

const typeOfTestingColumnFilter = {
    filters: typeOfTestingOptions,
    filterMode: 'tree',
    filterType: 'badge',
}

const cycleStatusColumnFilter = {
    filters: cycleStatusTags,
    filterMode: 'tree',
    filterType: 'badge',
}

export const suiteColumnsFilterConfig = {
    filters: typeOfTestingColumnFilter,
    filterMode: 'tree',
    filterType: 'iconWithStatus',
}

export const defaultSuitesFilter = {
    typeOfTesting: [],
    assignToUser: [],
}

export const defaultCyclesFilter = {
    status: ["unexecuted", "pass", "fail", "in progress", "block"],
    assignToUser: []
}

export const defaultChecklistsFilter = {
    priority: undefined,
    status: [],
}

export const defaultCasesFilter = {
    typeOfTesting: [],
    status: [],
    assignToUser: [],
}

export const columnsFilterConfig = {
    typeOfTesting: typeOfTestingColumnFilter,
}

export const cycleStatusFilterConfig = {
    status: cycleStatusColumnFilter
}