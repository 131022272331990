import { mdiAlertCircleOutline, mdiCheckCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Alert, Spin } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import StatElem from './StatElem';
import IssuesAndActivitiesTables from './IssuesAndActivitiesTables';

import { capitalize } from 'lodash';
import { getUserPublicProfileUUID } from '../../../store/selectors/selectors';
import { convertTimeFromMomentToHHhmmm, parseTimeFromMinutesToHHhmmm } from '../../../tools/dailyTools/dailyTools';
import { getTodayDaily, getTodayDailyFetching } from '../../../store/selectors/dailySectionSelectors';

const label = {
  success: 'What are you going to work with today:',
  info: '',
  error: 'Your daily plan is not completed. Please fill in the daily plan for more productive work',
};

const icon = {
  success: <Icon size={1} path={mdiCheckCircleOutline} color="#52c41a" />,
  info: <Icon size={1} path={mdiAlertCircleOutline} color="#1677ff" />,
  error: <Icon size={1} path={mdiAlertCircleOutline} color="#ff4d4f" />,
};

export default function DailyReportAudit() {
  const { t } = useTranslation();

  const myPublicEntityUUID = useSelector(getUserPublicProfileUUID);
  const dailyEntity = useSelector(getTodayDaily);
  const dailyFetching = useSelector(getTodayDailyFetching);

  const [type, setType] = useState('info');

  const {
    startWork = '',
    finishWork = '',
  } = get(dailyEntity, 'params') || {};

  const formatStartAndFinishWork = (cell) => {
    if (Number.isFinite(cell)) return parseTimeFromMinutesToHHhmmm(cell) || '-';

    return convertTimeFromMomentToHHhmmm(cell) || '-';
  };

  const statsOptions = useMemo(() => ([
    {
      label: 'start_work',
      value: formatStartAndFinishWork(startWork),
    },
    {
      label: 'finish_work',
      value: formatStartAndFinishWork(finishWork) === '24h00m'
        ? '00h00m'
        : formatStartAndFinishWork(finishWork),
    },
  ]), [startWork, finishWork]);

  const description = (
    <Spin spinning={dailyFetching}>
      {type === 'success' && (
        <div className="w-full flex mb-3 ml-1">
          <h7>How much are you going to work:</h7>
          <div className="flex ml-3">
            {statsOptions.map((el) => (
              <div className="flex mr-3 ">
                <StatElem
                  title={`${t(`wms.reports.stats.${el.label}`, el.label)}:`}
                  data={el.value}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <h7 className="ml-1">{label[type]}</h7>

      {type === 'success' && (
        <IssuesAndActivitiesTables
          currentDayData={dailyEntity}
        />
      )}
    </Spin>
  );

  const initFunc = () => {
    if (isEmpty(dailyEntity)
      || (isEmpty(get(dailyEntity, 'params.activityDailyPlan', []))
        || isEmpty(get(dailyEntity, 'params.activityDailyPlan', [])))
    ) {
      return setType('error');
    }

    return setType('success');
  };

  useEffect(() => {
    initFunc();
  }, [dailyEntity]);

  return (
    <div className="my-3">
      <Alert
        description={description}
        type={type}
        message={(
          <div className="flex justify-between">
            <div className="flex">
              {capitalize('daily plan')}
              <span className="inline-flex ml-2">
                {icon[type]}
              </span>
            </div>
            <Link to={`/public/user/${myPublicEntityUUID}/dailyplan&report`}>
              <BaseButton
                size="small"
                className="btnPrimary-outline ml-1"
              >
                Go to daily plan
              </BaseButton>
            </Link>
          </div>
        )}
      />
    </div>
  );
}
