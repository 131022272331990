import {
  head, isNil, prop, propOr,
} from 'ramda';
import { get, isArray } from 'lodash';

import { EntityConstants } from '../../../entity/constants/Constants';
import { axiosRequestPost } from '../../../api/apiAxios';
import { UsersPartitionConstants } from '../../constants/Constants';
import {
  getListAndReadEntities, entityUpdate, getOrCreateSingle, entityRead,
} from '../../../entity/actions/entityActions';
import { getArrayForItem } from '../../../MainUtils';
import { ProjectTypeCommonConstants } from '../../../projectFlow/constants/Constants';
import { setFetchingForActorList } from '../../../projectFlow/actions/projectFlowActions';
import { actorIsRootOrAdmin } from '../../../54origins/utils54origins';
import { partitionNamesConfig } from '../../../api/appConfig';

export const clearDailyPlan = { type: UsersPartitionConstants.CLEAR_DAILY_ENTITY };
export const clearDailyReport = { type: UsersPartitionConstants.CLEAR_DAILY_REPORT_ENTITY };

export function synchronizeWithAuthAndGetActor(actorUUID) {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(axiosRequestPost(
      'utility/actor/synchronize',
      [
        'FETCH_UTILITY_ACTOR_SYNCHRONIZE_REQUEST',
        'FETCH_UTILITY_ACTOR_SYNCHRONIZE_SUCCESS',
        'FETCH_UTILITY_ACTOR_SYNCHRONIZE_FAILURE',
      ],
      {
        uuid: actorUUID,
      },
      {
        onSuccess: resolve,
        onFailure: reject,
      },
    ));
  });
}

export function getListOfActorsForAssign({
  data = {},
  constants = null,
}) {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(axiosRequestPost(
      'utility/actor/get',
      constants || [
        EntityConstants.FETCH_ACTORS_FOR_ASSIGN_REQUEST,
        {
          type: EntityConstants.FETCH_ACTORS_FOR_ASSIGN_SUCCESS,
          payload: (res) => {
            resolve(res);
            return res;
          },
        },
        EntityConstants.FETCH_ACTORS_FOR_ASSIGN_FAILURE,
      ],
      data,
      {
        onFailure: reject,
      },
    ));
  });
}

// export function getListOfAllActors(
//   param = {},
//   banedUsersFlag,
// ) {
//   const data = {
//     not_banned: !isNil(banedUsersFlag) ? banedUsersFlag : true,
//     ...param,
//   };

//   return (dispatch) => new Promise((resolve, reject) => {
//     dispatch(axiosRequestPost(
//       'utility/actor/list',
//       [
//         EntityConstants.FETCH_ACTORS_REQUEST,
//         {
//           type: EntityConstants[!isNil(banedUsersFlag)
//             ? 'FETCH_ACTORS_AND_BANED_USERS_SUCCESS'
//             : 'FETCH_ACTORS_SUCCESS'],
//           payload: (res) => {
//             resolve(res);
//             return propOr(res, 'data', res);
//           },
//         },
//         EntityConstants.FETCH_ACTORS_FAILURE,
//       ],
//       data,
//       {
//         onFailure: reject,
//       },
//     ));
//   });
// }

export function createActorOnEntity(data) {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(axiosRequestPost(
      'utility/actor/create',
      [
        'ACTOR_CREATE_ON_AUTH_REQUEST',
        'ACTOR_CREATE_ON_AUTH_SUCCESS',
        'ACTOR_CREATE_ON_AUTH_FAILURE',
      ],
      data,
      {
        onSuccess: resolve,
        onFailure: reject,
      },
    ));
  });
}

export function resetWMSSessions() {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequestPost(
        '/utility/actor/session/delete',
        [
          'RESET_WMS_SESSIONS_REQUEST',
          'RESET_WMS_SESSIONS_SUCCESS',
          'RESET_WMS_SESSIONS_FAILURE',
        ],
        {},
        {
          onSuccess: resolve,
          onFailure: reject,
        },
      ),
    );
  });
}

export function removeActorFromWMS(uuid, hardDelete) {
  const params = { uuid };

  if (hardDelete) {
    params.hard_delete = true;
  }
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequestPost(
        'utility/actor/delete',
        [
          'DELETE_ENTITY_ACTOR_REQUEST',
          'DELETE_ENTITY_ACTOR_SUCCESS',
          'DELETE_ENTITY_ACTOR_FAILURE',
        ],
        params,
        {
          onSuccess: resolve,
          onFailure: reject,
        },
      ),
    );
  });
}

export function utilityActorGetRequest({
  data,
  types,
}) {
  return (dispatch) => new Promise(() => {
    dispatch(axiosRequestPost(
      'utility/actor/get',
      types,
      data,
    ));
  });
}

export function getUsersByUUID({
  uuid,
  userType = '',
  isGroups,
}) {
  const checkType = () => {
    switch (userType) {
      case 'default':
        return EntityConstants.FETCH_ACTORS_SUCCESS;
      case 'project':
        return EntityConstants.FETCH_PROJECT_ACTORS_SUCCESS;
      case 'public':
        return EntityConstants.FETCH_PUBLIC_ACTORS_SUCCESS;
      case 'temporaryActors':
        return EntityConstants.FETCH_TEMPORARY_ACTORS_SUCCESS;
      case 'other':
        return '';
      default:
        return EntityConstants.FETCH_ACTORS_SUCCESS;
    }
  };

  const data = {};

  const filteredUuid = isArray(uuid) ? uuid.filter((el) => !isNil(el)) : uuid;
  // if need get users from groups, use flag isGroups
  if (isGroups) {
    data.group_uuids = filteredUuid;
  } else {
    data.uuids = getArrayForItem(filteredUuid);
  }

  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(axiosRequestPost(
      'utility/actor/get',
      [
        EntityConstants.FETCH_ACTORS_REQUEST,
        {
          type: checkType(),
          payload: (response) => {
            const actors = response?.data || [];

            resolve(actors);

            return actors;
          },
        },
        {
          type: EntityConstants.FETCH_ACTORS_FAILURE,
          payload: (error) => {
            reject(error.response ? error.response.data : {});

            return error.response ? error.response.data : {};
          },
        },
      ],
      data,
    ));
  });
}

export const getGroupsForUser = (uuid) => {
  const params = {
    uuids: uuid,
  };

  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(axiosRequestPost(
      'utility/actor/get',
      [
        ProjectTypeCommonConstants.GET_ACTORS_NAME_REQUEST,
        {
          type: ProjectTypeCommonConstants.GET_ACTORS_NAME_SUCCESS,
          payload: (response) => {
            resolve(response);
            return response.actors;
          },
        },
        ProjectTypeCommonConstants.GET_ACTORS_NAME_FAILURE,
      ],
      params,
      {
        // partition: partitionNamesConfig.partition3,
        onFailure: reject,
      },
    ));
  });
};

export const getMyInfo = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(axiosRequestPost(
    'utility/actor/me',
    [
      EntityConstants.FETCH_USER_INFO_REQUEST,
      EntityConstants.FETCH_USER_INFO_SUCCESS,
      EntityConstants.FETCH_USER_INFO_FAILURE,
    ],
    {},
    {
      partition: partitionNamesConfig.partition1,
      onSuccess: resolve,
      onFailure: reject,
    },
  ));
});

export const getMyInfoAndListOfActors = (banedUsersFlag) => (dispatch) => {
  dispatch(getMyInfo())
    .then((res) => {
      const { uinfo } = res;
      // if (actorIsRootOrAdmin(res)) {
      //   dispatch(getListOfAllActors({
      //     // actor_type: ['user', 'classic_user'],
      //   }, banedUsersFlag));
      //   return;
      // }

      const userGroups = propOr([], 'groups', uinfo);

      if (userGroups.length !== 0) {
        // console.log('userGroups', userGroups);
        // dispatch(getUsersByUUID({
        //   uuid: userGroups,
        //   userType: 'default',
        //   isGroups: true,
        // }));

        dispatch(getGroupsForUser(userGroups));
        return;
      }

      dispatch(setFetchingForActorList(false));
    });
};

export function getMainPublicProject(meUUID) {
  const config = {
    data: {
      entity_type: 'publicProjectsStorage',
      parent: meUUID,
    },
    partition: partitionNamesConfig.partition3,
    constants: [
      'GET_MAIN_PUBLIC_PROJECT_REQUEST',
      UsersPartitionConstants.GET_MAIN_PUBLIC_PROJECT_SUCCESS,
      'GET_MAIN_PUBLIC_PROJECT_FAILURE',
    ],
  };

  return getOrCreateSingle(config);
}

export function getUserCookBookTemplateEntity(meUUID) {
  // console.log('getUserCookBookTemplateEntity', meUUID)
  const config = {
    data: {
      entity_type: 'cookBookTemplateEntity',
      parent: meUUID,
    },
    partition: partitionNamesConfig.partition3,
    constants: [
      UsersPartitionConstants.GET_COOK_BOOK_ENTITY_PUBLIC_REQUEST,
      UsersPartitionConstants.GET_COOK_BOOK_ENTITY_PUBLIC_SUCCESS,
      UsersPartitionConstants.GET_COOK_BOOK_ENTITY_PUBLIC_FAILURE,
    ],
  };

  return getOrCreateSingle(config);
}

export function getPublicSettings(uuid) {
  const config = {
    data: {
      entity_type: 'personalPublicSettings',
      parent: uuid,
    },
    partition: partitionNamesConfig.partition3,
    constants: [
      UsersPartitionConstants.GET_PUBLIC_PERSONAL_SETTINGS_REQUEST,
      UsersPartitionConstants.GET_PUBLIC_PERSONAL_SETTINGS_SUCCESS,
      UsersPartitionConstants.GET_PUBLIC_PERSONAL_SETTINGS_FAILURE,
    ],
  };

  return getListAndReadEntities(config);
}

export const getPrivateUserProfile = () => (dispatch) => new Promise((resolve) => {
  dispatch(
    axiosRequestPost(
      'utility/actor/user',
      [
        UsersPartitionConstants.GET_PRIVATE_USER_PROFILE_REQUEST,
        {
          type: UsersPartitionConstants.GET_PRIVATE_USER_PROFILE_SUCCESS,
          payload: (response) => {
            const uuid = get(response, 'entity_uuid', '');
            resolve(uuid);
            return uuid;
          },
        },
        UsersPartitionConstants.GET_PRIVATE_USER_PROFILE_FAILURE,
      ],
      {},
      {
        partition: partitionNamesConfig.partition2,
        doNotCheckError: true,
      },
    ),
  );
});

export const createOrUpdatePrivateSettingsChildren = (params, type) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(
    axiosRequestPost(
      `entity/${type}`,
      [
        UsersPartitionConstants.CREATE_OR_UPDATE_CHILD_IN_PRIVATE_SETTINGS_REQUEST,
        {
          type: UsersPartitionConstants.CREATE_OR_UPDATE_CHILD_IN_PRIVATE_SETTINGS_SUCCESS,
          payload: (response) => ({ [params.entity_type]: head(response) }),
        },
        UsersPartitionConstants.CREATE_OR_UPDATE_CHILD_IN_PRIVATE_SETTINGS_FAILURE,
      ],
      params,
      {
        partition: partitionNamesConfig.partition2,
        onSuccess: resolve,
        onFailure: reject,
      },
    ),
  );
});

export const getPrivateSettingsChildren = (privateSettingsUUID, nameEntity) => {
  const config = {
    data: {
      entity_type: nameEntity,
      parent: privateSettingsUUID,
    },
    partition: partitionNamesConfig.partition2,
    constants: [
      UsersPartitionConstants.GET_CHILD_FROM_PRIVATE_SETTINGS_REQUEST,
      '',
      UsersPartitionConstants.GET_CHILD_FROM_PRIVATE_SETTINGS_FAILURE,
    ],
  };

  return (dispatch) => dispatch(getListAndReadEntities(config)).then((res) => {
    const response = prop('data', res);
    if (response.length === 0) {
      const params = {
        entity_type: nameEntity,
        params: { projects: [], publicProjects: [] },
        parent: privateSettingsUUID,
      };
      return dispatch(createOrUpdatePrivateSettingsChildren(params, 'create'));
    }

    if (
      isNil(get(response, '[0]params.projects'))
      && nameEntity === 'favoriteSettings'
    ) {
      const params = {
        entity_type: 'favoriteSettings',
        params: {
          ...prop('params', head(response)),
          projects: [],
          publicProjects: [],
          testSuites: [],
        },
        entity_uuid: prop('uuid', head(response)),
      };
      dispatch(createOrUpdatePrivateSettingsChildren(params, 'update'));
      return Promise.resolve({ ...prop('params', head(response)), projects: [] });
    }

    dispatch({
      type: UsersPartitionConstants.GET_CHILD_FROM_PRIVATE_SETTINGS_SUCCESS,
      payload: { [nameEntity]: head(response) },
    });
    return Promise.resolve(head(response));
  });
};

export const getPrivateSettingsRequest = (uuid) => {
  const config = {
    data: {
      entity_type: 'personalPrivateSettings',
      parent: uuid,
    },
    partition: partitionNamesConfig.partition2,
    constants: [
      UsersPartitionConstants.GET_PRIVATE_SETTINGS_REQUEST,
      UsersPartitionConstants.GET_PRIVATE_SETTINGS_SUCCESS,
      UsersPartitionConstants.GET_PRIVATE_SETTINGS_FAILURE,
    ],
  };
  return (dispatch) => dispatch(getOrCreateSingle(config));
};

export const updatePrivateSettingsRequest = (uuid, params) => {
  const config = {
    data: {
      entity_type: 'personalPrivateSettings',
      entity_uuid: uuid,
      params,
    },
    constants: [
      'UPDATE_PRIVATE_SETTINGS_REQUEST',
      'UPDATE_PRIVATE_SETTINGS_SUCCESS',
      'UPDATE_PRIVATE_SETTINGS_FAILURE',
    ],
    options: {
      partition: partitionNamesConfig.partition2,
    },
  };
  return (dispatch) => dispatch(entityUpdate(config));
};

export const getListBookMark = (params = {}, partition = partitionNamesConfig.partition1) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(
    axiosRequestPost(
      'bookmark/list',
      [
        UsersPartitionConstants.GET_LIST_BOOKMARK_REQUEST,
        UsersPartitionConstants.GET_LIST_BOOKMARK_SUCCESS,
        UsersPartitionConstants.GET_LIST_BOOKMARK_FAILURE,
      ],
      params,
      {
        partition,
        onSuccess: resolve,
        onFailure: reject,
      },
    ),
  );
});

export const deleteBookMark = (params, partition = partitionNamesConfig.partition1) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(
    axiosRequestPost(
      'bookmark/delete',
      [
        'DELETE_BOOKMARK_REQUEST',
        'DELETE_BOOKMARK_SUCCESS',
        'DELETE_BOOKMARK_FAILURE',
      ],
      params,
      {
        partition,
        onSuccess: resolve,
        onFailure: reject,
      },
    ),
  );
});

export const getMyPublicEntity = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(
    axiosRequestPost(
      'utility/actor/public',
      [
        UsersPartitionConstants.GET_MY_PROFILE_PUBLIC_ENTITY_REQUEST,
        UsersPartitionConstants.GET_MY_PROFILE_PUBLIC_ENTITY_SUCCESS,
        UsersPartitionConstants.GET_MY_PROFILE_PUBLIC_ENTITY_FAILURE,
      ],
      {},
      {
        partition: partitionNamesConfig.partition3,
        onSuccess: resolve,
        onFailure: reject,
        doNotCheckError: true,
      },
    ),
  );
});

export const getActorPublicEntityAndGetPublicSettings = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getMyPublicEntity())
    .then((res) => {
      dispatch(getPublicSettings(propOr('', 'uuid', res)))
        .then((response) => resolve(response));
    });
});

export const getActorPublicEntity = (publicActorUUID) => {
  const config = {
    data: {
      entity_type: 'user',
      entity_uuids: [publicActorUUID],
    },
    options: { partition: partitionNamesConfig.partition3 },
    constants: [
      UsersPartitionConstants.GET_OTHER_USER_PROFILE_PUBLIC_ENTITY_REQUEST,
      UsersPartitionConstants.GET_OTHER_USER_PROFILE_PUBLIC_ENTITY_SUCCESS,
      UsersPartitionConstants.GET_OTHER_USER_PROFILE_PUBLIC_ENTITY_FAILURE,
    ],
  };
  return (dispatch) => dispatch(entityRead(config));
};
